import { DESKTOP_THRESHOLD } from "../../base";

const CATALOG_COMMON = {
  'app.catalog.common.template': {
    config: {
      as: 'main',
      id: 'main',
    },
    schema: {
      default: {
        padding: '1.2vw 0',
        background: 'var(--primary-background-color)',

        '@media (max-width: 992px)': {
          padding: 'inherit',
        },
      },
    },
  },
  'app.catalog.common.frame': {
    schema: {
      default: {
        backgroundColor: 'white',
        margin: '1.2vw',
        padding: '1.2vw',

        '@media (max-width: 992px)': {
          padding: '10px',
          margin: '16px',
        },
      },
    },
  },
  'app.catalog.common.pageDescription': {
    schema: {
      default: {
        maxWidth: 'var(--max-width-page)',
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        'align-content': 'center',
        'justify-items': 'center',
      },
    },
  },
  'app.catalog.common.heading.backButton': {
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/navigateTo',
          payload: {
            redirectsToSavedState: true,
          },
        },
      ],
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.catalog.common.heading.backButton.iconText.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        position: 'absolute',
        top: '45%',
        width: 'auto',

        '@media (max-width: 992px)': {
          position: 'inherit',
          top: '10px',
        },
      },
    },
  },
  'app.catalog.common.heading.backButton.iconText.translatedText': {
    config: {
      i18n: {
        id: 'i18n.output.commonets.edit.header.backButton.iconText.translatedText',
        fallback: '< BACK',
      },
    },
    schema: {
      default: {
        color: 'var(--color-info)',
        'font-size': '1rem',
        'font-weight': 400,
        'margin-left': '0.5em',
      },
    },
  },
  'app.catalog.common.heading.title': {
    _extends: 'app.common.heading',
    schema: {
      default: {
        maxWidth: '100%',

        '@media (max-width: 992px)': {
          marginBottom: '26px',
          'font-size': '22px',
        },
      },
    },
  },
  'app.catalog.common.description': {
    _extends: 'app.common.description',
    schema: {
      default: {
        maxWidth: '100%',
        textAlign: 'center',
      },
    },
  },
  'app.catalog.common.banner.sort': {
    _extends: 'app.output.common.select.field',
    components: {
      description: null,
    },
    schema: {
      default: {
        minWidth: '200px',
        'grid-row-gap': 0,
      },
    },
  },
  'app.catalog.common.leftBar': {
    schema: {
      default: {
        marginRight: '20px',
      },
    },
  },
  'app.catalog.common.content': {
    schema: {
      default: {
        display: 'grid',
        'align-self': 'flex-start',
        'grid-row-gap': '32px',
        'max-width': 'var(--max-width-page)',

        margin: '40px auto',
        width: '100%',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': '0.23fr 0.77fr',
        },
      },
    },
  },
  'app.catalog.common.banner': {
    schema: {
      default: {
        display: 'flex',
        'justify-content': 'space-between',
        'grid-column': '2',
      },
    },
  },
  'app.catalog.common.banner.right': {
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'justify-content': 'flex-end',
        'grid-column-gap': '12px',
      },
    },
  },
  'app.catalog.common.banner.left': {
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',

        '@media (max-width: 992px)': {
          'grid-column-gap': '16px',
        },

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'justify-content': 'flex-end',
          'grid-column-gap': '12px',
        },
      },
    },
  },
  'app.catalog.common.filters': {
    config: {
      as: 'aside',
    },
    schema: {
      default: {
        display: 'none',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'grid',
        },
        'grid-column-gap': '0px',
        'grid-row-gap': 'var(--gap-row-filters)',
        'align-self': 'flex-start',
      },
    },
  },
  'app.catalog.common.filters.header': {
    config: {
      i18n: {
        id: 'i18n.app.catalog.common.filters.header',
        fallback: 'Filters',
      },
      element: 'h2',
    },
    schema: {
      default: {
        fontWeight: 'var(--item-font-weight-value)',
        fontSize: 'var(--font-size)',

        '@media (max-width: 992px)': {
          fontSize: '16px',
        },
      },
    },
  },
  'app.catalog.common.list': {
    components: {
      pagination: 'app.catalog.common.list.pagination',
    },
    schema: {
      default: {
        display: 'grid',
        justifyContent: 'space-between',

        '@media (max-width: 992px)': {
          gridTemplateColumns: 'repeat(2, 1fr)',
          'grid-column-gap': '16px',
          'grid-row-gap': '32px',
        },

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '15px',
        },
      },
    },
  },
  'app.catalog.common.list.item': {
    schema: {
      skeleton: {
        'border-color': '#d3d3d3',
      },
    },
  },
  'app.catalog.common.list.pagination': {
    components: {
      translatedText: 'app.catalog.common.list.pagination.translatedText',
      next: 'app.catalog.common.list.pagination.next',
      previous: 'app.catalog.common.list.pagination.previous',
    },
    schema: {
      default: {
        'grid-column': '1 / -1',
        display: 'flex',
        'justify-content': 'flex-end',
        'align-items': 'center',
      },
    },
  },
  'app.catalog.common.list.pagination.translatedText': {
    config: {
      i18n: {
        id: 'i18n.catalog.list.pagination.translatedText',
        fallback: '{{page}} of {{total}}',
      },
    },
    schema: {
      default: {
        color: 'var(--second-color)',
      },
    },
  },
  'app.catalog.common.list.pagination.next': {
    config: {
      src: 'caret-down',
      title: 'Next',
      color: 'var(--second-color)',
      size: 'sm',
      role: 'button',
    },
    schema: {
      default: {
        transform: 'rotate(-0.25turn)',
      },
      disabled: {
        opacity: 0.5,
        'pointer-events': 'none',
        cursor: 'default',
      },
    },
  },
  'app.catalog.common.list.pagination.previous': {
    config: {
      src: 'caret-down',
      title: 'Previous',
      color: 'var(--second-color)',
      size: 'sm',
      role: 'button',
    },
    schema: {
      default: {
        transform: 'rotate(0.25turn)',
        'margin-left': '20px',
        'margin-right': '30px',
      },
      disabled: {
        opacity: 0.5,
        'pointer-events': 'none',
        cursor: 'default',
      },
    },
  },
  'app.catalog.common.field.range': {
    _extends: 'app.common.field',
    config: {
      validations: null,
    },
  },
  'app.catalog.common.rangeInput': {
    config: {
      component: 'FormInput',
      type: 'range',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr',
        'grid-row-gap': '10px',
        'align-items': 'center',
        width: '100%',
        height: '100%',
        'margin-bottom': '16px',
      },
    },
  },
  'app.catalog.common.rangeInput.input': {
    schema: {
      default: {
        height: '2px',
        background: 'black',
        '-webkit-appearance': 'none',
        width: '100%',
        '&::-webkit-slider-runnable-track': {
          '-webkit-appearance': 'none',
          'box-shadow': 'none',
          border: 'none',
          background: 'transparent',
        },
        '&::-webkit-slider-thumb': {
          '-webkit-appearance': 'none',
          height: '20px',
          width: '20px',
          'border-radius': '50%',
          border: '7px solid var(--primary-color)',
          background: '#ffffff',
          cursor: 'ew-resize',
          'box-shadow': '0 0 2px 0 #555',
          transition: 'background 0.3s ease-in-out',
        },
      },
    },
  },
  'app.catalog.common.rangeInput.text': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        'text-align': 'center',
        width: '100%',
        'font-family': 'Arial',
        'font-style': 'normal',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '14px',
      },
    },
  },
  'app.catalog.common.botton': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
        ],
      ],
    },
    schema: {
      default: {
        margin: '0 16px',
      },
    },
  },
  'app.catalog.common.select.field': {
    _extends: 'app.output.common.select.field',
    components: {
      description: null,
    },
  },
  'app.catalog.common.checkbox.field': {
    _extends: 'app.common.field',
    components: {
      label: null,
      tooltip: null,
      description: null,
    },
    schema: {
      default: {
        'grid-template-areas': '"label" "input"',
      },
    },
  },

  //Empty State Configurations
  'app.catalog.common.emptyState': {
    schema: {
      default: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  'app.catalog.common.emptyState.iconsSection': {
    schema: {
      default: {
        position: 'relative',
        margin: '30px 0 40px',
      },
    },
  },
  'app.catalog.common.emptyState.magnifier': {
    config: {
      component: 'Icon',
      src: 'search-catalog',
      color: 'var(--second-color)',
    },
    schema: {
      default: {
        border: '1px solid red',
        height: '155px',
        width: '155px',
        display: 'block',
      },
    },
  },
  'app.catalog.common.emptyState.serviceIcon': {
    config: {
      component: 'Icon',
      color: 'var(--second-color)',
    },
    schema: {
      default: {
        height: '48px',
        width: '48px',
        display: 'block',
        position: 'absolute',
        top: '35px',
        left: '35px',
      },
    },
  },
  'app.catalog.common.emptyState.resetButton': {
    config: {
      component: 'Button',
      content: [
        ['TranslatedText', 'app.catalog.common.emptyState.resetButton.text'],
      ],
    },
    schema: {
      default: {
        color: 'var(--color-info)',
        display: 'inline',
        width: 'auto',
        height: 'auto',
        padding: '0',
        marginLeft: '4px',
        textDecoration: 'underline',
        fontWeight: 'bold',
      },
    },
  },
  'app.catalog.common.emptyState.actionAndLegend': {
    schema: {
      default: {
        fontSize: 'var(--label-font-size)',
      },
    },
  },
  'app.catalog.common.emptyState.resetButton.text': {
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.button.resetFilters.translatedText',
        fallback: 'Reset Filters',
      },
    },
    schema: {
      default: {
        'text-align': 'center',
        width: '100%',
        color: 'var(--button-text-color)',
        display: 'inline',
        '@media (max-width: 992px)': {
          'font-size': '12px',
        },
      },
    },
  },
  'app.catalog.common.mobile.button.filters.button': {
    config: {
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.catalog.common.mobile.button.filters.button.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        'align-self': 'center',
        'text-align': 'center',
        'grid-column': '1/-1',
        'border-radius': '3px',
        height: '47px',
        backgroundColor: 'var(--primary-color)',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.catalog.common.mobile.button.filters.button.translatedText': {
    config: {
      i18n: {
        id: 'i18n.catalog.common.filters.mobile.button.translatedText',
        fallback: 'FILTERS',
      },
    },
    schema: {
      default: {
        'text-align': 'center',
        width: '100%',
        color: 'var(--text-primary-inverted-color)',
        'font-size': 'var(--font-size)',
        'font-weight': 'var(--item-font-weight-value)',
      },
    },
  },
  'app.catalog.common.mobile.button.allVehicles.translatedText': {
    config: {
      i18n: {
        id: 'i18n.catalog.common.mobile.button.allVehicles.translatedText',
        fallback: '< ALL VEHICLES',
      },
    },
    schema: {
      default: {
        'text-align': 'left',
        color: 'var(--color-info)',
        'font-size': 'var(--font-size)',
        'font-weight': 'var(--item-font-weight-value)',
      },
    },
  },
};

export default CATALOG_COMMON;
