// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import { FilterConfig } from './vehicles/vehicles.types';
import zappy from "@zappy-ride/library.react.components";

export function replaceValuesOfMatchingProps(objectToReplace, source) {
  return Object.keys(objectToReplace || {}).reduce((acc, key) => {
    if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
      return {
        ...acc,
        [key]: replaceValuesOfMatchingProps(objectToReplace[key], source[key]),
      };
    }
    return { ...acc, [key]: source[key] };
  }, {});
}

export function getClientApiConfigUrl(
  { location },
  { ADMIN_API_URL, ADMIN_DOMAIN }
) {
  let { hostname: clientName } = location;

  if (!ADMIN_API_URL) return;

  if (ADMIN_DOMAIN && clientName.includes(`.${ADMIN_DOMAIN}`)) {
    clientName = clientName.replace(`.${ADMIN_DOMAIN}`, '');
  }

  return ADMIN_API_URL.replace('[clientName]', `${clientName}.json`);
}

export function prepareFilters(
  formData: AnyObject,
  { values, mapper, filtersQuery }: FilterConfig
) {
  const filters: Array<[string, string | number | boolean, string?]> = [];

  const qry = mapper.filter(
    ([_, __, ___, config]) => {
      const { displayQuery } = config || {};
      if (!displayQuery) return true;
      const query = get(filtersQuery, displayQuery, {});
      return zappy.runQuery(undefined, query, { form: formData });
    }
  );

  // static values
  values &&
    Object.keys(values).forEach((key) => {
      filters.push([key, values[key]]);
    });

  // formData values
  qry.forEach(
    ([apiObjAttrName, formDataAttrName, operator, customValues]) => {
      if (Array.isArray(formDataAttrName)) {
        const values = formDataAttrName
          .map((arrAttrName) =>
            parseFormDataIfNeeded(get(formData, arrAttrName), customValues)
              ? arrAttrName.split('.').pop()
              : null
          )
          .filter(Boolean);
        filters.push([apiObjAttrName, values, operator]);
        return;
      }

      const value = parseFormDataIfNeeded(
        get(formData, formDataAttrName),
        customValues
      );
      filters.push([apiObjAttrName, value, operator]);
    }
  );

  return filters;
}

export const operators = {
  $eq: isEqual,
  $gt: (v1: number | string, v2: number | string) => v1 > v2,
  $gte: (v1: number | string, v2: number | string) => v1 >= v2,
  $lt: (v1: number | string, v2: number | string) => v1 < v2,
  $lte: (v1: number | string, v2: number | string) => v1 <= v2,
  $inc: (v1: number | string, v2: Array<number | string>) => v2.includes(v1),
  // Truthy operator. Indicates that the compared value is truthy. Only filtering if v2 is truthy as well.
  $tty: (v1: number | string, v2: boolean) => (v2 ? Boolean(v1) : true),
};
export type FilterOperatorType = keyof typeof operators;
export function runFilterOperation(
  v1: any,
  v2: any,
  operator: FilterOperatorType = '$eq'
) {
  const operation = operators[operator];

  if (typeof v1 === 'string' && Array.isArray(v2)) {
    // If using array comparison, make sure the array keys are spaceless and lower-cased.
    return operation(v1.toLowerCase().replaceAll(' ', ''), v2);
  }

  if (typeof v1 === 'string' && typeof v2 === 'string') {
    return operation(v1.toLowerCase(), v2.toLowerCase());
  }

  return operation(v1, v2);
}

const matchFilters =
  (
    filters: Array<[string, any, keyof typeof operators]>,
    skipValues: Array<string> = []
  ) =>
  (element: AnyObject) =>
    !filters.find(([attr, value, operator = '$eq']) => {
      if (value === undefined || skipValues.includes(value)) return false;
      return !runFilterOperation(element[attr], value, operator);
    });

export function filterArray(
  elements: Array<AnyObject>,
  filters: Array<[string, string | number | boolean, FilterOperatorType]>,
  skipValues: Array<string|number> = []
) {
  if (filters.length === 0) return elements;

  return elements.filter(matchFilters(filters, skipValues));
}

function parseFormDataIfNeeded(
  value: any,
  customValues: { [key: string]: string | number | boolean } = {}
) {
  const customValue = customValues[`${value}`];
  if (customValue !== undefined) return customValue;

  if (typeof value === 'number' || typeof value === 'boolean') return value;
  if (['false', 'true'].includes(value)) return Boolean(value);
  if (!isNaN(value)) return Number(value);
  if (Array.isArray(value))
    return value.map((val) =>
      typeof val === 'string' ? val.toLowerCase().replace(/\s/g, '') : val
    );
  return value;
}

export function sortArray<T>(elements: Array<T>, sort: string) {
  const [attrName, orderString] = sort.split(':') as [keyof T, string];
  const order = Number(orderString);

  return elements.sort((first, second) => {
    let valor1 = first[attrName] || '';
    let valor2 = second[attrName] || '';

    if (typeof valor1 === 'string' && typeof valor2 === 'string') {
      valor1 = valor1.toLowerCase();
      valor2 = valor2.toLowerCase();
    }

    if (valor1 > valor2) return order;
    if (valor1 < valor2) return -order;
    return 0;
  });
}

export function cleanupNumber( value: string | number ) {
  return Number((value || '').toString().replace(/,/g, ''));
}