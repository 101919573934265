// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Suspense } from 'react';
import zappy from '@zappy-ride/library.react.components';
import EvaluationsIncentives from './Incentives.Evaluations';
import CommercialIncentives from './Incentives.Commercial';

export const OutputIncentives = () => {
  const { 'assumptionsData.incentivesType': incentivesType } = zappy.useWatch({
    name: 'assumptionsData.incentivesType',
  });
  let Component = EvaluationsIncentives;

  if (incentivesType === 'commercial') Component = CommercialIncentives;

  return (
    <Suspense fallback={<>Loading...</>}>
      <Component />
    </Suspense>
  );
};

OutputIncentives.displayName = 'OutputIncentives';
