// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { App } from './App';

import './components';
import './presenters';
import LottieAnimation from './components/LottieAnimation';
import reportWebVitals from './reportWebVitals';

import { ASSETS_BASE_URL } from './config';

const fallbackLoadingSpinner = `${ASSETS_BASE_URL}/img/spinner/unbranded.fleets.d.zappyride.com.json`;

const loadingSpinner = `${ASSETS_BASE_URL}/img/spinner/${window.location.hostname}.json`;

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const spinnner = ReactDOM.createRoot(
  document.getElementById('spinner') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

spinnner.render(
  <React.StrictMode>
    <LottieAnimation uri={loadingSpinner} fallback={fallbackLoadingSpinner} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
