// @ts-nocheck
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { useFormContext } from 'react-hook-form';

export const financials = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const {
    vehicleSets,
    assumptionsData,
    constants,
  } = getValues();

  const componentData: any = {
    lifespanYears:  assumptionsData.lifespanYears,
    sets: vehicleSets.map(
      (set: any) => ({
        id: set.id,
        count: set.vehicleCount,
        name: set.vehicleSetName,
        prices: {
          electric: set.input.kind === 'ev' ? set.input.msrp : set.output.msrp,
          fossil: set.input.kind === 'ev' ? set.output.msrp : set.input.msrp
        },
        finance: {
          ...constants.finance,
          ...set.finance,
        },
        financeResult: set.financeResult
          ? set.financeResult
          : undefined
      })),
  }

  return {
    status: {
      isLoading: componentData.sets.length === 0,
      missingRequiredInformation: !(calculationsStatus && inputsStatus),
    },
    data: {
      componentData,
    },
  };
};
