import React, { useCallback, useContext, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { AnalyticsEvent, GA4Config } from './AnalyticsTracker.types';

const AnalyticsContext = React.createContext([] as any[]);

export const AnalyticsProvider = ({
  config,
  children,
  userId,
}: {
  config: GA4Config[];
  children: JSX.Element;
  userId: string;
}) => {
  const location = useLocation();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) return;

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  useEffect(() => {
    if (initialized.current) return;

    if (config && config.length) {
      ReactGA.initialize(config, {
        gaOptions: {
          userId,
          debug: process.env.NODE_ENV !== 'production',
          name: 'User Session Id',
        },
      });
      initialized.current = true;
    }
  }, [config]);

  const sendAnalyticsEvent = useCallback(
    (config: AnalyticsEvent, options?: AnyObject) => {
      if (!initialized.current) return;

      if (options && Object.keys(options).length > 0) {
        ReactGA.set({
          ...options,
        });
      }

      ReactGA.event(config);
    },
    []
  );

  return (
    <AnalyticsContext.Provider value={[sendAnalyticsEvent]}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsTracker = () => useContext(AnalyticsContext);
