// @ts-nocheck
import { useContext } from 'react';
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

export const useVehiclePreloads = (_preloads) => {
  const { preloads } = useContext(zappy.PreloadsContext);
  const vehiclesList = get(preloads, _preloads?.vehicles, []);
  const genericList = get(preloads, _preloads?.generic, []);
  const mappingsList = get(preloads, _preloads?.mapping, []);
  const areListsEmpty = isEmpty(vehiclesList) || isEmpty(genericList) || isEmpty(mappingsList);
  return {
    vehiclesList,
    genericList,
    mappingsList,
    areListsEmpty
  }
}
