import { DESKTOP_THRESHOLD } from '../../base';

const CHARGERS_CATALOG = {
  '##### CHARGERS CATALOG #####': '',
  'app.catalog.chargers': {
    _extends: 'app.catalog.common.template',
    config: {
      basePath: '/catalog/chargers',
      content: [['ContentContainer', 'app.catalog.chargers.frame']],
    },
  },
  'app.catalog.chargers.frame': {
    _extends: 'app.page.common.schema',
    config: {
      as: 'div',
      content: [
        ['ContentContainer', 'app.catalog.chargers.pageDescription'],
        ['ContentContainer', 'app.catalog.chargers.content'],
      ],
    },
    schema: {
      default: {
        padding: '0 1em',

        '@media (max-width: 992px)': {
          margin: '16px',
        },

        '&.display-filters': {
          '@media (max-width: 992px)': {
            '[data-design="app.catalog.chargers.leftBar"]': {
              display: 'block',
              marginRight: 0,
            },
            '[data-design="app.catalog.chargers.requirements"]': {
              position: 'inherit',
            },
            '[data-design="app.catalog.chargers.filters"]': {
              display: 'grid',
            },
            '[data-design="app.catalog.chargers.content"]': {
              marginTop: 0,
              'grid-row-gap': '12px',
            },
            '[data-design="app.catalog.chargers.mobile.button.allVehicles.action"]':
              {
                display: 'block',
              },
            '[data-design="app.catalog.chargers.banner"]': {
              display: 'none',
            },
            '[data-design="app.catalog.common.mobile.button.filters.button"]': {
              display: 'none',
            },
            '[data-design="app.catalog.chargers.pageDescription"]': {
              display: 'none',
            },
            '[data-design="app.catalog.chargers.list"]': {
              display: 'none',
              '+ div': {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
  'app.catalog.chargers.pageDescription': {
    _extends: 'app.catalog.common.pageDescription',
    config: {
      content: [
        ['ActionComponent', 'app.catalog.common.heading.backButton'],
        [
          'TranslatedText',
          'app.catalog.common.heading.title',
          {
            i18n: {
              id: 'i18n.catalog.chargers.heading.title',
              fallback: 'Select A Charger That Meets Your Needs',
            },
          },
        ],
        [
          'Formatter',
          'app.catalog.common.description',
          {
            watch: {
              name: ['vehicleSetName'],
            },
            as: 'p',
            pick: 'vehicleSetName',
            text: 'Select an available electric vehicle that meets your needs for {{picked}}',
            formatter: 'interpolate',
          },
        ],
      ],
    },
  },
  'app.catalog.chargers.content': {
    _extends: ['app.catalog.common.content'],
    config: {
      content: [
        ['ContentContainer', 'app.catalog.chargers.banner'],
        ['ActionComponent', 'app.catalog.chargers.mobile.button.filters'],
        ['ActionComponent', 'app.catalog.chargers.mobile.button.allVehicles'],
        ['CurrentVehicleSetContentContainer', 'app.catalog.chargers.leftBar'],
        ['ChargersCatalog', 'app.catalog.chargers.list'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'flex',
          'flex-direction': 'column',
        },
      },
    },
  },
  'app.catalog.chargers.mobile.button.filters': {
    components: {
      button: 'app.catalog.common.mobile.button.filters.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'catalog/showChargerFilters',
        },
      ],
      component: 'SmartNextButton',
    },
  },
  'app.catalog.chargers.mobile.button.allVehicles': {
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'catalog/showChargerFilters',
        },
      ],
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.catalog.common.mobile.button.allVehicles.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        textAlign: 'left',
        padding: '12px 0',
        display: 'none',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.catalog.chargers.banner': {
    _extends: ['app.catalog.common.banner', 'app.common.device.selector'],
    config: {
      content: [
        [
          'ContentContainer',
          'app.catalog.chargers.banner.right',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'ContentContainer',
          'app.catalog.chargers.banner.mobile',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
    },
    schema: {
      default: {
        'justify-content': 'end',
        'align-self': 'flex-end',
        'flex-direction': 'column',

        '@media (max-width: 992px)': {
          'justify-content': 'inherit',
          'align-self': 'inherit',
          'flex-direction': 'inherit',
          width: '100%',
        },
      },
    },
  },
  'app.catalog.chargers.banner.mobile': {
    config: {
      content: [
        ['FormField', 'app.catalog.chargers.banner.sort'],
        ['Formatter', 'app.catalog.chargers.banner.elementsCount'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        'flex-direction': 'column',
        'row-gap': '16px',
      },
    },
  },
  'app.catalog.chargers.banner.right': {
    _extends: 'app.catalog.common.banner.right',
    config: {
      content: [
        ['Formatter', 'app.catalog.chargers.banner.elementsCount'],
        ['FormField', 'app.catalog.chargers.banner.sort'],
      ],
    },
  },
  'app.catalog.chargers.banner.elementsCount': {
    config: {
      as: 'p',
      mapper: {
        'catalogs.filteredListSize': 'filteredListSize',
        'catalogs.allListSize': 'allListSize',
      },
      watch: {
        name: ['catalogs.filteredListSize', 'catalogs.allListSize'],
      },
      text: 'Showing {{filteredListSize}} of {{allListSize}} Chargers',
      formatter: 'interpolate',
    },
    schema: {
      default: {
        'font-size': 'var(--label-font-size)',
        'align-self': 'flex-end',
        color: 'var(--second-color)',

        '@media (max-width: 992px)': {
          'align-self': 'flex-start',
        },
      },
    },
  },
  'app.catalog.chargers.banner.sort': {
    _extends: 'app.catalog.common.banner.sort',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.sort',
        color: 'var(--second-color)',
        i18n: {
          id: 'i18n.catalog.banner.sort.label',
          fallback: 'Sort By',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          'price:1': {
            value: 'price:1',
            i18n: {
              id: 'i18n.sort.price.1',
              fallback: 'Estimated Price (increasing)',
            },
          },
          'price:-1': {
            value: 'price:-1',
            i18n: {
              id: 'i18n.sort.price.-1',
              fallback: 'Estimated Price (decreasing)',
            },
          },
          'portKw:1': {
            value: 'portKw:1',
            i18n: {
              id: 'i18n.sort.portKw.1',
              fallback: 'kW (increasing)',
            },
          },
          'portKw:-1': {
            value: 'portKw:-1',
            i18n: {
              id: 'i18n.sort.portKw.-1',
              fallback: 'kW (decreasing)',
            },
          },
        },
        name: 'catalogs.chargers.sort',
        placeholder: {
          i18n: {
            id: 'i18n.sort.placeholder',
            fallback: 'Select Sort',
          },
        },
      },
    },
  },
  'app.catalog.chargers.leftBar': {
    _extends: 'app.catalog.common.leftBar',
    config: {
      content: [
        ['ContentContainer', 'app.catalog.chargers.requirements'],
        ['ContentContainer', 'app.catalog.chargers.filters'],
      ],
    },
    schema: {
      default: {
        display: 'none',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'block',
        },
      },
    },
  },
  'app.catalog.chargers.requirements': {
    config: {
      content: [
        ['TranslatedText', 'app.catalog.chargers.requirements.title'],
        ['ContentContainer', 'app.catalog.chargers.requirements.table'],
      ],
    },
    schema: {
      default: {
        border: '1px solid var(--border-color)',
        padding: '6px',
        borderRadius: '5px',
        position: 'sticky',
        top: '6px',
        background: '#FFF',
        zIndex: 999,
      },
    },
  },
  'app.catalog.chargers.requirements.title': {
    config: {
      i18n: {
        id: 'i18n.catalog.chargers.requirements.title',
        fallback: 'Charger Requirements',
      },
    },
    schema: {
      default: {
        backgroundColor: '#EAECEE',
        borderRadius: '5px',
        fontWeight: 'bold',
        padding: '10px',
        fontSize: '14px',
        display: 'block',
      },
    },
  },
  'app.catalog.chargers.requirements.table': {
    config: {
      content: [
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.vehicle',
                    fallback: 'Vehicle',
                  },
                },
              ],
              ['Formatter', 'app.catalog.chargers.requirements.vehicle'],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.vehicleCount',
                    fallback: 'Number of vehicles',
                  },
                },
              ],
              ['Formatter', 'app.catalog.chargers.requirements.vehicleCount'],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.vehicleCount',
                    fallback: 'Battery Capacity',
                  },
                },
              ],
              [
                'Formatter',
                'app.catalog.chargers.requirements.batteryCapacity',
              ],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.milesPerDay',
                    fallback: 'Miles Per Day',
                  },
                },
              ],
              ['Formatter', 'app.catalog.chargers.requirements.milesPerDay'],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.efficiency',
                    fallback: 'Efficiency',
                  },
                },
              ],
              ['Formatter', 'app.catalog.chargers.requirements.efficiency'],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.chargingWindow',
                    fallback: 'Charging Window',
                  },
                },
              ],
              [
                'ChargingWindowFormatter',
                'app.catalog.chargers.requirements.chargingWindow',
              ],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.chargingDuration',
                    fallback: 'Charging Duration',
                  },
                },
              ],
              [
                'ChargingWindowFormatter',
                'app.catalog.chargers.requirements.chargingDuration',
              ],
            ],
          },
        ],
        [
          'ContentContainer',
          '',
          {
            content: [
              [
                'TranslatedText',
                '',
                {
                  i18n: {
                    id: 'i18n.catalog.chargers.requirements.requiredOutput',
                    fallback: 'Required Output',
                  },
                },
              ],
              ['Formatter', 'app.catalog.chargers.requirements.requiredOutput'],
            ],
          },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        flexDirection: 'column',
        padding: '3px',
        marginTop: '3px',
        maxWidth: '228px',
        '& div': {
          display: 'flex',
        },
        '& div > span': {
          fontSize: 'var(--label-font-size)',
        },
        '& div > span:nth-child(2)': {
          textAlign: 'right',
          fontWeight: 'bold',
          flex: 1,
        },

        '@media (max-width: 992px)': {
          maxWidth: 'inherit',
        },
      },
    },
  },
  'app.catalog.chargers.requirements.baseFormatter': {
    _extends: 'app.common.skeleton',
    config: {
      as: 'span',
      formatter: 'interpolate',
      text: '{{picked}}',
    },
  },
  'app.catalog.chargers.requirements.vehicle': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      pick: 'input.name',
    },
  },
  'app.catalog.chargers.requirements.vehicleCount': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      pick: 'vehicleCount',
    },
  },
  'app.catalog.chargers.requirements.batteryCapacity': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      pick: 'input.battery_capacity',
      text: '{{picked}} kWh',
    },
  },
  'app.catalog.chargers.requirements.milesPerDay': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      pick: 'avgDistancePerDay',
      text: '{{picked}} mi',
    },
  },
  'app.catalog.chargers.requirements.efficiency': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      partials: [
        {
          pick: 'input.electric_efficiency',
          format: {
            formatter: 'formatAsNumber',
            maximumFractionDigits: 1,
            as: 'electric_efficiency',
          },
        },
      ],
      pick: 'electric_efficiency',
      text: '{{picked}} kWh/100 mi',
    },
    schema: {
      default: {
        whiteSpace: 'nowrap',
      },
    },
  },
  'app.catalog.chargers.requirements.chargingWindow': {
    config: {
      multipleWindowsText: {
        i18n: {
          id: 'i18n.catalog.chargers.requirements.chargingWindow.multiple',
          fallback: 'Multiple time slots',
        },
      },
    },
  },
  'app.catalog.chargers.requirements.chargingDuration': {
    _extends: 'app.catalog.chargers.requirements.chargingWindow',
    config: {
      format: 'duration',
    },
  },
  'app.catalog.chargers.requirements.requiredOutput': {
    _extends: 'app.catalog.chargers.requirements.baseFormatter',
    config: {
      pick: 'input.ac_charging_power',
      text: '{{picked}} kW',
    },
  },

  // Chargers Empty State
  'app.catalog.emptyState.chargers': {
    _extends: 'app.catalog.common.emptyState',
    config: {
      graphics: 'app.catalog.emptyState.chargers.icon',
      actionAndLegend: 'app.catalog.emptyState.chargers.actionAndLegend',
    },
    schema: {
      default: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  'app.catalog.emptyState.chargers.icon': {
    _extends: 'app.catalog.common.emptyState.iconsSection',
    config: {
      content: [
        ['Icon', 'app.catalog.common.emptyState.magnifier'],
        [
          'Icon',
          'app.catalog.common.emptyState.serviceIcon',
          { src: 'electrical-services' },
        ],
      ],
    },
  },
  'app.catalog.emptyState.chargers.actionAndLegend': {
    config: {
      content: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.catalog.emptyState.chargers.actionAndLegend',
              fallback: 'No chargers meet the criteria. Remove criteria or',
            },
          },
        ],
        [
          'Button',
          'app.catalog.common.emptyState.resetButton',
          { action: 'resetFiltersFn' },
        ],
      ],
    },
  },

  'app.catalog.chargers.list': {
    _extends: 'app.catalog.common.list',
    components: {
      listItem: 'app.catalog.chargers.list.item',
    },
    config: {
      preload: 'unified_chargers',
      keyAttribute: 'id',
      redirectToIfNoHistory: '/onboarding',
      limit: 9,
      filters: {
        mapper: [
          ['customer_approved', 'chargers.rebate'],
          ['portKw', 'chargers.minimumOutput', '$gte'],
          ['price', 'chargers.maximumPrice', '$lte'],
          ['ports', 'chargers.ports'],
          ['make', 'chargers.make', '$inc'],
          ['charger_type', 'chargers.charger_type', '$inc'],
          [
            'form_factor',
            [
              'chargers.wallmounted',
              'chargers.pedestal',
              'chargers.overhead',
              'chargers.portable',
            ],
            '$inc',
          ],
          ['networked', 'chargers.networked', '$tty'],
          ['vgi_capable', 'chargers.vehicleToGrid', '$tty'],
          ['ocpp_version', 'chargers.OCPP', '$tty'],
          ['oscp_version', 'chargers.OSCP', '$tty'],
        ],
      },
      emptyState: 'app.catalog.emptyState.chargers',
    },
    schema: {
      default: {
        'grid-column-gap': '15px',

        '@media (max-width: 992px)': {
          display: 'flex',
          'flex-direction': 'column',
        },
      },
    },
  },
  'app.catalog.chargers.list.item': {
    _extends: 'app.catalog.common.list.item',
    config: {
      content: [['Table', 'app.catalog.chargers.list.item.table']],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          maxHeight: 'inherit',
        },
      },
    },
  },
  'app.catalog.chargers.list.item.table': {
    _extends: 'app.output.template.chargerTable.chargerSpecs',
    components: {
      wrapper: 'app.catalog.chargers.list.item.table.wrapper',
    },
    config: {
      footerRows: [['app.catalog.chargers.list.item.table.footer']],
    },
    schema: {
      default: {
        height: '100%',

        '@media (max-width: 992px)': {
          '& tbody > tr:last-child': {
            'border-bottom': '1px solid #acb1ba',
          },
        },
      },
    },
  },
  'app.catalog.chargers.list.item.table.wrapper': {
    _extends: 'app.output.template.tables.wrapper',
    schema: {
      default: {
        minWidth: '230px',
        height: '100%',

        '@media (max-width: 992px)': {
          minWidth: 'inherit',
          padding: '8px',
        },
      },
    },
  },
  'app.catalog.chargers.list.item.table.footer': {
    config: {
      cells: [
        [
          'ContentContainer',
          'app.catalog.chargers.list.item.table.footer.container',
          {},
          {
            colSpan: 2,
            schema: { default: { verticalAlign: 'bottom' } },
          },
        ],
      ],
    },
  },
  'app.catalog.chargers.list.item.table.footer.container': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.catalog.chargers.list.item.table.footer.selected',
          {
            displayQuery: 'isSelected',
          },
        ],
        [
          'ActionComponent',
          'app.catalog.chargers.list.item.table.footer.change',
          {
            displayQuery: 'isNotSelected',
          },
        ],
      ],
      watch: {
        name: ['catalogs.referenceId'],
      },
      displayQueries: {
        isSelected: {
          '["props", "globalData[id]"]': {
            $eq: '["watched", "catalogs.referenceId"]',
          },
        },
        isNotSelected: {
          '["props", "globalData[id]"]': {
            $neq: '["watched", "catalogs.referenceId"]',
          },
        },
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          marginTop: '8px',
        },
      },
      skeleton: {
        display: 'none',
      },
    },
  },
  'app.catalog.chargers.list.item.table.footer.selected': {
    _extends: [
      'app.common.skeleton',
      'app.common.button.primary',
      'app.common.button.small',
    ],
    config: {
      i18n: {
        id: 'i18n.catalog.chargers.list.item.table.footer.selected',
        fallback: 'SELECTED CHARGER',
      },
    },
  },
  'app.catalog.chargers.list.item.table.footer.change': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/updateCharger',
        },
        {
          type: 'form/navigateTo',
          payload: {
            redirectsToSavedState: true,
          },
        },
      ],
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.changeButton.button.translatedText',
        ],
      ],
    },
  },
  'app.catalog.chargers.filters': {
    _extends: 'app.catalog.common.filters',
    config: {
      content: [
        ['TranslatedText', 'app.catalog.common.filters.header'],
        ['FormField', 'app.catalog.chargers.filters.rebate'],
        ['FormField', 'app.catalog.chargers.filters.make'],
        ['FormField', 'app.catalog.chargers.filters.type'],
        ['FormField', 'app.catalog.chargers.filters.minimumOutput'],
        ['FormField', 'app.catalog.chargers.filters.maximumPrice'],
        ['FormField', 'app.catalog.chargers.filters.ports'],
        ['TranslatedText', 'app.catalog.chargers.filters.typeSection'],
        // ['FormField', 'app.catalog.chargers.filters.DCFC'],
        ['FormField', 'app.catalog.chargers.filters.wallMounted'],
        ['FormField', 'app.catalog.chargers.filters.pedestal'],
        [
          'FormField',
          'app.catalog.chargers.filters.overhead',
          { displayQuery: 'isOverheadCapable' },
        ],
        ['FormField', 'app.catalog.chargers.filters.portable'],
        ['TranslatedText', 'app.catalog.chargers.filters.featuresSection'],
        ['FormField', 'app.catalog.chargers.filters.networked'],
        ['FormField', 'app.catalog.chargers.filters.vehicleToGrid'],
        ['FormField', 'app.catalog.chargers.filters.OCPP'],
        ['FormField', 'app.catalog.chargers.filters.OSCP'],
      ],
      displayQueries: {
        isOverheadCapable: {
          'props.input.is_overhead_charging_capable': {
            $eq: true,
          },
        },
      },
    },
    schema: {
      default: {
        marginTop: '1em',
      },
    },
  },
  'app.catalog.chargers.filters.rebate': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.rebate.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.rebate',
        type: 'checkbox',
      },
      validations: null,
    },
    schema: {
      default: {
        display: 'none',
      },
    },
  },
  'app.catalog.chargers.filters.rebate.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.catalog.chargers.filters.rebate.checkbox',
          fallback: 'Qualifies for Evergy rebate',
        },
      },
      name: 'catalogs.chargers.rebate',
    },
  },
  'app.catalog.chargers.filters.type': {
    _extends: 'app.catalog.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.charger_type',
        i18n: {
          id: 'i18n.app.catalog.chargers.filters.type',
        },
      },
      input: {
        component: 'FormSelect',
        name: 'catalogs.chargers.charger_type',
        options: {
          all: {
            value: 'all',
            i18n: {
              id: 'i18n.app.catalog.chargers.filters.type.all',
            },
          },
          level_1: {
            value: 'level_1',
            i18n: {
              id: 'i18n.app.catalog.chargers.filters.type.level1',
            },
          },
          level_2: {
            value: 'level_2',
            i18n: {
              id: 'i18n.app.catalog.chargers.filters.type.level2',
            },
          },
          dc_fast_charging: {
            value: 'dc_fast_charging',
            i18n: {
              id: 'i18n.app.catalog.chargers.filters.type.dcfc',
            },
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.minimumOutput': {
    _extends: 'app.catalog.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.minimumOutput',
        i18n: {
          id: 'i18n.catalog.chargers.filters.minimumOutput',
          fallback: 'Minimum Output',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          all: {
            value: 'all',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.all',
              fallback: 'All',
            },
          },
          1: {
            value: '1',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.1kw',
              fallback: '≥ 1 kW',
            },
          },
          10: {
            value: '10',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.10kw',
              fallback: '≥ 10 kW',
            },
          },
          20: {
            value: '20',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.20kw',
              fallback: '≥ 20 kW',
            },
          },
          50: {
            value: '50',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.50kw',
              fallback: '≥ 50 kW',
            },
          },
          100: {
            value: '100',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.100kw',
              fallback: '≥ 100 kW',
            },
          },
          150: {
            value: '150',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.150kw',
              fallback: '≥ 150 kW',
            },
          },
          200: {
            value: '200',
            i18n: {
              id: 'i18n.catalog.chargers.filters.minimumOutput.200kw',
              fallback: '≥ 200 kW',
            },
          },
        },
        name: 'catalogs.chargers.minimumOutput',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.category.name.placeholder',
            fallback: 'Select Vehicle Category',
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.maximumPrice': {
    _extends: 'app.catalog.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.maximumPrice',
        i18n: {
          id: 'i18n.catalog.chargers.filters.maximumPrice',
          fallback: 'Maximum Price',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          all: {
            value: 'all',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.all',
              fallback: 'All',
            },
          },
          5000: {
            value: '5000',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.5000',
              fallback: 'Up to $5,000',
            },
          },
          10000: {
            value: '10000',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.10000',
              fallback: 'Up to $10,000',
            },
          },
          20000: {
            value: '20000',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.20000',
              fallback: 'Up to $20,000',
            },
          },
          50000: {
            value: '50000',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.50000',
              fallback: 'Up to $50,000',
            },
          },
          100000: {
            value: '100000',
            i18n: {
              id: 'i18n.catalog.chargers.filters.maximumPrice.100000',
              fallback: 'Up to $100,000',
            },
          },
        },
        name: 'catalogs.chargers.maximumPrice',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.category.name.placeholder',
            fallback: 'Select Maximum Price',
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.ports': {
    _extends: 'app.catalog.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.ports',
        i18n: {
          id: 'i18n.catalog.chargers.filters.ports',
          fallback: 'Ports',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          all: {
            value: 'all',
            i18n: {
              id: 'i18n.catalog.chargers.filters.ports.all',
              fallback: 'All',
            },
          },
          1: {
            value: '1',
            i18n: {
              id: 'i18n.catalog.chargers.filters.ports.1',
              fallback: '1',
            },
          },
          2: {
            value: '2',
            i18n: {
              id: 'i18n.catalog.chargers.filters.ports.2',
              fallback: '2',
            },
          },
          4: {
            value: '4',
            i18n: {
              id: 'i18n.catalog.chargers.filters.ports.4',
              fallback: '4',
            },
          },
        },
        name: 'catalogs.chargers.ports',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.category.name.placeholder',
            fallback: 'Select Ports',
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.make': {
    _extends: 'app.output.common.multipleSelect.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.make',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.make.label',
          fallback: 'Make',
        },
      },
      input: {
        component: 'FormVehicleAttrSelector',
        preload: 'unified_chargers',
        preloadAttrs: {
          attrForValue: 'make',
        },
        sortingOrder: 'asc',
        skipValues: ['all'],
        name: 'catalogs.chargers.make',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.chargers.make.name.input.placeholder',
            fallback: 'Select Charger Make',
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.typeSection': {
    config: {
      i18n: {
        id: 'i18n.catalog.chargers.filters.typeSection',
        fallback: 'Type',
      },
    },
  },
  'app.catalog.chargers.filters.DCFC': {
    _extends: 'app.catalog.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.chargers.DCFC',
        i18n: {
          id: 'i18n.catalog.chargers.filters.DCFC',
          fallback: 'DCFC',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          Yes: {
            value: 'Yes',
            i18n: {
              id: 'i18n.catalog.chargers.filters.DCFC',
              fallback: 'Yes',
            },
          },
          No: {
            value: 'No',
            i18n: {
              id: 'i18n.catalog.chargers.filters.DCFC',
              fallback: 'No',
            },
          },
        },
        name: 'catalogs.chargers.DCFC',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.category.name.placeholder',
            fallback: 'DCFC',
          },
        },
      },
    },
  },
  'app.catalog.chargers.filters.wallMounted': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.wallMounted.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.wallmounted',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.wallMounted.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.wallMounted',
          fallback: 'Wall Mounted',
        },
      },
      name: 'catalogs.chargers.wallmounted',
    },
  },
  'app.catalog.chargers.filters.pedestal': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.pedestal.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.pedestal',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.pedestal.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.pedestal',
          fallback: 'Pedestal',
        },
      },
      name: 'catalogs.chargers.pedestal',
    },
  },
  'app.catalog.chargers.filters.overhead': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.overhead.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.overhead',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.overhead.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.overhead',
          fallback: 'Overhead',
        },
      },
      name: 'catalogs.chargers.overhead',
    },
  },
  'app.catalog.chargers.filters.portable': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.portable.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.portable',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.portable.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.portable',
          fallback: 'Portable',
        },
      },
      name: 'catalogs.chargers.portable',
    },
  },
  'app.catalog.chargers.filters.featuresSection': {
    config: {
      i18n: {
        id: 'i18n.catalog.chargers.filters.featuresSection',
        fallback: 'Features',
      },
    },
  },
  'app.catalog.chargers.filters.networked': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.networked.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.networked',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.networked.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.networked',
          fallback: 'Networked',
        },
      },
      name: 'catalogs.chargers.networked',
    },
  },
  'app.catalog.chargers.filters.vehicleToGrid': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.vehicleToGrid.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.vehicleToGrid',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.vehicleToGrid.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.vehicleToGrid',
          fallback: 'Vehicle to Grid',
        },
      },
      name: 'catalogs.chargers.vehicleToGrid',
    },
  },
  'app.catalog.chargers.filters.OCPP': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.OCPP.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.OCPP',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.OCPP.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.ocpp',
          fallback: 'OCPP',
        },
      },
      name: 'catalogs.chargers.OCPP',
    },
  },
  'app.catalog.chargers.filters.OSCP': {
    _extends: 'app.catalog.common.checkbox.field',
    components: {
      input: 'app.catalog.chargers.filters.OSCP.checkbox',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.chargers.OSCP',
        type: 'checkbox',
      },
      validations: null,
    },
  },
  'app.catalog.chargers.filters.OSCP.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.oscp',
          fallback: 'OSCP',
        },
      },
      name: 'catalogs.chargers.OSCP',
    },
  },
};

export default CHARGERS_CATALOG;
