export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME || 'fleets';
export const CONVERSION_API_URL =
  process.env.REACT_APP_CONVERSION_API_URL ||
  'https://ycv870wsu7.execute-api.us-east-1.amazonaws.com/prod';
export const ADMIN_API_URL =
  process.env.REACT_APP_ADMIN_API_URL ||
  'http://localhost:3030/api/getclient?key=[clientName]';
export const ADMIN_DOMAIN =
  process.env.REACT_APP_ADMIN_DOMAIN || 'zappyride.com';
export const DEFAULT_CLIENT_NAME =
  process.env.REACT_APP_DEFAULT_CLIENT_NAME || 'unbranded';
export const DEFAULT_CONFIG_API_URL = ADMIN_API_URL.replace(
  '[clientName]',
  `${DEFAULT_CLIENT_NAME}.json`
);
export const ADMIN_API_KEY_NAME =
  process.env.REACT_APP_ADMIN_API_KEY_NAME || 'x-api-key';
export const ADMIN_API_KEY_VALUE =
  process.env.REACT_APP_ADMIN_API_KEY_VALUE || '';
export const UNIFIED_API_URL = process.env.REACT_APP_UNIFIED_API_URL;
export const UNIFIED_API_TOKEN = process.env.REACT_APP_UNIFIED_API_TOKEN;
export const DYNAMIC_CALCULATION_MODULE =
  process.env.REACT_APP_DYNAMIC_CALCULATION_MODULE;
export const PROJECT_API_URL = process.env.REACT_APP_PROJECT_API_URL || '';
export const ASSETS_BASE_URL =
  process.env.REACT_APP_ASSETS_BASE_URL || 'https://assets.zappyride.com';
export const CONSUMER_API = process.env.REACT_APP_CONSUMER_API || '';
export const CONSUMER_API_KEY = process.env.REACT_APP_CONSUMER_API_KEY || '';
