import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';

const tableNumbers = (num: number, defValue = 0) => {
  return num || defValue;
};

export const emissionsTable = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const isOffRoad = getValues('vehicleSets[0].input.is_offroad') || false;

  const cumulativeData = getStateValue({
    lookupKey: 'total.cumulative[-1]',
    states: ['emissions', 'vehicles'],
  }) as any;

  const treesPlantedLifetime = get(cumulativeData, 'emissions.treesPlanted', 0);
  const emissionsReducedLifetime = get(
    cumulativeData,
    'emissions.emissionsReduced',
    0
  );

  const yearlyData = getStateValue({
    lookupKey: 'total.yearly[0]',
    states: ['emissions', 'vehicles'],
  }) as any;

  const treesPlantedYearly = get(yearlyData, 'emissions.treesPlanted', 0);
  const emissionsReduced = get(yearlyData, 'emissions.emissionsReduced', 0);
  const cO2EmissionsByFossilFuel_gas = get(
    yearlyData,
    'emissions.fossil.gasoline.emissions',
    0
  );

  const emissionsVolumeByFossilFuel_gas = get(
    yearlyData,
    'emissions.fossil.gasoline.volume',
    0
  );

  const cO2EmissionsByFossilFuel_diesel = get(
    yearlyData,
    'emissions.fossil.diesel.emissions',
    0
  );
  const emissionsVolumeByFossilFuel_diesel = get(
    yearlyData,
    'emissions.fossil.diesel.volume',
    0
  );
  const electricityEmissions = get(
    yearlyData,
    'emissions.electricity.emissions',
    0
  );
  const totalFossilFuelEmissions = get(
    yearlyData,
    'emissions.fossil.totalEmissions',
    0
  );
  const electricityCO2Emissions = get(
    yearlyData,
    'emissions.electricity.inLbsPerMwh',
    0
  );
  const totalMwh = get(yearlyData, 'emissions.electricity.totalMwh', 0);
  const annualGasolineMiles = get(
    yearlyData,
    'emissions.fossil.gasoline.annualMiles',
    0
  );
  const annualGasolineHours = get(
    yearlyData,
    'emissions.fossil.gasoline.annualHours',
    0
  );
  const annualDieselMiles = get(
    yearlyData,
    'emissions.fossil.diesel.annualMiles',
    0
  );
  const annualDieselHours = get(
    yearlyData,
    'emissions.fossil.diesel.annualHours',
    0
  );
  const hasDiesel = tableNumbers(cO2EmissionsByFossilFuel_diesel) > 0;
  const hasGas = tableNumbers(cO2EmissionsByFossilFuel_gas) > 0;
  const hasCustomElectricEmissions = getValues('assumptionData.customElectricEmissionsToggle');
  const hasCustomIceEmissions = getValues('assumptionData.customIceEmissionsToggle');

  return {
    status: {
      isLoading: false,
      missingRequiredInformation: false,
    },
    data: {
      isOffRoad,
      dialogBoxesInfo: {
        treesPlantedLifetime: tableNumbers(treesPlantedLifetime),
        treesPlantedYearly: tableNumbers(treesPlantedYearly),
        co2Lifetime: tableNumbers(emissionsReducedLifetime) / 2000,
        co2Yearly: tableNumbers(emissionsReduced) / 2000,
      },
      emissionsTableData: {
        content: [],
      },
      emissionsTableGlobalData: {
        state: getValues('assumptionsData.state'),
        annualMiles_diesel: hasDiesel ? tableNumbers(annualDieselMiles) : 0,
        annualHours_diesel: hasDiesel ? tableNumbers(annualDieselHours) : 0,
        annualMiles_gas: hasGas ? tableNumbers(annualGasolineMiles) : 0,
        annualHours_gas: hasGas ? tableNumbers(annualGasolineHours) : 0,
        fossilFuelVolume_diesel: tableNumbers(
          emissionsVolumeByFossilFuel_diesel
        ),
        fossilFuelVolume_gas: tableNumbers(emissionsVolumeByFossilFuel_gas),
        fossilFuelCO2Emissions_diesel: hasCustomIceEmissions && hasDiesel
          ? getValues('assumptionsData.customIceEmissions')
          : getValues('constants.dieselEmissionsinLbsPerGal'
        ),
        fossilFuelCO2Emissions_gas: hasCustomIceEmissions && hasGas
        ? getValues('assumptionsData.customIceEmissions')
        : getValues('constants.gasolineEmissionsInLbsPerGal'),
        cO2EmissionsByFossilFuel_gas: tableNumbers(
          cO2EmissionsByFossilFuel_gas
        ),
        cO2EmissionsByFossilFuel_diesel: tableNumbers(
          cO2EmissionsByFossilFuel_diesel
        ),
        totalCO2Emissions:
          tableNumbers(cO2EmissionsByFossilFuel_gas) +
          tableNumbers(cO2EmissionsByFossilFuel_diesel),
        electricityEquivalent: totalMwh.toFixed(2),
        electricityCO2Emissions: tableNumbers(electricityCO2Emissions),
        cO2EmissionsFromEVCharging: tableNumbers(electricityEmissions),
        cO2EmissionsReduced:
          tableNumbers(totalFossilFuelEmissions) -
          tableNumbers(electricityEmissions),
        // TODO: Add this rows when the GHG data is ready
        // cO2ToGHGConversion: 1.00364,
        // gHGEmissionsReduced: 185772,
        // gHGEmissionsReducedSimplified: 93,
        treesPlanted: tableNumbers(treesPlantedYearly),
      },
    },
  };
};
