import { prepareI18nFallback, vehicleLabelFormatters } from '../utils';
import {
  GenericVehicleModel,
  PreparedVehicleModel,
  PrepareOptionAttrs,
  VehicleModel,
} from '../../types/vehicles.types';
import {
  FilterConfig,
  FilterFormData,
  Mapping,
  Preloads,
} from './vehicles.types';
import get from 'lodash.get';
import omitBy from 'lodash.omitby';
import {
  FilterOperatorType,
  operators,
  runFilterOperation,
} from '../../utils/functionHelpers';

const types = {
  PASSENGER: 'Passenger',
  COMMERCIAL: 'Commercial',
};

const workAroundApiResponseForTypes = {
  // for vehicles they are first letter Uppercase
  Sedan: types.PASSENGER,
  Crossover: types.PASSENGER,
  SUV: types.PASSENGER,
  Hatchback: types.PASSENGER,
  Coupe: types.PASSENGER,
  Minivan: types.PASSENGER,
  'Station Wagon': types.PASSENGER,

  // for archetypes they are lowercase
  sedan: types.PASSENGER,
  crossover: types.PASSENGER,
  suv: types.PASSENGER,
  hatchback: types.PASSENGER,
  coupe: types.PASSENGER,
  minivan: types.PASSENGER,
  'station wagon': types.PASSENGER,
  // for fossil fuel vehicles
  'Cargo Van': 'cargo_van',
};

const enum ElectricEfficiencyUnits {
  KM = 'kwh per 100 km',
  MILES = 'kwh per 100 miles',
}

const KWH_PER_MILE_GALLON_E = 33.7;
const LE_PER_100_KM = 0.11231;
export const ELECTRIC_EFFICIENCY_UNIT_KEY_NAME = 'electricEfficiencyUnit';

export type ElectricEfficiencyUnit = `${ElectricEfficiencyUnits}`;

/**
 * This is a work around to map vehicle.type to its appropriated value following the rule below:
 * If vehicle.form_factor is one of workAroundApiResponseForTypes type must be "Passenger"
 * Otherwise vehicle.form_factor
 * @param VehicleModel
 * @returns string
 */
export function vehicleType({
  form_factor,
}: VehicleModel | GenericVehicleModel) {
  return (
    workAroundApiResponseForTypes[
      form_factor as keyof typeof workAroundApiResponseForTypes
    ] || form_factor
  );
}

export function isPassengerVehicle(
  vehicle: VehicleModel | GenericVehicleModel
) {
  return vehicleType(vehicle) === types.PASSENGER;
}

export function vehicleSubtype(vehicle: VehicleModel | GenericVehicleModel) {
  const { form_factor, subtype } = vehicle;
  return isPassengerVehicle(vehicle) ? form_factor : subtype;
}

export function vehicleModel(vehicle: VehicleModel) {
  const { model, trim, model_year } = vehicle;

  const modelTrim = `${model || ''} ${trim || ''}`.trim();

  if (isPassengerVehicle(vehicle) && model_year) {
    return modelTrim.concat(` (${model_year})`);
  }
  return modelTrim;
}

export function vehicleName(vehicle: VehicleModel) {
  return `${vehicle.make || ''} ${vehicleModel(vehicle)}`.trim();
}

export function vehicleImageUrl(vehicle: VehicleModel) {
  return vehicle.images?.find(({ url_thumbnail }) => url_thumbnail)?.url_full;
}

export function toLowerCase(value: unknown) {
  if (typeof value === 'string') return value.toLowerCase();
  return value;
}

const vehicleMatchFilters =
  (
    filters: Array<[string, any, keyof typeof operators]>,
    skipValues: Array<string> = []
  ) =>
  (vehicle: PreparedVehicleModel) => {
    return !filters.find(([attr, value, operator = '$eq']) => {
      const vehicleAttrValue = vehicle[attr as keyof typeof vehicle];
      const shouldSkip = Array.isArray(value)
        ? skipValues.some((skip) => value.includes(skip))
        : skipValues.includes(value);

      // Validations to skip the filter, we only check for length it is an array
      if (
        shouldSkip ||
        value === undefined ||
        (Array.isArray(value) && !value.length)
      )
        return false;

      return !runFilterOperation(vehicleAttrValue, value, operator);
    });
  };

export function filterVehicles(
  vehiclesDetails: Array<PreparedVehicleModel>,
  filters: Array<
    [string, string | number | boolean | string[], FilterOperatorType]
  >,
  skipValues: Array<string> = []
) {
  return vehiclesDetails.filter(vehicleMatchFilters(filters, skipValues)) || {};
}

export function prepareOptions(
  vehiclesDetails: Array<PreparedVehicleModel>,
  attrs: PrepareOptionAttrs
) {
  return Object.values(
    vehiclesDetails?.reduce((acc, vehicle) => {
      const option = prepareOption(vehicle, attrs);
      if (!option) return acc;
      return {
        ...acc,
        [option.value]: option,
      };
    }, {})
  );
}

export function prepareOption(
  vehicle: PreparedVehicleModel,
  {
    attrForLabel,
    attrForValue,
    labelFormatters,
    labelFormatter,
  }: PrepareOptionAttrs
) {
  const value = vehicle[attrForValue];
  if (!value) return;

  const fallback = attrForLabel ? vehicle[attrForLabel] : value;

  let label = prepareI18nFallback(fallback);

  if (labelFormatter) {
    label = vehicleLabelFormatters[labelFormatter](label);
  }

  const formatter =
    labelFormatters && vehicleLabelFormatters[labelFormatters[fallback]];

  const formattedLabel = formatter ? formatter(label) : label;

  return {
    value,
    i18n: {
      id: `i18n.vehicles.${value.toLowerCase().replaceAll(' ', '-')}`,
      fallback: formattedLabel,
    },
  };
}

export function prepareGenericOption({
  value,
  fallback,
}: {
  value: string;
  fallback: string;
}) {
  return {
    value,
    i18n: {
      id: `i18n.vehicle.attr.select.${fallback}`,
      fallback,
    },
  };
}

/**
 * @param vehicleDetails VehicleModel
 * @param electricEfficiencyUnit ElectricEfficiencyUnit
 * @returns Number which either is base on MILES or KM unit
 * ** base on MILES will return miles per galon e
 * ** base on KM will return le per 100 km (Liters equivalent of gasoline per 100 km)
 */
export function viewElectricEfficiency(vehicleDetails: VehicleModel) {
  const electricEfficiencyUnit = getClientElectricEfficiencyUnit();
  if (electricEfficiencyUnit === ElectricEfficiencyUnits.KM) {
    return vehicleElectricEfficiencyIn100Km(vehicleDetails) * LE_PER_100_KM;
  }
  /**
   * electricEfficiency is used per 100 miles.
   * But gallons must be calculated per mile.
   * So it is diving by 100 to normalize to per mile.
   */
  const electricEfficiencyPerMile =
    vehicleElectricEfficiencyIn100Miles(vehicleDetails) / 100;
  return KWH_PER_MILE_GALLON_E / electricEfficiencyPerMile;
}

export function vehicleElectricEfficiencyFromMilesPerGallonE(
  milesPerGallonE: number
) {
  return KWH_PER_MILE_GALLON_E / milesPerGallonE;
}

export function vehicleElectricEfficiencyFromLeKmMiles(LePerKm: number) {
  return LePerKm / LE_PER_100_KM;
}

export function convertBackViewElectricEfficiencyToDefaultUnit(
  viewElectricEfficiency: number
) {
  const clientConfigElectricEfficiencyUnit = getClientElectricEfficiencyUnit();
  if (clientConfigElectricEfficiencyUnit === ElectricEfficiencyUnits.KM) {
    return vehicleElectricEfficiencyFromLeKmMiles(viewElectricEfficiency) / 100;
  }
  return vehicleElectricEfficiencyFromMilesPerGallonE(viewElectricEfficiency);
}

export function vehicleElectricEfficiencyIn100Km({
  electric_efficiency_unit,
  electric_efficiency,
}: Pick<VehicleModel, 'electric_efficiency_unit' | 'electric_efficiency'>) {
  const key =
    electric_efficiency_unit as keyof typeof workAroundForElectricEfficiencyToBeInKwhPer100Km;
  return (
    (workAroundForElectricEfficiencyToBeInKwhPer100Km[key] || 100) *
    electric_efficiency
  );
}

export function vehicleElectricEfficiencyIn100Miles({
  electric_efficiency_unit,
  electric_efficiency,
}: Pick<VehicleModel, 'electric_efficiency_unit' | 'electric_efficiency'>) {
  const key =
    electric_efficiency_unit as keyof typeof workAroundForElectricEfficiencyToBeInKwhPer100Miles;
  return (
    (workAroundForElectricEfficiencyToBeInKwhPer100Miles[key] || 100) *
    electric_efficiency
  );
}

const workAroundForElectricEfficiencyToBeInKwhPer100Km = {
  'kwh per km': 100,
  'kwh per 100 km': 1,
  'kwh per hour': 1,
};

const MILE_TO_KM = 1.609344;
const workAroundForElectricEfficiencyToBeInKwhPer100Miles = {
  'kwh per km': 100 * MILE_TO_KM,
  'kwh per mile': 100,
  'kwh per 100 miles': 1,
  'kwh per 100 km': MILE_TO_KM,
  'kwh per hour': 1,
};

export function vehicleElectricEfficiency(vehicleDetails: VehicleModel) {
  const electricEfficiencyUnit = getClientElectricEfficiencyUnit();
  if (electricEfficiencyUnit === ElectricEfficiencyUnits.KM) {
    return vehicleElectricEfficiencyIn100Km(vehicleDetails);
  }
  return vehicleElectricEfficiencyIn100Miles(vehicleDetails);
}

export function vehicleDcTimeToFullCharge({
  dc_charging_power,
  battery_capacity,
}: VehicleModel) {
  return dc_charging_power
    ? (battery_capacity / dc_charging_power) * 1.15
    : null;
}

export function vehicleAcTimeToFullCharge({
  ac_charging_power,
  battery_capacity,
}: VehicleModel) {
  const acChargingRateKw = Math.min(ac_charging_power, 7.7) * 0.85;

  return acChargingRateKw ? battery_capacity / acChargingRateKw : null;
}

export function vehicleIsOffroad({ on_off_road }: GenericVehicleModel) {
  return !on_off_road?.includes('On-Road');
}

export function genericVehicleName({ type, subtype }: GenericVehicleModel) {
  return `Generic ${subtype} ${type}`.trim();
}

export function getClientElectricEfficiencyUnit() {
  return (
    (localStorage.getItem(
      ELECTRIC_EFFICIENCY_UNIT_KEY_NAME
    ) as ElectricEfficiencyUnit) || ElectricEfficiencyUnits.MILES
  );
}

export function prepareVehicles(
  vehiclesDetails: Array<VehicleModel>,
  args: { [key: string]: string | number } = {}
) {
  return vehiclesDetails.map((vehicleDetails) => ({
    ...args,
    image_url: vehicleImageUrl(vehicleDetails),

    ...vehicleDetails,
    model: vehicleModel(vehicleDetails),
    model_raw: vehicleDetails.model,
    name: vehicleName(vehicleDetails),
    type: vehicleType(vehicleDetails),
    subtype: vehicleSubtype(vehicleDetails),
    electric_efficiency: vehicleElectricEfficiency(vehicleDetails),
    ac_time_to_full_charge: vehicleAcTimeToFullCharge(vehicleDetails),
    dc_time_to_full_charge: vehicleDcTimeToFullCharge(vehicleDetails),
    // This is still using miles_per_galon_e as attr name otherwise it's needed to update all clients config
    miles_per_galon_e: viewElectricEfficiency(vehicleDetails),
  }));
}

export function prepareGenericVehicles(
  genericVehiclesDetails: Array<GenericVehicleModel>,
  args: { [key: string]: string | number } = {}
) {
  return {
    ice: genericVehiclesDetails.map((genericDetails) =>
      convertGenericToIce({ genericDetails, args: { kind: 'ice', ...args } })
    ),
    ev: genericVehiclesDetails.map((genericDetails) =>
      convertGenericToEv({ genericDetails, args: { kind: 'ev', ...args } })
    ),
  };
}

function removeUnnecessaryData(value: any, key: string) {
  return key.startsWith('ev_') || key.startsWith('ice_');
}

function cleanUpGenericDetails(details: any) {
  return {
    ...omitBy(details, removeUnnecessaryData),
  };
}

type ConvertGenericVehicleProps = {
  genericDetails: GenericVehicleModel;
  args: { [key: string]: string | number };
};

export function convertGenericToEv({
  genericDetails,
  args,
}: ConvertGenericVehicleProps) {
  const electricEfficiencyCombination = {
    electric_efficiency: genericDetails.ev_electric_efficiency,
    electric_efficiency_unit: genericDetails.electric_efficiency_unit,
  } as VehicleModel;

  return cleanUpGenericDetails({
    ...args,
    ...genericDetails,
    name: genericVehicleName(genericDetails),
    type: vehicleType(genericDetails),
    subtype: vehicleSubtype(genericDetails),
    fuel: genericDetails.ev_fuel_type,
    msrp: genericDetails.ev_msrp,
    battery_capacity: genericDetails.ev_battery_capacity,
    ac_charging_power: genericDetails.ev_ac_charging_power,
    dc_charging_power: genericDetails.ev_dc_charging_power,
    eligible_for_ca_rebate: genericDetails.ev_eligible_for_ca_rebate,
    total_range: genericDetails.ev_range,
    electric_efficiency: vehicleElectricEfficiency(
      electricEfficiencyCombination
    ),
    // This is still using miles_per_galon_e as attr name otherwise it's needed to update all clients config
    miles_per_galon_e: viewElectricEfficiency(electricEfficiencyCombination),
    maintenance_cost: genericDetails.ev_maint_cost,
  });
}

export function convertGenericToIce({
  genericDetails,
  args,
}: ConvertGenericVehicleProps) {
  return cleanUpGenericDetails({
    ...args,
    ...genericDetails,
    name: genericVehicleName(genericDetails),
    type: vehicleType(genericDetails),
    subtype: vehicleSubtype(genericDetails),
    fuel:
      genericDetails.ice_fuel_type === 'regular'
        ? 'gas'
        : genericDetails.ice_fuel_type,
    msrp: genericDetails.ice_msrp,
    total_range: genericDetails.ice_range,
    range_sources: genericDetails.ice_range_sources,
    fossil_fuel_efficiency: genericDetails.ice_fossil_fuel_efficiency,
    maintenance_cost: genericDetails.ice_maint_cost,
  });
}

export function convertGenericVehicle(
  kind: 'ice' | 'ev',
  genericVehicleProps: ConvertGenericVehicleProps
) {
  return kind === 'ice'
    ? convertGenericToIce(genericVehicleProps)
    : convertGenericToEv(genericVehicleProps);
}

export function prepareFilters(
  formData: FilterFormData,
  { values, mapper }: FilterConfig
) {
  const filters: Array<[string, string | number | boolean, string?]> = [];

  // static values
  values &&
    Object.keys(values).forEach((key) => {
      filters.push([key, values[key]]);
    });

  // formData values
  mapper.forEach(
    ([apiObjAttrName, formDataAttrName, operator, customValues]) => {
      const formDataAttrValue = formData[formDataAttrName];
      const value = parseFormDataIfNeeded(formDataAttrValue, customValues);
      filters.push([apiObjAttrName, value, operator]);
    }
  );

  return filters;
}

export function sortVehicles(
  vehiclesDetails: Array<PreparedVehicleModel>,
  // "<vehicle_attribute_name:1|-1>"
  // 1 ascending
  // -1 descending
  sort: string
) {
  const [attrName, orderString] = sort.split(':') as [
    keyof PreparedVehicleModel,
    string
  ];
  const order = Number(orderString);
  return vehiclesDetails.sort((v1, v2) => {
    let valor1 = v1[attrName] || '';
    let valor2 = v2[attrName] || '';

    if (typeof valor1 === 'string' && typeof valor2 === 'string') {
      valor1 = valor1.toLowerCase();
      valor2 = valor2.toLowerCase();
    }

    if (valor1 > valor2) return order;
    if (valor1 < valor2) return -order;
    return 0;
  });
}

function parseFormDataIfNeeded(
  value: any,
  customValues: { [key: string]: string | number | boolean } = {}
) {
  const customValue = customValues[`${value}`];
  if (customValue !== undefined) return customValue;

  if (typeof value === 'number' || typeof value === 'boolean') return value;
  if (['false', 'true'].includes(value)) return Boolean(value);
  if (!isNaN(value)) return Number(value);
  return value;
}

// Providing an EV and preloads, it matches to an equivalent ICE vehicle.
export function findEquivalentICEforEV(ev: VehicleModel, preloads: Preloads) {
  const iceVehicles = get(preloads, 'fossil_vehicles', [] as VehicleModel[]);
  if (iceVehicles.length === 0) return null;

  // Search in the mappings array
  const mappings = get(preloads, 'mapping_ev_ice', [] as Mapping[]);
  if (mappings.length === 0) return null;

  const mappingMatch = mappings.find(
    ({ input_handle }) => input_handle === ev.handle
  );
  const { output_handle, output_archetype_handle } = mappingMatch || {};
  if (mappingMatch) {
    const match = iceVehicles.find(
      ({ handle }) =>
        handle === output_handle || handle === output_archetype_handle
    );

    if (match) return match;
  }

  // No match by handle, or matched by handle mapping was not found. provide a generic.
  const genericList = get(
    preloads,
    'generic_vehicles.ice',
    [] as VehicleModel[]
  );
  // Try to find a match by handle.
  // Otherwise, the fallback will be the first generic who matches type and subtype.
  const genericMatch = genericList.find(
    ({ handle, type, subtype }) =>
      handle === output_handle ||
      handle === output_archetype_handle ||
      (type.toLowerCase() === ev.type.toLowerCase() &&
        subtype.toLowerCase().trim() === ev.subtype.toLowerCase().trim())
  );
  return genericMatch || {};
}

export function totalSavingMobileTable(data: any) {
  const content = data.content as Array<AnyObject>;

  const dataMobile = content.map((item) => {
    const title = `${item.label}`;
    const subLabel = item.subLabel !== '' ? ` - ${item.subLabel}` : '';
    const label = { prop: `${title}${subLabel}` };
    const electricVehicles = { prop: 'Electric Vehicles', val: item.electric };
    const fossilVehicles = { prop: 'Fossil Vehicles', val: item.fossil };
    const eVSavings = { prop: 'EV Savings', val: item.savings };

    return [label, electricVehicles, fossilVehicles, eVSavings];
  });

  const total = data.globalData as AnyObject;

  const totalSaving = [
    { prop: 'Total', val: '' },
    { prop: 'Electric Vehicles', val: total.totalElectric },
    { prop: 'Fossil Vehicles', val: total.totalFossil },
    { prop: 'EV Savings', val: total.totalSavings },
  ];

  dataMobile.push(totalSaving);

  return dataMobile;
}
