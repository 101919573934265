// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Move this to designs
const VEHICLE_DETAILS_FIELDS = [
  { name: 'type', label: 'Type', show: ['BEV', 'PHEV', 'OFFROAD'], table: '1' },
  {
    name: 'subtype',
    label: 'Subtype',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '1',
  },
  { name: 'gvwr', label: 'GVWR', show: ['BEV', 'PHEV', 'OFFROAD'], table: '1' },
  {
    name: 'weight_class',
    label: 'Weight Class',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '1',
  },
  {
    name: 'availability',
    label: 'Availability',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '1',
  },
  {
    name: 'electric_range',
    label: 'Electric Range',
    show: ['BEV', 'PHEV'],
    table: '2',
  },
  { name: 'total_range', label: 'Total Range', show: ['PHEV'], table: '2' },
  {
    name: 'total_range',
    label: 'Runtime',
    show: ['OFFROAD'],
    table: '2',
    tooltip:
      'Runtime and efficiency depend on usage intensity (load moved, acceleration etc). Medium duty usage is most common.',
  },
  {
    name: 'electric_efficiency',
    label: 'Fuel Efficiency',
    show: ['OFFROAD'],
    table: '2',
    tooltip:
      'Runtime and efficiency depend on usage intensity (load moved, acceleration etc). Medium duty usage is most common.',
  },
  {
    name: 'miles_per_galon_e',
    label: 'MPGe',
    show: ['BEV', 'PHEV'],
    table: '2',
  },

  { name: 'fossil_fuel_efficiency', label: 'MPG', show: ['PHEV'], table: '2' },

  {
    name: 'fuel',
    label: 'Fuel Type(s)',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '2',
  },
  {
    name: 'battery_capacity',
    label: 'Battery Capacity',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '3',
  },
  {
    name: 'ac_charging_power',
    label: 'Maximum AC Charge Rate',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '3',
  },
  {
    name: 'dc_charging_power',
    label: 'Maximum DC Charge Rate',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '3',
  },
  {
    name: 'ac_time_to_full_charge',
    label: 'Time to Charge Level 2',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '3',
  },
  {
    name: 'dc_time_to_full_charge',
    label: 'Time to Charge DC',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '3',
  },
  {
    name: 'plug_types',
    label: 'Plug Types',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '4',
  },

  {
    name: 'cargo_volume_max',
    label: 'Cargo Space',
    show: ['BEV', 'PHEV'],
    table: '4',
  },
  {
    name: 'seats_max',
    label: 'Seats',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '4',
  },
  {
    name: 'payload',
    label: 'Payload',
    show: ['BEV', 'PHEV', 'OFFROAD'],
    table: '4',
  },
];

// TODO: Move this to designs
const SLICES = {
  BEV: [
    [0, 5],
    [5, 9],
    [9, 13],
    [13, 17],
  ],
  PHEV: [
    [0, 5],
    [5, 10],
    [10, 15],
    [15, 20],
  ],
  OFFROAD: [
    [0, 5],
    [5, 9],
    [9, 13],
    [13, 17],
  ],
};

export const VehicleDetails = ({ design }: { design: string }) => {
  const { schema, config, components } = zappy.useDesign(design);
  const { preloads } = useContext(zappy.PreloadsContext);
  const { handle } = useParams();
  const navigate = useNavigate();

  const vehiclesList = get(preloads, config.preload, []);
  const selectedVehicle = useMemo(
    () => vehiclesList.find((vehicle) => vehicle.handle === handle),
    [vehiclesList, handle]
  );

  useEffect(() => {
    // We can't allow the user to land directly to this page.
    if (window.history.length <= 2) navigate(config.redirectToIfNoHistory);
  }, []);

  if (isEmpty(selectedVehicle)) return 'Loading';

  const show = selectedVehicle.is_offroad ? 'OFFROAD' : selectedVehicle.fuel;

  const vehiclesRequiredFields = VEHICLE_DETAILS_FIELDS.filter((field) =>
    field.show.includes(show)
  );

  const tableData = vehiclesRequiredFields.map((field) => ({
    label: field.label,
    prop: field.name,
    val: selectedVehicle[field.name],
    tooltip: field?.tooltip || null,
  }));

  const tablesData = SLICES[show].reduce((acc, slice, index) => {
    acc[`table${index + 1}`] = tableData.slice(...slice);
    return acc;
  }, {});

  const data = {
    ...tablesData,
    is_offroad: selectedVehicle.is_offroad,
  };

  return (
    <zappy.FactoryComponent schema={schema}>
      <zappy.ContentContainer design={components.back} />
      <zappy.ContentContainer
        design={components.name}
        values={selectedVehicle}
      />
      <zappy.ContentContainer
        design={components.price}
        values={selectedVehicle}
      />
      <zappy.ContentContainer
        design={components.tableContainer}
        values={data}
        autoIndex={true}
      />
    </zappy.FactoryComponent>
  );
};

VehicleDetails.displayName = 'VehicleDetails';
