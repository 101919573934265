import { DESKTOP_THRESHOLD } from '../../base';

const ONBOARDING_SELECTION = {
  'app.onboarding.selection': {
    _extends: 'app.page.common',
    config: {
      content: [
        ['TranslatedText', 'app.onboarding.selection.heading'],
        ['TranslatedText', 'app.onboarding.selection.description'],
        ['ContentContainer', 'app.onboarding.selection.form'],
      ],
    },
  },
  'app.onboarding.selection.form': {
    _extends: 'app.onboarding.common.form',
    config: {
      as: 'form',
      content: [
        ['ContentContainer', 'app.onboarding.selection.form.sections'],
        ['ContentContainer', 'app.onboarding.selection.form.footer'],
      ],
    },
  },
  'app.onboarding.selection.form.sections': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.vehicleSet',
        ],
        ['LayoutWithRoutes', 'app.onboarding.selection.form.sections.routes'],
        ['ContentContainer', 'app.onboarding.selection.form.sections.stats'],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
        ],
        [
          'RenderByRoute',
          'app.onboarding.selection.form.sections.collapsible.content',
        ],
      ],
    },
    schema: {
      default: {
        backgroundColor: 'white',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          padding: '0 7.64vw 4.93vh 7.64vw',
          width: '80vw',
          'min-height': '37vh',
        },

        '@media (max-width: 992px)': {
          'grid-row-gap': '24px',
          padding: '24px 0',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.collapsible.content': {
    config: {
      routes: [
        [
          '/onboarding/selection/fossilFuelModel',
          'app.onboarding.selection.form.sections.routes.collapsible.fossil.containers',
          { component: 'Collapsible' },
        ],
        [
          '/onboarding/selection/evModel',
          'app.onboarding.selection.form.sections.routes.collapsible.containers',
          { component: 'Collapsible' },
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes': {
    config: {
      basePath: '/',
      index: '/onboarding/selection/evModel',
      content: [
        [
          'HeaderRoutesFooterLayout',
          'app.onboarding.selection.form.sections.routes.template',
        ],
      ],
      routes: [
        {
          path: 'evModel',
          design: 'app.onboarding.selection.form.sections.routes.evModel',
        },
        {
          path: 'fossilFuelModel',
          design:
            'app.onboarding.selection.form.sections.routes.fossilFuelModel',
        },
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.tabs.fossil.text',
        ],
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection',
        ],
      ],
      scrollTop: false,
    },
  },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition': {
    _extends: 'app.common.fields.row',
    config: {
      content: [
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax',
        ],
      ],
    },
    schema: {
      default: {
        'border-top': 'var(--input-border)',
        'margin-top': '24px',
        'padding-top': '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'output.cargo_volume_max',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax.label',
            fallback: 'Cargo Capacity (Cubic Feet)',
          },
        },
        input: {
          component: 'FormInput',
          name: 'output.cargo_volume_max',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax.placeholder',
              fallback: 'Insert Cargo Capacity',
            },
          },
        },
        validations: {
          pattern: {
            value: '/^\\d+$/',
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax.required',
              fallback: 'Cargo Capacity (Cubic Feet) must be a number',
            },
          },
          min: {
            value: -1,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax.min',
              fallback:
                'Cargo Capacity must be an integer between 0 and 10,000',
            },
          },
          max: {
            value: 10000,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.cargoVolumeMax.max',
              fallback:
                'Cargo Capacity must be an integer between 0 and 10,000',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'output.msrp',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.label',
            fallback: 'Estimated Price ($)*',
          },
        },
        input: {
          component: 'FormInput',
          name: 'output.msrp',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.placeholder',
              fallback: 'Insert Estimated Price',
            },
          },
        },
        validations: {
          required: {
            value: true,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.required',
              fallback: 'Estimated Price is required',
            },
          },
          pattern: {
            value: '/^\\d+$/',
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.required',
              fallback: 'Estimated Price must be a number',
            },
          },
          min: {
            value: 1000,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.min',
              fallback:
                'Estimated Price must be an integer between 1,000 and 10,000,000',
            },
          },
          max: {
            value: 10000000,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp.max',
              fallback:
                'Estimated Price must be an integer between 1,000 and 10,000,000',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'output.fossil_fuel_efficiency',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.label',
            fallback: 'Efficiency (MPG)*',
          },
        },
        input: {
          component: 'FormInput',
          name: 'output.fossil_fuel_efficiency',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.placeholder',
              fallback: 'Insert Efficiency',
            },
          },
        },
        validations: {
          required: {
            value: true,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.required',
              fallback: 'Efficiency is required',
            },
          },
          pattern: {
            value: '/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/',
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.required',
              fallback: 'Efficiency must be a number',
            },
          },
          min: {
            value: 0.1,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.min',
              fallback: 'MPGe must be between 0.1 and 100',
            },
          },
          max: {
            value: 100,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.max',
              fallback: 'MPGe must be between 0.1 and 100',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'output.gvwr',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr.label',
            fallback: 'GVWR (lbs)',
          },
        },
        input: {
          component: 'FormInput',
          name: 'output.gvwr',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr.placeholder',
              fallback: 'Insert GVWR',
            },
          },
        },
        validations: {
          pattern: {
            value: '/^\\d+$/',
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr.required',
              fallback: 'GVWR must be a number',
            },
          },
          min: {
            value: 100,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr.min',
              fallback: 'GVWR must be an integer between 100 and 100,000',
            },
          },
          max: {
            value: 150000,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr.max',
              fallback: 'GVWR must be an integer between 100 and 150,000',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.offRoad':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range',
      config: {
        label: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.runtime.label',
            fallback: 'Runtime (Hours)',
          },
        },
        input: {
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.runtime.placeholder',
              fallback: 'Insert Runtime',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'output.total_range',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.label',
            fallback: 'Range (Miles)',
          },
        },
        input: {
          component: 'FormInput',
          name: 'output.total_range',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.placeholder',
              fallback: 'Insert Range',
            },
          },
        },
        validations: {
          pattern: {
            value: '/^\\d+$/',
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.required',
              fallback: 'Range must be a number',
            },
          },
          min: {
            value: 1,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.min',
              fallback: 'Range must be an integer between 1 and 1,000',
            },
          },
          max: {
            value: 1000,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.max',
              fallback: 'Range must be an integer between 1 and 1,000',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection': {
    _extends: 'app.common.fields.row',
    config: {
      content: [
        [
          'FormField',
          'app.onboarding.selection.form.sections.fossilVehicle.type',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.fossilVehicle.subType',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '24px',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '4.24vw',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.fossilVehicle.subType': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input:
        'app.onboarding.selection.form.sections.fossilVehicle.subtype.input',
    },
    config: {
      label: {
        htmlFor: 'subtype',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.fossilVehicle.subtype.label',
          fallback: 'Vehicle Subtype',
        },
      },
      input: {
        name: 'subtype',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.fossilVehicle.subtype.required',
            fallback: 'Vehicle Subtype is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.fossilVehicle.subtype.input': {
    _extends: 'app.common.select',
    config: {
      component: 'FormVehicleAttrSelector',
      preload: 'generic_vehicles.ice',
      preloadAttrs: {
        attrForValue: 'subtype',
        labelFormatter: 'capitalizeFormatter',
      },
      watch: {
        name: ['type'],
      },
      name: 'subtype',
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.subtype.placeholder',
          fallback: 'Select EV Subtype',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name':
    {
      _extends: 'app.common.field',
      components: {
        description: null,
        input:
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.input',
      },
      config: {
        label: {
          htmlFor: 'handle',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.label',
            fallback: 'Vehicle Model',
          },
        },
        input: {
          name: 'handle',
        },
        validations: {
          required: {
            value: true,
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.required',
              fallback: 'Vehicle Model is required',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.input':
    {
      _extends: [
        'app.common.select',
        'app.onboarding.selection.FormVehicleAttrSelector.inputOnOffRoadFilterQuery',
      ],
      config: {
        component: 'FormVehicleAttrSelector',
        disabledTabs: {
          preload: 'fossil_vehicles',
          includeGeneric: true,
        },
        preload: 'fossil_vehicles',
        preloadAttrs: {
          attrForValue: 'handle',
          attrForLabel: 'name',
        },
        watch: {
          name: ['vehicleSets[0].input.is_offroad', 'type', 'subtype'],
        },
        skipOnFilter: ['vehicleSets[0].input.is_offroad'],
        includeGeneric: true,
        name: 'handle',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.input.placeholder',
            fallback: 'Select Model',
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.fossilVehicle.type': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.selection.form.sections.fossilVehicle.type.input',
    },
    config: {
      label: {
        htmlFor: 'type',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.fossilVehicle.type.label',
          fallback: 'Vehicle Type',
        },
      },
      input: {
        name: 'type',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.fossilVehicle.type.required',
            fallback: 'Vehicle Type is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.template': {
    components: {
      header: 'app.onboarding.selection.form.sections.routes.header',
    },
  },
  'app.onboarding.selection.form.sections.routes.header': {
    config: {
      content: [
        [
          'ProjectVehicleSelectionTabs',
          'app.onboarding.selection.form.sections.routes.header.tabs',
          { displayQuery: 'step3TabsEnabled' },
        ],
      ],
      watch: {
        name: ['step3Tabs'],
      },
      displayQueries: {
        step3TabsEnabled: {
          '["watched", "step3Tabs"]': { $eq: true },
        },
        step3TabsDisabled: {
          '["watched", "step3Tabs"]': { $eq: false },
        },
      },
    },
    schema: {
      default: {
        marginBottom: '24px',
        marginTop: '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs': {
    components: {
      modal: 'app.onboarding.selection.form.sections.routes.header.tabs.modal',
    },
    config: {
      component: 'ProjectVehicleSelectionTabs',
      as: 'menu',
      content: [
        [
          'Button',
          'app.onboarding.selection.form.sections.routes.header.tabs.fossilFuelModel',
          {
            activePathname: '/onboarding/selection/fossilFuelModel',
            to: 'fossilFuelModel',
          },
        ],
        [
          'Button',
          'app.onboarding.selection.form.sections.routes.header.tabs.evModel',
          {
            activePathname: '/onboarding/selection/evModel',
            to: 'evModel',
          },
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        padding: '8px',
        'grid-template-columns': 'repeat(2, 1fr)',
        'grid-column-gap': '16px',
        border: 'var(--input-border)',
        marginBottom: '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.button': {
    schema: {
      default: {
        padding: '12px 24px',
      },
      active: {
        'background-color': 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.fossilFuelModel': {
    _extends:
      'app.onboarding.selection.form.sections.routes.header.tabs.button',
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.tabs.fossilFuelModel.text',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.fossilFuelModel.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.header.tabs.button.text',
      config: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.routes.tabs.fossilFuelModel.text',
          fallback: 'Fossil Fuel Model',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.button.text': {
    config: {
      scale: 3,
      element: 'span',
    },
    schema: {
      default: {
        'text-transform': 'uppercase',
      },
      active: {
        color: 'white',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal': {
    _extends: 'app.common.modal',
    components: {
      portal: 'app.common.portal.fullPage.contentCentered',
      actions:
        'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions',
      content:
        'app.onboarding.selection.form.sections.routes.header.tabs.modal.content',
    },
    config: {
      closeOnConfirm: false,
      closeOnCancel: true,
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions': {
    _extends: 'app.common.modal.actions',
    config: {
      content: [
        [
          'Button',
          'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.cancel',
          { action: 'onCancel' },
        ],
        [
          'Button',
          'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.confirm',
          { action: 'onConfirm' },
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.cancel':
    {
      _extends: 'app.common.modal.button',
      config: {
        content: [
          [
            'TranslatedText',
            'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.cancel.text',
          ],
        ],
      },
      schema: {
        default: {
          'background-color': 'white',
          color: 'var(--primary-color)',
          border: '1px solid var(--primary-color)',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.confirm':
    {
      _extends: 'app.common.modal.button',
      config: {
        content: [
          [
            'TranslatedText',
            'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.confirm.text',
          ],
        ],
      },
      schema: {
        default: {
          'background-color': '#F44336',
          color: 'white',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.cancel.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.common.button.text',
      config: {
        i18n: {
          id: 'i18n.modal.actions.cancel.text',
          fallback: 'GO BACK',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.confirm.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.common.button.text',
      config: {
        i18n: {
          id: 'i18n.modal.actions.confirm.text',
          fallback: 'LEAVE TAB',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.content': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.tabs.modal.content.header',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.tabs.modal.content.text',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.content.header':
    {
      config: {
        element: 'h2',
        scale: 4,
        fontWeight: 700,
        i18n: {
          id: 'i18n.modal.content.text',
          fallback: 'Are you sure',
        },
      },
      schema: {
        default: {
          'border-bottom': 'var(--input-border)',
          'padding-bottom': '16px',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.header.tabs.modal.content.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.common.button.text',
      config: {
        element: 'p',
        i18n: {
          id: 'i18n.modal.content.text',
          fallback:
            'Are you sure you want to leave this tab? Your input data will be lost',
        },
      },
      schema: {
        default: {
          margin: '16px 0',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.evModel.text': {
    config: {
      scale: 3,
      element: 'p',
      textAlign: 'left',
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.routes.header.text',
        dangerouslySetInnerHTML: true,
        fallback:
          '<h4 style="font-weight: bold; font-size: 20px;">Select an Electric Vehicle</h4>\n<p style="font-size: 14px;"><em>Selecting an Electric Vehicle will automatically select a generic fossil fuel vehicle to compare. To  select a specific fossil fuel vehicle, select the edit button above.</em></p>',
      },
    },
    schema: {
      default: {
        marginBottom: '24px',
        marginTop: '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.fossil.text': {
    config: {
      scale: 3,
      element: 'p',
      textAlign: 'left',
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.routes.header.tabs.fossil.text',
        dangerouslySetInnerHTML: true,
        fallback:
          '<h4 style="font-weight: bold; font-size: 20px;">Select a Fossil Fuel Vehicle</h4>\n<p style="font-size: 14px;"><em>Selecting a Fossil Fuel Vehicle will automatically select a generic EV to compare.</p>',
      },
    },
    schema: {
      default: {
        marginBottom: '24px',
        marginTop: '24px',
      },
    },
  },

  'app.onboarding.selection.form.sections.routes.header.tabs.evModel': {
    _extends:
      'app.onboarding.selection.form.sections.routes.header.tabs.button',
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.tabs.evModel.text',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.routes.header.tabs.evModel.text': {
    _extends:
      'app.onboarding.selection.form.sections.routes.header.tabs.button.text',
    config: {
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.routes.tabs.evModel.text',
        fallback: 'EV Replacement',
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.collapsible.containers': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    config: {
      open: false,
    },
    components: {
      headerText:
        'app.onboarding.selection.form.sections.routes.collapsible.electricSpecs.text',
      content: 'app.onboarding.selection.form.sections.edition',
    },
    schema: {
      default: {
        'padding-left': '10px',
        'margin-top': '25px',
        '& > div > div': {
          display: 'grid',
          'grid-template-columns': 'repeat(2, 1fr)',
          'column-gap': '50px',
          'row-gap': '25px',
          'padding-bottom': '25px;',
        },
        '& > button[type="button"][aria-expanded="true"]': {
          borderBottom: '1px solid #E4E4E4',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.routes.collapsible.fossil.containers':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.collapsible.containers',
      components: {
        headerText:
          'app.onboarding.selection.form.sections.routes.collapsible.fossilSpecs.text',
        content: 'app.onboarding.selection.form.sections.fossil.edition',
      },
    },
  'app.onboarding.selection.form.sections.routes.collapsible.electricSpecs.text':
    {
      _extends: 'app.output.template.routes.tabs.collapsible.text',
      config: {
        i18n: {
          id: 'i18n.app.onboarding.selection.form.sections.routes.collapsible.text',
          fallback: 'Overwrite Default Specs',
        },
      },
      schema: {
        default: {
          'padding-left': '0px',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.collapsible.fossilSpecs.text':
    {
      _extends: 'app.output.template.routes.tabs.collapsible.text',
      config: {
        i18n: {
          id: 'i18n.app.onboarding.selection.form.sections.routes.collapsible.text',
          fallback: 'Overwrite Default Specs',
        },
      },
      schema: {
        default: {
          'padding-left': '0px',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.evModel': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.evModel.text',
        ],
        ['ContentContainer', 'app.onboarding.selection.form.sections.evModel'],
      ],
      scrollTop: false,
    },
  },
  'app.onboarding.selection.form.sections.edition': {
    _extends: [
      'app.common.fields.row',
      'app.onboarding.selection.common.inputOnOffRoadDisplayQuery',
    ],
    config: {
      content: [
        [
          'FormField',
          'app.onboarding.selection.form.sections.edition.range.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.edition.range.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.edition.gvwr',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.edition.efficiency.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.edition.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        ['FormField', 'app.onboarding.selection.form.sections.edition.msrp'],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.edition.message',
        ],
      ],
    },
    schema: {
      default: {
        'border-top': 'var(--input-border)',
        'margin-top': '24px',
        'padding-top': '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.fossil.edition': {
    _extends: [
      'app.common.fields.row',
      'app.onboarding.selection.common.inputOnOffRoadDisplayQuery',
    ],
    config: {
      content: [
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.edition.message',
        ],
      ],
    },
    schema: {
      default: {
        'border-top': 'var(--input-border)',
        'margin-top': '24px',
        'padding-top': '24px',
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.message': {
    _extends: 'app.onboarding.common.message',
    config: {
      element: 'p',
      i18n: {
        dangerouslySetInnerHTML: true,
        id: 'i18n.onboarding.selection.form.sections.edition.message',
        fallback:
          'Specifications shown based on manufacturer\'s figures. Numbers can be changed and overridden. All comparative specifications provided by ZappyRide. Standard vehicle specifications shown.',
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.cargoVolumeMax': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      label: {
        htmlFor: 'input.cargo_volume_max',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.cargoVolumeMax.label',
          fallback: 'Cargo Capacity (Cubic Feet)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'input.cargo_volume_max',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.cargoVolumeMax.placeholder',
            fallback: 'Insert Cargo Capacity',
          },
        },
      },
      validations: {
        pattern: {
          value: '/^\\d+$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.cargoVolumeMax.required',
            fallback: 'Cargo Capacity (Cubic Feet) must be a number',
          },
        },
        min: {
          value: -1,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.cargoVolumeMax.min',
            fallback: 'Cargo Capacity must be an integer between 0 and 10,000',
          },
        },
        max: {
          value: 10000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.cargoVolumeMax.max',
            fallback: 'Cargo Capacity must be an integer between 0 and 10,000',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.msrp': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      label: {
        htmlFor: 'input.msrp',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.msrp.label',
          fallback: 'Estimated Price ($)*',
        },
      },
      input: {
        component: 'FormInput',
        name: 'input.msrp',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.msrp.placeholder',
            fallback: 'Insert Estimated Price',
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.msrp.required',
            fallback: 'Estimated Price is required',
          },
        },
        pattern: {
          value: '/^\\d+$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.msrp.required',
            fallback: 'Estimated Price must be a number',
          },
        },
        min: {
          value: 1000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.msrp.min',
            fallback:
              'Estimated Price must be an integer between 1,000 and 10,000,000',
          },
        },
        max: {
          value: 10000000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.msrp.max',
            fallback:
              'Estimated Price must be an integer between 1,000 and 10,000,000',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.efficiency': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      input: {
        decimalScale: 3,
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.required',
            fallback: 'Efficiency is required',
          },
        },
        pattern: {
          value: '/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.required',
            fallback: 'Efficiency must be a number',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.efficiency.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.edition.efficiency',
    config: {
      label: {
        htmlFor: 'input.miles_per_galon_e',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.efficiency.onRoad.label',
          fallback: 'Efficiency (MPGe)*',
        },
      },
      input: {
        component: 'FormInput',
        name: 'input.miles_per_galon_e',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.placeholder',
            fallback: 'Insert Efficiency',
          },
        },
      },
      validations: {
        min: {
          value: 0.999999,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.min',
            fallback: 'Efficiency must be between 1 and 1000',
          },
        },
        max: {
          value: 1001,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.max',
            fallback: 'Efficiency must be between 1 and 1000',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.efficiency.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.edition.efficiency',
    config: {
      label: {
        htmlFor: 'input.electric_efficiency',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.efficiency.offRoad.label',
          fallback: 'Efficiency (Gals/hr)*',
        },
      },
      input: {
        component: 'FormInput',
        name: 'input.electric_efficiency',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.placeholder',
            fallback: 'Insert Efficiency',
          },
        },
      },
      validations: {
        min: {
          value: 0.099999,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.min',
            fallback: 'Efficiency must be between 0.1 and 100',
          },
        },
        max: {
          value: 101,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.max',
            fallback: 'Efficiency must be between 0.1 and 100',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.gvwr': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      label: {
        htmlFor: 'input.gvwr',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.gvwr.label',
          fallback: 'GVWR (lbs)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'input.gvwr',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.gvwr.placeholder',
            fallback: 'Insert GVWR',
          },
        },
      },
      validations: {
        pattern: {
          value: '/^\\d+$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.gvwr.required',
            fallback: 'GVWR must be a number',
          },
        },
        min: {
          value: 100,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.gvwr.min',
            fallback: 'GVWR must be an integer between 100 and 150,000',
          },
        },
        max: {
          value: 150000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.gvwr.max',
            fallback: 'GVWR must be an integer between 100 and 150,000',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.range': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      input: {
        decimalScale: 3,
        component: 'FormInput',
        name: 'input.total_range',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.range.placeholder',
            fallback: 'Insert Range',
          },
        },
      },
      validations: {
        pattern: {
          value: '/^\\d+$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.range.required',
            fallback: 'Range must be a number',
          },
        },
        min: {
          value: 1,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.range.min',
            fallback: 'Range must be an integer between 1 and 1,000',
          },
        },
        max: {
          value: 1000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.range.max',
            fallback: 'Range must be an integer between 1 and 1,000',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.range.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.edition.range',
    config: {
      label: {
        htmlFor: 'input.total_range',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.range.label',
          fallback: 'Range (Miles)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.edition.range.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.edition.range',
    config: {
      label: {
        htmlFor: 'input.total_range',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.edition.range.offRoad.label',
          fallback: 'Runtime (Hours)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats': {
    config: {
      content: [
        [
          'FormStatsVehicle',
          'app.onboarding.selection.form.sections.stats.ice',
        ],
        ['FormStatsVehicle', 'app.onboarding.selection.form.sections.stats.ev'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-gap': '1px',
        border: 'var(--input-border)',
        width: '100%',
        'min-height': '223px',
        margin: '24px 0',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, calc(100%/2))',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice': {
    components: {
      edit: 'app.onboarding.selection.form.sections.stats.ice.editModal',
    },
    config: {
      componentByPathname: {
        '/onboarding/selection/evModel': 'FormOutputVehicle',
        '/onboarding/selection/fossilFuelModel': 'FormInputVehicle',
        '/vehicleSet/edit': 'FormOutputVehicle',
      },
      kindByPathname: {
        '/onboarding/selection/evModel': 'output',
        '/onboarding/selection/fossilFuelModel': 'input',
        '/vehicleSet/edit': 'output',
      },
      preloadsByKind: {
        input: {
          mapping: 'mapping_ice_ev',
          vehicles: 'fossil_vehicles',
          generic: 'generic_vehicles.ice',
        },
        output: {
          mapping: 'mapping_ev_ice',
          vehicles: 'fossil_vehicles',
          generic: 'generic_vehicles.ice',
        },
      },
      type: 'ice',
      kind: 'output',
      fallbackFilters: [
        ['type', 'input.type'],
        ['subtype', 'input.subtype'],
      ],
      preload: {
        mapping: 'mapping_ev_ice',
        vehicles: 'fossil_vehicles',
        generic: 'generic_vehicles.ice',
      },
      content: [
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.stats.ice.left',
        ],
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.stats.ice.right',
        ],
      ],
    },
    schema: {
      default: {
        padding: '16px',
        display: 'grid',
        'grid-template-columns': '42.3% 49%',
        'grid-column-gap': '8.7%',

        '@media (max-width: 992px)': {
          padding: '8px',
        },
      },
      active: {
        'background-color': 'var(--info-background-color)',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal': {
    components: {
      portal: 'app.common.portal.fullPage.contentCentered',
      actions:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions',
      content:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.content',
    },
    schema: {
      default: {
        margin: 'auto',
        padding: '32px',
        'background-color': 'white',
        width: '960px',
        'box-shadow': '0px 0px 20px 2px rgba(0, 0, 0, 0.5)',
      },
    },
    config: {
      closeOnConfirm: false,
      closeOnCancel: true,
    },
  },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions':
    {
      config: {
        content: [
          [
            'Button',
            'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.cancel',
            { action: 'onCancel' },
          ],
          [
            'SmartNextButton',
            'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm.validate',
            { action: 'onConfirm' },
          ],
        ],
      },
      schema: {
        default: {
          display: 'grid',
          'grid-template-columns': '120px 120px',
          'justify-content': 'space-between',
          'padding-top': '24px',
          'margin-top': '24px',
          'border-top': 'var(--input-border)',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.content':
    {
      schema: {
        default: {
          display: 'grid',
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '24px',
          'grid-row-gap': '24px',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.cancel':
    {
      _extends: 'app.common.modal.button',
      config: {
        content: [
          [
            'TranslatedText',
            'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.cancel.text',
          ],
        ],
      },
      schema: {
        default: {
          'background-color': 'white',
          color: 'var(--button-primary-color)',
          border: '1px solid var(--button-primary-color)',
          'border-radius': '50px',
          'justify-content': 'center',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm':
    {
      _extends: ['app.common.modal.button', 'app.common.button.primary'],
      config: {
        component: 'Button',
        content: [
          [
            'TranslatedText',
            'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm.text',
          ],
        ],
      },
      schema: {
        default: {
          'background-color': 'var(--button-primary-color)',
          color: 'white',
          'border-radius': '50px',
          'justify-content': 'center',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm.validate':
    {
      components: {
        button:
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm',
      },
      config: {
        disabled: '(function (formStateValues, isValid) { return !isValid; })',
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.common.button.text':
    {
      config: {
        element: 'span',
        scale: 3,
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.cancel.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.common.button.text',
      config: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.stats.common.editModal.modal.actions.cancel.text',
          fallback: 'CANCEL',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm.text':
    {
      _extends:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.actions.common.button.text',
      config: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.stats.common.editModal.modal.actions.confirm.text',
          fallback: 'SAVE',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal': {
    components: {
      button:
        'app.onboarding.selection.form.sections.stats.common.editModal.button',
    },
    schema: {
      default: {
        'margin-top': '8px',
        'grid-column': '2/2',
        'justify-self': 'flex-end',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.common.editModal.button': {
    _extends: 'app.common.button.secondary',
    config: {
      content: [
        [
          'Icon',
          'app.onboarding.selection.form.sections.stats.common.editModal.button.icon',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.common.editModal.button.icon': {
    _extends: 'app.common.icon.secondary',
    config: {
      src: 'pencil',
      title: 'Edit selection',
      size: 'md',
    },
    schema: {
      default: {
        height: '18px',
        width: '18px',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.header':
    {
      config: {
        element: 'h2',
        scale: 4,
        fontWeight: 700,
      },
      schema: {
        default: {
          'border-bottom': 'var(--input-border)',
          'padding-bottom': '16px',
          'grid-column': '1 / -1',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message':
    {
      _extends: 'app.onboarding.common.message',
      config: {
        element: 'p',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
          fallback:
            'Vehicles listed are for reference purposes only and availability is dependent on local inventory',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.image':
    {
      config: {
        component: 'Logo',
        watch: {
          name: ['edit.image_url', 'edit.name'],
        },
        mapper: {
          'edit.image_url': 'src',
          'edit.name': 'alt',
        },
        converter: {
          undefined:
            'https://assets.zappyride.com/img/vehicles/placeholder.png',
        },
      },
      schema: {
        default: {
          width: 'auto',
          height: '70px',
          'justify-self': 'center',
          'align-self': 'flex-end',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal': {
    _extends: 'app.onboarding.selection.form.sections.stats.common.editModal',
    components: {
      modal: 'app.onboarding.selection.form.sections.stats.ice.editModal.modal',
    },
    config: {
      displayIfPathname: ['/onboarding/selection/evModel', '/vehicleSet/edit'],
      kind: 'output',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal': {
    _extends:
      'app.onboarding.selection.form.sections.stats.common.editModal.modal',
    components: {
      content:
        'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content': {
    _extends: [
      'app.onboarding.selection.form.sections.stats.common.editModal.modal.content',
      'app.onboarding.selection.common.inputOnOffRoadDisplayQuery',
    ],
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.header',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.selection.name',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.image',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.range',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.gvwr',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.runtime',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.efficiency',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.msrp',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.msrp':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.msrp',
      config: {
        label: {
          htmlFor: 'edit.msrp',
        },
        input: {
          name: 'edit.msrp',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.efficiency':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency',
      config: {
        label: {
          htmlFor: 'edit.fossil_fuel_efficiency',
        },
        input: {
          name: 'edit.fossil_fuel_efficiency',
        },
      },
    },
  'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.offRoad':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency',
      config: {
        label: {
          htmlFor: 'output.fossil_fuel_efficiency',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency.offRoad',
            fallback: 'Efficiency (Gals/hr)*',
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.efficiency.offRoad':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.efficiency',
      config: {
        label: {
          htmlFor: 'edit.fossil_fuel_efficiency',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.offRoad.label',
            fallback: 'Efficiency (Gals/hr)*',
          },
        },
        input: {
          component: 'FormInput',
          name: 'edit.fossil_fuel_efficiency',
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.edition.efficiency.placeholder',
              fallback: 'Insert Efficiency',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.gvwr':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.gvwr',
      config: {
        label: {
          htmlFor: 'edit.gvwr',
        },
        input: {
          name: 'edit.gvwr',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.range':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.edition.range',
      components: {
        description: null,
      },
      config: {
        label: {
          htmlFor: 'edit.total_range',
        },
        input: {
          name: 'edit.total_range',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.runtime':
    {
      _extends:
        'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.range',
      config: {
        label: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.runtime.label',
            fallback: 'Runtime (Hours)',
          },
        },
        input: {
          placeholder: {
            i18n: {
              id: 'i18n.onboarding.selection.form.sections.routes.fossilFuelModel.edition.runtime.placeholder',
              fallback: 'Insert Runtime',
            },
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.header':
    {
      _extends:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.header',
      config: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.stats.ice.editModal.modal.content.header',
          fallback: 'Edit Fossil Fuel Vehicle',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.selection.name':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name',
      components: {
        input:
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.selection.name.input',
      },
      config: {
        label: {
          htmlFor: 'modal.handle',
        },
        input: {
          name: 'modal.handle',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.selection.name.input':
    {
      _extends:
        'app.onboarding.selection.form.sections.routes.fossilFuelModel.selection.name.input',
      config: {
        name: 'modal.handle',
        watch: {
          name: ['type', 'subtype', 'vehicleSets[0].input.is_offroad'],
        },
        skipOnFilter: ['vehicleSets[0].input.is_offroad'],
      },
    },
  'app.onboarding.selection.form.sections.stats.ice.left': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.ice.left.title',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.ice.left.image',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.ice.left.name',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right': {
    config: {
      content: [
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.range.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.range.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.fuel',
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.efficiency.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.weightClass',
          { displayQuery: 'onRoad' },
        ],
        ['', 'app.onboarding.selection.form.sections.stats.ev.right.divider'],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ice.right.msrp',
        ],
      ],
      watch: {
        name: ['output.is_offroad'],
      },
      displayQueries: {
        onRoad: { '["watched", "output.is_offroad"]': { $neq: true } },
        offRoad: {
          '["watched", "output.is_offroad"]': { $eq: true },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.msrp': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.msrp.label',
        fallback: 'Estimated Price',
      },
      watch: {
        name: ['output.msrp'],
      },
      pick: 'output.msrp',
      autoPick: 'msrp',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.divider': {
    schema: {
      default: {
        height: '1px',
        'background-color': 'var(--color-gray-500)',
        weight: '100%',
        margin: '6px 0',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.weightClass': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.weightClass',
        fallback: 'Weight Class',
      },
      watch: {
        name: ['output.weight_class'],
      },
      pick: 'output.weight_class',
      converter: {
        undefined: 'N/A',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.efficiency.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.efficiency.label',
        fallback: 'Efficiency',
      },
      watch: {
        name: ['output.fossil_fuel_efficiency'],
      },
      pick: 'output.fossil_fuel_efficiency',
      autoPick: 'fossil_fuel_efficiency',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.efficiency.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.efficiency.label',
        fallback: 'Efficiency',
      },
      watch: {
        name: ['output.fossil_fuel_efficiency'],
      },
      preset: {
        mapper: {
          'output.fossil_fuel_efficiency': 'rowData[val]',
        },
      },
      mapper: {
        'rowData[val]': 'picked',
      },
      autoPick: 'fossil_fuel_efficiency',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.fuel': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.fuel.label',
        fallback: 'Fuel',
      },
      watch: {
        name: ['output.fuel'],
      },
      pick: 'output.fuel',
      fuel_type: 'children',
      autoPick: 'fuel',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.range.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.right.range.label',
        fallback: 'Range',
      },
      watch: {
        name: ['output.total_range'],
      },
      pick: 'output.total_range',
      autoPick: 'total_range',
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.right.range.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.runtime.label',
        fallback: 'Runtime',
      },
      watch: {
        name: ['output.total_range'],
      },
      pick: 'output.total_range',
      format: {
        formatter: 'formatAsDurationInHoursAndMinutes',
        fallback: 'N/A',
      },
    },
  },

  'app.onboarding.selection.form.sections.stats.ice.left.name': {
    config: {
      as: 'p',
      watch: {
        name: ['output.name'],
      },
      pick: 'output.name',
    },
    schema: {
      default: {
        width: 'auto',

        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.left.title': {
    config: {
      element: 'p',
      textAlign: 'left',
      fontWeight: 'bold',
      scale: '3',
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.stats.ice.title',
        fallback: 'Fossil Fuel Vehicle',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ice.left.image': {
    config: {
      component: 'Logo',
      watch: {
        name: ['output.image_url', 'output.name'],
      },
      mapper: {
        'output.image_url': 'src',
        'output.name': 'alt',
      },
      converter: {
        undefined: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
    schema: {
      default: {
        width: '129px',
        height: 'auto',
        margin: '16px 0',
      },
    },
  },

  'app.onboarding.selection.form.sections.stats.ev': {
    components: {
      edit: 'app.onboarding.selection.form.sections.stats.ev.editModal',
    },
    config: {
      componentByPathname: {
        '/onboarding/selection/evModel': 'FormInputVehicle',
        '/onboarding/selection/fossilFuelModel': 'FormOutputVehicle',
        '/vehicleSet/edit': 'FormInputVehicle',
      },
      kindByPathname: {
        '/onboarding/selection/evModel': 'input',
        '/onboarding/selection/fossilFuelModel': 'output',
        '/vehicleSet/edit': 'input',
      },
      preloadsByKind: {
        input: {
          mapping: 'mapping_ice_ev',
          vehicles: 'electric_vehicles',
          generic: 'generic_vehicles.ev',
        },
        output: {
          mapping: 'mapping_ice_ev',
          vehicles: 'electric_vehicles',
          generic: 'generic_vehicles.ev',
        },
      },
      type: 'ev',
      kind: 'input',
      fallbackFilters: [
        ['type', 'output.type'],
        ['subtype', 'output.subtype'],
      ],
      preload: {
        mapping: 'mapping_ice_ev',
        generic: 'generic_vehicles.ev',
        vehicles: 'electric_vehicles',
      },
      content: [
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.stats.ev.left',
        ],
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.stats.ev.right',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        padding: '16px',
        'grid-template-columns': '42.3% 49%',
        'grid-column-gap': '8.7%',

        '@media (max-width: 992px)': {
          padding: '8px',
        },
      },

      active: {
        '@media (max-width: 992px)': {
          'border-top': '1px solid var(--border-color)',
        },
        'background-color': 'var(--info-background-color)',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.editModal': {
    _extends: 'app.onboarding.selection.form.sections.stats.common.editModal',
    components: {
      modal: 'app.onboarding.selection.form.sections.stats.ev.editModal.modal',
    },
    config: {
      displayIfPathname: [
        '/onboarding/selection/fossilFuelModel',
        '/vehicleSet/edit',
      ],
      kind: 'input',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal': {
    _extends:
      'app.onboarding.selection.form.sections.stats.common.editModal.modal',
    components: {
      content:
        'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content': {
    _extends: [
      'app.onboarding.selection.form.sections.stats.common.editModal.modal.content',
      'app.onboarding.selection.common.inputOnOffRoadDisplayQuery',
    ],
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.header',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.name',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.image',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.range',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.gvwr',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ice.editModal.modal.content.edition.runtime',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.efficiency',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormField',
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.msrp',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.efficiency.offRoad':
    {
      _extends:
        'app.onboarding.selection.form.sections.edition.efficiency.offRoad',
      config: {
        label: {
          htmlFor: 'edit.electric_efficiency',
        },
        input: {
          name: 'edit.electric_efficiency',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.range':
    {
      _extends: 'app.onboarding.selection.form.sections.edition.range.onRoad',
      config: {
        label: {
          htmlFor: 'edit.total_range',
        },
        input: {
          name: 'edit.total_range',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.gvwr':
    {
      _extends: 'app.onboarding.selection.form.sections.edition.gvwr',
      config: {
        label: {
          htmlFor: 'edit.gvwr',
        },
        input: {
          name: 'edit.gvwr',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.efficiency':
    {
      _extends:
        'app.onboarding.selection.form.sections.edition.efficiency.onRoad',
      config: {
        label: {
          htmlFor: 'edit.miles_per_galon_e',
        },
        input: {
          name: 'edit.miles_per_galon_e',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.msrp':
    {
      _extends: 'app.onboarding.selection.form.sections.edition.msrp',
      config: {
        label: {
          htmlFor: 'edit.msrp',
        },
        input: {
          name: 'edit.msrp',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.name':
    {
      _extends: 'app.onboarding.selection.form.sections.evModel.name',
      components: {
        input:
          'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.name.input',
      },
      config: {
        label: {
          htmlFor: 'modal.handle',
        },
        input: {
          name: 'modal.handle',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.selection.name.input':
    {
      _extends: [
        'app.common.select',
        'app.onboarding.selection.FormVehicleAttrSelector.outputOnOffRoadFilterQuery',
      ],
      config: {
        component: 'FormVehicleAttrSelector',
        preload: 'electric_vehicles',
        preloadAttrs: {
          attrForValue: 'handle',
          attrForLabel: 'name',
        },
        sortingOrder: 'asc',
        watch: {
          name: [
            'vehicleSets[0].output.is_offroad',
            'output.type',
            'output.subtype',
          ],
        },
        watchToFiltersMap: {
          'output.type': 'type',
          'output.subtype': 'subtype',
        },
        skipOnFilter: ['vehicleSets[0].output.is_offroad'],
        name: 'modal.handle',
        includeGeneric: true,
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.evModel.name.placeholder',
            fallback: 'Select EV Model',
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.editModal.modal.content.header':
    {
      _extends:
        'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.header',
      config: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.stats.ev.editModal.modal.content.header',
          fallback: 'Edit EV Vehicle',
        },
      },
    },
  'app.onboarding.selection.form.sections.stats.ev.right': {
    _extends: 'app.onboarding.selection.common.inputOnOffRoadDisplayQuery',
    config: {
      content: [
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.range.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.range.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.capacity',
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.efficiency.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.efficiency.offRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.weightClass',
          { displayQuery: 'onRoad' },
        ],
        ['', 'app.onboarding.selection.form.sections.stats.ev.right.divider'],
        [
          'FormVehicleStatsRow',
          'app.onboarding.selection.form.sections.stats.ev.right.msrp',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.msrp': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.msrp.label',
        fallback: 'Estimated Price',
      },
      watch: {
        name: ['input.msrp'],
      },
      pick: 'input.msrp',
      autoPick: 'msrp',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.divider': {
    schema: {
      default: {
        height: '1px',
        'background-color': 'var(--color-gray-500)',
        width: '100%',
        margin: '6px 0',
        'grid-column': '1 / -1',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.weightClass': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.weightClass',
        fallback: 'Weight Class',
      },
      watch: {
        name: ['input.weight_class'],
      },
      pick: 'input.weight_class',
      converter: {
        undefined: 'N/A',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.efficiency.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.efficiency.label',
        fallback: 'Efficiency',
      },
      watch: {
        name: ['input.miles_per_galon_e'],
      },
      preset: {
        mapper: {
          'input.miles_per_galon_e': 'rowData[val]',
        },
      },
      mapper: {
        'rowData[val]': 'picked',
      },
      autoPick: 'miles_per_galon_e',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.efficiency.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.efficiency.label',
        fallback: 'Efficiency',
      },
      watch: {
        name: ['input.electric_efficiency'],
      },
      preset: {
        mapper: {
          'input.electric_efficiency': 'rowData[val]',
        },
      },
      mapper: {
        'rowData[val]': 'picked',
      },
      autoPick: 'offroad_electric_efficiency',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.capacity': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.capacity.label',
        fallback: 'Battery Capacity',
      },
      watch: {
        name: ['input.battery_capacity'],
      },
      pick: 'input.battery_capacity',
      autoPick: 'battery_capacity',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.range.offRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.runtime.label',
        fallback: 'Runtime',
      },
      watch: {
        name: ['input.total_range'],
      },
      pick: 'input.total_range',
      format: {
        formatter: 'formatAsDurationInHoursAndMinutes',
        fallback: 'N/A',
        approximated: false,
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.range.onRoad': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.common',
    config: {
      i18nLabel: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.right.range.label',
        fallback: 'Range',
      },
      watch: {
        name: ['input.total_range'],
      },
      pick: 'input.total_range',
      autoPick: 'total_range',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.common': {
    _extends: 'app.common.formatter.auto.value',
    components: {
      label:
        'app.onboarding.selection.form.sections.stats.ev.right.common.label',
      unit: 'app.onboarding.selection.form.sections.stats.ev.right.common.unit',
      value:
        'app.onboarding.selection.form.sections.stats.ev.right.common.value',
    },
    schema: {
      default: {
        display: 'flex',
        'justify-content': 'space-between',
        margin: '6px 0',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.common.label': {
    config: {
      element: 'span',
      textAlign: 'left',
      fontWeight: '600',
    },
    schema: {
      default: {
        width: 'auto',

        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.common.unit': {
    _extends:
      'app.onboarding.selection.form.sections.stats.ev.right.common.label',
    config: {
      fontWeight: '400',
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.right.common.value': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        fontWeight: '400',
        'text-align': 'right',

        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.left': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.ev.left.title',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.ev.left.image',
        ],
        [
          'Formatter',
          'app.onboarding.selection.form.sections.stats.ev.left.name',
        ],
      ],
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.left.name': {
    config: {
      as: 'p',
      watch: {
        name: ['input.name'],
      },
      pick: 'input.name',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.left.title': {
    config: {
      element: 'p',
      textAlign: 'left',
      fontWeight: 'bold',
      scale: '3',
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.stats.ev.left.title',
        fallback: 'Electric Vehicle',
      },
    },
  },
  'app.onboarding.selection.form.sections.stats.ev.left.image': {
    config: {
      component: 'Logo',
      watch: {
        name: ['input.image_url', 'input.name'],
      },
      mapper: {
        'input.image_url': 'src',
        'input.name': 'alt',
      },
      converter: {
        undefined: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
    schema: {
      default: {
        width: '129px',
        height: 'auto',
        margin: '16px 0',
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel': {
    _extends: 'app.common.fields.row',
    config: {
      content: [
        ['FormField', 'app.onboarding.selection.form.sections.evModel.type'],
        ['FormField', 'app.onboarding.selection.form.sections.evModel.subtype'],
        ['FormField', 'app.onboarding.selection.form.sections.evModel.name'],
        [
          'ActionComponent',
          'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection.button',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '24px',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '4.24vw',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection.button':
    {
      components: {
        button:
          'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection.button.select.button',
      },
      config: {
        eventName: 'onClick',
        actions: [
          {
            type: 'form/navigateTo',
            payload: {
              to: '/catalog/vehicles',
              from: '/onboarding/selection',
            },
          },
        ],
        component: 'SmartNextButton',
      },
    },
  'app.onboarding.selection.form.sections.evModel.editVehicleSet': {
    _extends: 'app.common.fields.row',
    config: {
      content: [
        ['FormField', 'app.onboarding.selection.form.sections.evModel.type'],
        ['FormField', 'app.onboarding.selection.form.sections.evModel.subtype'],
        ['FormField', 'app.onboarding.selection.form.sections.evModel.name'],
        [
          'ActionComponent',
          'app.onboarding.selection.form.sections.evModel.editVehicleSet.vehicleCatalogSelection.button',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.stats.common.editModal.modal.content.selection.message',
        ],
      ],
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '4.24vw',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.editVehicleSet.vehicleCatalogSelection.button':
    {
      components: {
        button:
          'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection.button.select.button',
      },
      config: {
        eventName: 'onClick',
        actions: [
          {
            type: 'form/navigateTo',
            payload: {
              to: '/catalog/vehicles',
              from: '/vehicleSet/edit',
            },
          },
        ],
        component: 'SmartNextButton',
      },
    },
  'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection.button.select.button':
    {
      config: {
        component: 'Button',
        content: [
          [
            'TranslatedText',
            'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection',
          ],
        ],
      },
      schema: {
        default: {
          '@media (max-width: 992px)': {
            padding: '0px',
          },
        },
      },
    },
  'app.onboarding.selection.form.sections.evModel.vehicleCatalogSelection': {
    _extends: 'app.common.button.secondary',
    config: {
      i18n: {
        id: 'i18n.onboarding.selection.form.sections.evModel.vehicleCatalogSelection',
        fallback: 'SELECT FROM VEHICLE CATALOG',
      },
    },
    schema: {
      default: {
        width: '100%',
      },
    },
  },

  'app.onboarding.selection.form.sections.evModel.name': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.selection.form.sections.evModel.name.input',
    },
    config: {
      label: {
        htmlFor: 'handle',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.name.label',
          fallback: 'Vehicle Model',
        },
      },
      input: {
        name: 'handle',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.evModel.name.required',
            fallback: 'Vehicle Model is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.name.input': {
    _extends: 'app.common.select',
    config: {
      component: 'FormVehicleAttrSelector',
      preload: 'electric_vehicles',
      preloadAttrs: {
        attrForValue: 'handle',
        attrForLabel: 'name',
      },
      sortingOrder: 'asc',
      watch: {
        name: ['type', 'subtype'],
      },
      includeGeneric: true,
      name: 'handle',
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.name.placeholder',
          fallback: 'Select EV Model',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.type': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.selection.form.sections.evModel.type.input',
    },
    config: {
      label: {
        htmlFor: 'type',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.type.label',
          fallback: 'Vehicle Type',
        },
      },
      input: {
        name: 'type',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.evModel.type.required',
            fallback: 'Vehicle Type is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.type.input': {
    _extends: [
      'app.common.select',
      'app.onboarding.selection.FormVehicleAttrSelector.inputOnOffRoadFilterQuery',
    ],
    config: {
      component: 'FormVehicleAttrSelector',
      preload: 'electric_vehicles',
      preloadAttrs: {
        attrForValue: 'type',
        labelFormatters: {
          tru: 'acronymFormatter',
          bev_drive_system: 'bevFormatter',
        },
      },
      sortingOrder: 'asc',
      name: 'type',
      skipOnFilter: ['vehicleSets[0].input.is_offroad'],
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.type.placeholder',
          fallback: 'Select EV Type',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.subtype': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.selection.form.sections.evModel.subtype.input',
    },
    config: {
      label: {
        htmlFor: 'subtype',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.subtype.label',
          fallback: 'Vehicle Subtype',
        },
      },
      input: {
        name: 'subtype',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.evModel.subtype.required',
            fallback: 'Vehicle Subtype is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.evModel.subtype.input': {
    _extends: 'app.common.select',
    config: {
      component: 'FormVehicleAttrSelector',
      preload: 'electric_vehicles',
      preloadAttrs: {
        attrForValue: 'subtype',
        labelFormatter: 'capitalizeFormatter',
      },
      sortingOrder: 'asc',
      watch: {
        name: ['type'],
      },
      name: 'subtype',
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.subtype.placeholder',
          fallback: 'Select EV Subtype',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.fossilVehicle.type.input': {
    _extends: [
      'app.common.select',
      'app.onboarding.selection.FormVehicleAttrSelector.inputOnOffRoadFilterQuery',
    ],
    config: {
      component: 'FormVehicleAttrSelector',
      preload: 'generic_vehicles.ice',
      preloadAttrs: {
        attrForValue: 'type',
        labelFormatters: {
          tru: 'acronymFormatter',
          bev_drive_system: 'bevFormatter',
        },
      },
      sortingOrder: 'asc',
      name: 'type',
      skipOnFilter: ['vehicleSets[0].input.is_offroad'],
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.evModel.type.placeholder',
          fallback: 'Select Type',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.vehicleSet': {
    _extends: 'app.common.fields.row',
    config: {
      content: [
        ['FormField', 'app.onboarding.selection.form.sections.vehicleSet.name'],
        [
          'FormField',
          'app.onboarding.selection.form.sections.vehicleSet.count',
        ],
      ],
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '4.24vw',
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.vehicleSet.name': {
    _extends: 'app.common.field',
    config: {
      label: {
        htmlFor: 'vehicleSetName',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.vehicleSet.name',
          fallback: 'Name your vehicle set',
        },
      },
      input: {
        component: 'FormInput',
        name: 'vehicleSetName',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.name.placeholder',
            fallback: 'Enter vehicle set name',
          },
        },
      },
      description: null,
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.name.required',
            fallback: 'Vehicle set name is required',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.sections.vehicleSet.count': {
    _extends: 'app.common.field',
    config: {
      component: 'FormField',
      label: {
        htmlFor: 'vehicleCount',
        i18n: {
          id: 'i18n.onboarding.selection.form.sections.vehicleSet.count',
          fallback: 'Number of vehicles',
        },
      },
      input: {
        component: 'FormInput',
        name: 'vehicleCount',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.count.placeholder',
            fallback: 'Enter number of vehicles for this set',
          },
        },
      },
      description: null,
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.count.required',
            fallback: 'Number of vehicle is required',
          },
        },
        pattern: {
          value: '/^\\d+$/',
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.count.int',
            fallback: 'Number of vehicle must be an integer',
          },
        },
        min: {
          value: 1,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.vehicleset.count.min',
            fallback: 'Number of vehicle must be greater than 0',
          },
        },
      },
    },
  },
  'app.onboarding.selection.form.footer': {
    _extends: 'app.onboarding.common.form.footer',
    config: {
      content: [
        ['ContentContainer', 'app.onboarding.selection.footer.button.back'],
        ['ActionComponent', 'app.onboarding.selection.footer.smartButton'],
      ],
    },
  },
  'app.onboarding.selection.footer.button.back': {
    _extends: 'app.onboarding.common.footer.button.back',
    config: {
      to: '../info',
    },
  },
  'app.onboarding.selection.footer.smartButton': {
    _extends: 'app.onboarding.common.footer.smartButton.next',
    components: {
      button: 'app.onboarding.selection.footer.smartButton.button',
    },
    config: {
      component: 'SmartNextButton',
      eventName: 'onClick',
      actions: [
        {
          type: 'form/setValue',
          payload: {
            name: 'selectionIsDirty',
            func: '(function() { return false; })',
          },
        },
        {
          type: 'form/navigateTo',
          payload: {
            to: '/onboarding/usage',
          },
        },
      ],
      disabled: '(function (formStateValues, isValid) { return !isValid; })',
    },
  },
  'app.onboarding.selection.footer.smartButton.button': {
    _extends: 'app.onboarding.common.footer.smartButton.next.button',
    config: {
      component: 'Button',
    },
  },
  'app.onboarding.selection.heading': {
    _extends: 'app.onboarding.common.heading',
    config: {
      i18n: {
        id: 'i18n.onboarding.selection.heading',
        fallback: 'Select Vehicle',
      },
    },
  },
  'app.onboarding.selection.description': {
    _extends: 'app.onboarding.common.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.selection.description',
        fallback:
          'Tell us about your plans for purchasing new or replacement vehicles.',
      },
    },
  },
  'app.onboarding.selection.common.inputOnOffRoadDisplayQuery': {
    config: {
      watch: {
        name: ['input.is_offroad'],
      },
      displayQueries: {
        onRoad: { '["watched", "input.is_offroad"]': { $neq: true } },
        offRoad: {
          '["watched", "input.is_offroad"]': { $eq: true },
        },
      },
    },
  },
  'app.onboarding.selection.FormVehicleAttrSelector.inputOnOffRoadFilterQuery':
    {
      config: {
        watch: {
          name: ['vehicleSets[0].input.is_offroad'],
          defaultValue: {
            vehicleSets: [
              {
                input: {
                  is_offroad: null,
                },
              },
            ],
          },
        },
        filterQuery: {
          $or: [
            {
              '["watched","vehicleSets[0].input.is_offroad"]': {
                $eq: null,
              },
            },
            {
              is_offroad: {
                $eq: ['watched', 'vehicleSets[0].input.is_offroad'],
              },
            },
          ],
        },
      },
    },
  'app.onboarding.selection.FormVehicleAttrSelector.outputOnOffRoadFilterQuery':
    {
      config: {
        watch: {
          name: ['vehicleSets[0].output.is_offroad'],
          defaultValue: {
            vehicleSets: [
              {
                output: {
                  is_offroad: null,
                },
              },
            ],
          },
        },
        filterQuery: {
          $or: [
            {
              '["watched","vehicleSets[0].output.is_offroad"]': {
                $eq: null,
              },
            },
            {
              is_offroad: {
                $eq: ['watched', 'vehicleSets[0].output.is_offroad'],
              },
            },
          ],
        },
      },
    },
};

export default ONBOARDING_SELECTION;
