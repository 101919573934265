import zappy from '@zappy-ride/library.react.components';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const ExternalLinksModal = ({ design }: { design: string }) => {
  const [currentHref, setCurrentHref] = useState('');
  const { getValues } = useFormContext();
  const clientName = getValues('clientName');

  const [isOpen, setIsOpen] = useState(false);

  const confirmAction = (href: string) => {
    setCurrentHref(href);
    setIsOpen(true);
  };

  const openLinkInNewWindow = (url: string) => {
    window.open(url, '_blank');
    setIsOpen(false);
  };

  const actions = useMemo(
    () => ({
      onConfirm: () => openLinkInNewWindow(currentHref),
      onCancel: () => setIsOpen(false),
    }),
    [currentHref]
  );

  const handleElementClicked = useCallback((ev: MouseEvent) => {
    if (!(ev.target instanceof HTMLElement)) return;

    // sometimes we have a span or another element inside an Anchor,
    // so, we need to find the closest ancestor anchor element to handle the clicked link
    const target = ev.target.closest('A') as HTMLAnchorElement;
    if (!target) return;

    const rawHref = target.attributes.getNamedItem('href')?.value;
    const isExternalLink = rawHref && rawHref?.startsWith('http');
    if (!isExternalLink) return;

    ev.preventDefault();
    confirmAction(target.href);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleElementClicked);

    return () => {
      document.removeEventListener('click', handleElementClicked);
    };
  }, []);

  return (
    <zappy.PrimaryModal
      design={design}
      data-design={design}
      //@ts-ignore
      values={{ clientName }}
      open={isOpen}
      onConfirm={actions.onConfirm}
      onCancel={actions.onCancel}
    />
  );
};

ExternalLinksModal.displayName = 'ExternalLinksModal';
