// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMatch } from "react-router-dom";
import { DESKTOP_THRESHOLD } from '../data/default/base';

const MOBILE = 'mobile';
const DESKTOP = 'desktop';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const getDevice = () => {
  return getWindowDimensions().width > DESKTOP_THRESHOLD ? DESKTOP : MOBILE;
};
export const useDevice = () => {
  const { setValue } = useFormContext();
  const print = useMatch('/print/*');

  function handleResize() {
    const  device = getDevice();
    // We don't want to print on mobile
    setValue('device', device === MOBILE && print ? DESKTOP : device)
    setValue('printing', Boolean(print));
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return handleResize();
};
