// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { FormatterComponent } from "@zappy-ride/library.react.components/dist/components/atoms/Formatter";
import { useTranslation } from '@zappy-ride/library.react.components/dist/abstractions/hooks/useTranslation';

export const I18nFormatterSimple = (props) => {
  const { translate } = useTranslation();
  const {
    'data-design': dd,
    autoFormats: af = {},
    converter: baseConverter,
    converter_i18n: i18nConverter,
    text,
    i18n
  } = props;
  const cv = i18nConverter || baseConverter;
  const autoFormats = Object.keys(af).reduce((acc, key) => {
    const { converter, converter_i18n = null, fallback } = af[key];
    const tr = i18nObject(af[key], translate);

    acc[key] = {
      ...af[key],
      ...(tr && tr !== fallback && {text: tr}),
      ...(converter && {
        converter:
          converter_i18n
            ? translateConverter(converter_i18n, translate)
            : transformConverter(converter, translate)
      })
    }
    return acc;
  }, {});

  const newProps = {
    ...props,
    autoFormats,
    ...(cv && {
      converter: transformConverter(cv, translate)
    }),
    values: {
      ...props.values,
    },
    text: i18n ? i18nObject({i18n, text}, translate) : text,
  }

  return (
    <FormatterComponent design={dd} {...newProps}/>
  )
}

export const i18nFormatter = zappy.withDesign(I18nFormatterSimple);
i18nFormatter.displayName = 'i18nFormatter';

const i18nObject = (obj, translate) => {
  const { i18n = '', id, text, fallback } = obj;
  return translate({
    id: id || i18n,
    fallback: fallback || text
  }) || text || fallback;
}


const translateConverter = (obj, translate) => {
  return Object.keys(obj).reduce((acc, key) => {
    const {id, fallback } = obj[key];
    if (!id) {
      acc[key] = obj[key];
      return acc;
    }

    acc[key] = i18nObject(obj[key], translate);
    return acc;
  }, {});
}

const transformConverter = (obj, translate) => {
  return Object.keys(obj).reduce((acc, key) => {
    const {i18n = null, text, fallback } = obj[key];
    if (!i18n) {
      acc[key] = obj[key];
      return acc;
    }

    acc[key] = i18nObject(obj[key], translate);
    return acc;
  }, {});
}