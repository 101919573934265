// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

export const RadioGroupRates = ({ design, ...props }: { design: string }) => {
  const { 'assumptionsData.rates': electricRatesInfo } = zappy.useWatch({
    name: ['assumptionsData.rates'],
  });
  const { setValue } = useFormContext();

  const handleChange = (e) => {
    setValue(
      'assumptionsData.selectedRateName',
      get(electricRatesInfo, `${e.target.value}.longName`)
    );
  };

  const dynamicItems = useMemo(() => {
    return Object.keys(electricRatesInfo)
      ?.filter((key) => key !== 'simple')
      ?.map((key) => {
        const { name, longName, description } = electricRatesInfo[key];
        return {
          label: {
            i18n: {
              id: `i18n.RadioGroupRates.${name}`,
              dangerouslySetInnerHTML: true,
              fallback: `<strong>${longName}:</strong> ${description || ''}`,
            },
            htmlFor: name,
          },
          value: name,
          id: name,
        };
      });
  }, [electricRatesInfo]);

  return (
    <zappy.FormControlledRadioInputGroup
      design={design}
      preItems={dynamicItems}
      onChange={handleChange}
    />
  );
};

RadioGroupRates.displayName = 'RadioGroupRates';
