// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useTranslation } from '@zappy-ride/library.react.components/dist/abstractions/hooks/useTranslation';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  filterVehicles,
  prepareGenericOption,
  prepareOptions,
} from '../../presenters/vehicles';

const ARRAY_OPERATOR = '$inc';

/**
 * Transform watch values into filters.
 * @param values - Object containing values to transform.
 * @param config - VehicleAttrSelector configuration.
 * @returns An array of filters transformed from the watch values.
 */
function transformWatchValuesIntoFilters(values, config) {
  const {
    watch,
    watchToFiltersMap = {},
    skipOnFilter = [],
    mapFilteredValues = {},
  } = config;
  const { name: watchNames } = watch || {};
  if (!watchNames) return values;
  return watchNames
    .filter((name) => !skipOnFilter.includes(name))
    .map((name) => {
      const filterValues = Array.isArray(values[name])
        ? values[name].map((el) =>
            typeof el === 'string' ? el.toLowerCase() : el
          )
        : values[name];
      const filterValueMapped = mapFilteredValues[name]
        ? mapFilteredValues[name]?.[values[name]] ?? filterValues
        : filterValues;
      const operator = Array.isArray(values[name]) ? ARRAY_OPERATOR : undefined;
      return [watchToFiltersMap[name] || name, filterValueMapped, operator];
    });
}

function handleFilterVehicles({
  preload,
  config,
  filters,
  watchValues: watched,
}) {
  const { filterQuery } = config;

  if (isEmpty(filters) && !filterQuery) return preload;

  let preFiltered = preload;
  if (filterQuery) {
    const customValues = { watched };

    preFiltered = preload.filter((item) =>
      zappy.runQuery(item, filterQuery, customValues)
    );
  }

  return filterVehicles(preFiltered, filters, config.skipValues);
}

function prepareOptionsBasedOnWatchValues(props) {
  const filtered = handleFilterVehicles(props);
  const { config, translate } = props;
  const { preloadAttrs, sortingOrder, sortTranslated } = config;
  const preparedOptions = prepareOptions(filtered, preloadAttrs);
  if (sortingOrder) {
    return Object.values(preparedOptions).sort((a, b) => {
      const aI18n = a?.i18n && translate && sortTranslated ? translate(a.i18n) : null;
      const bI18n = b?.i18n && translate && sortTranslated ? translate(b.i18n) : null;

      const aValue = !isNaN(a.value)
        ? a.value
        : (aI18n || a.value).toString().toLowerCase();
      const bValue = !isNaN(b.value)
        ? b.value
        : (bI18n || b.value).toString().toLowerCase();
      if (aValue !== bValue) {
        return sortingOrder === 'asc'
          ? aValue > bValue
            ? 1
            : -1
          : aValue < bValue
          ? 1
          : -1;
      }
      return 0;
    });
  }
  return Object.values(preparedOptions);
}

function includeStaticOptionIfConfigured({ config, preparedOptionsValues }) {
  const { includeStaticOption } = config;
  if (isEmpty(includeStaticOption)) return;
  preparedOptionsValues.unshift(prepareGenericOption(includeStaticOption));
}

function includeGenericVehicleIfConfigured({
  config,
  genericVehicles,
  preparedOptionsValues,
  filters,
}) {
  if (!config.includeGeneric || isEmpty(filters) || isEmpty(genericVehicles))
    return;

  const { preload } = config;
  const genericType = preload === 'fossil_vehicles' ? 'ice' : 'ev';
  const vehicles = get(genericVehicles, genericType);
  const generic = filterVehicles(vehicles, filters);

  if (!isEmpty(generic)) {
    const { handle: value } = generic[0];
    const fallback = 'Generic';
    preparedOptionsValues.unshift(prepareGenericOption({ value, fallback }));
  }
}

function triggerSelectorChangeIfNeeded({
  preparedOptionsValues,
  getValues,
  setValue,
  config,
  defaultValue,
  changingValue,
}) {
  if (!changingValue && (config.defaultValue || defaultValue)) return;

  const preSelectedValue = preparedOptionsValues?.[0]?.value;
  const currentValue = getValues(config.name);

  if (preSelectedValue && currentValue !== preSelectedValue) {
    setValue(config.name, preSelectedValue, { shouldDirty: true });
  }
}

export const FormVehicleAttrSelector = ({
  design,
  ...props
}: {
  design: string;
}) => {
  const [options, setOptions] = useState([]);
  const [preload, setPreload] = useState(null);
  const firstRenderFinished = useRef(false);
  const { translate } = useTranslation();

  const { config: designConfig } = zappy.useDesign(design);
  const { step3Tabs } = zappy.useWatch({ name: 'step3Tabs' });
  const { disabledTabs } = designConfig;
  //disabledTabsConfigs is needed to keep fossil vehicle input working (with previous config)
  //over the edit modal when the tabs are disabled
  const disabledTabsConfigs = !step3Tabs ? disabledTabs : {};

  const { preloads } = useContext(zappy.PreloadsContext);

  const config = useMemo(
    () => ({ ...designConfig, ...disabledTabsConfigs, ...props }),
    [props, designConfig, disabledTabsConfigs]
  );

  const updatedPreload = get(preloads, config.preload);

  const { setValue, getValues, watch } = useFormContext();

  const watchValues = zappy.useWatch(config.watch);

  // sometimes zappy.useWatch is not returning the updated value
  // so we need to force setValue so the watchValues are updated

  const { current: defaultValue } = useRef(getValues(config.name));
  const genericVehicles = get(preloads, 'generic_vehicles');

  /**
   * Determines whether the options for a configuration are ready for use
   */
  const shouldPrepareOptions = useMemo(() => {
    const isWatchedValuesPrepared =
      !config.watch || !Object.values(watchValues).includes(undefined);
    return (
      preload && config.preloadAttrs?.attrForValue && isWatchedValuesPrepared
    );
  }, [config, preload, watchValues]);

  useEffect(() => {
    if (shouldPrepareOptions) {
      const filters = transformWatchValuesIntoFilters(watchValues, config);

      const preparedOptionsValues = prepareOptionsBasedOnWatchValues({
        config,
        preload,
        watchValues,
        filters,
        translate,
      });

      includeGenericVehicleIfConfigured({
        config,
        genericVehicles,
        filters,
        preparedOptionsValues,
      });

      includeStaticOptionIfConfigured({
        config,
        preparedOptionsValues,
      });

      setOptions(preparedOptionsValues);

      triggerSelectorChangeIfNeeded({
        preparedOptionsValues,
        getValues,
        setValue,
        config,
        defaultValue,
        changingValue: firstRenderFinished.current,
      });

      firstRenderFinished.current = true;
    }
  }, [genericVehicles, preload, watchValues]);

  useEffect(() => {
    if (config.source) {
      setPreload(getValues(config.source));
      return;
    }

    if (!updatedPreload || updatedPreload === preload) return;

    setPreload(updatedPreload);
  }, [preload, updatedPreload]);

  return (
    <zappy.FormSelect
      isFetching={isEmpty(options)}
      design={design}
      options={options}
      {...props}
    />
  );
};

FormVehicleAttrSelector.displayName = 'FormVehicleAttrSelector';
