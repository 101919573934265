// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useFormContext } from 'react-hook-form';
import { GenericCatalog } from './GenericCatalog';

import { memo, useCallback, useRef } from 'react';
import isEqual from 'lodash.isequal';
import {
  useResetFiltersForCatalogs
} from "../../hooks/useResetFiltersForCatalogs";

export const VehiclesCatalog = memo(
  ({ design }: { design: string }) => {
    const { setValue, getValues, resetField } = useFormContext();
    const defaultsApplied = useRef(null);
    const resetUtilities = useResetFiltersForCatalogs('vehicles');

    const defaultFiltersFn = useCallback(() => {
      if (defaultsApplied.current) return;
      // Customer's values
      const { type: defType, subtype: defSubtype, ...rest } = getValues();
      // Filters values
      const { type: filType, subType: filSubType } = rest.catalogs.vehicles;
      const category = rest.input.is_offroad ? 'off-road' : 'on-road';

      resetField('catalogs.vehicles.category', { defaultValue: category });
      resetField('catalogs.vehicles.type', { defaultValue: [defType || filType] });
      resetField('catalogs.vehicles.subType', { defaultValue: defSubtype || filSubType });
      resetUtilities.saveFilters();
      defaultsApplied.current = true;
    }, [defaultsApplied.current, resetUtilities.resetOriginalValues]);

    // Used to reset the filters to the original values
    const resetFunction = useCallback(async () => {
      const { fieldValidator, originalFilters } = resetUtilities;
      await fieldValidator('category', originalFilters.category);
      await fieldValidator('type', originalFilters.type);
      await fieldValidator('subType', originalFilters.subType);
      resetUtilities.resetOriginalValues();
    }, [defaultFiltersFn, getValues, setValue, resetUtilities]);

    return (
      <GenericCatalog
        design={design}
        defaultFiltersFn={defaultFiltersFn}
        resetFunction={resetFunction}
        catalogName="vehicles"
        deferredLoading={250}
      />
    );
  },
  (prevProps, nextProps) => {
    // FactoryComponent creates a new function every time it renders, even if the function is the same
    const { onClick: prevOnClick, ...prevRest } = prevProps;
    const { onClick: nextOnClick, ...nextRest } = nextProps;
    return isEqual(prevRest, nextRest);
  }
);

VehiclesCatalog.displayName = 'VehiclesCatalog';
