const OPERATIONS = {
  '#### OUTPUT OPERATION ####': '',
  'app.output.template.routes.operations.template.operations': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'IconText',
          'app.output.template.routes.operations.template.operations.dialog',
        ],
        [
          'Chart',
          'app.output.template.routes.operation.template.annualMaintenance',
        ],
        [
          'Collapsible',
          'app.output.template.routes.operations.template.collapsible.printing',
          {
            displayQuery: 'printing',
          },
        ],
        [
          'Collapsible',
          'app.output.template.routes.operations.template.collapsible',
          {
            displayQuery: 'notPrinting',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.container.onRoad',
          {
            displayQuery: {
              '["props", "vehicleCategoryIsOffRoad"]': { $eq: false },
            },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.container.offRoad',
          {
            displayQuery: {
              '["props", "vehicleCategoryIsOffRoad"]': { $eq: true },
            },
          },
        ],
        [
          'Chart',
          'app.output.template.routes.operation.template.insuranceCosts',
          {
            displayQuery: 'showInsuranceUI',
          },
        ],
        [
          'Collapsible',
          'app.output.template.routes.operations.template.collapsible.insurance.printing',
          {
            displayQuery: 'showPrintCollapsible',
          },
        ],
        [
          'Collapsible',
          'app.output.template.routes.operations.template.collapsible.insurance',
          {
            displayQuery: 'showNotPrintCollapsible',
          },
        ],
      ],
      watch: {
        name: ['assumptionsData.hideInsuranceUI', 'assumptionsData.incentivesType', 'printing'],
      },
      displayQueries: {
        showInsuranceUI: {
          $and: [
            {
              '["watched", "assumptionsData.hideInsuranceUI"]': { $neq: true },
              '["watched", "assumptionsData.incentivesType"]': { $neq: 'commercial' },
            }
          ]
        },
        showPrintCollapsible: {
          $and: [
            {
              '["watched", "printing"]': { $eq: true },
            },
            {
              '["watched", "assumptionsData.hideInsuranceUI"]': { $neq: true },
            },
          ],
        },
        showNotPrintCollapsible: {
          $and: [
            {
              '["watched", "printing"]': { $eq: false },
            },
            {
              '["watched", "assumptionsData.hideInsuranceUI"]': { $neq: true },
            },
          ],
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'row-gap': '30px',
      },
    },
  },
  'app.output.template.routes.operations.template.operations.dialog': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.output.template.routes.operations.template.operations.dialog',
          dangerouslySetInnerHTML: true,
          fallback:
            'Maintenance costs include routine periodic maintenance. Excludes battery replacement.',
        },
      },
    },
  },
  'app.output.template.routes.operations.template.collapsible.insurance.printing':
    {
      _extends:
        'app.output.template.routes.operations.template.collapsible.insurance',
      config: {
        open: true,
      },
    },
  'app.output.template.routes.operations.template.collapsible.insurance': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    config: {
      open: false,
    },
    components: {
      headerText:
        'app.output.template.routes.operations.template.collapsible.insurance.text',
      content:
        'app.output.template.routes.operations.template.collapsible.insurance.content',
    },
    schema: {
      default: {
        'padding-left': '10px',
      },
    },
  },
  'app.output.template.routes.operations.template.collapsible.insurance.text': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.operations.insurance.headerText',
        fallback: 'Insurance Costs Assumptions',
      },
    },
    schema: {
      default: {
        'padding-left': '0px',
      },
    },
  },
  'app.output.template.routes.operations.template.collapsible.insurance.content':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.operation.template.collapsible.insurance.content.wrapper',
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.wrapper':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.insuranceCost',
          ],
          [
            'ContentContainer',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.comprehensive.text',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.offRoad.text',
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.insuranceCost':
    {
      config: {
        component: 'TranslatedText',
        as: 'p',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.insuranceCost.text',
          dangerouslySetInnerHTML: true,
          fallback:
            '<b>Insurance Costs</b> <br/> Insurance cost amounts are estimates. These estimates are based on <a style=" border-bottom: solid 1px var(--primary-color); color: var(--primary-color) " target="_blank" href="https://greet.es.anl.gov/afleet_tool">AFLEET 2020</a> data.',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.text',
          ],
          [
            'ContentContainer',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list',
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.text':
    {
      config: {
        component: 'TranslatedText',
        as: 'p',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.text',
          dangerouslySetInnerHTML: true,
          fallback:
            '<b>Liability insurance</b> is derived from a function of vehicle type and state of operation.',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list':
    {
      config: {
        as: 'ul',
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.a',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.b',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.c',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.d',
          ],
        ],
      },
      schema: {
        default: {
          listStyle: 'lower-latin',
          'margin-left': '17px',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.a':
    {
      config: {
        as: 'li',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.a',
          fallback:
            'For cars, pickups, cargo vans, step vans, utility trucks, specialty vehicles and cutaway chassis 50/100/50 liability insurance is assumed.',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.b':
    {
      config: {
        as: 'li',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.b',
          fallback:
            'For school buses, transit buses shuttle buses, and motor coaches $2M Combined Single Limit (CSL) insurance is assumed.',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.c':
    {
      config: {
        as: 'li',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.c',
          fallback:
            'For box trucks, BEV drive systems, and refuse trucks $500,000 CSL insurance is assumed',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.d':
    {
      config: {
        as: 'li',
        i18n: {
          id: 'i18n.output.template.routes.operation.template.collapsible.insurance.content.liabilityInsurance.list.d',
          fallback: 'For trailer trucks $1M CSL is assumed',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.comprehensive.text':
    {
      config: {
        component: 'TranslatedText',
        as: 'p',
        i18n: {
          id: 'i18n.app.output.template.routes.operation.template.collapsible.insurance.content.comprehensive.text',
          dangerouslySetInnerHTML: true,
          fallback:
            '<b>Comprehensive and Collision insurance</b> - is derived from the vehicle type, state of operation and Estimated Price. This assumes $500k comprehensive / collision coverage..',
        },
      },
    },
  'app.output.template.routes.operation.template.collapsible.insurance.content.offRoad.text':
    {
      config: {
        component: 'TranslatedText',
        as: 'p',
        i18n: {
          id: 'i18n.app.output.template.routes.operation.template.collapsible.insurance.content.offRoad.text',
          dangerouslySetInnerHTML: true,
          fallback:
            '<b>Off Road Vehicle Insurance</b> - insurance is not calculated for off road vehicles. (Please check on premium amounts with your carrier).',
        },
      },
      schema: {
        default: {
          'margin-bottom': '20px',
        },
      },
    },
  'app.output.template.routes.operations.template.collapsible.printing': {
    _extends: 'app.output.template.routes.operations.template.collapsible',
    config: {
      open: true,
    },
  },
  'app.output.template.routes.operations.template.collapsible': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    config: {
      open: false,
    },
    components: {
      headerText:
        'app.output.template.routes.operations.template.collapsible.text',
      content:
        'app.output.template.routes.operations.template.collapsible.content',
    },
    schema: {
      default: {
        'padding-left': '10px',
      },
    },
  },
  'app.output.template.routes.operations.template.collapsible.text': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.operations.vehicleSet.headerText',
        fallback: 'Maintenance Costs Assumptions',
      },
    },
    schema: {
      default: {
        'padding-left': '0px',
      },
    },
  },
  'app.output.template.routes.operations.template.collapsible.content': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.collapsible.content.wrapper',
        ],
      ],
    },
  },
  'app.output.template.routes.operation.template.collapsible.content.wrapper': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.sectionTitle.title',
        ],
        [
          'ContentContainer',
          'app.output.template.operationTableContainer.tableSection',
        ],

        [
          'TranslatedText',
          'app.output.template.routes.operation.template.source',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.costEV',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.costICE',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  'app.output.template.operationTableContainer.tableSection': {
    config: {
      content: [['Table', 'app.output.template.operationTable.operationSpecs']],
    },
  },
  'app.output.template.operationTable.operationSpecs': {
    config: {
      component: 'Table',
      rowsDesign: 'app.output.template.operationTable.operationSpecs.rows',
      targetSource: 'vehicleSetsData',
    },
  },
  'app.output.template.operationTable.operationSpecs.rows': {
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.operationTable.operationSpecs.cellContainer',
        ],
      ],
    },
  },
  'app.output.template.operationTable.operationSpecs.cellContainer': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.vehicleSet.title',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.vehicleSet.list',
        ],
      ],
    },
  },
  'app.output.template.routes.operation.template.sectionTitle.title': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.output.template.routes.operation.maintenanceCosts.text',
        fallback: 'Maintenance costs are based on',
      },
    },
  },
  'app.output.template.routes.operation.template.vehicleSet.title': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.vehicleSet.title.text',
        fallback: '{{rowData[vehicleSetName]}}',
      },
    },
  },
  'app.output.template.routes.operation.template.vehicleSet.list': {
    config: {
      as: 'ul',
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.vehicleSet.annualMiles',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.vehicleSet.perMileElectric',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.vehicleSet.perMileFossil',
        ],
      ],
    },
    schema: {
      default: {
        listStyle: 'disc',
        'margin-left': '30px',
      },
    },
  },
  'app.output.template.routes.operation.template.vehicleSet.annualMiles': {
    config: {
      as: 'li',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.annualMiles.text',
        fallback:
          '{{rowData[name]}} are being driven by {{rowData[annualMiles]}} miles per year',
      },
    },
  },
  'app.output.template.routes.operation.template.vehicleSet.perMileElectric': {
    config: {
      as: 'li',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.perMileElectric.text',
        fallback:
          '{{rowData[perMileElectric]}} per mile for electric {{rowData[outputName]}}',
      },
    },
  },
  'app.output.template.routes.operation.template.vehicleSet.perMileFossil': {
    config: {
      as: 'li',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.perMileFossil.text',
        fallback:
          '{{rowData[perMileFossil]}} per mile for an archetype fossil fuel {{rowData[outputName]}}',
      },
    },
  },
  'app.output.template.routes.operation.template.source': {
    config: {
      component: 'TranslatedText',
      as: 'p',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.source.text',
        dangerouslySetInnerHTML: true,
        fallback:
          'SOURCE: <a style=" border-bottom: solid 1px var(--primary-color); color: var(--primary-color) " target="_blank" href="https://greet.es.anl.gov/afleet_tool">AFLEET 2020</a> and manufacturers figures (where available).',
      },
    },
  },
  'app.output.template.routes.operation.template.costEV': {
    config: {
      component: 'TranslatedText',
      as: 'p',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.costEV.text',
        dangerouslySetInnerHTML: true,
        fallback:
          'Maintenance costs for EVs are increased after year 1 based on this formula which is modeled on AFLEET annual cost increase percentages: <br/> %increase = 0.0004 * year<sup>4</sup> - 0.0128 * year<sup>3</sup> + 0.126 * year<sup>2</sup> - 0.1762 * year + 1.0563.',
      },
    },
  },
  'app.output.template.routes.operation.template.costICE': {
    config: {
      component: 'TranslatedText',
      as: 'p',
      i18n: {
        id: 'i18n.output.template.routes.operation.template.costICE.text',
        dangerouslySetInnerHTML: true,
        fallback:
          'Maintenance cost for ICE vehicles is increased after year 1 based on this formula which is modeled on AFLEET annual cost increase percentages: <br/>  % increase = 0.0002 * year<sup>4</sup> - 0.0065 * year<sup>3</sup> + 0.0547 * year<sup>2</sup> + 0.1258 * year + 0.7821.',
      },
    },
    schema: {
      default: {
        'margin-bottom': '20px',
      },
    },
  },
  'app.output.template.routes.operation.template.annualMaintenance': {
    _extends: 'app.output.print.common.canvas',
    config: {
      type: 'bar',
      scaleOptions: {
        y: {
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
      },
      labelsTemplate: {
        id: 'i18n.app.output.template.routes.operation.template.annualMaintenance.label.{{index}}',
        fallback: 'Year {{index}}',
        startingValue: 1,
      },
      pick: [
        {
          source: 'fossilFuelVehicles',
          label: {
            id: 'i18n.barChartWithValuesProps.fossil.label',
            fallback: 'Fossil Fuel Vehicles',
          },
          backgroundColor: 'var(--chart-color-3)',
        },
        {
          source: 'electricVehicles',
          label: {
            id: 'i18n.barChartWithValuesProps.electric.label',
            fallback: 'Electric Vehicles',
          },
          backgroundColor: 'var(--chart-color-4)',
        },
      ],
    },
    components: {
      title:
        'app.output.template.routes.operation.template.annualMaintenance.title',
    },
  },
  'app.output.template.routes.operation.template.annualMaintenance.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.operation.template.annualMaintenance.title',
        fallback: 'Annual Maintenance Costs',
      },
    },
  },
  'app.output.template.routes.operation.template.insuranceCosts': {
    _extends: 'app.output.print.common.canvas',
    config: {
      type: 'bar',
      scaleOptions: {
        x: {
          stacked: true,
        },
        y: {
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
      },
      sourceParent: 'annualInsuranceCosts',
      labels: [
        { id: '', fallback: 'Fossil Fuel Vehicles' },
        { id: '', fallback: 'Electric Vehicles' },
      ],
      pick: [
        {
          source: 'fossilInsurance',
          barPercentage: 0.6,
          label: {
            id: 'i18n.barChartWithValuesProps.fossil.label',
            fallback: 'Fossil Fuel Vehicles',
          },
          backgroundColor: 'var(--chart-color-3)',
        },
        {
          prependData: [0],
          source: 'electricInsurance',
          barPercentage: 0.6,
          label: {
            id: 'i18n.barChartWithValuesProps.electric.label',
            fallback: 'Electric Vehicles',
          },
          backgroundColor: 'var(--chart-color-4)',
        },
      ],
    },
    components: {
      title:
        'app.output.template.routes.operation.template.insuranceCosts.title',
    },
    schema: {
      default: {
        breakInside: 'avoid',
      },
    },
  },
  'app.output.template.routes.operation.template.insuranceCosts.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.operation.template.insuranceCosts.title',
        fallback: 'Annual Insurance Costs',
      },
    },
  },

  // ON ROAD
  'app.output.template.routes.operation.template.tables.container.onRoad': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.individual.container.electricFleet.onRoad',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.individual.container.iceFleet.onRoad',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '24px',
        marginTop: '24px',
      },
    },
  },
  'app.output.template.routes.operation.template.tables.individual.container.electricFleet.onRoad':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.tablesTitle',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.operation.template.tables.individual.container.electricFleet.onRoad',
                fallback: 'Electric Vehicle Fleet',
              },
            },
          ],
          [
            'Table',
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric',
            {
              targetSource: 'emptyDynamicTables',
              globalDataSource: 'maintenanceDataElectric',
            },
          ],
        ],
      },
      schema: {
        default: {
          flexBasis: '100%',
        },
      },
    },
  'app.output.template.routes.operation.template.tables.individual.container.iceFleet.onRoad':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.tablesTitle',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.operation.template.tables.individual.container.iceFleet.onRoad',
                fallback: 'ICE Vehicle Fleet',
              },
            },
          ],
          [
            'Table',
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice',
            {
              targetSource: 'emptyDynamicTables',
              globalDataSource: 'maintenanceDataFossil',
            },
          ],
        ],
      },
      schema: {
        default: {
          flexBasis: '100%',
        },
      },
    },

  //OFF ROAD
  'app.output.template.routes.operation.template.tables.container.offRoad': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.individual.container.electricFleet.offRoad',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.operation.template.tables.individual.container.iceFleet.offRoad',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '24px',
        marginTop: '24px',
      },
    },
  },
  'app.output.template.routes.operation.template.tables.individual.container.electricFleet.offRoad':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.tablesTitle',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.operation.template.tables.individual.container.electricFleet.offRoad',
                fallback: 'Electric Vehicle Fleet',
              },
            },
          ],
          [
            'Table',
            'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.electric',
            {
              targetSource: 'emptyDynamicTables',
              globalDataSource: 'maintenanceDataElectric',
            },
          ],
        ],
      },
      schema: {
        default: {
          flexBasis: '100%',
        },
      },
    },
  'app.output.template.routes.operation.template.tables.individual.container.iceFleet.offRoad':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.operation.template.tablesTitle',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.operation.template.tables.individual.container.iceFleet.offRoad',
                fallback: 'ICE Vehicle Fleet',
              },
            },
          ],
          [
            'Table',
            'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.ice',
            {
              targetSource: 'emptyDynamicTables',
              globalDataSource: 'maintenanceDataFossil',
            },
          ],
        ],
      },
      schema: {
        default: {
          flexBasis: '100%',
        },
      },
    },

  //VEHICLE FLEET ON-ROAD ELECTRIC
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric':
    {
      _extends: 'app.output.template.routes.operation.template.tables',
      config: {
        bodyRows: [
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.0',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.1',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.2',
          ],
        ],
        cellsDesign:
          'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.cells',
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.0':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.0.text',
                fallback: 'Average Maintenance Cost Per Mile',
              },
            },
          ],
          [
            'Formatter',
            '',
            {
              pick: 'globalData[perMile]',
              formatter: 'formatAsCents',
            },
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.1':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.1.text',
                fallback: 'Maintenance Cost Per Year',
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.operation.template.tables.vehicleFleet.perYear',
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.2':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.2.text',
                fallback: 'Total Maintenance Cost',
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.operation.template.tables.vehicleFleet.totalCell',
          ],
        ],
      },
    },

  //VEHICLE FLEET ON-ROAD ICE
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice':
    {
      _extends: 'app.output.template.routes.operation.template.tables',
      config: {
        bodyRows: [
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.0',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.1',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.2',
          ],
        ],
        cellsDesign:
          'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.cells',
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.0':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.0.text',
                fallback: 'Average Maintenance Cost Per Mile',
              },
            },
          ],
          [
            'Formatter',
            '',
            {
              pick: 'globalData[perMile]',
              formatter: 'formatAsCents',
            },
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.1':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.1.text',
                fallback: 'Maintenance Cost Per Year',
              },
            },
          ],
          [
            'Formatter',
            '',
            {
              pick: 'globalData[perYear]',
              formatter: 'formatAsDollars',
            },
          ],
        ],
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.2':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.2.text',
                fallback: 'Total Maintenance Cost',
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.operation.template.tables.vehicleFleet.totalCell',
          ],
        ],
      },
    },

  //VEHICLE FLEET OFF-ROAD ELECTRIC
  'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.electric':
    {
      _extends: 'app.output.template.routes.operation.template.tables',
      config: {
        bodyRows: [
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.electric.rows.0',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.1',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.2',
          ],
        ],
        cellsDesign:
          'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.cells',
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.electric.rows.0':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.electric.rows.0.text',
                fallback: 'Maintenance Cost Per Hour',
              },
            },
          ],
          [
            'Formatter',
            '',
            {
              pick: 'globalData[perHour]',
              formatter: 'formatAsCents',
            },
          ],
        ],
      },
    },

  //VEHICLE FLEET OFF-ROAD ICE
  'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.ice':
    {
      _extends: 'app.output.template.routes.operation.template.tables',
      config: {
        bodyRows: [
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.ice.rows.0',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.1',
          ],
          [
            'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.2',
          ],
        ],
        cellsDesign:
          'app.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.cells',
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleetOffRoad.ice.rows.0':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.output.template.routes.operation.template.tables.vehicleFleetOnRoad.ice.rows.0.text',
                fallback: 'Maintenance Cost Per Hour',
              },
            },
          ],
          [
            'Formatter',
            '',
            {
              pick: 'globalData[perHour]',
              formatter: 'formatAsCents',
            },
          ],
        ],
      },
    },

  //COMMON ELEMENTS IN COSTS PER MILE/HOUR TABLES
  'app.output.template.routes.operation.template.tablesTitle': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        fontSize: '22px',
        marginBottom: '16px',
      },
    },
  },
  'app.output.template.routes.operation.template.tables': {
    schema: {
      default: {
        width: '100%',
        fontSize: 'var(--label-font-size)',
        'td:first-child': {
          fontWeight: 'bold',
        },
        'td:last-child': {
          textAlign: 'right',
        },
        'tr:not(:last-child)': {
          borderBottom: '1px solid #ACB1BA',
        },
        tr: {
          height: '40px',
        },
      },
    },
  },
  'app.output.template.routes.operation.template.tables.vehicleFleet.totalCell':
    {
      config: {
        pick: 'globalData[total]',
        formatter: 'formatAsDollars',
      },
    },
  'app.output.template.routes.operation.template.tables.vehicleFleet.perYear': {
    config: {
      pick: 'globalData[perYear]',
      formatter: 'formatAsDollars',
    },
  },
};

export default OPERATIONS;
