// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useCalculationSelectors } from '../../../../selectors/calculations';
import { useValidateMultipleStatusElements } from '../../../../hooks/useValidateMultipleStatusElements';

export const OutputFuelCosts = ({ design }: { design: string }) => {
  const { schema } = zappy.useDesign(design);
  const [
    { data, status },
    { data: ratesTableData, status: ratesTableStatus },
    { data: tableContent, status: fuelCostsTableStatus },
  ] = useCalculationSelectors('fuelCosts', 'ratesTable', 'fuelCostsTable');
  const combinedData = { ...data, ratesTableData, tableContent };

  const isLoadingComplete = useValidateMultipleStatusElements(
    status.isLoading,
    ratesTableStatus.isLoading,
    fuelCostsTableStatus.isLoading
  );

  return !isLoadingComplete ? (
    'Loading'
  ) : (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <zappy.ContentContainer design={design} values={combinedData || {}} />
    </zappy.FactoryComponent>
  );
};

OutputFuelCosts.displayName = 'OutputFuelCosts';
