export const status = {
  NOT_READY: 'NOT_READY',
  STORED_DATA_SETUP_INIT: 'STORED_DATA_SETUP_INIT',
  STORED_DATA_SETUP_DONE: 'STORED_DATA_SETUP_DONE',
  FULL_SETUP_INIT: 'FULL_SETUP_INIT',
  FULL_SETUP_DONE: 'FULL_SETUP_DONE',
  START_OVER: 'START_OVER',
  READY: 'READY',
};

export const stateAttrs = {
  STATUS: 'status',

  IS_NOT_READY: 'isNotReady',
  IS_STORED_DATA_SETUP_DONE: 'isStoredDataSetupDone',
  IS_FULL_SETUP_RUNNING: 'isFullSetupRunning',
  IS_READY: 'isReady',

  IS_LOADED_FROM_SHARED_LINK: 'isLoadedFromSharedLink',
  IS_LOADED_FROM_LOCALSTORAGE: 'isLoadedFromLocalStorage',
  IS_CALCULATIONS_READY: 'isCalculationsReady',
  IS_ASSUMPTIONS_DATA_READY: 'isAssumptionsDataReady',
  IS_OUTPUT_PREALODS_READY: 'isOutputPreloadsReady',
  IS_INPUT_OUTPUT_READY: 'isInputOutputReady',
  IS_PROJECT_READY: 'isProjectReady',
  IS_SAVE_TO_LOCALSTORAGE_ENABLED: 'isSaveToLocalStorageEnabled',
  IS_LOCATION_READY: 'isLocationReady',
  IS_FILTERS_CHANGED: 'isFiltersChanged',
  IS_POWER_SUPPLIERS_READY: 'isPowerSuppliersReady',
  IS_COMSUMER_DATA_SENT: 'isConsumerDataSent',
};
