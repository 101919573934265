const OUTPUT_EMISSIONS = {
  'app.output.template.routes.emissions.template': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.emissions.header',
          {
            displayQuery: 'mobileOrPrinting',
            className: 'pageHeader'
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.emissions.template.cardsContainer',
          {
            className: 'cardsContainer',
          }
        ],
        [
          'ContentContainer',
          'app.output.template.routes.emissions.template.emissionsTable',
          {
            displayQuery: 'desktopOrPrinting',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.emissions.template.emissionsTable.mobile',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
    },
  },
  'app.output.template.mobile.emissions.header': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.emissions.header.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
          {
            className: 'headerActionButton'
          }
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',
        marginBottom: '25px',
      },
    },
  },
  'app.output.template.mobile.emissions.header.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.emissions.header.title',
        fallback: 'Emissions',
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable': {
    config: {
      content: [
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.table.onRoad',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
            displayQuery: 'onRoad',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.table.offRoad',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
            displayQuery: 'offRoad',
          },
        ],
      ],
      displayQueries: {
        onRoad: { '["props", "isOffRoad"]': { $neq: true } },
        offRoad: {
          '["props", "isOffRoad"]': { $eq: true },
        },
      },
    },
  },

  //Template Cards
  'app.output.template.routes.emissions.template.cardsContainer': {
    config: {
      content: [
        [
          'IconText',
          'app.output.template.routes.emissions.template.emissionsCard.card1',
        ],
        [
          'IconText',
          'app.output.template.routes.emissions.template.emissionsCard.card2',
        ],
        [
          'IconText',
          'app.output.template.routes.emissions.template.emissionsCard.card3',
        ],
        [
          'IconText',
          'app.output.template.routes.emissions.template.emissionsCard.card4',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '10px',
        marginBottom: '25px',
        '@media (max-width: 992px)': {
          flexDirection: 'column',
        },
      },
    },
  },

  // Emissions Table
  'app.output.template.routes.emissions.template.emissionsTable.table.onRoad': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      bodyRows: [
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row0',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row1',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row2',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row3',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row4',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row5',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row6',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row7',
        ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row8',
        ],
        // TODO: Add this rows when the GHG data is ready - Rows: 9, 10, 11
        // [
        //   'app.output.template.routes.emissions.template.emissionsTable.table.row9',
        // ],
        // [
        //   'app.output.template.routes.emissions.template.emissionsTable.table.row10',
        // ],
        // [
        //   'app.output.template.routes.emissions.template.emissionsTable.table.row11',
        // ],
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.row12',
        ],
      ],
      rowsDesign:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      headerRows: [
        [
          'app.output.template.routes.emissions.template.emissionsTable.table.header.row1',
        ],
      ],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.table.offRoad':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.onRoad',
      config: {
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row0.offRoad',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row1.offRoad',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row2',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row3',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row4',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row5',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row6',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row7',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row8',
          ],
          // TODO: Add this rows when the GHG data is ready - Rows: 9, 10, 11
          // [
          //   'app.output.template.routes.emissions.template.emissionsTable.table.row9',
          // ],
          // [
          //   'app.output.template.routes.emissions.template.emissionsTable.table.row10',
          // ],
          // [
          //   'app.output.template.routes.emissions.template.emissionsTable.table.row11',
          // ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.table.row12',
          ],
        ],
      },
    },

  // Header Rows
  'app.output.template.routes.emissions.template.emissionsTable.table.header.row1':
    {
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.header.row1.item',
                fallback: 'Item',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.header.row1.diesel',
                fallback: 'Diesel',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.header.row1.gasoline',
                fallback: 'Gasoline',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.header.row1.notes',
                fallback: 'Notes',
              },
            },
          ],
        ],
      },
      schema: {
        default: {
          fontSize: '14px',
          fontWeight: 'bold',
          '& > td': {
            height: '54px',
          },
          '& > td:nth-child(1)': {
            paddingRight: '10px',
            minWidth: '250px',
          },
          '& > td:nth-child(2)': {
            minWidth: '180px',
          },
          '& > td:nth-child(3)': {
            maxWidth: '110px',
            width: '110px',
          },
          '& > td:nth-child(4)': {
            width: '280px',
          },
        },
      },
    },

  // Static Created Rows
  'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign':
    {
      schema: {
        default: {
          '& > td': {
            height: '54px',
            borderTop: '1px solid #e0e0e0',
            lineHeight: '14px',
          },
          '& > td:nth-child(1)': {
            fontWeight: 'bold',
          },
          '@media (max-width: 992px)': {
            '& > td:nth-child(1)': {
              width: '25%',
            },
            '& > td': {
              height: 'auto',
              padding: '0.7em 0',
              fontSize: 'var(--font-size-mobile)',
            },
          },
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row0': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.annualMiles.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.annualMiles.diesel',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.annualMiles.gasoline',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.annualMiles.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.annualMiles.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.annualMiles.label',
          fallback: 'Annual Miles',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.annualMiles.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[annualMiles_diesel]',
        autoPick: 'miles',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.annualMiles.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[annualMiles_gas]',
        autoPick: 'miles',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.annualMiles.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.annualMiles.notes',
          fallback: 'The total number of miles driven annually by your fleet.',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row1': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.diesel',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.gasoline',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.label',
          fallback: 'Fossil Fuel Volume',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[fossilFuelVolume_diesel]',
        autoPick: 'fuelVolume',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[fossilFuelVolume_gas]',
        autoPick: 'fuelVolume',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.notes',
          fallback:
            "For each vehicle set, we applied each vehicle's mpg to the annual miles driven.",
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row2': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.diesel',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.gasoline',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.table.commonNote',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.label':
    {
      config: {
        i18n: {
          id: 'output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.label',
          fallback: 'Fossil Fuel CO₂ Emissions',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[fossilFuelCO2Emissions_diesel]',
        autoPick: 'co2EmissionsLbsGal',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[fossilFuelCO2Emissions_gas]',
        autoPick: 'co2EmissionsLbsGal',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row3': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.diesel',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.gasoline',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.label',
          fallback: 'CO₂ Emissions by Fossil Fuel',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[cO2EmissionsByFossilFuel_diesel]',
        autoPick: 'emissionsLbs',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[cO2EmissionsByFossilFuel_gas]',
        autoPick: 'emissionsLbs',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.notes',
          fallback: '"Fossil Fuel Volume" * "CO₂ Emissions In Lbs/Gal"',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row4': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.label',
          fallback: 'Total CO₂ Emissions',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.centerAlign': {
    schema: {
      default: {
        'text-align': 'center',
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions':
    {
      _extends: [
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
        'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
      ],
      config: {
        pick: 'globalData[totalCO2Emissions]',
        autoPick: 'co2EmissionsLbs',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.notes',
          fallback:
            '"Diesel CO₂ Emissions" + "Gasoline CO₂ Emissions" + "LPG CO₂ emissions"',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row5': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.label',
          fallback: 'Electricity Equivalent',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent':
    {
      _extends: [
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
        'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
      ],
      config: {
        pick: 'globalData[electricityEquivalent]',
        autoPick: 'emissionsMwh',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.notes',
          fallback:
            'The amount of electricity required to replace the selected fleet of fossil fuel vehicles one-to-one with electric vehicles.',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row6': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.table.commonNote',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions.label',
          fallback: 'Electricity CO₂ Emissions',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions':
    {
      _extends: [
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
        'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
      ],
      config: {
        pick: 'globalData[electricityCO2Emissions]',
        autoPick: 'emissionsLbsMwh',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row7': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.label',
          fallback: 'CO₂ Emissions from EV charging',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging':
    {
      _extends: [
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
        'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
      ],
      config: {
        pick: 'globalData[cO2EmissionsFromEVCharging]',
        autoPick: 'co2EmissionsLbs',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.notes',
        }
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row8': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.label',
          fallback: 'CO₂ Emissions Reduced',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced':
    {
      _extends: [
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
        'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
      ],
      config: {
        pick: 'globalData[cO2EmissionsReduced]',
        autoPick: 'co2EmissionsLbs',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.notes',
          fallback:
            '"Total Emissions from Fossil Fuels" - "CO₂ Emissions for Equivalent EVs"',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row9': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.label',
        ],
        [
          'Formatter',
          'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.label',
          fallback: 'CO₂ to GHG conversion',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[cO2ToGHGConversion]',
        autoPick: 'formatAsNumber',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.notes',
          fallback:
            'Conversion based on Global Warming Potential of the Primary Greenhouse Gases',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row10': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.label',
        ],
        [
          'Formatter',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.label',
          fallback: 'GHG Emissions Reduced',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[gHGEmissionsReduced]',
        autoPick: 'emissionsLbsGhg',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.notes',
          fallback: 'CO₂ Emissions Reduced converted to GHG',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row11': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.label',
        ],
        [
          'Formatter',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.notes',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.label',
          fallback: 'GHG Emissions Reduced (Simplified)',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      config: {
        pick: 'globalData[gHGEmissionsReducedSimplified]',
        autoPick: 'emissionsTonsGhg',
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.notes':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.notes',
          fallback: 'A US ton is equivalent to 2,000 lbs',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row12': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.treesPlanted.label',
        ],
        [
          'Formatter',
          'app.output.template.routes.emissions.template.emissionsTable.treesPlanted',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.emissions.template.emissionsTable.table.EEANote',
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.treesPlanted.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.treesPlanted.label',
          fallback: 'Trees Planted (Equivalency)',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.treesPlanted': {
    _extends: [
      'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
      'app.output.template.routes.emissions.template.emissionsTable.centerAlign',
    ],
    config: {
      pick: 'globalData[treesPlanted]',
      autoPick: 'trees',
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.table.EEANote':
    {
      component: 'TranslatedText',
      config: {
        values: {
          url: 'https://www.eea.europa.eu/articles/forests-health-and-climate-change/key-facts/trees-help-tackle-climate-change',
        },
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.EEANote.label',
          dangerouslySetInnerHTML: true,
          fallback:
            'Assumes a tree absorbs 48lbs of CO₂ per year. Source: <a href="{{url}}" class="link" target="_blank">European Environment Agency</a>',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.commonNote':
    {
      component: 'TranslatedText',
      config: {
        values: {
          url: 'https://www.eia.gov/environment/emissions/co2_vol_mass.php',
        },
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.commonNote.label',
          dangerouslySetInnerHTML: true,
          fallback:
            'Source: <a href="{{url}}" class="link" target="_blank">U.S. Energy Information Administration</a>',
        },
      },
      schema: {
        default: {
          '& > span > a.link': {
            color: 'var(--color-info)',
            textDecoration: 'underline',
          },
        },
      },
    },

  //OffRoad Row 1
  'app.output.template.routes.emissions.template.emissionsTable.table.row0.offRoad':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.row1',
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.row0.offRoad.annualHours',
                fallback: 'Annual Hours',
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
            {
              pick: 'globalData[annualHours_diesel]',
              autoPick: 'hours',
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
            {
              pick: 'globalData[annualHours_gas]',
              autoPick: 'hours',
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.row0.offRoad.totalNumberHours',
                fallback: 'The total number of hours your fleet is operated.',
              },
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.table.row1.offRoad':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.row1',
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.row1.offRoad.fossilFuelVolume',
                fallback: 'Fossil Fuel Volume',
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
            {
              pick: 'globalData[fossilFuelVolume_diesel]',
              autoPick: 'fuelVolumePerHour',
            },
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
            {
              pick: 'globalData[fossilFuelVolume_gas]',
              autoPick: 'fuelVolumePerHour',
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.emissions.template.emissionsTable.table.row1.offRoad.vehicleSetDisclaimer',
                fallback:
                  'For each vehicle set, we applied each vehicle’s gal/hr to the annual hours ran.',
              },
            },
          ],
        ],
      },
    },

  //Cards for the Emissions page
  'app.output.template.routes.emissions.template.emissionsCard.card1': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsCard.template.card',
    config: {
      icon: {
        src: 'cloud',
      },
      header: {
        pick: 'dialogBoxesInfo.co2Yearly',
        autoPick: 'tons',
        component: 'Formatter',
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.card.breakeven.text.perYear',
          fallback: 'of CO₂ potentially saved per year',
        },
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsCard.card2': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsCard.template.card',
    config: {
      icon: {
        src: 'cloud',
      },
      header: {
        pick: 'dialogBoxesInfo.co2Lifetime',
        autoPick: 'tons',
        component: 'Formatter',
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.card.breakeven.text.lifetime',
          fallback: 'of CO₂ potentially saved over vehicle lifetime',
        },
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsCard.card3': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsCard.template.card',
    config: {
      icon: {
        src: 'leaf',
      },
      header: {
        component: 'Formatter',
        formatter: 'formatAsThousands',
        pick: 'dialogBoxesInfo.treesPlantedYearly',
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.card.breakeven.text.treesPlanted.perYear',
          fallback: 'trees planted per year',
        },
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsCard.card4': {
    _extends:
      'app.output.template.routes.emissions.template.emissionsCard.template.card',
    config: {
      icon: {
        src: 'leaf',
      },
      header: {
        component: 'Formatter',
        formatter: 'formatAsThousands',
        pick: 'dialogBoxesInfo.treesPlantedLifetime',
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.emissions.template.card.breakeven.text.treesPlanted.lifetime',
          fallback: 'Trees planted over vehicle lifetime',
        },
      },
    },
  },
  'app.output.template.routes.emissions.template.emissionsCard.template.card': {
    _extends: 'app.output.template.routes.overview.template.card',
    components: {
      icon: 'app.common.icon',
      header:
        'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
    },
    config: {
      icon: {
        schema: {
          default: {
            gridArea: 'icon',
            marginBottom: 0,
          },
        },
      },
      header: {
        schema: {
          default: {
            fontWeight: 'bold',
            gridArea: 'header',
            textAlign: 'left',
          },
        },
      },
      text: {
        schema: {
          default: {
            marginTop: 0,
            gridArea: 'description',
            textAlign: 'left',
            fontSize: '14px',
            alignSelf: 'baseline',
            lineHeight: '120%',
          },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        gridTemplateAreas: '"icon header" "icon description"',
        'grid-column-gap': '24px',
        alignItems: 'baseline',
        '@media (max-width: 992px)': {
          'grid-column-gap': '20px',
          padding: '12px 20px',
        },
      },
    },
  },

  'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value':
    {
      config: {
        locale: 'en-US',
        autoFormats: {
          fuelVolume: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} Gal',
            i18n: 'i18n.app.formatter.autoFormats.values.fuelVolume',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          fuelVolumePerHour: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} KWh',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          co2EmissionsLbsGal: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} Lbs CO₂/Gal',
            i18n:'i18n.app.formatter.autoFormats.values.co2EmissionsLbsGal',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          co2EmissionsLbs: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            i18n: 'i18n.app.formatter.autoFormats.values.co2EmissionsLbs',
            text: '{{picked}} Lbs CO₂',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          emissionsLbs: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            i18n: 'i18n.app.formatter.autoFormats.values.emissionsLbs',
            text: '{{picked}} Lbs',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          emissionsLbsGhg: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            i18n: 'i18n.app.formatter.autoFormats.values.emissionsLbsGhg',
            text: '{{picked}} Lbs GHG',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          emissionsTonsGhg: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} Tons GHG',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          emissionsMwh: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} MWh',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          emissionsLbsMwh: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            i18n: 'i18n.app.formatter.autoFormats.values.emissionsLbsMwh',
            text: '{{picked}} Lbs/MWh',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          trees: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            i18n: 'i18n.app.formatter.autoFormats.values.trees',
            text: '{{picked}} trees',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          tons: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} tons',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          miles: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} miles',
            id:'i18n.app.formatter.autoFormats.values.miles',
            i18n:'i18n.app.formatter.autoFormats.values.miles',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
          hours: {
            partials: [
              {
                pick: 'useParentPick',
                format: {
                  formatter: 'formatAsNumber',
                  maximumFractionDigits: 0,
                  as: 'useParentPick',
                },
              },
            ],
            text: '{{picked}} hours',
            formatter: 'interpolate',
            fallback: 'N/A',
          },
        },
      },
    },

  // Mobile Common
  'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          fallback: 'Diesel',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label',
          fallback: 'Gasoline',
        },
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          fallback: 'Notes',
        },
      },
    },
  'app.output.emissions.common.tables.mobile': {
    _extends: 'app.output.common.tables',
    schema: {
      default: {
        marginBottom: '25px',
      },
    },
  },
  'app.output.emissions.common.tables.mobile.headerRow': {
    schema: {
      default: {
        fontWeight: 'bold',
      },
    },
  },

  // Mobile
  'app.output.template.routes.emissions.template.emissionsTable.mobile': {
    config: {
      content: [
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
        // [
        //   'Table',
        //   'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion',
        //   {
        //     targetSource: 'emissionsTableData.content',
        //     globalDataSource: 'emissionsTableGlobalData',
        //   },
        // ],
        // [
        //   'Table',
        //   'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced',
        //   {
        //     targetSource: 'emissionsTableData.content',
        //     globalDataSource: 'emissionsTableGlobalData',
        //   },
        // ],
        // [
        //   'Table',
        //   'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified',
        //   {
        //     targetSource: 'emissionsTableData.content',
        //     globalDataSource: 'emissionsTableGlobalData',
        //   },
        // ],
        [
          'Table',
          'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted',
          {
            targetSource: 'emissionsTableData.content',
            globalDataSource: 'emissionsTableGlobalData',
          },
        ],
      ],
    },
  },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.gasoline',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.annualMiles.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'i18nFormatter',
            'app.output.template.routes.emissions.template.emissionsTable.annualMiles.diesel',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label',
          ],
          [
            'i18nFormatter',
            'app.output.template.routes.emissions.template.emissionsTable.annualMiles.gasoline',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.annualMiles.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.annualMiles.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.gasoline',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'i18nFormatter',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.diesel',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label',
          ],
          [
            'i18nFormatter',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.gasoline',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelVolume.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelVolume.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.gasoline',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'i18nFormatter',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.diesel',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.fossilFuelCO2Emissions.gasoline',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.fossilFuelCO2Emissions.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.table.commonNote',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.gasoline',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.diesel',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.gasoline':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.gasoline.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.gasoline',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsByFossilFuel.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsByFossilFuel.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.totalCO2Emissions.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.totalCO2Emissions.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityEquivalent.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.electricityEquivalent.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.electricityCO2Emissions',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.electricityCO2Emissions.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.table.commonNote',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsFromEVCharging.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsFromEVCharging.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2EmissionsReduced.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2EmissionsReduced.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.cO2ToGHGConversion.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.cO2ToGHGConversion.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReduced.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReduced.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.gHGEmissionsReducedSimplified.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.gHGEmissionsReducedSimplified.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted':
    {
      _extends: 'app.output.emissions.common.tables.mobile',
      config: {
        component: 'Table',
        headerRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.header',
          ],
        ],
        bodyRows: [
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.diesel',
          ],
          [
            'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.notes',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.header':
    {
      _extends: 'app.output.emissions.common.tables.mobile.headerRow',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.treesPlanted.label',
            {},
            {
              colSpan: 3,
            },
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.diesel':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.diesel.label',
          ],
          [
            'Formatter',
            'app.output.template.routes.emissions.template.emissionsTable.treesPlanted',
          ],
        ],
      },
    },
  'app.output.template.routes.emissions.template.emissionsTable.mobile.treesPlanted.notes':
    {
      _extends:
        'app.output.template.routes.emissions.template.emissionsTable.table.rowsDesign',
      config: {
        cells: [
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.mobile.common.notes.label',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.emissions.template.emissionsTable.table.EEANote',
          ],
        ],
      },
    },
};

export default OUTPUT_EMISSIONS;
