/* eslint-disable no-template-curly-in-string */
const FUEL_COSTS = {
  // ##### OUTPUT FUEL COSTS :: ELECTRICITY COSTS #####
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts',
        ],
        [
          'Collapsible',
          'app.output.template.routes.fuelCosts.template.collapsible',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'TranslatedText',
          'app.output.fuelCosts.fleetElectricityCosts.text',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.caption',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'IconText',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'Chart',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.overview.template.fuelCosts',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'Collapsible',
          'app.output.template.routes.rateComponentTable.template.collapsible',
          {
            displayQuery: 'showCustomRateEngine',
          },
        ],
      ],
      watch: {
        name: ['device'],
      },
      displayQueries: {
        mobile: {
          '["watched", "device"]': {
            $eq: 'mobile',
          },
        },
        desktop: {
          '["watched", "device"]': {
            $eq: 'desktop',
          },
        },
        mobileOrPrinting: {
          $or: [
            { '["watched", "device"]': { $eq: 'mobile' } },
            { '["watched", "printing"]': { $eq: true } },
          ],
        },
        showCustomRateEngine: {
          $and: [
            { '["props", "tableContent", "isCustomRateEngine"]': { $eq: false } },
            {
              $or: [
                { '["watched", "device"]': { $eq: 'desktop' } },
                { '["watched", "printing"]': { $eq: true } },
              ],
            }
          ],
        }
      },
    },
    schema: {
      default: {
        margin: '24px 0 40px',
      },
    },
  },
  'app.output.fuelCosts.fleetElectricityCosts.text': {
    _extends: 'app.output.template.headline',
    config: {
      i18n: {
        id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.title',
        fallback: 'Fleet Electricity Costs',
      },
    },
    schema: {
      default: {
        '@media print': {
          pageBreakBefore: 'always',
        }
      },
    },
  },
  // Annual fuel costs
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.mobile.fuelCost.head',
            {
              displayQuery: 'mobile',
            },
          ],
          [
            'IconText',
            'app.output.template.routes.fuelCosts.template.info',
            {
              displayQuery: 'mobile',
            },
          ],
          [
            'TranslatedText',
            'app.output.template.headline',
            {
              id: 'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.title',
              i18n: {
                id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.title',
                fallback: 'Annual Fuel Costs',
              },
              className: 'pageHeader',
            },
          ],
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.caption',
          ],
          [
            'Chart',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart',
            {
              displayQuery: 'desktop',
            },
          ],
          [
            'Chart',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.mobile',
            {
              displayQuery: 'mobile',
            },
          ],
        ],
        watch: {
          name: ['device'],
        },
        displayQueries: {
          mobile: {
            '["watched", "device"]': {
              $eq: 'mobile',
            },
          },
          desktop: {
            '["watched", "device"]': {
              $eq: 'desktop',
            },
          },
        },
      },
    },
  'app.output.template.mobile.fuelCost.head': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.fuelCost.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',
      },
    },
  },
  'app.output.template.mobile.fuelCost.head.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.fuelCost.head.title',
        fallback: 'Fuel Cost',
      },
    },
  },

  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.caption':
    {
      config: {
        as: 'p',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.caption',
          dangerouslySetInnerHTML: true,
          fallback:
            'Based on your selections, your electric fleet will cost <strong>${{annualFuelSavings}}</strong> less per year than its fossil fuel equivalent.',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart':
    {
      _extends: 'app.output.print.common.canvas',
      config: {
        'aria-labelledby':
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.title',
        type: 'bar',
        indexAxis: 'y',
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        datalabels: {
          display: true,
          align: 'right',
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
        scaleOptions: {
          y: {
            stacked: true,
          },
          x: {
            formatOpts: {
              formatter: 'formatAsDollars',
            },
          },
        },
        labels: [
          {
            id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.evLabel',
            fallback: 'Electric Vehicle Fleet\n(your selection)',
          },
          {
            id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.iceLabel',
            fallback: 'Fossil Fuel Fleet\n(Similar fossil fuel vehicle)',
          },
        ],
        pick: [
          {
            source: 'annualFossilFuelCost',
            backgroundColor: 'var(--chart-color-3)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.iceLegendLabel',
              fallback: 'Fossil Fuel Vehicles',
            },
            prependData: [0],
            barPercentage: 0.6,
          },
          {
            source: 'annualElectricFuelCost',
            backgroundColor: 'var(--chart-color-4)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.evLegendLabel',
              fallback: 'Electric Vehicles',
            },
            barPercentage: 0.6,
          },
        ],
      },
      schema: {
        default: {
          height: '200px',
          width: '100%',
          marginBottom: '2em',
        },
      },
    },

  'app.output.template.routes.fuelCosts.template.info': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.output.fuelCost.computer.icon',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.info',
          dangerouslySetInnerHTML: true,
          fallback:
            'This application is calculation heavy and not meant to be used on a small screen. For best results, please view this on a desktop device.',
        },
      },
    },
    schema: {
      default: {
        'margin-bottom': '1.5rem',
        'margin-top': '32px',
      },
    },
  },
  'app.output.fuelCost.computer.icon': {
    config: {
      component: 'Icon',
      src: 'https://assets.zappyride.com/img/icons/evergy/computer.svg',
      as: 'img',
    },
    schema: {
      default: {
        width: '32px',
      },
    },
  },

  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.mobile':
    {
      _extends: [
        'app.output.common.chart.responsive',
        'app.output.print.common.canvas',
        'app.output.print.common.samePageWhenPossible',
      ],
      config: {
        'aria-labelledby':
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.title',
        type: 'bar',
        indexAxis: 'x',
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 11,
            },
          },
        },
        scaleOptions: {
          x: {
            stacked: true,
            ticks: {
              font: {
                size: 11,
              },
            },
          },
          y: {
            formatOpts: {
              formatter: 'formatAsDollars',
            },
            ticks: {
              font: {
                size: 11,
              },
            },
          },
        },
        labels: [
          {
            id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.evLabel',
            fallback: 'Electric Vehicle Fleet\n(your selection)',
          },
          {
            id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.iceLabel',
            fallback: 'Fossil Fuel Fleet\n(Similar fossil fuel vehicle)',
          },
        ],
        datalabels: {
          display: true,
          align: 'end',
          offset: 0,
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
        pick: [
          {
            source: 'annualFossilFuelCost',
            backgroundColor: 'var(--chart-color-3)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.iceLegendLabel',
              fallback: 'Fossil Fuel Vehicles',
            },
            prependData: [0],
            barPercentage: 0.7,
          },
          {
            source: 'annualElectricFuelCost',
            backgroundColor: 'var(--chart-color-4)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.annualFuelCosts.chart.evLegendLabel',
              fallback: 'Electric Vehicles',
            },
            barPercentage: 0.7,
          },
        ],
      },
      schema: {
        default: {
          height: '35vh',
        },
      },
    },

  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph.link':
    {
      config: {
        color: 'var(--color-info)',
        'text-decoration': 'underline',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph':
    {
      config: {
        content: [
          [
            'TranslatedText',
            '',
            {
              config: {
                as: 'span',
                i18n: {
                  id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph.text',
                  dangerouslySetInnerHTML: true,
                  fallback:
                    'Total annual electricity costs of <strong>${{annualElectricFuelCost}}</strong> are calculated from the load profile on the ',
                },
              },
            },
          ],
          [
            'Link',
            '',
            {
              components: {
                translatedText:
                  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph.link',
              },
              to: '/output/electricity',
              i18n: {
                id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph.link',
                dangerouslySetInnerHTML: true,
                fallback: 'Electricity Tab',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              config: {
                as: 'span',
                i18n: {
                  id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.paragraph.selectedRate',
                  dangerouslySetInnerHTML: true,
                  fallback:
                    ' and the <strong>{{selectedRateName}}</strong> Plan explained below.',
                },
              },
            },
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.caption':
    {
      config: {
        as: 'p',
        values: {
          ecaUrl:
            'https://www.evergy.com/manage-account/rate-information-link/how-rates-are-set/rate-overviews/rate-riders-and-adjustments',
          ptsUrl:
            'https://www.evergy.com/manage-account/rate-information-link/how-rates-are-set/rate-overviews/rate-riders-and-adjustments',
          tdcUrl:
            'https://www.evergy.com/manage-account/rate-information-link/how-rates-are-set/rate-overviews/detailed-tariffs',
          detailedUrl:
            'https://www.evergy.com/manage-account/rate-information-link/how-rates-are-set/rate-overviews/detailed-tariffs',
        },
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.caption',
          dangerouslySetInnerHTML: true,
          fallback:
            'The prices do not include taxes, <a href="{{ecaUrl}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">Energy Cost Adjustment (ECA)</a>, <a href="{{ptsUrl}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">Property Tax Surcharge (PTS)</a> or <a href="{{tdcUrl}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">Transmission Delivery Charge (TDC)</a>. To see all the details of the Electric Vehicle Plan, <a href="{{detailedUrl}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">please reference detailed tariffs.</a>',
        },
        schema: {
          default: {
            'font-style': 'italic',
            'margin-top': '15px',
          },
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates':
    {
      _extends: 'app.common.iconText',
      components: {
        icon: 'app.common.icon.electricServicesFilled',
        header: 'app.common.iconText.text',
        text: 'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.button',
      },
      config: {
        header: {
          i18n: {
            id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates',
            dangerouslySetInnerHTML: true,
            fallback: '{{selectedRateDescriptionFormatted}}',
          },
        },
        text: {
          component: 'CompareRatesButton',
        },
      },
      schema: {
        default: {
          'margin-bottom': '1.5rem',
          'margin-top': '20px',
          'grid-template-columns': 'auto 1fr auto',
          'align-items': 'center',
          a: {
            color: 'var(--primary-color)',
            textDecoration: 'underline',
          },
          '@media print': {
            display: 'none',
          }
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.button':
    {
      _extends: 'app.common.greeButton',
      components: {
        modal:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal',
      },
      config: {
        component: 'CompareButton',
        role: 'button',
        as: 'button',
        action: 'onClickCompare',
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.button.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.button.text':
    {
      config: {
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.button.text',
          fallback: 'COMPARE RATES',
        },
      },
      schema: {
        default: {
          fontSize: '14px',
        },
      },
    },
  // ##### OUTPUT FUEL COSTS :: COMPARE RATES MODAL #####
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal':
    {
      components: {
        portal: 'app.common.portal.fullPage.contentCentered',
        actions:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions',
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content',
      },
      schema: {
        default: {
          margin: 'auto',
          padding: '32px',
          'background-color': 'white',
          'min-width': '875px',
          'box-shadow': '0px 0px 20px 2px rgba(0, 0, 0, 0.5)',
        },
      },
      config: {
        closeOnConfirm: true,
        type: 'dynamic'
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions':
    {
      config: {
        content: [
          [
            'Button',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.apply',
            { action: 'onApply' },
          ],
          [
            'Button',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.confirm',
            { action: 'onClose' },
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          direction: 'unset',
          'justify-content': 'flex-end',
          'column-gap': '30px',
          'margin-top': '15px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.apply':
    {
      _extends: 'app.common.greeButton',
      config: {
        role: 'button',
        as: 'button',
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.apply.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.apply.text':
    {
      config: {
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.apply.text',
          fallback: 'APPLY',
        },
      },
      schema: {
        default: {
          fontSize: '16px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.confirm':
    {
      _extends: 'app.common.greeButton',
      config: {
        role: 'button',
        as: 'button',
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.confirm.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.confirm.text':
    {
      config: {
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.actions.confirm.text',
          fallback: 'CLOSE',
        },
      },
      schema: {
        default: {
          fontSize: '16px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.caption',
          ],
          [
            'Chart',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph',
          ],
          [
            'ContentContainer',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header.title',
            { actions: null },
          ],
          [
            'Icon',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header.closeButton',
            { action: 'onClose' },
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          'justify-content': 'space-between',
          width: '100%',
          'border-bottom': 'var(--input-border)',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header.title':
    {
      config: {
        element: 'h2',
        fontSize: '22px',
        fontWeight: 700,
        i18n: {
          id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header.title',
          fallback: 'Compare & choose Rate',
        },
      },
      schema: {
        default: {
          'padding-bottom': '16px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.header.closeButton':
    {
      config: {
        src: 'close',
        size: 'md',
      },
      schema: {
        default: {
          'padding-bottom': '16px',
          'background-color': '#333333',
          cursor: 'pointer',
          width: '14px',
          height: '14px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection':
    {
      config: {
        content: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection',
                fallback:
                  'Choose from the following options to use in fleet electrification model:',
              },
              actions: null,
            },
          ],
          [
            'RadioGroupRates',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates',
          ],
        ],
        schema: {
          default: {
            'margin-top': '15px',
          },
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates':
    {
      components: {
        radioInput:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput',
      },
      config: {
        name: 'rateSelection.selectedRateId',
        items: [
          {
            design:
              'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.customItem',
            id: 'simple',
            value: 'simple',
          },
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.customItem':
    {
      _extends:
        'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput',
      components: {
        label: null,
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.customItem.content',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.customItem.content':
    {
      config: {
        content: [
          [
            'FormField',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.disabled',
            {
              displayQuery: {
                '["watched", "rateSelection.selectedRateId"]': {
                  $neq: 'simple',
                },
              },
            },
          ],
          [
            'FormField',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.enabled',
            {
              displayQuery: {
                '["watched", "rateSelection.selectedRateId"]': {
                  $eq: 'simple',
                },
              },
            },
          ],
        ],
        watch: { name: ['rateSelection.selectedRateId'] },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.disabled':
    {
      _extends:
        'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.enabled',
      config: {
        input: {
          autoFocus: false,
        },
      },
      schema: {
        default: {
          input: {
            opacity: 0.5,
            'pointer-events': 'none',
          },
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.enabled':
    {
      components: {
        label:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.label',
        input:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.input',
        description: null,
      },
      config: {
        label: {
          htmlFor: 'simple',
          element: 'label',
          fontSize: '16px',
          fontWeight: '600',
          i18n: {
            id: 'i18n.formProvider.form.field.label',
            dangerouslySetInnerHTML: true,
            fallback: '<strong>Enter Simple/Custom Rate: ($/kWh)</strong>',
          },
        },
        input: {
          component: 'FormInput',
          name: 'rateSelection.customRatePrice',
          type: 'number',
          autoFocus: true,
          placeholder: {
            i18n: {
              id: 'i18n.radioInputGroup.options.custom.field',
              fallback: 'Enter Custom Rate',
            },
          },
        },
      },
      schema: {
        default: {
          display: 'grid',
          'grid-template-columns': 'auto 1fr',
          'grid-column-gap': '16px',
          'align-items': 'center',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.options.custom.field.input':
    {
      config: {
        name: 'rateSelection.customRatePrice',
      },
      schema: {
        default: {
          padding: '16px 12px',
          border: '1px solid #ACB1BA',
          'border-radius': '3px',
          width: '240px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput':
    {
      components: {
        input:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput.input',
        label:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput.label',
      },
      schema: {
        default: {
          display: 'grid',
          'grid-template-columns': 'auto 1fr',
          'grid-column-gap': '16px',
          'align-items': 'center',
          padding: '16px',
          border: '1px solid #ACB1BA',
          'border-radius': '5px',
          'margin-bottom': '24px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput.input':
    {
      schema: {
        default: {
          width: '22px',
          height: '22px',
          'background-color': 'white',
          'border-radius': '5px',
          border: '2px solid #333333',
          display: 'flex',
        },
        active: {
          'border-color': '#317CC0',
          backgroundColor: '#317CC0',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.selection.radioGroupRates.radioInput.label':
    {
      config: {
        element: 'label',
        fontSize: '16px',
        color: 'var(--primary-dark-color)',
      },
    },

  // #### OUTPUT FUEL COSTS :: VEHICLE FLEET FUEL TABLE
  'app.output.template.routes.fuelCosts.template.collapsible': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    components: {
      headerText:
        'app.output.template.routes.fuelCosts.template.collapsible.text',
      content:
        'app.output.template.routes.fuelCosts.template.tablesContainer.collapsible.content',
    },
  },
  'app.output.template.routes.fuelCosts.template.collapsible.text': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.app.output.template.routes.fuelCosts.template.collapsible.text',
        fallback: 'Annual Fuel Costs By Vehicle Set',
      },
    },
  },
  'app.output.template.routes.fuelCosts.template.tablesContainer.collapsible.content':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.fuelCosts.template.tablesContainer.collapsible.content.wrapper',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.tablesContainer.collapsible.content.wrapper':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.fuelCosts.template.tablesContainer.fossilFuel',
          ],
          [
            'ContentContainer',
            'app.output.template.routes.fuelCosts.template.tablesContainer.electricFuel',
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          flexDirection: 'column',
          gap: '50px',
          marginBottom: '20px',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.tablesContainer.electricFuel':
    {
      config: {
        content: [
          // Electric Table Title
          [
            'TranslatedText',
            'app.output.template.routes.tables.title',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.fuelCosts.template.tablesContainer.electricFuel.electricFuelVehicleFleet',
                fallback: 'Electric Fuel Vehicle Fleet',
              },
            },
          ],
          // ON ROAD TABLES
          [
            'Table',
            'app.output.template.vehicleFleetTableElectricity.onRoad',
            {
              targetSource: 'tableContent.contentElectricFuelFleet',
              globalDataSource: 'tableContent.globalElectric',
              displayQuery: 'isNotCustomRateEngineForOnRoad',
            },
          ],
          [
            'Table',
            'app.output.template.vehicleFleetTableElectricityTotal.onRoad',
            {
              targetSource: 'tableContent.contentElectricFuelFleetTotal',
              globalDataSource: 'tableContent.globalElectric',
              displayQuery: 'isCustomRateEngineForOnRoad',
            },
          ],
          // OFF ROAD TABLES
          [
            'Table',
            'app.output.template.vehicleFleetTableElectricity.offRoad',
            {
              targetSource: 'tableContent.contentElectricFuelFleet',
              globalDataSource: 'tableContent.globalElectric',
              displayQuery: 'isNotCustomRateEngineForOffRoad',
            },
          ],
          [
            'Table',
            'app.output.template.vehicleFleetTableElectricityTotal.offRoad',
            {
              targetSource: 'tableContent.contentElectricFuelFleetTotal',
              globalDataSource: 'tableContent.globalElectric',
              displayQuery: 'isCustomRateEngineForOffRoad',
            },
          ],

          // Electric Tables notes
          [
            'TranslatedText',
            'app.output.template.routes.tables.note',
            {
              i18n: {
                id: 'i18n.app.output.template.routes.fuelCosts.template.tablesContainer.electricFuel.electricDisclaimer',
                fallback:
                  'Electric vehicle fueling costs are calculated in aggregate for the site, and can not be separated by vehicle set.',
              },
            },
          ],
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.tablesContainer.tablesNotes.link',
          ],
        ],
        displayQueries: {
          isCustomRateEngineForOnRoad: {
            $and: [
              {
                '["props", "tableContent", "isCustomRateEngine"]': {
                  $eq: true,
                },
              },
              { '["props", "tableContent", "isOffRoad"]': { $eq: false } },
            ],
          },
          isCustomRateEngineForOffRoad: {
            $and: [
              {
                '["props", "tableContent", "isCustomRateEngine"]': {
                  $eq: true,
                },
              },
              { '["props", "tableContent", "isOffRoad"]': { $eq: true } },
            ],
          },
          isNotCustomRateEngineForOnRoad: {
            $and: [
              {
                '["props", "tableContent", "isCustomRateEngine"]': {
                  $eq: false,
                },
              },
              { '["props", "tableContent", "isOffRoad"]': { $eq: false } },
            ],
          },
          isNotCustomRateEngineForOffRoad: {
            $and: [
              {
                '["props", "tableContent", "isCustomRateEngine"]': {
                  $eq: false,
                },
              },
              { '["props", "tableContent", "isOffRoad"]': { $eq: true } },
            ],
          },
        },
      },
      schema: {
        default: {
          'span > a.link': {
            color: 'var(--color-info)',
            textDecoration: 'underline',
          },
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.tablesContainer.tablesNotes.link':{
    _extends: 'app.output.template.routes.tables.note',
    config: {
      values: {
        url: 'https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a',
      },
      i18n: {
        dangerouslySetInnerHTML: true,
        id: 'i18n.app.output.template.routes.fuelCosts.template.tablesContainer.electricFuel.electricitySource',
        fallback:
          'Electricity Price Source: <a href="{{url}}" class="link" target="_blank">Evergy</a>',
      },
    },
  },
  'app.output.template.routes.fuelCosts.template.tablesContainer.fossilFuel': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.tables.title',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.fuelCosts.template.tablesContainer.fossilFuel.fossilFuelVehicleFleet',
              fallback: 'Fossil Fuel Vehicle Fleet',
            },
          },
        ],
        [
          'Table',
          'app.output.template.vehicleFleetTableFuel.onRoad',
          {
            targetSource: 'tableContent.contentFossilFuelFleet',
            globalDataSource: 'tableContent.globalFossil',
            displayQuery: 'onRoad',
          },
        ],
        [
          'Table',
          'app.output.template.vehicleFleetTableFuel.offRoad',
          {
            targetSource: 'tableContent.contentFossilFuelFleet',
            globalDataSource: 'tableContent.globalFossil',
            displayQuery: 'offRoad',
          },
        ],
        [
          'TranslatedText',
          'app.output.template.routes.tables.note',
          {
            values: {
              url: 'https://gasprices.aaa.com/',
            },
            i18n: {
              dangerouslySetInnerHTML: true,
              id: 'i18n.app.output.template.routes.fuelCosts.template.tablesContainer.fossilFuel.gasolinePriceSource',
              fallback:
                'Gasoline Price Source: <a href="{{url}}" class="link" target="_blank">AAA</a>',
            },
          },
        ],
      ],
      displayQueries: {
        onRoad: { '["props", "tableContent", "isOffRoad"]': { $neq: true } },
        offRoad: {
          '["props", "tableContent", "isOffRoad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        'span > a.link': {
          color: 'var(--color-info)',
          textDecoration: 'underline',
        },
      },
    },
  },
  // ON-ROAD TABLES
  'app.output.template.vehicleFleetTableFuel.onRoad': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      rowsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.rows',
      cellsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.cells',
      headerRows: [
        ['app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row'],
      ],
      footerRows: [
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row1'],
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2'],
      ],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },
  'app.output.template.vehicleFleetTableElectricity.onRoad': {
    _extends: 'app.output.template.vehicleFleetTableFuel.onRoad',
    config: {
      headerRows: [
        [
          'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1',
        ],
      ],
    },
  },
  'app.output.template.vehicleFleetTableElectricityTotal.onRoad': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      rowsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal',
      cellsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.cells',
      headerRows: [
        [
          'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1',
        ],
      ],
      footerRows: [
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2'],
      ],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },

  // OFF-ROAD TABLES
  'app.output.template.vehicleFleetTableFuel.offRoad': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      rowsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.rows.offRoad',
      cellsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.cells',
      headerRows: [
        [
          'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad',
        ],
      ],
      footerRows: [
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row1'],
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2'],
      ],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },
  'app.output.template.vehicleFleetTableElectricity.offRoad': {
    _extends: 'app.output.template.vehicleFleetTableFuel.offRoad',
    config: {
      headerRows: [
        [
          'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad',
        ],
      ],
    },
  },
  'app.output.template.vehicleFleetTableElectricityTotal.offRoad': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      rowsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.offRoad',
      cellsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.cells',
      headerRows: [
        [
          'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad',
        ],
      ],
      footerRows: [
        ['app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2'],
      ],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.rows.offRoad': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'app.output.template.vehicleFleetTableFuelAndElectricity.rows.offRoad.setName',
              fallback: '{{rowData[setName]}}',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rows.offRoad.setCount',
              fallback: '{{rowData[count]}}',
            },
          },
        ],
        [
          'Formatter',
          '',
          {
            pick: 'rowData[efficiency]',
            format: {
              formatter: 'formatAsNumber',
              maximumFractionDigits: 2,
              fallback: 'N/A',
            },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.vehicleFleetTableFuelAndElectricity.hoursContainer',
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsCents',
            pick: 'rowData[fuelCost]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'rowData[totalCost]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.offRoad': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.offRoad.allVehicleSets',
              fallback: 'All Vehicle Sets',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.offRoad.setCount',
              fallback: '{{rowData[count]}}',
            },
          },
        ],
        [
          'Formatter',
          '',
          {
            pick: 'rowData[efficiency]',
            format: {
              formatter: 'formatAsNumber',
              maximumFractionDigits: 2,
              fallback: 'N/A',
            },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.vehicleFleetTableFuelAndElectricity.hoursContainer',
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsCents',
            pick: 'rowData[fuelCost]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'rowData[totalCost]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },

  //TABLES COMMON CONFIG
  'app.output.template.vehicleFleetTableFuelAndElectricity.rows': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rows.setName',
              fallback: '{{rowData[setName]}}',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rows.setCount',
              fallback: '{{rowData[count]}}',
            },
          },
        ],
        [
          'Formatter',
          '',
          {
            pick: 'rowData[efficiency]',
            format: {
              formatter: 'formatAsNumber',
              maximumFractionDigits: 2,
              fallback: 'N/A',
            },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.vehicleFleetTableFuelAndElectricity.milesContainer',
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsCents',
            pick: 'rowData[fuelCost]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'rowData[totalCost]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.allVehicleSets',
              fallback: 'All Vehicle Sets',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.rowsTotal.count',
              fallback: '{{rowData[count]}}',
            },
          },
        ],
        [
          'Formatter',
          '',
          {
            pick: 'rowData[efficiency]',
            format: {
              formatter: 'formatAsNumber',
              maximumFractionDigits: 2,
              fallback: 'N/A',
            },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.vehicleFleetTableFuelAndElectricity.milesContainer',
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsCents',
            pick: 'rowData[fuelCost]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'rowData[totalCost]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.cells': {
    schema: {
      default: {
        padding: '0 10px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.row1': {
    _extends: 'app.output.template.vehicleFleetTableFuelAndElectricity.rows',
    config: {
      cells: [
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Vehicle Set',
              },
            },
          },
        ],
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Count',
              },
            },
          },
        ],
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Efficiency (MPG)',
              },
            },
          },
        ],
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Miles',
              },
            },
          },
        ],
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Fuel Cost ($/Gal)',
              },
            },
          },
        ],
        [
          'IconText',
          '',
          {
            text: {
              i18n: {
                id: 'i18n.iconHeaderText.text',
                fallback: 'Cost',
              },
            },
          },
        ],
      ],
    },
  },

  //OFF ROAD HEADERS
  'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad':
    {
      _extends: 'app.output.template.routes.tabs.fuel.tables.common',
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.vehicleSet',
                fallback: 'Vehicle Set',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.count',
                fallback: 'Count',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.efficiency',
                fallback: 'Efficiency (Gallon/Hr)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.hours',
                fallback: 'Hours (Per Year)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.fuelCost',
                fallback: 'Fuel Cost ($/Gal)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.offRoad.cost',
                fallback: 'Cost',
              },
            },
          ],
        ],
      },
      schema: {
        default: {
          height: '40px',
        },
      },
    },
  'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad':
    {
      _extends: 'app.output.template.routes.tabs.fuel.tables.common',
      config: {
        cells: [
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.vehicleSet',
                fallback: 'Vehicle Set',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.count',
                fallback: 'Count',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.efficiency',
                fallback: 'Efficiency (KWh/Hr)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.hours',
                fallback: 'Hours (Per Year)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.fuelCost',
                fallback: 'Fuel Cost ($/kWh)',
              },
            },
          ],
          [
            'TranslatedText',
            '',
            {
              i18n: {
                id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.offRoad.cost',
                fallback: 'Cost',
              },
            },
          ],
        ],
      },
      schema: {
        default: {
          height: '40px',
        },
      },
    },

  //ON ROAD HEADERS
  'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row': {
    _extends: 'app.output.template.routes.tabs.fuel.tables.common',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.vehicleSet',
              fallback: 'Vehicle Set',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.count',
              fallback: 'Count',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.efficiency',
              fallback: 'Efficiency (MPG)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.miles',
              fallback: 'Miles',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.fuelCost',
              fallback: 'Fuel Cost ($/Gal)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row.cost',
              fallback: 'Cost',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1': {
    _extends: 'app.output.template.routes.tabs.fuel.tables.common',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.vehicleSet',
              fallback: 'Vehicle Set',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.count',
              fallback: 'Count',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.efficiency',
              fallback: 'Efficiency (MPGe)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.miles',
              fallback: 'Miles',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.fuelCost',
              fallback: 'Fuel Cost ($/kWh)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.tHeader.row1.cost',
              fallback: 'Cost',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
      },
    },
  },

  'app.output.template.vehicleFleetTableFuelAndElectricity.footer.row1': {
    _extends: 'app.output.template.routes.tabs.fuel.tables.common',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.footer.row1.annualTotal',
              fallback: 'Annual Total',
            },
          },
          {
            colSpan: 5,
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[annualTotal]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2': {
    _extends: 'app.output.template.routes.tabs.fuel.tables.common',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.vehicleFleetTableFuelAndElectricity.footer.row2.vehicleLifetimeTotal',
              fallback: 'Vehicle Lifetime Total',
            },
          },
          {
            colSpan: 5,
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[lifetimeTotal]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
      },
    },
  },

  // TOOLTIP FOR MILES
  'app.output.template.vehicleFleetTableFuelAndElectricity.milesContainer': {
    config: {
      content: [
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            as: 'span',
            formatter: 'formatAsThousands',
            pick: 'rowData[distance]',
          },
        ],
        [
          'IconText',
          'app.output.template.vehicleFleetTableFuelAndElectricity.milesTooltip',
          {
            tooltip: {
              popover: {
                translatedText: {
                  _extends:
                    'app.output.template.vehicleFleetTableFuelAndElectricity.tooltipPopOverText',
                  i18n: {
                    id: 'i18n.output.template.vehicleFleetTableFuelAndElectricity.milesTooltip',
                    fallback: 'Miles for all vehicles in the set',
                  },
                },
              },
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.milesTooltip.text': {
    component: 'Formatter',
    config: {
      as: 'span',
      formatter: 'formatAsThousands',
      pick: 'rowData[distance]',
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.milesTooltip': {
    components: {
      tooltip:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip',
      header: null,
      text: null,
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.tooltipPopOverText':
    {
      schema: {
        default: {
          color: 'white',
          fontSize: '14px',
        },
      },
    },

  // TOOLTIP FOR HOURS
  'app.output.template.vehicleFleetTableFuelAndElectricity.hoursContainer': {
    config: {
      content: [
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            as: 'span',
            formatter: 'formatAsThousands',
            pick: 'rowData[hours]',
          },
        ],
        [
          'IconText',
          'app.output.template.vehicleFleetTableFuelAndElectricity.hoursTooltip',
          {
            tooltip: {
              popover: {
                translatedText: {
                  _extends:
                    'app.output.template.vehicleFleetTableFuelAndElectricity.tooltipPopOverText',
                  i18n: {
                    id: 'i18n.output.template.vehicleFleetTableFuelAndElectricity.milesTooltip',
                    fallback: 'Usage hours for all vehicles in the set',
                  },
                },
              },
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      },
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.hoursContainer.text':
    {
      component: 'Formatter',
      config: {
        as: 'span',
        formatter: 'formatAsThousands',
        pick: 'rowData[distance]',
      },
    },
  'app.output.template.vehicleFleetTableFuelAndElectricity.hoursTooltip': {
    components: {
      tooltip:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip',
      header: null,
      text: null,
    },
  },
  'app.output.template.vehicleFleetTableFuelAndElectricity.tooltipPopOverTextForHours':
    {
      schema: {
        default: {
          color: 'white',
          fontSize: '14px',
        },
      },
    },

  // #### ESTIMATED ANNUAL AVERAGE COST
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost':
    {
      _extends: [
        'app.output.print.common.canvas',
        'app.output.print.common.samePageWhenPossible',
      ],
      config: {
        type: 'bar',
        indexAxis: 'y',
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        datalabels: {
          display: true,
          align: 'right',
          formatOpts: {
            formatter: 'formatAsDollars',
          },
          stacked: true,
        },
        scaleOptions: {
          y: {
            stacked: true,
          },
          x: {
            stacked: true,
            formatOpts: {
              formatter: 'formatAsDollars',
            },
          },
        },
        labels: [
          {
            id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.allVehicleSets',
            fallback: 'All Vehicle Sets',
          },
        ],
        pick: [
          {
            source: 'estimatedAnnualAverageCost.fixedCost',
            backgroundColor: 'var(--chart-color-1)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.fixed',
              fallback: 'Fixed',
            },
            barPercentage: 0.6,
          },
          {
            source: 'estimatedAnnualAverageCost.energyCost',
            backgroundColor: 'var(--chart-color-2)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.energy',
              fallback: 'Energy',
            },
            barPercentage: 0.6,
          },
          {
            source: 'estimatedAnnualAverageCost.demandCost',
            backgroundColor: 'var(--chart-color-4)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.demand',
              fallback: 'Demand',
            },
            barPercentage: 0.6,
          },
          {
            source: 'estimatedAnnualAverageCost.otherCost',
            backgroundColor: 'var(--chart-color-3)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.other',
              fallback: 'Other',
            },
            barPercentage: 0.6,
          },
        ],
      },
      components: {
        title:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.title',
        legend:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend',
      },
      schema: {
        default: {
          height: '200px',
          width: '100%',
          marginBottom: '5em',
          display: 'grid',
          'grid-template-areas': '"title" "chart" "legend"',
          '@media print': {
            marginBottom: '1em',
          }
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.title':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.title',
          fallback: 'Estimated annual average cost',
        },
      },
      schema: {
        default: {
          marginBottom: '1em',
          display: 'block',
          'grid-area': 'title',
          '@media print': {
            marginTop: '2em',
          }
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend':
    {
      config: {
        content: [
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other',
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          'justify-content': 'end',
          width: '100%',
          'grid-area': 'legend',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed',
          fallback: 'Fixed',
        },
        color: 'var(--chart-color-1)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.fixed.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of fixed monthly energy charges like base subscription fees.',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy',
          fallback: 'Energy',
        },
        color: 'var(--chart-color-2)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.energy.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of charges billed based on energy used.',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand',
          fallback: 'Demand',
        },
        color: 'var(--chart-color-4)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.demand.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of charges billed based on demand (power, units of KW).',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other',
          fallback: 'Other',
        },
        color: 'var(--chart-color-3)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.estimatedAverageCost.legend.other.content.tooltip.popover.text',
          fallback:
            'Represents the annual regulatory and other charges not assessed based on energy use or demand.',
        },
      },
    },

  // RATE COMPONENT COSTS
  'app.output.template.routes.rateComponentTable.template.collapsible': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    components: {
      headerText:
        'app.output.template.routes.rateComponentTable.template.collapsible.text',
      content:
        'app.output.template.routes.rateComponentTable.template.tablesContainer.collapsible.content',
    }
  },
  'app.output.template.routes.rateComponentTable.template.collapsible.text': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.app.output.template.routes.rateComponentTable.template.collapsible.text',
        fallback: 'Rate Component Cost',
      },
    },
  },
  'app.output.template.routes.rateComponentTable.template.tablesContainer.collapsible.content':
    {
      config: {
        content: [
          [
            'Table',
            'app.output.template.rateComponentTable.table',
            {
              targetSource: 'ratesTableData.ratesTable',
              globalDataSource: 'ratesTableData.ratesTableInfo',
            },
          ],
        ],
      },
    },
  'app.output.template.rateComponentTable.table': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      cellsDesign:
        'app.output.template.vehicleFleetTableFuelAndElectricity.cells',
      headerRows: [['app.output.template.rateComponentTable.tHeader.row']],
      bodyRows: [
        ['app.output.template.rateComponentTable.rows1'],
        ['app.output.template.rateComponentTable.rows2'],
        ['app.output.template.rateComponentTable.rows3'],
        ['app.output.template.rateComponentTable.rows4'],
      ],
      footerRows: [['app.output.template.rateComponentTable.footer.row1']],
    },
    schema: {
      default: {
        fontSize: '14px',
        marginBottom: '26px',
      },
    },
  },
  'app.output.template.rateComponentTable.rows.template': {
    schema: {
      default: {
        '& > td:nth-child(2)': {
          maxWidth: '300px',
          textAlign: 'right',
        },
      },
    },
  },
  'app.output.template.rateComponentTable.tHeader.row': {
    _extends: 'app.output.template.rateComponentTable.rows.template',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.tHeader.row.rateComponent',
              fallback: 'Rate Component',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.tHeader.row.cost',
              fallback: 'Cost',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
        fontWeight: 'bold',
      },
    },
  },
  'app.output.template.rateComponentTable.rows1': {
    _extends: 'app.output.template.rateComponentTable.rows.template',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.rows1.fixedCharges',
              fallback: 'Fixed Charges',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[fixedCharges]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.rateComponentTable.rows2': {
    _extends: 'app.output.template.rateComponentTable.rows.template',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.rows2.energyCharges',
              fallback: 'Energy Charges',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[energyCharges]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.rateComponentTable.rows3': {
    _extends: 'app.output.template.rateComponentTable.rows.template',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.rows3.demandCharges',
              fallback: 'Demand Charges',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[demandCharges]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.rateComponentTable.rows4': {
    _extends: 'app.output.template.rateComponentTable.rows.template',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.rows4.otherCharges',
              fallback: 'Other Charges',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[otherCharges]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #CCC',
        height: '40px',
      },
    },
  },
  'app.output.template.rateComponentTable.footer.row1': {
    _extends: [
      'app.output.template.routes.tabs.fuel.tables.common',
      'app.output.template.rateComponentTable.rows.template',
    ],
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.rateComponentTable.footer.row1.annualTotal',
              fallback: 'Annual Total',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'globalData[totalCharges]',
            formatter: 'formatAsDollars',
          },
        ],
      ],
    },
    schema: {
      default: {
        height: '40px',
        '& > td': {
          borderBottom: '1px solid transparent',
        },
      },
    },
  },
  // ###

  // #### RATE COMPARISON CHART

  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph':
    {
      config: {
        type: 'bar',
        indexAxis: 'y',
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
        datalabels: {
          display: true,
          align: 'right',
          formatOpts: {
            formatter: 'formatAsDollars',
          },
          stacked: true,
        },
        scaleOptions: {
          y: {
            stacked: true,
          },
          x: {
            stacked: true,
            formatOpts: {
              formatter: 'formatAsDollars',
            },
          },
        },
        values: {},
        sourceParent: 'ratesInfo',
        labelsAttr: 'label',
        pick: [
          {
            source: 'fixedCost',
            backgroundColor: 'var(--chart-color-1)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.fixed',
              fallback: 'Fixed',
            },
            barPercentage: 0.75,
          },
          {
            source: 'energyCost',
            backgroundColor: 'var(--chart-color-2)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.energy',
              fallback: 'Energy',
            },
            barPercentage: 0.75,
          },
          {
            source: 'demandCost',
            backgroundColor: 'var(--chart-color-4)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.demand',
              fallback: 'Demand',
            },
            barPercentage: 0.75,
          },
          {
            source: 'otherCost',
            backgroundColor: 'var(--chart-color-3)',
            label: {
              id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.demand',
              fallback: 'Other',
            },
            barPercentage: 0.75,
          },
        ],
      },
      components: {
        title:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.title',
        legend:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend',
      },
      schema: {
        default: {
          marginTop: '20px',
          height: '350px',
          width: '100%',
          marginBottom: '5em',
          display: 'grid',
          'grid-template-areas': '"title" "chart" "legend"',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.title':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.title',
          fallback: 'Estimated average cost per year',
        },
      },
      schema: {
        default: {
          marginBottom: '1em',
          display: 'block',
          'grid-area': 'title',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.caption':
    {
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.caption',
          fallback:
            'The rates shown below and on the fuel costs page are only those you qualify for based on your energy consumption patterns. Changes to your energy consumption patterns may alter the qualifying rates shown to you and included in your electricity cost calculations, due to total site demand exceeding the limits specified by a given rate.',
        },
      },
      schema: {
        default: {
          marginTop: '1em',
          display: 'block',
          'grid-area': 'title',
          fontSize: '14px',
          fontStyle: 'italic',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend':
    {
      config: {
        content: [
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand',
          ],
          [
            'ChartLegendElement',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other',
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          'justify-content': 'end',
          width: '100%',
          'grid-area': 'legend',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed',
          fallback: 'Fixed',
        },
        color: 'var(--chart-color-1)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.fixed.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of fixed monthly energy charges like base subscription fees.',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy',
          fallback: 'Energy',
        },
        color: 'var(--chart-color-2)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.energy.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of charges billed based on energy used.',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand',
          fallback: 'Demand',
        },
        color: 'var(--chart-color-4)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.demand.content.tooltip.popover.text',
          fallback:
            'Represents the annual total of charges billed based on demand (power, units of KW).',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other':
    {
      _extends: 'app.common.legendItem',
      components: {
        content:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content',
      },
      config: {
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other',
          fallback: 'Other',
        },
        color: 'var(--chart-color-3)',
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content':
    {
      config: {
        content: [
          [
            'Tooltip',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip':
    {
      _extends: 'app.common.tooltip',
      components: {
        popover:
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip.popover',
      },
      schema: {
        default: {
          width: '100%',
          height: '100%',
        },
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip.popover.text',
          ],
        ],
      },
    },
  'app.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip.popover.text':
    {
      config: {
        element: 'span',
        i18n: {
          id: 'i18n.output.template.routes.fuelCosts.template.fleetElectricityCosts.compareRates.modal.content.comparisonGraph.legend.other.content.tooltip.popover.text',
          fallback:
            'Represents the annual regulatory and other charges not assessed based on energy use or demand.',
        },
      },
    },
};

export default FUEL_COSTS;
