// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';

export function postcodeValidation(value) {
  if (!postcodeValidation.zipcodes) return false;
  if (!value && value !== 0) return true;
  const inputZipcode = value.toString()?.replace(/ /, '')?.toUpperCase();
  return postcodeValidation.zipcodes.includes(inputZipcode);
}

postcodeValidation.setZipcodes = function (zipcodes: Array<string>) {
  postcodeValidation.zipcodes = zipcodes;
};

export function preparePowerSupplierValues(powerSuppliers: Array<string>) {
  if (!powerSuppliers) {
    return {};
  }

  return {
    encodedPowerSuppliers: encodeURIComponent(powerSuppliers.join(',')),
    powerSuppliers: powerSuppliers.join(', '),
  };
}

zappy.FactoryInstance.registerValidation(
  postcodeValidation,
  'postcodeBySupplier'
);
