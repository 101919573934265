// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCalculationSelectors } from '../../selectors/calculations';
import { InterceptorContext } from '../../contexts/Interceptor';
import {
  API_REQUEST_SET_PROJECT,
  FILTERS_APPLY,
  FORM_ADD_VEHICLE_SET,
} from '../../contexts/Interceptor/actions';
import isEmpty from 'lodash.isempty';
import { replaceValuesOfMatchingProps } from '../../utils/functionHelpers';
import { AppStateContext } from '../../contexts/AppState';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { sendConsumerData } from '../../api';
import { stateAttrs } from '../../contexts/AppState/contants';
import { getLocalLang } from '../../utils/localStorageUtils';

export const OutputTemplateSimple = ({ design }: { design: string }) => {
  const callAction = useContext(InterceptorContext);
  const { reset, getValues } = useFormContext();
  const {
    appState: {
      isReady,
      isLoadedFromLocalStorage,
      isOutputPreloadsReady,
      isProjectReady,
      isFilterChanged,
      isConsumerDataSent,
    },
    changeResourceState,
  } = useContext(AppStateContext);
  const localstorage = useLocalStorage('partialFormState');
  const { components, schema } = zappy.useDesign(design);
  const [{ data, status }] = useCalculationSelectors('summary');
  const [electricity] = useCalculationSelectors('electricity');

  const electricityStatus = electricity.status;
  const electricityData = electricity.data;

  const { summary, filters, routes, container } = components;

  const setProject = useRef(false);

  useLayoutEffect(() => {
    if (isProjectReady && isReady && isOutputPreloadsReady) return;

    window.globalLoading(true);
  }, []);

  useEffect(() => {
    if (
      isProjectReady ||
      !isReady ||
      setProject.current ||
      !isOutputPreloadsReady
    )
      return;

    if (isEmpty(getValues('vehicleSets')) && !isLoadedFromLocalStorage) {
      callAction({ type: FORM_ADD_VEHICLE_SET });
    }

    setProject.current = true;
    callAction({ type: API_REQUEST_SET_PROJECT });
  }, [
    isReady,
    isLoadedFromLocalStorage,
    isOutputPreloadsReady,
    isProjectReady,
  ]);

  useEffect(() => {
    if (
      window.GLOBAL_LOADING &&
      isReady &&
      !status.isLoading &&
      !status.missingRequiredInformation &&
      isProjectReady &&
      !isFilterChanged &&
      setProject.current
    ) {
      window.globalLoading(false);
    }
  }, [
    status.isLoading,
    status.missingRequiredInformation,
    isReady,
    isProjectReady,
  ]);

  const actions = {
    onRestoreClick: () => {
      const { filters, ...formValues } = getValues();
      const oldFilters = replaceValuesOfMatchingProps(filters, formValues);
      reset(
        {
          ...formValues,
          filters: oldFilters,
        },
        { keepDefaultValues: true }
      );
    },
    onApplyClick: () => {
      callAction({ type: FILTERS_APPLY });
    },
  };

  useEffect(() => {
    if (
      isReady &&
      isProjectReady &&
      !electricityStatus.isLoading &&
      !electricityStatus.missingRequiredInformation &&
      !isConsumerDataSent
    ) {
      const localstorageData = localstorage.getLocalStorage();
      const estimatedPeak = electricityData.peakKwh;
      const estimatedElectricity = electricityData.estimatedKwh;

      sendConsumerData(localstorageData, {
        estimatedPeak,
        estimatedElectricity,
      });

      changeResourceState(stateAttrs.IS_COMSUMER_DATA_SENT, true);
    }
  }, [
    isReady,
    isProjectReady,
    electricityStatus.isLoading,
    electricityStatus.missingRequiredInformation,
  ]);

  if (isEmpty(data)) return false;

  const calculationsStatus =
    status.isLoading || status.missingRequiredInformation || !status.isPending;

  return (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <zappy.ContentContainer
        design={summary}
        values={{ ...data, isLoading: calculationsStatus }}
      />
      <zappy.FactoryComponent design={container}>
        <zappy.ContentContainer design={filters} actions={actions} />
        <zappy.LayoutWithRoutes design={routes} />
      </zappy.FactoryComponent>
    </zappy.FactoryComponent>
  );
};

export const OutputTemplate = (props) => {
  const language = getLocalLang()
  return <OutputTemplateSimple {...props} key={language} />;
}


OutputTemplate.displayName = 'OutputTemplate';
