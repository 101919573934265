/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export const FormOutputVehicleEditModal = ({
  design,
  output,
  vehiclesList,
  genericList,
}: {
  design: string;
}) => {
  const { components, schema, config } = zappy.useDesign(design);
  const [isOpen, setIsOpen] = useState(false);

  const { setValue, getValues, trigger } = useFormContext();
  const { 'modal.handle': selectedHandle } = zappy.useWatch({
    name: 'modal.handle',
  });

  const { pathname } = useLocation();

  useEffect(() => {
    setValue('modal.handle', output.handle, { shouldDirty: true });
    setValue('edit', output, { shouldDirty: true });
  }, [output, isOpen]);

  useEffect(() => {
    const editHandle = getValues('edit.handle');

    if (
      selectedHandle &&
      selectedHandle !== editHandle &&
      vehiclesList &&
      genericList
    ) {
      const presetEditVehicle = [...genericList, ...vehiclesList].find(
        ({ handle }) => selectedHandle === handle
      );

      setValue('edit', presetEditVehicle, { shouldDirty: true });
    }
  }, [selectedHandle, vehiclesList, genericList, pathname]);

  const closeModal = () => {
    trigger().finally(
      () => {
        setIsOpen(false);
      }
    );
  };

  const handleConfirm = () => {
    const { edit: editVehicle } = getValues();

    const vehicle =
      genericList.find(({ handle }) => handle === editVehicle.handle) ||
      vehiclesList.find(({ handle }) => handle === editVehicle.handle);
    setValue(
      config.kind,
      { ...vehicle, ...editVehicle },
      { shouldDirty: true }
    );

    closeModal();
  };

  const actions = useMemo(
    () => ({
      onConfirm: handleConfirm,
      onCancel: closeModal,
    }),
    [genericList, vehiclesList]
  );

  if (
    config.displayIfPathname &&
    !config.displayIfPathname.includes(pathname)
  ) {
    return false;
  }

  return (
    <zappy.FactoryComponent schema={schema} data-design={design} as={config.as}>
      <zappy.Button
        design={components.button}
        onClick={() => setIsOpen(true)}
        type="button"
      />
      {isOpen && <zappy.Modal design={components.modal} actions={actions} />}
    </zappy.FactoryComponent>
  );
};

FormOutputVehicleEditModal.displayName = 'FormOutputVehicleEditModal';
