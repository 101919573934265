const ONBOARDING_TEMPLATE = {
  'app.onboarding.template': {
    components: {
      header: 'app.onboarding.template.header',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-rows': 'auto 1fr auto',
        height: '100%',
        background: 'var(--primary-background-color)',
      },
    },
  },
  'app.onboarding.template.header': {
    config: {
      content: [
        ['SmartStepper', 'app.onboarding.template.header.smartStepper'],
      ],
    },
    schema: {
      default: {
        margin: '1.5em',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '1.5em',

        '@media (max-width: 992px)': {
          margin: '16px',
        },
      },
    },
  },
  'app.onboarding.template.header.smartStepper': {
    components: {
      stepper: 'app.onboarding.template.header.stepper',
    },
    config: {
      routeSteps: {
        '/onboarding/intro': 1,
        '/onboarding/info': 2,
        '/onboarding/selection/*': 3,
        '/onboarding/usage': 4,
        '/onboarding/charging': 5,
      },
    },
  },
  'app.onboarding.template.header.stepper': {
    components: {
      doneIndicator: 'app.onboarding.template.header.stepper.indicator.done',
      currentIndicator:
        'app.onboarding.template.header.stepper.indicator.current',
      nextIndicator: 'app.onboarding.template.header.stepper.indicator.next',
      doneSeparator: 'app.onboarding.template.header.stepper.separator.done',
      nextSeparator: 'app.onboarding.template.header.stepper.separator.next',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          '& > hr': {
            'min-width': '14px',
          },
        },
      },
    },
  },
  'app.onboarding.template.header.stepper.separator': {
    config: {
      type: 'line',
    },
  },
  'app.onboarding.template.header.stepper.separator.spacer': {
    config: {
      size: 'md',
    },
  },
  'app.onboarding.template.header.stepper.indicator': {
    config: {
      type: 'icon',
      size: 'md',
    },
  },
  'app.onboarding.template.header.stepper.indicator.done': {
    _extends: 'app.onboarding.template.header.stepper.indicator',
    components: {
      icon: 'app.onboarding.template.header.stepper.indicator.done.icon',
      bar: 'app.onboarding.template.header.stepper.indicator.done.bar',
    },
    schema: {
      default: {
        color: 'white',
        backgroundColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.indicator.done.icon': {
    config: {
      src: 'check',
    },
    schema: {
      default: {
        backgroundColor: 'white',
        borderColor: 'white',
        color: 'white',
      },
    },
  },
  'app.onboarding.template.header.stepper.indicator.done.bar': {
    schema: {
      default: {
        backgroundColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
        color: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.done': {
    _extends: 'app.onboarding.template.header.stepper.separator',
    components: {
      line: 'app.onboarding.template.header.stepper.separator.done.line',
      spacer: 'app.onboarding.template.header.stepper.separator.done.spacer',
    },
  },
  'app.onboarding.template.header.stepper.separator.done.line': {
    schema: {
      default: {
        color: 'var(--primary-color)',
        backgroundColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.done.spacer': {
    _extends: 'app.onboarding.template.header.stepper.separator.spacer',
  },
  'app.onboarding.template.header.stepper.indicator.current': {
    _extends: 'app.onboarding.template.header.stepper.indicator',
    components: {
      icon: 'app.onboarding.template.header.stepper.indicator.current.icon',
      bar: 'app.onboarding.template.header.stepper.indicator.current.bar',
    },
    config: {},
    schema: {
      default: {
        color: 'var(--primary-color)',
        backgroundColor: 'transparent',
        borderColor: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.indicator.current.icon': {
    config: {
      src: 'circle-fill',
      size: 'sm',
    },
    schema: {
      default: {
        backgroundColor: 'var(--primary-color)',
        width: '10px',
        height: '10px',
      },
    },
  },
  'app.onboarding.template.header.stepper.indicator.current.bar': {
    schema: {
      default: {
        backgroundColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
        color: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.current': {
    _extends: 'app.onboarding.template.header.stepper.separator',
    components: {
      line: 'app.onboarding.template.header.stepper.separator.current.line',
      spacer: 'app.onboarding.template.header.stepper.separator.current.spacer',
    },
  },
  'app.onboarding.template.header.stepper.separator.current.line': {
    schema: {
      default: {
        color: 'var(--primary-color)',
        backgroundColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.current.spacer': {
    _extends: 'app.onboarding.template.header.stepper.separator.spacer',
  },
  'app.onboarding.template.header.stepper.indicator.next': {
    _extends: 'app.onboarding.template.header.stepper.indicator',
    components: {
      bar: 'app.onboarding.template.header.stepper.indicator.next.bar',
    },
    schema: {
      default: {
        color: '#BBBBBB',
        backgroundColor: 'white',
        borderColor: '#BBBBBB',
      },
    },
  },
  'app.onboarding.template.header.stepper.indicator.next.bar': {
    schema: {
      default: {
        backgroundColor: '#BBBBBB',
        borderColor: '#BBBBBB',
        color: '#BBBBBB',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.next': {
    _extends: 'app.onboarding.template.header.stepper.separator',
    components: {
      line: 'app.onboarding.template.header.stepper.separator.next.line',
      spacer: 'app.onboarding.template.header.stepper.separator.next.spacer',
    },
  },
  'app.onboarding.template.header.stepper.separator.next.line': {
    schema: {
      default: {
        color: '#BBBBBB',
        backgroundColor: '#BBBBBB',
        borderColor: '#BBBBBB',
      },
    },
  },
  'app.onboarding.template.header.stepper.separator.next.spacer': {
    _extends: 'app.onboarding.template.header.stepper.separator.spacer',
  },
};

export default ONBOARDING_TEMPLATE;
