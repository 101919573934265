// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useFormContext } from 'react-hook-form';
import zappy from '@zappy-ride/library.react.components';

export const useHasTabs = () => {
  const { getValues } = useFormContext();
  const {tabs, ...rest} = getValues();
  return (tab: string) =>{
    const foundTab = tabs?.find((t: string)=>t.id.toLowerCase() === tab.toLowerCase());

    return !!foundTab && (foundTab.displayQuery ? zappy.runQuery(undefined, foundTab.displayQuery, { watched: rest }) : true)
  }
};
