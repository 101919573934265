// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Presenters to convert existing state data and vehicles data to be compatible with current calculation module
import { types as CalculationsTypes } from '@zappy-ride/library.calculations';
import { v4 as uuidv4 } from 'uuid';
import startCase from 'lodash.startcase';
import {
  VehicleSetData,
  VehicleSetDataChargingWindow,
} from '../../types/form.types';
import { PreparedVehicleModel } from '../../types/vehicles.types';
import { convertVehicleSetCharger } from '../chargers';
import { FormVehicleDataSet } from '../../types/tables.types';
import get from 'lodash.get';
import { convertBackViewElectricEfficiencyToDefaultUnit } from '../vehicles';
import zappy from '@zappy-ride/library.react.components';

export function convertBaseVehicleProps({
  fuel,
  is_offroad,
  msrp,
  form_factor,
  duty,
  maintenance_cost,
}: PreparedVehicleModel = {}) {
  return {
    // temporary hack till we get all the possible fuel calculations
    fuel: ['gas', 'regular', 'propane'].includes(fuel) ? 'gasoline' : fuel,
    category: is_offroad ? 'Off-Road' : 'On-Road', // Off-Road, On-Road
    efficiency: undefined,
    efficiencyHeavyLoad: undefined, // off-road (one of for solectract //TODO: Remove)
    efficiencyMediumLoad: undefined, // input.fossil_fuel_efficiency set on convertIceToExistingVehicle
    efficiencyLightLoad: undefined, // off-road (one of for solectract //TODO: Remove)
    msrp: Number(msrp),
    formFactor: form_factor,
    duty: duty ? `${duty}_duty` : duty,
    maintenanceCostPerHour: maintenance_cost,
    maintenanceCostPerMile: maintenance_cost,
  };
}

export function convertEvToReplacementVehicle(
  vehicle: PreparedVehicleModel
): CalculationsTypes.ElectricVehicle {
  const {
    battery_capacity,
    total_range,
    electric_efficiency,
    miles_per_galon_e,
    ac_charging_power,
    dc_charging_power,
  } = vehicle;

  // input.electric_efficiency is in kwh per 100 miles,
  // or it is in Le per KM
  // now transform back it to its client configured unit
  const efficiencyInMilesPerKwh =
    convertBackViewElectricEfficiencyToDefaultUnit(miles_per_galon_e);

  return {
    ...convertBaseVehicleProps(vehicle),
    batteryCapacityInKwh: battery_capacity,
    rangeInMiles: total_range,
    efficiencyMile: undefined,
    rangeInHours: total_range,
    idlingFactor: undefined, // not used right now
    efficiencyInHoursPerKwhHeavy: 0, // off-road (one of for solectract //TODO: Remove)
    efficiencyInHoursPerKwhMedium: electric_efficiency,
    efficiencyInHoursPerKwhLight: 0, // off-road (one of for solectract //TODO: Remove)
    efficiencyInMilesPerKwh,
    acChargingPower: ac_charging_power,
    dcChargingPower: dc_charging_power,
  };
}

export function convertIceToExistingVehicle(
  vehicle: PreparedVehicleModel
): CalculationsTypes.FossilVehicle {
  const { fossil_fuel_efficiency } = vehicle || {};
  return {
    ...convertBaseVehicleProps(vehicle),
    milesPerGallon: fossil_fuel_efficiency,
    efficiencyMediumLoad: fossil_fuel_efficiency, // off-road (ignore)
  };
}

export function convertToVehicleSet(formData: VehicleSetData) {
  const {
    vehicleSetName: name,
    vehicleCount,
    milesPerWorkday,
    hoursPerWorkdayMedium,
    personalMilesPerWorkday,
    personalMilesPaidFor,
    workdays,
    workmonths,
    input,
    output,
    chargingWindows,
    id,
    finance,
    location,
  } = formData;
  const ice = input.kind === 'ice' ? input : output;
  const ev = input.kind === 'ev' ? input : output;

  const existingVehicle = convertIceToExistingVehicle(ice);
  const replacementVehicle = convertEvToReplacementVehicle(ev);

  if (location && finance && !finance.salesTax) {
    finance.salesTax =
      location.regional_financial_references[0].sales_tax.combined;
  }

  if (location && finance && !finance.interestRate) {
    finance.interestRate =
      location.national_financial_references[0].rates.sofr + 2.5;
  }

  const vehicleSet: CalculationsTypes.VehicleSet = {
    id: id || uuidv4(),
    existingVehicle,
    replacementVehicle,
    name,
    existingVehicleId: ice.handle,
    replacementVehicleId: ev.handle, // input.handle
    vehicleCount, // vehicleSetCount
    milesPerWorkday, // avgBusinessDistance
    personalMilesPerWorkday, // avgPersonalMiles
    personalMilesPaidFor, // includePersonalMiles
    hoursPerWorkdayHigh: 0, // could be undefined
    hoursPerWorkdayMedium,
    hoursPerWorkdayLow: 0, // could be undefined
    workdays, // 0  a 6
    workmonths, // 0 a 11
    finance,
    chargingWindows: convertVehicleSetChargingWindows(chargingWindows),
  };

  return vehicleSet;
}

export function convertVehicleSetChargingWindows(
  chargingWindows: Array<VehicleSetDataChargingWindow>
): Array<CalculationsTypes.ChargingWindows> {
  return chargingWindows.map((chargingWindow) =>
    convertVehicleSetChargingWindow(chargingWindow)
  );
}

export function convertVehicleSetChargingWindow({
  charger,
  start,
  finish,
}: VehicleSetDataChargingWindow): CalculationsTypes.ChargingWindows {
  return {
    id: uuidv4(),
    start, // 24 horas -> 0 - 23
    finish, // 24 horas -> 0 - 23
    chargingApproach: 'max',
    chargerId: charger?.charger_id,
    isGeneric: true,
    charger: convertVehicleSetCharger(charger),
  };
}
const fossilFuelKeys = ['diesel', 'gasoline', 'gas', 'propane', 'diesel'];

const electricFuelKeys = ['BEV', 'PHEV', 'FCEV'];
export function getVehicleFleetData(
  vehicleSet: FormVehicleDataSet,
  annualFuelCostPerSet: Record<string, unknown>,
  fuelType: string,
  assumptionsData
): fuelTableType {
  const { type: vehicleType, subtype, input, output } = vehicleSet;
  const isBEV = electricFuelKeys.includes(fuelType);
  const isDiesel = fuelType === fossilFuelKeys[0];
  const fuelCost = isBEV
    ? assumptionsData.customRatePrice || assumptionsData.electricityPrice
    : isDiesel
    ? assumptionsData.dieselPrice
    : assumptionsData.gasolinePrice;
  return {
    setName: `${startCase(vehicleType)} ${subtype}`,
    count: vehicleSet.vehicleCount as number,
    distance: annualFuelCostPerSet.annualMiles as number,
    hours: annualFuelCostPerSet.annualHours as number,
    type: isBEV ? 'electric' : 'fossil',
    efficiency: isBEV
      ? zappy.formatters.formatAsNumber(input.miles_per_galon_e, {
        maximumFractionDigits: 2,
      })
      : output.fossil_fuel_efficiency,
    fuelCost,
    totalCost: (isBEV
      ? annualFuelCostPerSet.electricAnnualFuelCost
      : annualFuelCostPerSet.fossilAnnualFuelCost) as number,
  };
}

const onRoadCommonData = [
  'cargo_volume_max',
  'seats_max',
  'headroom',
  'legroom',
];
const onRoadElectricData = [
  'annualTotalElectric',
  'electric_efficiency',
  'miles_per_galon_e',
  'perMileElectric',
  'millage',
  'fossil_fuel_cost_assumptions',
  ...onRoadCommonData,
];

const onRoadFossilData = [
  'annualTotalFossil',
  'fossil_fuel_efficiency',
  'perMileDiesel',
  'millage',
  'fossil_fuel_cost_assumptions',
  ...onRoadCommonData,
];

export const getOnRoadComparisonData = (
  formData: any,
  annualData: any,
  isOffRoad: boolean
) => {
  const isElectric = electricFuelKeys.includes(formData.fuel);
  const isFossil = fossilFuelKeys.includes(formData.fuel);
  const isDiesel = formData.fuel === fossilFuelKeys[0];
  const isGasoline = formData.fuel !== fossilFuelKeys[0] && !isElectric;
  const dataSource = isElectric ? onRoadElectricData : onRoadFossilData;
  const vehicleName =
    get(formData, 'name') || get(formData, 'vehicle_name', 'Generic');
  const mappedData = {};
  dataSource.forEach((field) => {
    mappedData[field] = get(formData, field, null);
  });
  return {
    ...mappedData,
    ...annualData,
    isElectric,
    isDiesel,
    isGasoline,
    isOffRoad,
    image: formData.image_url,
    model: vehicleName || get(formData, 'model', vehicleName),
    year: get(formData, 'model_year', new Date().getFullYear()),
    name: vehicleName,
    fuelType: isDiesel || isGasoline || isFossil ? formData.fuel : 'electric',
  };
};
