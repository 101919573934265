import { ELECTRIC_EFFICIENCY_UNIT_KEY_NAME } from '../../presenters/vehicles';

export const TABS = [
  {
    to: '/output/overview',
    label: 'Overview',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.overview',
  },
  {
    to: '/output/fuelCosts',
    label: 'Fuel Costs',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.fuel-costs',
  },
  {
    to: '/output/electricity',
    label: 'Electricity',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.electricity',
  },
  {
    to: '/output/incentives',
    label: 'Incentives',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.incentives',
  },
  {
    to: '/output/charging',
    label: 'Charging',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.charging',
  },
  {
    to: '/output/operations',
    label: 'Operations',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.operations',
  },
  {
    to: '/output/vehicles',
    label: 'Vehicles',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.vehicles',
  },
  {
    to: '/output/emissions',
    label: 'Emissions',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.emissions',
  },
  {
    to: '/output/lcfs',
    label: 'LCFS',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.lcfs',
  },
  {
    to: '/output/financing',
    label: 'Financing',
    i18nKey: 'i18n.app.output.template.routes.layout.header.tabs.financials',
  },
];

export const hasTab = (tabLabel) => {
  return TABS.find((t) => t.label.toLowerCase() === tabLabel);
};

// default values for clients that having financing tab enabled
export const FINANCE_DEFAULT_VALUES_ENABLED = {
  financeType: 'cash',
  annualFees: 300,
  downPayment: 20,
  financeTerm: 5,
  yearlyDepreciation: 15,
  residualValue: 16.73,
  salesTaxPayment: 'upfront',
};

export const FINANCE_DEFAULT_VALUES = {
  financeType: 'cash',
  financeTerm: 5,
  salesTaxPayment: 'upfront',
  annualFees: 0,
  downPayment: 0,
  yearlyDepreciation: 0,
  residualValue: 0,
};

export const DESKTOP_THRESHOLD = 993;

const BASE = {
  theme: {
    globals: {
      ':root': {
        '--item-font-weight-value': '700',
        '--font-family':
          'Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif',
        '--font-size': '16px',
        '--font-size-mobile': '14px',
        '--font-weight': '400',
        '--font-style': 'normal',
        '--text-primary-color-normal': '#333333',
        '--text-primary-color-bold': '#333333',
        '--text-primary-color-light': '#404040',
        '--text-primary-inverted-color': 'white',
        '--text-gray-normal': '#3D3935',
        '--primary-color': '#004E9A',
        '--border-color': '#ACB1BA',
        '--color-info': '#004E9A',
        '--color-dark-link': '#66B2FF',
        '--color-error': '#98271A',
        '--input-border': '1px solid #B9BCC0',
        '--color-gray-500': '#B9BCC0',
        '--info-background-color': '#D9EDF6',
        '--icon-dark': '#172536',
        '--warning-background-color': '#FFEAC2',
        '--error-background-color': '#FFEFEF',
        '--disabled-background-color': '#ACB1BA',
        '--disabled-text-color': '#333333',
        '--disabled-color': '#FFFFFF',
        '--max-width-page': '1140px',
        '--gap-row-filters': '20px',
        '--label-font-size': '14px',
        '--second-color': '#4D4D4F',
        '--item-text-color-label': '#595959',
        '--button-text-color': '#004E9A',
        '--primary-dark-color': '#2a2a2a',
        '--secondary-background-color': 'rgba(172, 177, 186, 0.2)',
        '--link-color': '#1565c0',
        '--danger-color': '#98271a',
        '--danger-background-color': '#f2dedf',
        '--info-color': '#0072ce',
        '--primary-background-color':
          'linear-gradient(0deg, rgba(172, 177, 186, 0.25), rgba(172, 177, 186, 0.25)), #FFFFFF;',
        '--button-primary-color': '#172536',
        '--button-primary-color-hover': '#FDA536',
        '--button-secondary-color': '#FDA536',
        '--button-secondary-color-hover': '#172536',
        '--chart-color-1': '#2A2A2A',
        '--chart-color-2': '#ACB1BA',
        '--chart-color-3': '#004E9A',
        '--chart-color-4': '#76BB40',
        '--chart-color-5': '#003353',
        '--item-font-size': '16px',
        '--item-font-size-mobile': '12px',
      },
      '#root': {
        position: 'absolute',
        overflow: 'auto',
        height: '100vh',
        width: '100%',
        fontFamily: 'var(--font-family)',
        fontSize: 'var(--font-size)',
        fontWeight: 'var(--font-weight)',
        fontStyle: 'var(--font-style)',
        color: 'var(--text-color-normal)',
        '-webkit-print-color-adjust': 'exact !important',
        '@media print': {
          height: 'auto',
          display: 'block',
          width: 'unset',
          '& > div > footer': {
            fontSize: '10px',
          },
          '& > div, & main': {
            width: 'unset',
          },
        },
      },
      '#root-portal': {
        fontFamily: 'var(--font-family)',
      },
    },
    fonts: [
      {
        family: 'Roboto',
        src: 'url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)',
        style: 'normal',
        weight: '400',
        unicodeRange:
          'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
      },
      {
        family: 'Roboto',
        style: 'normal',
        weight: '400',
        src: 'url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2)',
        unicodeRange:
          'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
      },
    ],
    mui: {
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              borderBottom: '1px solid #DCDCDC',
              height: 'auto !important',
              padding: '16px',
              '&& img': {
                width: 'auto',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '24px',
              },
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              border: '2px solid var(--primary-color) !important',
              width: '48px !important',
              '& .MuiSwitch-thumb': {
                backgroundColor: 'var(--primary-color) !important',
                styleOverrides: {
                  checked: {
                    backgroundColor: 'white !important',
                  },
                },
              },
              '& .MuiSwitch-switchBase': {
                left: '-1px !important',
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                left: '-1px !important',
              },
              '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                backgroundColor: 'white !important',
              },
            },
          },
        },
        MuiFormGroup: {
          styleOverrides: {
            root: {
              '& .PrivateSwitchBase-root': {
                alignItems: 'center',
                scale: '1.3',
              },
              '& .MuiSwitch-switchBase': {
                alignItems: 'center',
                left: '-1px',
              },
              '& .MuiSwitch-switchBase.checked': {
                left: 0,
              },
              '& .MuiSvgIcon-root': {
                color: 'var(--primary-color)',
              },
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginLeft: '0px !important',
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            '& .Mui-expanded': {
              minHeight: 'auto !important',
            },
            root: {
              '& .MuiAccordionSummary-content.Mui-expanded': {
                marginTop: '5px !important',
              },
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              '& .MuiFormControl-root+.MuiSvgIcon-root': {
                marginTop: '40px',
              },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              color: 'var(--border-color)',
            },
          },
        },
      },
      breakpoints: {
        values: {
          sm: DESKTOP_THRESHOLD,
        },
      },
    },
  },
  preloads: {
    watch: {
      name: ['zipcode', 'encodedPowerSuppliers'],
    },
    requests: {
      chargers: {
        url: '{{api.url}}/unified-chargers?types=archetype',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'chargers',
        presenterId: 'chargers.presenter',
      },
      unified_chargers: {
        url: '{{api.url}}/unified-chargers?types=commercial,archetype',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'chargers',
        presenterId: 'chargers.presenter',
      },
      organization_types: {
        url: '{{api.awsUrl}}?key=unbranded.fleets.d.zappyride.com.organization-types.json',
        opts: {
          includes: [['headers.x-api-key', 'ADMIN_API_KEY_VALUE']],
        },
      },
      electric_vehicles: {
        url: '{{api.url}}/commercial/vehicles?postcode={{zipcode}}&fuel_type=BEV',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'vehicles',
        presenterId: 'vehicles.presenter',
        presenterArgs: {
          kind: 'ev',
        },
      },
      mapping_ice_ev: {
        url: '{{api.url}}/vehicle-mapping?type=ice_ev',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'vehicle-mapping',
      },
      mapping_ev_ice: {
        url: '{{api.url}}/vehicle-mapping?type=ev_ice',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'vehicle-mapping',
      },
      fossil_vehicles: {
        url: '{{api.url}}/commercial/vehicles?postcode={{zipcode}}&fuel_type=gas',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'vehicles',
        presenterId: 'vehicles.presenter',
        presenterArgs: {
          kind: 'ice',
        },
      },
      generic_vehicles: {
        url: '{{api.url}}/archetype-vehicles',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'archetypes',
        presenterId: 'generic.presenter',
      },
      operation_days: {
        url: '{{api.awsUrl}}?key=operation-days-new.json',
        opts: {
          includes: [['headers.x-api-key', 'ADMIN_API_KEY_VALUE']],
        },
      },
      operation_months: {
        url: '{{api.awsUrl}}?key=operation-months-new.json',
        opts: {
          includes: [['headers.x-api-key', 'ADMIN_API_KEY_VALUE']],
        },
      },
      charging_window_hours: {
        url: '{{api.awsUrl}}?key=unbranded.fleets.d.zappyride.com.charging-window-hours.json',
        opts: {
          includes: [['headers.x-api-key', 'ADMIN_API_KEY_VALUE']],
        },
      },
      commercial_incentives: {
        url: '{{api.url}}/commercial/incentives?postcode={{zipcode}}',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'incentives',
        presenterId: 'commercialIncentives.presenter',
      },
      location: {
        url: '{{api.url}}/location?postcode={{zipcode}}&convertToDollars=true',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'location',
      },
      power_suppliers_zipcodes: {
        url: '{{api.url}}/powersupplierspostcodes?name={{encodedPowerSuppliers}}',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'postcodes',
      },
      power_suppliers: {
        url: '{{api.url}}/powersuppliers?postcode={{zipcode}}',
        opts: {
          includes: [['headers.Authorization', 'api.Authorization']],
        },
        dataAttrName: 'suppliers',
      },
    },
  },
  api: {
    Authorization: process.env.REACT_APP_UNIFIED_API_TOKEN,
    url: process.env.REACT_APP_UNIFIED_API_URL,
    awsUrl:
      'https://6y4a6f112d.execute-api.us-east-1.amazonaws.com/Prod/getclient',
  },
  form: {
    device: null,
    printing: false,
    url: null,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      tabs: TABS,
      confirmTermsAndConditions: false,
      step3Tabs: false,
      clientName: 'Unbranded',
      mode: 'new',
      encodedPowerSuppliers: '',
      powerSuppliers: '',
      constants: {
        dieselEER: 5.0,
        gasolineEER: 3.4,
        energyDensity: 3.6,
        gridIntensity: 82.92,
        dieselEmissionsinLbsPerGal: 22.45,
        gasolineEmissionsInLbsPerGal: 17.86,
        finance: hasTab('financing')
          ? FINANCE_DEFAULT_VALUES_ENABLED
          : FINANCE_DEFAULT_VALUES,
      },
      assumptionsData: {
        complexRateEnabled: true,
        rates: {},
        selectedRateId: 'BEV',
        selectedRateName: 'Business EV Rate',
        selectedUtilityName: 'Evergy Kansas Central',
        isExternalRateEngine: false,
        year: 2023,
        state: 'California',
        carbonCreditsPrice: 175,
        gasolinePrice: 5.317,
        dieselPrice: 6.321,
        electricityPrice: 0.2677,
        customRatePrice: 0.2677,
        electricityEmissions: 10146.853,
        publicChargerElectricityCost: 0.35,
        lifespanYears: 10,
        languageSelected: 'eng',
        langEnabled: false,
        level2ChargersInstallationCost: 6266,
        dcfcChargersInstallationCost: 79534,
        chargerInstallationCost: null,
        customChargerInstallationCost: 12532,
        incentives: null,
        publicEntity: 'No',
        taxExempt: 'No',
        customChargerInstallationCostToggler: false,
        offroadDieselPrice: 3.553,
        hideSummaryIncentives: false,
        incentivesType: 'evaluators',
        hideInsuranceUI: false,

        // currency conversion , currently working only cad.
        // cad, eur, gbp, jpy, brl, mxn
        currencyConversion: null,
      },
      simpleRate: {
        name: 'simple',
        longName: 'Simple/Custom Rate',
        descriptionFormatted:
          'This rate uses a singular value for energy costs, with no demand charges. We provide a default value from the EIA’s regional average rate data, but you can modify this value',
      },
      rateSelection: {
        selectedRateId: 'BEV',
        customRatePrice: 0.2677,
        enableSimpleRate: true,
      },
      filters: {
        organizationType: 'medium_or_large_businesses',
        isPrioritySchoolDistrict: false,
        isMilitary: false,
        zipcode: '66015',
        inADAC: 'No',
        assumptionsData: {
          lifespanYears: 10,
          publicEntity: 'No',
          taxExempt: 'No',
          customChargerInstallationCost: null,
          customChargerInstallationCostToggler: false,
          gasolinePrice: 5.317,
          dieselPrice: 6.321,
          offroadDieselPrice: 3.553,
          publicChargerElectricityCost: 0.35,
          selectedUtilityName: 'Evergy Kansas Central',
        },
        customEmissions: {
          customIce: 0,
          customElectric: 0,
          toggleIce: false,
          toggleElectric: false,
          isDiesel: false,
        },
      },
      catalogs: {
        filteredListSize: 0,
        allListSize: 0,
        referenceId: null,
        referenceIndex: 0,
        sort: 'total_range:-1',
        vehicles: {
          category: 'on-road',
          fuelType: 'all',
          type: [],
          subType: 'all',
          make: 'all',
          budget: 2000000,
          minRange: 10,
          minRuntime: 0,
          availability: true,
          weightClass: '0',
          availableSorts: [
            'total_range:-1',
            'total_range:1',
            'price:1',
            'price:-1',
          ],
          sort: 'total_range:-1',
        },
        chargers: {
          minimumOutput: 'all',
          charger_type: 'all',
          maximumPrice: 'all',
          ports: 'all',
          wallmounted: true,
          pedestal: true,
          overhead: true,
          portable: true,
          DCFC: 'Yes',
          networked: false,
          vehicleToGrid: false,
          OCPP: false,
          OSCP: false,
          sort: 'price:1',
          availableSorts: ['price:1', 'price:-1'],
          make: 'all',
          rebate: false,
        },
      },
      warnings: {
        electricity: {
          maxKwh: 200,
        },
      },
      id: '',
      zipcode: '66015',
      forMultipleSites: false,
      weekdaysOnly: true,
      allMonths: true,
      personalMilesPaidFor: true,
      type: 'pickup_truck',
      subtype: 'Medium Duty',
      handle: 'on_road_pick_up_truck_medium_duty',
      vehicleSets: [],
      editingVehicleSetIndex: null,
      selectedVehicleSetId: null,
      vehicleCount: 2,
      vehicleSetName: 'Vehicle Set #1',
      input: {},
      output: {},
      finance: hasTab('financing') ? FINANCE_DEFAULT_VALUES_ENABLED : null,
      electricityData: {
        dayOfWeek: 'monday',
      },
      workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      workdays: [1, 2, 3, 4, 5],
      selectionIsDirty: true,
      milesPerWorkday: 140,
      personalMilesPerWorkday: 20,
      hoursPerWorkdayMedium: 3,
      inADAC: 'No',
      organizationType: 'medium_or_large_businesses',
      modal: {
        handle: '',
        type: '',
        subtype: '',
      },
      stateChargeThreshold: 20,
      minimumStateChargePercentage: 21,
      defaultChargingWindow: {
        start: 18,
        finish: 6,
        chargerId: 'AC_9.6_1_wire',
      },
      chargingWindows: [
        {
          start: 18,
          finish: 6,
          //chargerId should be blank so we force to use recommendedCharger
          chargerId: '',
          access: 1,
          charger: {
            commercial: true,
            id: 'AC_9.6_1_wire',
            charger_power_level: 9.6,
            portKw: 9.6,
            charger_type: 'level_2',
            imageUrl:
              'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-02.png',
            charger_id: 'AC_9.6_1_wire',
            type: 'AC',
            kw: 9.6,
            ports: 1,
            price: 100,
            networked: false,
            bluetooth: false,
            ethernet: false,
            warranty: 3,
            wifi: true,
            label: 'Level 2 - 9.6 kW',
            cellular_support: [],
            plug_types: ['J1772'],
            form_factor: 'Wall mounted',
            cord_length: 22.2,
            home_outlet: ['14-50'],
            oscp_version: '',
            ocpp_version: '',
            vgi_capable: false,
            certified_by: ['UL', 'cUL'],
            certifications: ['UL', 'cUL'],
            connector_types: ['J1772'],
            load_balancing: null,
            image_url:
              'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-02.png',
          },
        },
      ],
      printing: false,
    },
  },
  interceptor: {
    actions: {
      'form/setAvgDistancePerDay': {
        type: 'form/setAvgDistancePerDay',
        payload: {
          name: 'avgDistancePerDay',
        },
        watch: {
          name: ['milesPerWorkday', 'personalMilesPerWorkday'],
        },
      },
      'form/addVehicleSet': {
        type: 'form/addVehicleSet',
      },
      'form/editVehicleSet': {
        type: 'form/editVehicleSet',
      },
      'form/removeVehicleSet': {
        type: 'form/removeVehicleSet',
      },

      'form/toggleCustomEmissions': {
        type: 'form/toggleCustomEmissions',
        watch: {
          name: ['filters.customEmissions.toggleElectric'],
        },
        payload: {
          emissionsType: 'electric',
        },
      },
      'form/toggleCustomEmissionsIce': {
        type: 'form/toggleCustomEmissions',
        watch: {
          name: ['filters.customEmissions.toggleIce'],
        },
        payload: {
          emissionsType: 'ice',
        },
      },
      'form/setCustomEmissionsIce': {
        type: 'form/setCustomEmissions',
        watch: {
          name: ['vehicleSets'],
        },
        payload: {
          emissionsType: 'ice',
        },
      },

      'form/setChargerInstallationCosts': {
        type: 'form/setChargerInstallationCosts',
        watch: {
          name: [
            'vehicleSets',
            'assumptionsData.customChargerInstallationCostToggler',
          ],
        },
      },
      'api/request/setProject': {
        type: 'api/request/setProject',
      },
      'form/setKwhPerDayNeeded': {
        type: 'form/setKwhPerDayNeeded',
        payload: {
          name: 'kWhPerDayNeeded',
        },
        watch: {
          name: [
            'milesPerWorkday',
            'personalMilesPerWorkday',
            'input.electric_efficiency',
            'input.isReady',
          ],
        },
      },
      'form/zipcodeChanged': {
        type: 'form/analyticsTrack',
        payload: {
          category: 'Zipcode Changed',
          action: 'Zipcode Changed',
          label: '{{picked}}',
          pick: 'zipcode',
        },
        watch: {
          name: ['zipcode'],
        },
      },
      'form/validateTotalRange': {
        type: 'form/validateTotalRange',
        watch: {
          name: ['input.total_range'],
        },
      },
    },
  },
  designs: {},
  configs: {
    [ELECTRIC_EFFICIENCY_UNIT_KEY_NAME]: 'kwh per 100 miles',
    dynamicCalculationModule:
      'https://calculations.fleets.d.zappyride.com/development/beta.library.calculations.bundle.js',
    rateEngineParams: {
      url: 'https://apigw.beta.zappyride.com/rates',
    },
    translations: {
      defaultLng: 'eng',
      supportedLngs: ['eng'],
      fallbackLng: 'app_default',
      loadPath:
        '{{api.awsUrl}}?key=unbranded.fleets.d.zappyride.com.{{lng}}.json',
      loadPaths: null,
      loadPathsForLogo: null,
      basePath: '{{api.awsUrl}}?key=',
    },
    abilities: {
      header: {
        show: [
          {
            fields: ['languageSwitcher', 'userMenu'],
          },
        ],
      },
      financeForm: {
        show: [
          {
            fields: ['registrationFees'],
          },
        ],
      },
    },
    defaultValues: {
      powerSuppliers: [
        'Evergy Missouri Metro',
        'Evergy Missouri West',
        'Evergy Kansas Central',
        'Evergy Kansas Metro',
      ],
      onboarding: {
        ice: undefined,
        ev: {
          input: {
            kind: 'ev',
            handle: 'on_road_pick_up_truck_medium_duty',
            preload: 'generic_vehicles.ev',
          },
          output: {
            kind: 'ice',
            handle: 'on_road_pick_up_truck_medium_duty',
            preload: 'generic_vehicles.ice',
          },
        },
      },
    },
  },
  analytics: [
    {
      trackingId: 'G-FENGXPS8NZ',
    },
  ],
};

export default BASE;
