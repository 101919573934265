const SUMMARY = {
  'app.output.template.summary': {
    config: {
      content: [
        ['ContentContainer', 'app.output.template.summary.title'],
        ['ContentContainer', 'app.output.template.summary.statsElements'],
      ],
    },
    schema: {
      default: {
        'background-color': 'white',
        'border-radius': '5px',
        padding: '24px',
        'grid-column': '1/3',
        'margin-bottom': '24px',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.summary.stats.divider': {
    schema: {
      default: {
        display: 'inline-block',
        'background-color': '#ACB1BA',
        height: '48px',
        width: '1px',
      },
    },
  },
  'app.output.template.summary.statsElements': {
    _extends: ['app.common.incentives.displayQuery'],
    config: {
      content: [
        ['ContentContainer', 'app.output.template.summary.stats', {
          displayQuery: 'showSummaryIncentives',
          className: 'showSummaryIncentives',
        }],
        ['ContentContainer', 'app.output.template.summary.stats', {
          displayQuery: 'hideSummaryIncentives',
          className: 'hideSummaryIncentives',
        }],
      ],
    },
  },
  'app.output.template.summary.stats': {
    _extends: ['app.common.incentives.displayQuery'],
    config: {
      role: 'stats',
      content: [
        ['IconText', 'app.output.template.summary.lifetimeSavings'],
        ['', 'app.output.template.summary.stats.divider'],
        ['IconText', 'app.output.template.summary.vehicleCount'],
        ['', 'app.output.template.summary.stats.divider'],
        ['IconText', 'app.output.template.summary.annualFuelSavings'],
        [
          'IconText',
          'app.output.template.summary.annualFuelSavingsPerMile',
          { displayQuery: 'onRoad' },
        ],
        [
          'IconText',
          'app.output.template.summary.annualFuelSavingsPerHour',
          { displayQuery: 'offRoad' },
        ],
        ['', 'app.output.template.summary.stats.divider', { displayQuery: 'showSummaryIncentives' }],
        [
          'IconText',
          'app.output.template.summary.totalIncentives',
          { displayQuery: 'showSummaryIncentives' }
        ],
        ['', 'app.output.template.summary.stats.divider'],
        ['IconText', 'app.output.template.summary.totalEmissions'],
      ],
      displayQueries: {
        onRoad: { '["props", "isOffRoad"]': { $neq: true } },
        offRoad: {
          '["props", "isOffRoad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'justify-content': 'center',
        'align-items': 'center',
        '&.showSummaryIncentives': {
          'grid-template-columns': '1fr 1px 0.7fr 1px 1fr 0.3fr 1px 1.2fr 1px 1.2fr',
        },
        '&.hideSummaryIncentives': {
          'grid-template-columns': '1fr 1px 0.7fr 1px 1fr 0.3fr 1px 1.2fr',
        },
      },
    },
  },

  'app.output.template.summary.totalEmissions.text': {
    _extends: [
      'app.output.template.summary.iconText.common.text',
      'app.output.template.routes.emissions.template.emissionsTable.formatters.auto.value',
    ],
  },

  'app.output.template.summary.totalEmissions': {
    _extends: 'app.output.template.summary.iconText.common',
    components: {
      text: 'app.output.template.summary.totalEmissions.text',
    },
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/evergy-emissions.svg',
        alt: 'Total GHG Emissions Saved',
      },
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.totalEmissions',
          fallback: 'Total GHG Emissions Saved',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'totalEmissions',
        autoPick: 'tons',
        'aria-level': 6,
      },
      tooltip: {
        popover: {
          position: 'SW',
          translatedText: {
            i18n: {
              id: 'i18n.output.template.summary.totalEmissions.tooltip',
              fallback:
                'View the Emissions tab for an explanation and breakdown of how this lifetime savings figure was calculated',
            },
          },
        },
      },
    },
    schema: {
      default: {
        '& > span': {
          'font-size': '22px',
        },
      },
    },
  },
  'app.output.template.summary.totalIncentives': {
    _extends: 'app.output.template.summary.iconText.common',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/evergy-dollar.svg',
        alt: 'Federal, State & Local Incentives',
      },
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.totalIncentives',
          fallback: 'Federal, State & Local Incentives',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'totalIncentives',
        formatter: 'formatAsDollars',
        'aria-level': 5,
      },
      tooltip: {
        popover: {
          position: 'SW',
          translatedText: {
            i18n: {
              id: 'i18n.output.template.summary.totalIncentives.tooltip',
              fallback:
                'View the Incentives tab for an explanation and breakdown of how this lifetime savings figure was calculated',
            },
          },
        },
      },
    },
  },
  'app.output.template.summary.annualFuelSavingsPerMile': {
    _extends: 'app.output.template.summary.iconText.common',
    components: {
      icon: null,
      tooltip: null,
    },
    config: {
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.annualFuelSavingsPerMile',
          fallback: 'Per Mile',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'annualFuelSavingsPerMile',
        formatter: 'formatAsCents',
        'aria-level': 4,
      },
    },
    schema: {
      default: {
        'grid-template-columns': '1fr',
        'grid-column-gap': 'auto',
        'padding-left': '0',
      },
    },
  },
  'app.output.template.summary.annualFuelSavingsPerHour': {
    _extends: 'app.output.template.summary.iconText.common',
    components: {
      icon: null,
      tooltip: null,
    },
    config: {
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.annualFuelSavingsPerHour',
          fallback: 'Per Hour',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'annualFuelSavingsPerHour',
        formatter: 'formatAsCents',
      },
    },
    schema: {
      default: {
        'grid-template-columns': '1fr',
        'grid-column-gap': 'auto',
        'padding-left': '0',
      },
    },
  },
  'app.output.template.summary.annualFuelSavings': {
    _extends: 'app.output.template.summary.iconText.common',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/evergy-gas.svg',
        alt: 'Annual Fuel Savings',
      },
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.annualFuelSavings',
          fallback: 'Annual Fuel Savings',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'annualFuelSavings',
        formatter: 'formatAsDollars',
        'aria-level': 3,
      },
      tooltip: {
        popover: {
          translatedText: {
            i18n: {
              id: 'i18n.output.template.summary.annualFuelSavings.tooltip',
              fallback:
                'View the Fuel Costs tab for an explanation and breakdown of how this lifetime savings figure was calculated',
            },
          },
        },
      },
    },
    schema: {
      default: {
        'padding-right': '12px',
      },
    },
  },
  'app.output.template.summary.lifetimeSavings': {
    _extends: 'app.output.template.summary.iconText.common',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/evergy-stats.svg',
        alt: 'Lifetime Savings',
      },
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.lifetimeSavings',
          fallback: 'Lifetime Savings',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'lifetimeSavings',
        formatter: 'formatAsDollars',
        'aria-level': 1,
      },
      tooltip: {
        popover: {
          translatedText: {
            i18n: {
              id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
            },
          },
        },
      },
    },
  },
  'app.output.template.summary.vehicleCount': {
    _extends: 'app.output.template.summary.iconText.common',
    components: {
      tooltip: null,
    },
    config: {
      icon: {
        src: ' https://assets.zappyride.com/img/icons/evergy/evergy-car.svg',
        alt: 'Vehicle Count',
      },
      header: {
        i18n: {
          id: 'i18n.output.template.summary.iconText.vehicleCount',
          fallback: 'Vehicle Count',
        },
      },
      text: {
        component: 'Formatter',
        as: 'span',
        pick: 'vehicleCount',
        'aria-level': 2,
      },
    },
  },
  'app.output.template.summary.title': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.summary.title.text'],
        ['Tooltip', 'app.output.template.summary.title.tooltip'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        alignItems: 'center',
        'margin-bottom': '12px',
      },
    },
  },
  'app.output.template.summary.title.text': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.output.template.summary.title',
        fallback: 'Fleet Summary',
      },
    },
    schema: {
      default: {
        'font-style': 'normal',
        'font-weight': '600',
        'font-size': '16px',
        'line-height': '16px',
      },
    },
  },
  'app.output.template.summary.title.tooltip.popover.text': {
    config: {
      element: 'span',
      i18n: {
        id: 'i18n.output.template.summary.title.tooltip.popover.text',
        fallback:
          'This tool provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications or incentive estimates. Please refer to referenced sources and original equipment manufacturers for up-to-date costs, hardware specifications and available incentives.',
      },
    },
  },
  'app.output.template.summary.title.tooltip.popover': {
    _extends: 'app.common.tooltip.popover',
    config: {
      position: 'SE',
      alwaysOn: true,
      content: [
        [
          'TranslatedText',
          'app.output.template.summary.title.tooltip.popover.text',
        ],
      ],
    },
    schema: {
      default: {
        margin: '5px',
        textAlign: 'left',
      },
    },
  },
  'app.output.template.summary.title.tooltip': {
    _extends: 'app.common.tooltip',
    components: {
      popover: 'app.output.template.summary.title.tooltip.popover',
    },
  },
  'app.output.template.summary.iconText.common.header': {
    config: {
      as: 'p',
      color: 'var(--primary-dark-color)',
    },
    schema: {
      default: {
        textAlign: 'left',
        'grid-row': '1',
        'grid-column': '2',
        fontSize: '12px',
        'line-height': '12px',
      },
    },
  },
  'app.output.template.summary.iconText.common.text': {
    _extends: 'app.common.skeleton',
    config: {
      as: 'p',
      textAlign: 'left',
      color: 'var(--primary-dark-color)',
      role: 'heading',
    },
    schema: {
      default: {
        'grid-column': '-1 / 2',
        'grid-row': '-1 / 2',
        'font-weight': '400',
        'font-size': '22px',
        'line-height': '22px',
        'min-height': '22px',
        'margin-top': '6px',
      },
      skeleton: {
        color: 'transparent',
        width: '100%',
      },
    },
  },
  'app.output.template.summary.iconText.common.icon': {
    config: {
      component: 'Icon',
      color: 'var(--primary-color)',
    },
    schema: {
      default: {
        'grid-column': '1',
        'grid-row': '1 / -1',
        height: '26px',
        width: '26px',
        'align-self': 'center',
        'margin-right': '24px',
      },
    },
  },
  'app.output.template.summary.iconText.common.tooltip.activator': {
    config: {
      component: 'Icon',
      src: 'info',
    },
    schema: {
      default: {
        backgroundColor: '#323232',
        height: '14px',
        width: '14px',
        margin: 'auto',
        'align-self': 'center',
      },
    },
  },
  'app.output.template.summary.iconText.common.tooltip.popover': {
    config: {
      position: 'SE',
      alwaysOn: true,
    },
    schema: {
      default: {
        padding: '8px 16px',
        background: '#2A2A2A',
        'border-radius': '5px',
        'box-shadow': '0 0 10px 5px #2222',
        'max-width': '400px',
      },
    },
  },
  'app.output.template.summary.iconText.common.tooltip': {
    components: {
      activator: 'app.common.tooltip.activator',
      popover: 'app.output.template.summary.iconText.common.tooltip.popover',
    },
    schema: {
      default: {
        'grid-area': 'tooltip',
        display: 'flex',
        marginLeft: '0.4rem',
        'grid-column': '3',
        'grid-row': '1',
      },
    },
  },
  'app.output.template.summary.iconText.common': {
    components: {
      tooltip: 'app.output.template.summary.iconText.common.tooltip',
      header: 'app.output.template.summary.iconText.common.header',
      text: 'app.output.template.summary.iconText.common.text',
      icon: 'app.output.template.summary.iconText.common.icon',
    },
    config: {
      tooltip: {
        popover: {
          translatedText: {
            _extends:
              'app.output.template.summary.iconText.common.tooltip.popover.text',
          },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'background-color': 'transparent',
        'grid-template-rows': 'repeat(2, auto)',
        'grid-template-columns': 'auto auto',
        'align-items': 'flex-start',
        padding: '12px 24px',
        'min-height': '72px',
        'justify-items': 'center',
      },
    },
  },
  'app.output.template.summary.iconText.common.tooltip.popover.text': {
    schema: {
      default: {
        'font-size': '12px',
        'line-height': '14px',
        color: '#FFFFFF',
      },
    },
  },
};

export default SUMMARY;
