export const OUTPUT_INCENTIVES = {
  'app.output.template.routes.incentives': {
    config: {
      role: 'tabpanel',
      content: [
        ['ContentContainer', 'app.output.template.routes.incentives.content'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        flex: 1,
      },
    },
  },
  'app.output.template.routes.incentives.content': {
    _extends: 'app.common.device.selector',
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.incentives.head',
          {
            displayQueries: 'mobileOrPrinting',
            className: 'pageHeader',
          },
        ],
        ['OutputIncentives', 'app.output.template.routes.incentives.template'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'span.MuiTypography-tableBody': {
          wordBreak: 'break-word',
        },
      },
    },
  },
  'app.output.template.mobile.incentives.head': {
    _extends: 'app.output.template.mobile.common.head',
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.incentives.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        'margin-bottom': '32px',
      },
    },
  },
  'app.output.template.mobile.incentives.head.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.incentives.head.title',
        fallback: 'Incentives',
      },
    },
  },
  'app.output.template.routes.incentives.template': {
    components: {
      headline: 'app.output.template.routes.incentives.template.headline',
      subHeadline: 'app.output.template.routes.incentives.template.subHeadline',
      incentives: 'app.output.template.routes.incentives.template.incentives',
    },
  },
  'app.output.template.routes.incentives.template.headline': {
    config: {
      as: 'h2',
      fontSize: '22px',
      i18n: {
        id: 'i18n.output.template.routes.incentives.template.headline',
        fallback: 'Potential eligible incentives',
      },
    },
    schema: {
      default: {
        marginBottom: '16px',
      },
    },
  },
  'app.output.template.routes.incentives.template.subHeadline': {
    config: {
      as: 'p',
      fontSize: '16px',
      i18n: {
        id: 'i18n.output.template.routes.incentives.template.subHeadline',
        fallback:
          'This project may potentially be eligible for the following incentives.',
      },
    },
    schema: {
      default: {
        marginBottom: '24px',
      },
    },
  },
  'app.output.template.routes.incentives.template.incentives': {
    components: {
      eligibleDialog:
        'app.output.template.routes.incentives.template.incentives.eligibleDialog',
      incentive:
        'app.output.template.routes.incentives.template.incentives.incentive',
      ineligibleDialog:
        'app.output.template.routes.incentives.template.incentives.ineligibleDialog',
    },
    schema: {
      default: {
        '@media print': {
          '& > div:first-child': {
            display: 'block',
          },
        },
      },
    },
  },
  'app.output.template.routes.incentives.template.incentives.eligibleDialog': {
    _extends: 'app.common.iconText.exclamationMarkFilled',
    config: {
      icon: {
        src: 'info',
        color: 'var(--info-color)',
      },
      text: {
        i18n: {
          id: 'i18n.output.template.routes.incentives.template.incentives.eligibleDialog',
          fallback:
            'These are available incentives based on information you entered. Please be aware you must apply directly with the organization referenced and confirm eligibility and amount directly with that organization. Funding amounts estimated here are estimates only and are not guaranteed.',
        },
      },
    },
    schema: {
      default: {
        backgroundColor: 'var(--info-background-color)',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.routes.incentives.template.incentives.ineligibleDialog':
    {
      _extends: 'app.common.iconText',
      config: {
        icon: {
          src: 'block',
          color: 'var(--danger-color)',
        },
        text: {
          i18n: {
            id: 'i18n.app.output.template.routes.incentives.template.incentives.ineligibleDialog',
            dangerouslySetInnerHTML: true,
            fallback:
              'Ineligible Incentives<br/>Your project is ineligible for the incentives below',
          },
        },
      },
      schema: {
        default: {
          'background-color': 'var(--danger-background-color)',
          breakBefore: 'page',
        },
      },
    },
  'app.output.template.routes.incentives.template.incentives.incentive': {
    components: {
      spec: 'app.output.template.routes.incentives.template.incentives.incentive.spec',
    },
    config: {
      css: {
        eligible: {
          backgroundColor: 'var(--primary-background-color)',
          borderLeftColor: 'var(--primary-color)',
        },
        ineligible: {
          backgroundColor: 'var(--secondary-background-color)',
          borderLeftColor: 'var(--primary-dark-color)',
        },
        hiatus: {
          backgroundColor: 'var(--secondary-background-color)',
          borderLeftColor: 'var(--primary-dark-color)',
        },
        default: {
          backgroundColor: 'var(--secondary-background-color)',
          borderLeftColor: 'var(--primary-dark-color)',
        },
      },
      eligibility: {
        eligible: {
          color: '#50802a',
        },
        ineligible: {
          color: '#E35205',
        },
        hiatus: {
          color: 'var(--primary-dark-color)',
        },
        default: {
          color: 'var(--primary-dark-color)',
        },
      },
      estimatedAmount: {
        format: {
          formatter: 'formatAsDollars',
        },
        css: {
          eligible: {
            color: 'var(--primary-color)',
          },
          default: {
            color: 'var(--primary-dark-color)',
          },
        },
      },
      expirationDate: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      typicalAmount: {
        label: {
          converter: {
            'Up to $1,000': 'Max',
            'Up to $9,500 per charger': 'Max/Charger',
            'Up to $9,500 per charging station': 'Max/Charger',
            'Up to $9,500 per port': 'Max/Port',
            'Up to $9,500 per vehicle': 'Max/Vehicle',
            'Up to $9,500 per forklift': 'Max/Forklift',
            'Up to $9,500 per project.': 'Max/Project',
            'Up to $30,000 per project.': 'Max/Project',
            'Up to $9,500 per site': 'Max/Site',
            'Up to $9,500 per year': 'Max/Year',
            'Up to 80%': 'Max/Project',
            'Up to 30% discount': 'Max',
            'Up to 80% of costs': 'Max % Costs',
            'Up to 2% of tax': 'Max %Tax',
            fallback: 'Max',
          },
        },
        value: {
          converter: {
            'Up to $1,000': '$1,000',
            'Up to $9,500 per charger': '$9,500',
            'Up to $9,500 per charging station': '$9,500',
            'Up to $9,500 per port': '$9,500',
            'Up to $9,500 per vehicle': '$9,500',
            'Up to $9,500 per forklift': '$9,500',
            'Up to $9,500 per project.': '$9,500',
            'Up to $30,000 per project.': '$30,000',
            'Up to $9,500 per site': '$9,500',
            'Up to $9,500 per year': '$9,500',
            'Up to 80%': '80%',
            'Up to 30% discount': '30%',
            'Up to 80% of costs': '80%',
            'Up to 2% of tax': '2%',
            fallback: '---',
          },
        },
      },
      funding: {
        format: {
          formatter: 'formatAsDollars',
          fallback: '---',
        },
      },
      translations: {
        eligibilityRationale: {
          id: 'i18n.incentive.eligibilityRationale',
          fallback: 'Project Eligibility Analysis',
        },
        status: {
          id: 'i18n.incentive.status',
          fallback: 'Status',
        },
        details: {
          id: 'i18n.incentive.details',
          fallback: 'Details',
        },
        fundingBreakdown: {
          id: 'i18n.incentive.fundingBreakdown',
          fallback: 'Funding Breakdown',
        },
        assumptions: {
          id: 'i18n.incentive.assumptions',
          fallback: 'Assumptions',
        },
        closingDate: {
          id: 'i18n.incentive.closingDate',
          fallback: 'Closing date',
        },
        max: {
          id: 'i18n.incentive.max',
          fallback: 'Max',
        },
        organizations: {
          id: 'i18n.incentive.organizations',
          fallback: 'Organizations',
        },
        totalAvailable: {
          id: 'i18n.incentive.totalAvailable',
          fallback: 'Total available',
        },
        fundingToDate: {
          id: 'i18n.incentive.fundingToDate',
          fallback: 'Funding to date',
        },
        applicabilaty: {
          id: 'i18n.incentive.applicability',
          fallback: 'Applicability',
        },
        learnMore: {
          id: 'i18n.incentive.link',
          fallback: 'Learn More',
        },
        logoText: {
          id: 'i18n.incentive.logoText',
          fallback: 'summarized by',
        },
      },
      link: {
        to: 'https://google.com',
      },
    },
    schema: {
      default: {
        pageBreakAfter: 'auto',
        pageBreakInside: 'avoid',
      },
    },
  },
  'app.output.template.routes.incentives.template.incentives.incentive.spec': {
    config: {
      readMore: {
        id: 'i18n.incentive.truncate.readMore',
        fallback: 'Read More',
      },
      readLess: {
        id: 'i18n.incentive.truncate.readLess',
        fallback: 'Read Less',
      },
    },
  },
};

export default OUTPUT_INCENTIVES;
