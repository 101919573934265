import zappy from '@zappy-ride/library.react.components';
import { OutputFuelCosts } from './FuelCosts';
import { CompareRatesButton } from './CompareRatesButton';
import { RadioGroupRates } from './RadioGroupRates';

export * from './FuelCosts';
export * from './CompareRatesButton';

zappy.FactoryInstance.registerComponent(OutputFuelCosts);
zappy.FactoryInstance.registerComponent(CompareRatesButton);
zappy.FactoryInstance.registerComponent(RadioGroupRates);
