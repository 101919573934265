import { useMemo, useState } from 'react';
import {
  saveToLocalStorage,
  getDecompressedLocalData,
  removeFromLocalStorage,
  getSessionId,
} from '../utils/localStorageUtils';

export const useLocalStorage = (givenKey: string) => {
  const [loadingKey, setLoadingKey] = useState(givenKey);

  const accessFunctions = useMemo(
    () => ({
      saveToLocalStorage: (value: any, key = givenKey) =>
        saveToLocalStorage(value, key),
      getLocalStorage: (key = loadingKey) => getDecompressedLocalData(key),
      removeFromLocalStorage: (key = givenKey) => {
        removeFromLocalStorage(loadingKey);
        removeFromLocalStorage(key);
      },
      getSessionId,
    }),
    [loadingKey]
  );

  return {
    setLoadingKey,
    getLocalStorage: accessFunctions.getLocalStorage,
    saveToLocalStorage: accessFunctions.saveToLocalStorage,
    removeFromLocalStorage: accessFunctions.removeFromLocalStorage,
    getSessionId: accessFunctions.getSessionId,
  };
};
