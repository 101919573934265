import zappy from '@zappy-ride/library.react.components';
import merge from 'lodash.merge';
import { memo } from 'react';
import { useGetCurrentOrFirstVehicleSet } from '../../hooks/useGetCurrentOrFirstVehicleSet';

export const CurrentVehicleSetContentContainer = memo(
  ({ design, values, ...rest }: { design: string; values: AnyObject }) => {
    const currentVehicleSet = useGetCurrentOrFirstVehicleSet();
    const mergedValues = merge({}, values, currentVehicleSet);

    return (
      <zappy.ContentContainer design={design} values={mergedValues} {...rest} />
    );
  }
);

CurrentVehicleSetContentContainer.displayName =
  'CurrentVehicleSetContentContainer';
