// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useCalculationSelectors } from '../../../../selectors/calculations';

export const OutputOverview = ({ design }: { design: string }) => {
  const { schema } = zappy.useDesign(design);
  const [{ data, status }] = useCalculationSelectors('overview');

  if (status.isLoading) return 'Loading';

  if (status.missingRequiredInformation)
    return 'Missing required info for calculations';

  return (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <zappy.ContentContainer design={design} values={data} />
    </zappy.FactoryComponent>
  );
};

OutputOverview.displayName = 'OutputOverview';
