import {
  ProfileInfo,
  CalculationResultsGroup,
  ProfilePerWeekdays,
} from '@zappy-ride/library.calculations';
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import merge from 'lodash.merge';
import sum from 'lodash.sum';
import { toTitleCase } from '../../../utils/stringHelpers';
import { useTranslation } from '@zappy-ride/library.react.components/dist/abstractions/hooks/useTranslation';

const ALL = 'all';

export const electricity = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { translate } = useTranslation()

  const { selectedSetId } = zappy.useWatch({
    name: 'selectedSetId',
  }) as { [key: string]: any };

  const { 'electricityData.dayOfWeek': dayOfWeek } = zappy.useWatch({
    name: 'electricityData.dayOfWeek',
  }) as { [key: string]: any };

  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  const [annualProfile, profileStatus] = getStateValue({
    lookupKey: ['total.annual.profile', 'status.profile.isReady'],
    states: ['profile'],
  }) as [ProfileInfo, boolean];

  const [perSet, status] = getStateValue({
    lookupKey: ['perSet', 'status.profile.isReady'],
    states: ['vehicles', 'fuel'],
  }) as [Record<string, CalculationResultsGroup>, any];

  const dayOfWeeks = get(
    perSet?.[selectedSetId],
    'annual.profile.weekdays',
    []
  ) as Array<ProfilePerWeekdays>;

  const daily = dayOfWeeks.find((item: any) => item.day === dayOfWeek);

  const dailyData = daily?.dailyProfile?.map((hour: any) => {
    return zappy.formatters.formatAsNumber(hour.max, {
      locale: 'en-US',
      // @ts-ignore
      maximumFractionDigits: 2,
    });
  });

  const allDaysData = dayOfWeeks.map((day: any) => {
    const profileTotal = (day?.dailyProfile || []).reduce((acc: number, el: any) => {
      return acc + (el.average || 0)
    }, 0)
    return zappy.formatters.formatAsNumber(profileTotal, {
      locale: 'en-US',
      // @ts-ignore
      maximumFractionDigits: 2,
    }) as number | undefined;
  });

  allDaysData.push(allDaysData.shift());

  const dayOfWeeksClone: Array<Partial<ProfilePerWeekdays>> = merge(
    [],
    dayOfWeeks
  );

  dayOfWeeksClone.push(dayOfWeeksClone.shift() || {});
  const langKey = 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select'

  const weekData = dayOfWeeksClone.reduce(
    (
      acc: Array<{ label: string; value: number | null }>,
      el: Partial<ProfilePerWeekdays>
    ) => {
      const { day, dailyProfile } = el;
      if (!day || !dailyProfile) return acc;

      return acc.concat(
        dailyProfile.map(({ battery }) => ({
          label: toTitleCase(
            translate({
              id: `${langKey}.${day}`,
              fallback: day,
            })
          ),
          value: battery / 100 || null,
        }))
      );
    },
    []
  );

  const selectedDayTranslated =  toTitleCase(
    translate({
      id: `${langKey}.${dayOfWeek}`,
      fallback: dayOfWeek,
    })
  )
  const dayLabel = dayOfWeek !== ALL ? selectedDayTranslated : 'All days';

  // is loading till all status came as true
  const isLoading = !profileStatus && !status && !selectedSetId && !dayOfWeek;

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation = !(calculationsStatus && inputsStatus);

  // the local is hardcoded cause today Formatter config does not allow us to interpolate formatted values with i18n translations.
  // TODO: when enhancing the Formatter, we should refactor this code to use Formatter Component

  const maxKwh = annualProfile
    ? Math.max(...annualProfile?.months?.map((m) => m?.amount?.max))
    : 0;

  const peakKwh = zappy.formatters.formatAsNumber(maxKwh, { locale: 'en-US' });

  const estimatedKwh = zappy.formatters.formatAsNumber(
    sum(annualProfile?.months.map((m) => m?.amount?.sum)) /
      (annualProfile?.months?.length || 1),
    {
      locale: 'en-US',
      // @ts-ignore
      maximumFractionDigits: 0,
    }
  );

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      peakKwh,
      estimatedKwh,
      dailyData,
      weeklyBatteryData: weekData,
      dayLabel,
      allDays: dayOfWeek === ALL,
      allDaysData,
    },
  };
};
