// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef } from 'react';
import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';
import { filterVehicles } from '../../presenters/vehicles';
import { FormOutputVehicleEditModal } from '../FormOutputVehicleEditModal';
import { useLocation } from 'react-router-dom';
import { useVehiclePreloads } from '../../utils/vehicleSelectionHelpers';

function createFallbackGenericFilters({ getValues, fallbackFilters }) {
  return fallbackFilters.map(([type, formValuePath]) => [
    type,
    getValues(formValuePath),
  ]);
}

// TODO: merge logic with the function finding a match here: saas.fleets/src/presenters/vehicles/vehicles.ts:411
function updateOutputVehicleIfNeeded({
  setValue,
  mapping,
  vehiclesList,
  genericList,
  selectedHandle,
  getValues,
  kind,
  fallbackFilters
}) {
  const { [kind]: current } = getValues();

  const { handle: currentVehicleHandle } = current;
  const match = mapping.find(
    ({ input_handle, default: isDefault }) =>
      isDefault && input_handle === selectedHandle
  );

  const { output_archetype_handle, output_handle } = match || {};

  if (output_handle) {
    const vehicle = vehiclesList.find(({ handle }) => handle === output_handle);

    if (vehicle) {
      setValue(kind, vehicle, {
        shouldDirty: vehicle.handle !== currentVehicleHandle,
      });
      return;
    }
  }

  let vehicle = genericList.find(
    ({ handle }) => handle === (output_archetype_handle || selectedHandle)
  );

  if (!vehicle && fallbackFilters) {
    const filters = createFallbackGenericFilters({
      getValues,
      fallbackFilters,
    });
    vehicle = filterVehicles(genericList, filters)?.[0];
  }

  if (vehicle) {
    setValue(kind, vehicle, {
      shouldDirty: vehicle.handle !== currentVehicleHandle,
    });
  }
}

export const FormOutputVehicle = ({
  design,
  children,
  preloads: _preloads,
  ...props
}: {
  design: string;
}) => {
  const { schema, config: designConfig, components } = zappy.useDesign(design);
  const { setValue, getValues } = useFormContext();
  const {
    vehiclesList,
    genericList,
    mappingsList: mapping,
    areListsEmpty
  } = useVehiclePreloads(_preloads)
  const defaultVehicles = getValues('defaultVehicles');

  const config = { ...props, ...designConfig };

  const { handle: selectedHandle } = zappy.useWatch({
    name: 'handle',
  });
  const { ['modal.handle']: mh } = zappy.useWatch({
    name: 'modal.handle',
  });
  const subType = getValues('subtype');
  const currentVehicleHandle = getValues(`${config.kind === 'input' ? 'output' : 'input'}.handle`);
  const previousSubType = useRef(null);
  const previousHandle = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const selectionIsDirty = getValues('selectionIsDirty');
    if (
      selectionIsDirty &&
      selectedHandle &&
      !areListsEmpty &&
      !!defaultVehicles
    ) {

      if(!defaultVehicles.isReady) {
        setValue('defaultVehicles.isReady', true);
        return
      }

      updateOutputVehicleIfNeeded({
        setValue,
        mapping,
        vehiclesList,
        genericList,
        selectedHandle,
        getValues,
        kind: config.kind,
        fallbackFilters: config.fallbackFilters,
        type: designConfig.type
      });
    }
  }, [areListsEmpty, selectedHandle, defaultVehicles]);

  useEffect(() => {
    const selectionIsDirty = getValues('selectionIsDirty');
    if (previousSubType.current === subType) return;
    if (!previousSubType.current) {
      previousSubType.current = subType;
      return;
    }

    if (!selectionIsDirty) setValue('selectionIsDirty', true);
  }, [subType]);

  useEffect(() => {
    const selectionIsDirty = getValues('selectionIsDirty');
    if (previousHandle.current === selectedHandle) return;
    if (!previousHandle.current) {
      previousHandle.current = selectedHandle;
      return;
    }

    if (!selectionIsDirty) setValue('selectionIsDirty', true);
  }, [selectedHandle]);

  const formOutputPath =
    pathname.includes('evModel') || pathname.includes('vehicleSet')
      ? 'output'
      : 'input';

  return (
    <zappy.FactoryComponent schema={schema} {...props}>
      {children}
      {components.edit && (
        <FormOutputVehicleEditModal
          design={components.edit}
          vehiclesList={vehiclesList}
          genericList={genericList}
          output={getValues(formOutputPath)}
        />
      )}
    </zappy.FactoryComponent>
  );
};

FormOutputVehicle.displayName = 'FormOutputVehicle';
