import axios from 'axios';
import {
  ADMIN_API_KEY_NAME,
  ADMIN_API_KEY_VALUE,
  CONVERSION_API_URL,
} from '../config';

export default function currencyFormatter(
  lng: string,
  value: number | null,
  currency: string = 'USD',
  emptyIndicator = '-'
) {
  if (value === null) return emptyIndicator;

  return Intl.NumberFormat(lng, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export const fetchCurrency = async (currencyConversion: string) => {
  try {
    const {
      data: { body },
    } = await axios.get(`${CONVERSION_API_URL}/usdto${currencyConversion}`, {
      headers: {
        'Content-Type': 'application/json',
        [ADMIN_API_KEY_NAME]: ADMIN_API_KEY_VALUE,
      },
    });

    const regex = /\d+\.\d+/;
    const match = body.match(regex);

    if (match) {
      return parseFloat(match[0]);
    }

    return 1;
  } catch (error) {
    console.error('error', error);
    return 1;
  }
};
