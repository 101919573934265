// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useDynamicCalculations } from '@zappy-ride/library.calculations';
import zappy from '@zappy-ride/library.react.components';
import { DesignsKeys } from '@zappy-ride/library.react.components/dist/designs/types/Designs.types';
import { useContext } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import isEmpty from 'lodash.isempty';
import { convertToVehicleSet } from '../../presenters/calculations/calculations';
import { convertObjectArrayToHourArray } from '../../utils/dateTimeUtils';
import { AppStateContext } from '../../contexts/AppState';
import isEqual from 'lodash.isequal';

const AVAILABLE_CHARGING_WINDOW = Array.from({ length: 24 }, (_, a) => a);

function setChargerAtWindowIfNeeded({
  chargingWindows,
  setValue,
  fieldName,
  preloads,
  formData,
  recommendedCharger,
  isNotReady,
}) {
  if (isEmpty(preloads?.chargers) || isNotReady) return;

  const unsetChargerIndex = chargingWindows.findIndex(
    ({ chargerId, charger }) =>
      !chargerId || !charger || chargerId !== charger.id
  );
  if (unsetChargerIndex === -1) return;

  const { chargerId, start, finish } = chargingWindows[unsetChargerIndex];

  const baseFieldName = `${fieldName}.${unsetChargerIndex}`;
  if (!chargerId) {
    const charger = getRecommendedCharger({
      formData,
      start,
      finish,
      recommendedCharger,
    });

    if (charger) {
      setValue(`${baseFieldName}.chargerId`, charger.id);
      setValue(`${baseFieldName}.charger`, charger);
      setValue('defaultChargingWindow.chargerId', charger.id);
    }
    return;
  }

  const charger = preloads.chargers.find(
    ({ id }) => chargingWindows[unsetChargerIndex].chargerId === id
  );
  setValue(`${baseFieldName}.charger`, charger);
}

function getRecommendedCharger({
  formData,
  start,
  finish,
  recommendedCharger,
}) {
  const input = convertToVehicleSet({ ...formData, chargingWindows: [] });
  const charger = recommendedCharger?.({
    input,
    start,
    finish,
    includePersonal: formData.personalMilesPaidFor,
  });
  return charger;
}

function setMinimumStateChargePercentage({
  chargingWindows,
  getValues,
  setValue,
  minimumStateOfCharge,
  formData,
}) {
  const year = getValues('assumptionsData.year');
  const filteredChargingWindows = chargingWindows.filter(
    ({ chargerId, charger }) => chargerId && charger
  );
  if (isEmpty(filteredChargingWindows)) return;

  const input = convertToVehicleSet({
    ...formData,
    chargingWindows: filteredChargingWindows,
  });
  const newMinimunStateChargePercentage = minimumStateOfCharge({ input, year });
  const minimumStateChargePercentage = getValues(
    'minimumStateChargePercentage'
  );

  if (minimumStateChargePercentage === newMinimunStateChargePercentage) return;

  setValue('minimumStateChargePercentage', newMinimunStateChargePercentage);
}

function setErrorIfExists({
  chargingWindows,
  overlapError,
  depletedError,
  fieldName,
  setError,
  minimumStateChargePercentage,
  clearErrors,
  trigger,
  errors,
}) {
  const newWindows = convertObjectArrayToHourArray(chargingWindows, [
    'start',
    'finish',
  ]);

  const error = errors[fieldName]?.design;
  const hasOverlpingError = new Set(newWindows).size !== newWindows.length;
  const hasMinimumStateChargerError = minimumStateChargePercentage < 0;

  if (error && !hasOverlpingError && !hasMinimumStateChargerError) {
    clearErrors(fieldName);
    trigger(fieldName);
    return;
  }

  if (hasOverlpingError && error !== overlapError) {
    setError(fieldName, { design: overlapError });
    return;
  }

  if (hasMinimumStateChargerError && error !== depletedError) {
    setError(fieldName, { design: depletedError });
  }
}

// This is needed reset charing windows if vehicle has changed
function setChargingWindowInputHandleIfNeeded({
  getValues,
  resetField,
  setValue,
  fieldName,
}) {
  const chargingWindowInputHandle = getValues('chargingWindowInputHandle');
  const inputHandle = getValues('input.handle');

  if (chargingWindowInputHandle === inputHandle) return;

  resetField(fieldName);
  setValue('chargingWindowInputHandle', getValues('input.handle'));
}

function normalizeZeroValueForSelect(value) {
  if (value !== 0) return value;
  return '0';
}

export const ChargingWindow = ({ design }: { design: DesignsKeys }) => {
  const { config, components } = zappy.useDesign(design);
  const [nextAvailableWindow, setNextAvailableWindow] = useState(null);
  const [recommendedCharger, minimumStateOfCharge] = useDynamicCalculations(
    'recommendedCharger',
    'minimumStateOfCharge'
  );
  const {
    clearErrors,
    trigger,
    setError,
    formState: { errors },
    getValues,
    setValue,
    resetField,
  } = useFormContext();
  const { preloads } = useContext(zappy.PreloadsContext);
  const { appState } = useContext(AppStateContext);

  const fieldName = config.name;
  const minimumStateChargePercentage = useWatch({
    name: 'minimumStateChargePercentage',
  });

  const formData = getValues();
  const chargingWindows = useWatch({ name: fieldName });

  const { isNotReady } = appState;

  const appendNextAvailableWindow = useCallback(
    (finalAvailability) => {
      if (!finalAvailability.length) {
        setNextAvailableWindow(null);
        return;
      }

      // Prefer PM hours
      const availableWindowsPM = finalAvailability.filter((el) => el >= 12);
      const preferedWindows =
        availableWindowsPM.length > 0 ? availableWindowsPM : finalAvailability;

      if (preferedWindows.length === 1) {
        const windowStartsAt = preferedWindows[0];
        const windowEndsAt = windowStartsAt === 23 ? 0 : windowStartsAt + 1;
        const charger = getRecommendedCharger({
          formData,
          start: windowStartsAt,
          finish: windowEndsAt,
          recommendedCharger,
        });
        setNextAvailableWindow({
          start: normalizeZeroValueForSelect(windowStartsAt),
          finish: normalizeZeroValueForSelect(windowEndsAt),
          chargerId: charger?.id,
          charger,
          access: config?.defaultChargerAccess || 0,
        });
        return;
      }

      const [start, finish] = preferedWindows.slice(0, 2);
      const windowEndsAt = finish + 1;
      const charger = getRecommendedCharger({
        formData,
        start,
        finish: windowEndsAt,
        recommendedCharger,
      });
      setNextAvailableWindow({
        start: normalizeZeroValueForSelect(start),
        finish: normalizeZeroValueForSelect(finish === 23 ? 0 : windowEndsAt),
        chargerId: charger?.id,
        charger,
        access: config?.defaultChargerAccess || 0,
      });
    },
    [recommendedCharger, formData, config?.defaultChargerAccess]
  );

  const { overlapError, depletedError } = components;

  const onFieldArrayChanged = useCallback(
    (fieldsArray) => {
      if (!fieldsArray.length) return;
      // Convert the windows to an array of integers
      const rangesInUse = convertObjectArrayToHourArray(fieldsArray, [
        'start',
        'finish',
      ]);

      //deny next available window if it has less than 3 hours available given
      //the automatic window have 2 hours of interval
      // and we need 1 hour between charging window.
      const availableSlots =
        AVAILABLE_CHARGING_WINDOW.length - rangesInUse.length;
      const finalAvailability = AVAILABLE_CHARGING_WINDOW.filter(
        (a) => !rangesInUse.includes(a) && availableSlots > 2
      );

      appendNextAvailableWindow(finalAvailability);
    },
    [appendNextAvailableWindow]
  );

  useEffect(() => {
    setErrorIfExists({
      chargingWindows,
      overlapError,
      depletedError,
      fieldName,
      setError,
      minimumStateChargePercentage,
      clearErrors,
      trigger,
      errors,
    });
  }, [
    chargingWindows,
    errors,
    fieldName,
    setError,
    overlapError,
    depletedError,
    minimumStateChargePercentage,
    clearErrors,
    trigger,
  ]);

  useEffect(() => {
    setChargerAtWindowIfNeeded({
      chargingWindows,
      setValue,
      fieldName,
      preloads,
      recommendedCharger,
      formData,
      isNotReady,
    });
  }, [
    fieldName,
    chargingWindows,
    setValue,
    preloads,
    formData,
    recommendedCharger,
    isNotReady,
  ]);

  useEffect(() => {
    setChargingWindowInputHandleIfNeeded({
      getValues,
      resetField,
      setValue,
      fieldName,
    });
  }, [getValues, setValue, resetField, fieldName]);

  useEffect(() => {
    if (minimumStateOfCharge) {
      setMinimumStateChargePercentage({
        chargingWindows,
        minimumStateOfCharge,
        getValues,
        setValue,
        formData,
      });
    }
  }, [chargingWindows, setValue, formData, getValues, minimumStateOfCharge]);

  useEffect(() => {
    const normalizedValue = chargingWindows.map((c) => ({
      ...c,
      start: normalizeZeroValueForSelect(c.start),
      finish: normalizeZeroValueForSelect(c.finish),
    }));

    if (!isEqual(normalizedValue, chargingWindows)) {
      setValue(fieldName, normalizedValue);
    }

    onFieldArrayChanged(chargingWindows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingWindows]);

  return (
    <zappy.FormFieldArray
      design={design}
      disabled={!nextAvailableWindow}
      defaultAddValues={nextAvailableWindow}
    />
  );
};

ChargingWindow.displayName = 'ChargingWindow';
