import {
  useCalculationSelector,
  CalculationSelectors,
} from '@zappy-ride/library.calculations';
import { charger, chargerTable, chargerAssignment } from './selectors/chargers';
import { operation, maintenanceCosts } from './selectors/operations';
import { fuelCosts, fuelCostsTable, ratesTable } from './selectors/fuelCosts';
import { summary } from './selectors/summary';
import { overview, overviewTable } from './selectors/overview';
import { electricity } from './selectors/electricity';
import { vehicleComparisonTable } from './selectors/vehicles';
import { emissionsTable } from './selectors/emissions';
import { lcfsCosts } from './selectors/lcfs';
import { financials } from './selectors/financials';

//TODO: object keys and path need to come from configuration file?
//TODO: need to be able to inject default values from configuration like preload?
const selectors: CalculationSelectors = {
  summary,
  overview,
  overviewTable,
  fuelCosts,
  fuelCostsTable,
  electricity,
  charger,
  chargerTable,
  chargerAssignment,
  vehicleComparisonTable,
  operation,
  maintenanceCosts,
  ratesTable,
  emissionsTable,
  lcfsCosts,
  financials,
};
// @ts-ignore
export const useCalculationSelectors = (
  ...selectorKeys: Array<keyof CalculationSelectors>
) => {
  const getStateValue = useCalculationSelector();

  return selectorKeys.map((selector) => selectors[selector](getStateValue));
};
