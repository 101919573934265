// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';

import {
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { prepareFilters } from '../../utils/functionHelpers';
import { useClientSidePagination } from '../../hooks/useClientSidePagination';
import { Pagination } from '../Pagination';
import { NAVIGATION_REDIRECTION_PROP } from '../../contexts/Interceptor/actions';
import { CatalogEmptyState } from './CatalogEmptyState';
import { CatalogLoadingState } from './CatalogLoadingState';
import { CatalogItems } from './CatalogItems';
import isEqual from "lodash.isequal";

export interface GenericCatalogProps {
  design: string;
  catalogName: string;
  dataPreparingFn?: (values: Array<unknown>) => Array<unknown>;
  dataHidingFn?: (values: Array<unknown>) => Array<unknown>;
  defaultFiltersFn?: (values: AnyObject) => void;
  resetFunction?: (values: AnyObject) => void;
  deferredLoading?: boolean;
}

const FILTERS_ROOT_PATH = 'catalogs';


export const GenericCatalog = memo(
  ({
    design,
    catalogName,
    defaultFiltersFn,
    dataPreparingFn,
    dataHidingFn,
    resetFunction,
    deferredLoading = false,
  }: GenericCatalogProps) => {
    const { getValues } = useFormContext();
    const { schema, config, components } = zappy.useDesign(design);
    const {
      keyAttribute,
      preload,
      limit: perPage,
      filters: configFilters,
      redirectToIfNoHistory,
      skipDefaultFilters,
    } = config;
    const listItem = zappy.useDesign(components.listItem);
    const [showLoadingState, setShowLoadingState] = useState(true);
    const [initialFiltersApplied, setInitialFiltersApplied] = useState(false);

    const navigate = useNavigate();
    const { setValue } = useFormContext();

    // Preload Control
    const { preloads } = useContext(zappy.PreloadsContext);
    const elementsList = useMemo(() => {
      return get(preloads, preload, []);
    }, [preloads]);

    //Watched Values control
    const watchedValues = useWatch({ name: FILTERS_ROOT_PATH });
    const deferredWatchedValues = useDeferredValue(watchedValues);

    // Filters Control
    const filters = useMemo(() => {
      return prepareFilters(deferredWatchedValues, configFilters);
    }, [deferredWatchedValues]);
    const deferredFilters = useDeferredValue(filters);

    //Pagination Control
    const paginationData = useClientSidePagination({
      filters: deferredFilters,
      sort: deferredWatchedValues[catalogName].sort,
      elements: elementsList,
      perPage,
      dataPreparingFn,
      dataHidingFn,
    });

    const { paginatedElements, totalElements, pagination } = paginationData;
    const { currentPage, totalPages, onPreviousPage, onNextPage } = pagination;
    const deferredPaginatedElements = useDeferredValue(paginatedElements);

    // Empty state is shown when there are no elements to show in the deferred list
    const showEmptyState =
      !showLoadingState &&
      deferredPaginatedElements?.length === 0;

    const showPagination = totalPages > 1 && !showEmptyState && !showLoadingState;

    const resetFilters = useCallback(async () => {
      setShowLoadingState(true);
      setInitialFiltersApplied(false);
      await resetFunction();
    }, [resetFunction]);


    // Update the form with the total elements
    const updateForm = useCallback(() => {
      setValue(`${FILTERS_ROOT_PATH}.filteredListSize`, totalElements);
      setValue(`${FILTERS_ROOT_PATH}.allListSize`, elementsList.length);

      if (!!elementsList && !skipDefaultFilters) {
        defaultFiltersFn?.();
        if (deferredLoading) {
          setTimeout(() => {
            setInitialFiltersApplied(true);
          }, deferredLoading);
        } else {
          setInitialFiltersApplied(true);
        }
      }
      // if preloads are loaded, hide loading state
      if (preloads?.[preload] && initialFiltersApplied) {
        setShowLoadingState(false);
      }
    }, [totalElements, elementsList, initialFiltersApplied, preload]);

    useEffect(() => {
      // We can't allow the user to land directly to this page.
      const returnIsSet = getValues(NAVIGATION_REDIRECTION_PROP);
      if (!returnIsSet) navigate(redirectToIfNoHistory);
      updateForm();
    }, [updateForm]);

    return (
      <>
        {/* Empty State */}
        <CatalogEmptyState
          design={config.emptyState}
          elements={paginatedElements}
          resetFiltersFn={resetFilters}
          shouldBeVisible={showEmptyState}
        />

        <zappy.FactoryComponent
          design={design}
          data-design={design}
          schema={schema}
        >
          {/* Loading State */}
          <CatalogLoadingState
            listItem={listItem}
            limit={perPage}
            shouldBeVisible={showLoadingState}
          />

          {/* Catalog Items */}
          <CatalogItems
            elements={deferredPaginatedElements}
            listItem={listItem}
            keyAttribute={keyAttribute}
            shouldBeVisible={!showEmptyState && !showLoadingState}
          />
        </zappy.FactoryComponent>

        {/* Pagination */}
        <Pagination
          design={components.pagination}
          onNext={onNextPage}
          onPrevious={onPreviousPage}
          page={currentPage}
          total={totalPages}
          shouldBeVisible={showPagination}
        />
      </>
    );
  }, (prevProps, nextProps) => {
    return  isEqual(prevProps, nextProps);
  }
);


GenericCatalog.displayName = 'GenericCatalog';
