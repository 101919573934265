import zappy from '@zappy-ride/library.react.components';

import {
  ProjectOrganizationType,
  FilterProjectOrganizationType,
} from './ProjectOrganizationType';
export * from './ProjectOrganizationType';

zappy.FactoryInstance.registerComponent(ProjectOrganizationType);

zappy.FactoryInstance.registerComponent(FilterProjectOrganizationType);
