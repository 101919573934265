// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';

import { getAssumptionsFromLocations } from './location';

export * from './location';

zappy.PreloadsProvider.registerPresenter(
  'location.presenter',
  getAssumptionsFromLocations
);
