// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext, useState } from 'react';
import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { InterceptorContext } from '../../../../contexts/Interceptor';
import { UPDATE_CALCULATIONS_INPUTS } from '../../../../contexts/Interceptor/actions';
import { useAnalyticsTracker } from '../../../../contexts/Analytics/AnalyticsTracker';

export const CompareRatesButton = ({ design, values }: { design: string }) => {
  const {
    schema,
    components,
    config: { content, as, action },
  } = zappy.useDesign(design);
  const [isOpen, setIsOpen] = useState(false);
  const { setValue, getValues } = useFormContext();
  const callAction = useContext(InterceptorContext);
  const [sendAnalyticsEvent] = useAnalyticsTracker();

  const actions = {
    onApply:() => {
      const selectedRateId = getValues('rateSelection.selectedRateId');
      setValue('assumptionsData.selectedRateId', selectedRateId);

      const electricRatesInfo = getValues('assumptionsData.rates');
      setValue(
        'assumptionsData.selectedRateName',
        get(electricRatesInfo, `${selectedRateId}.longName`)
      );

      const customRatePrice = getValues('rateSelection.customRatePrice');
      setValue('assumptionsData.customRatePrice', customRatePrice);

      setValue(
        'assumptionsData.isExternalRateEngine',
        !['simple'].includes(selectedRateId)
      );

      callAction({
        type: UPDATE_CALCULATIONS_INPUTS,
      });
    },
    onClose: () => {
      const selectedRateId = getValues('assumptionsData.selectedRateId');
      setValue('rateSelection.selectedRateId', selectedRateId);

      const customRatePrice = getValues('assumptionsData.customRatePrice');
      setValue('rateSelection.customRatePrice', customRatePrice);

      setIsOpen(false);
    },
    onClickCompare: () => {
      sendAnalyticsEvent({
        category: 'Compare Rates',
        action: 'Clicked on Compare Rates',
        label: 'Compare Rates',
      });
      setIsOpen(true);
    },
  };

  return (
    <>
      {isOpen && (
        <zappy.Modal
          design={components.modal}
          actions={actions}
          values={values || {}}
        />
      )}
      <zappy.FactoryComponent schema={schema} as={as} onClick={actions[action]}>
        {content.map(([componentName, componentDesign, componentConfig]) => (
          <zappy.FactoryComponent
            key={componentDesign}
            component={componentName}
            design={componentDesign}
            onClick={actions[componentConfig?.action]}
          />
        ))}
      </zappy.FactoryComponent>
    </>
  );
};

CompareRatesButton.displayName = 'CompareRatesButton';
