import get from 'lodash.get';
import mergeWith from 'lodash.mergewith';
import set from 'lodash.set';
import { ADMIN_API_KEY_NAME, ADMIN_API_KEY_VALUE } from '../config';
import base from '../data/default/base';

function importAll(r: { [key: string]: any }) {
  let images = {};
  r.keys().forEach((item: string) => {
    // @ts-ignore
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

function mergeAll(files: any) {
  let merged = {};
  Object.keys(files).forEach((key) => {
    Object.assign(merged, files[key]?.default);
  });
  return merged;
} 

const js = importAll(
  // @ts-ignore
  require.context('../data/default/designs', true, /\.(js)$/)
);

// a function that replace the values {{api.url}} and {{api.awsUrl}} in a json file
// with the values from api.url and api.awsUrl of the same json file
export const replaceUrls = (config: any) => {
  const api = config.api;
  const replace = (str: string) => {
    return str.replace('{{api.url}}', api.url).replace('{{api.awsUrl}}', api.awsUrl);
  };
  const replaceInObject = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'string') {
        obj[key] = replace(obj[key]);
      } else if (typeof obj[key] === 'object') {
        if(obj[key]){
          replaceInObject(obj[key]);
        }
      }
    });
  };
  replaceInObject(config);
  return config;
};

export const unbrandedLocalDesings = replaceUrls({
  ...base,
  designs: mergeAll(js),
});

export function fetchClientConfig(url: string) {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      [ADMIN_API_KEY_NAME]: ADMIN_API_KEY_VALUE,
    },
  }).then(
    (res) => {
      if (!res.ok) {
        throw new Error(`There is no such config file ${url}`);
      }
      return res.json();
    },
    (e) => {
      throw new Error(e);
    }
  ).then((res) => {

    if (res.configs?.mergeConfig === true){
      return handleFeatureFlags(replaceUrls(mergeCustomAndDefaultsConfig(res, unbrandedLocalDesings)));
    }

    return handleFeatureFlags(replaceUrls(res));
  });
}

// a function to merge the custom config with the default config using lodash merge function
// with a custom merge function that verify if the custom object has a property override === true, replace the default value with the custom
// if not, just merge the custom and default
export const mergeCustomAndDefaultsConfig = (custom: any, defaults: any) => {
  return mergeWith(defaults, custom, (objValue: any, srcValue: any) => {
    if (srcValue?.override || Array.isArray(srcValue)) {
      return srcValue;
    }
  });
}

export const handleFeatureFlags = (config: any) => {

  const tabs = get(config.designs, 'app.output.template.routes.layout.header.tabs', {}).config?.options;

  if(!tabs) return config;

  tabs.forEach((tab: any) => {
    tab.id = tab.label.replace(/\ /g, '').toLowerCase()
  });
  
  const hasTab = (tabLabel: string) => {
    return tabs.find((t: any) => t.id.toLowerCase() === tabLabel.toLowerCase())
  }

  if(!hasTab('financing')){
    set(config.form.defaultValues, 'finance', null)
    set(config.form.defaultValues.constants, 'finance', null)
  }
  const lcfsTab = hasTab('lcfs')

  if(lcfsTab && !lcfsTab.displayQuery){
      lcfsTab.displayQuery = {
        $and: [
          {
            '["watched", "zipcode"]': { $gte: 90001 },
          },
          { '["watched", "zipcode"]': { $lte: 96162 } },
        ],
      }
  }

  set(config.form.defaultValues, 'tabs', tabs);
  
  return config;
}


  
