// @ts-nocheck

import zappy from '@zappy-ride/library.react.components';

interface CatalogItemsProps {
  elements?: Array<any>;
  listItem: any;
  shouldBeVisible?: boolean;
  keyAttribute?: string;
}

export const CatalogItems = ({
  elements = [],
  listItem,
  shouldBeVisible = false,
  keyAttribute
}: CatalogItemsProps) => {
  return !shouldBeVisible
    ? null
    : elements.map((el) => {
        const key = el.globalDataPerTable
          ? el.globalDataPerTable[keyAttribute]
          : el[keyAttribute];

        return (
          <zappy.FactoryComponent key={key} schema={listItem.schema}>
            <zappy.FactoryContent
              config={listItem.config}
              values={el.data || el}
              globalData={el.globalDataPerTable}
            />
          </zappy.FactoryComponent>
        );
      });
};

CatalogItems.displayName = 'CatalogItems';
