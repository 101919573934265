import { types as CalculationsTypes } from '@zappy-ride/library.calculations';
import merge from 'lodash.merge';
import { ArchetypeCharger, UnifiedCharger } from '../../types/chargers.types';

export function convertVehicleSetCharger(
  charger: ArchetypeCharger
): CalculationsTypes.Charger {
  const {
    charger_id: id,
    kw: portKw,
    type: chargerType,
    image_url: imageUrl,
  } = charger;
  return {
    id,
    estimatedRebate: undefined,
    portKw,
    chargerType,
    imageUrl,
    ...charger,
  };
}

export function prepareUnifiedCharger(
  charger: UnifiedCharger
): CalculationsTypes.Charger {
  const {
    charger_id: id,
    kw_ac,
    kw_dc,
    current: chargerType,
    img: imageUrl,
    num_of_ports: ports,
    connector_types: plug_types,
    cellular_support
  } = charger;

  return {
    ...charger,
    id,
    portKw: kw_ac || kw_dc,
    chargerType,
    imageUrl,
    ports,
    estimatedRebate: undefined,
    ...{ cellular_support: cellular_support || [],  plug_types},
  };
}

export function prepareChargers(
  chargers: Array<UnifiedCharger | ArchetypeCharger>
) {
  return chargers.map((chargerDetails) =>
    merge(
      {},
      // TODO: Remove back compatibility as soon as clients are deployed
      convertVehicleSetCharger(chargerDetails as ArchetypeCharger),
      prepareUnifiedCharger(chargerDetails as UnifiedCharger)
    )
  );
}
