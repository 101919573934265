import zappy from '@zappy-ride/library.react.components';
import { useWatch } from 'react-hook-form';

const FormVehicleSets = ({ design }: any) => {
  const vehicleSets = useWatch({ name: 'vehicleSets' });

  if (!vehicleSets || !vehicleSets.length) return null;

  return (
    <>
      {vehicleSets.map((vehicleSet: any, index: number) => (
        <zappy.ContentContainer
          key={vehicleSet.id}
          values={{ ...vehicleSet, editingVehicleSetIndex: index }}
          design={design}
        />
      ))}
    </>
  );
};

FormVehicleSets.displayName = 'FormVehicleSets';

export default FormVehicleSets;
