// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export const useUrl = () => {
  const { setValue } = useFormContext();
  const {pathname} = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const url = pathname.split('/');
    setValue('url', url);
  }, [pathname]);
};
