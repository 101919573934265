import zappy from '@zappy-ride/library.react.components';
import { ChargersCatalog } from './ChargersCatalog';
import { CurrentVehicleSetContentContainer } from './CurrentVehicleSetContentContainer';
import { VehiclesCatalog } from './VehiclesCatalog';

export * from './ChargersCatalog';
export * from './VehiclesCatalog';

zappy.FactoryInstance.registerComponent(VehiclesCatalog);
zappy.FactoryInstance.registerComponent(ChargersCatalog);
zappy.FactoryInstance.registerComponent(CurrentVehicleSetContentContainer);
