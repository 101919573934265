// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useContext, createContext, useMemo, useRef } from 'react';
import zappy from '@zappy-ride/library.react.components';
import {
  useCalculations,
  useCalculationsInput,
} from '@zappy-ride/library.calculations';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash.isempty';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AppStateContext } from '../AppState';
import { useAnalyticsTracker } from '../Analytics/AnalyticsTracker';

function preSetActionsHandlers(
  {
    getValues,
    setValue,
    reset,
    preloads,
    calculations,
    setCalculationsInput,
    navigate,
    location,
    defaultValues,
    formDefaultValues,
    isDirty,
    getLocalStorage,
    saveToLocalStorage,
    removeFromLocalStorage,
    setLoadingKey,
    changeAppStatus,
    changeResourceState,
    appState,
    sendAnalyticsEvent,
    clearErrors,
    translate
  },
  actionsHandlers
) {
  return (props) => {
    const { type, payload, watchedValues, ...rest } = props || {};
    return actionsHandlers[type]?.({
      payload,
      watchedValues,
      getValues,
      setValue,
      preloads,
      calculations,
      setCalculationsInput,
      navigate,
      location,
      reset,
      defaultValues,
      formDefaultValues,
      isDirty,
      getLocalStorage,
      saveToLocalStorage,
      removeFromLocalStorage,
      setLoadingKey,
      changeAppStatus,
      changeResourceState,
      appState,
      sendAnalyticsEvent,
      clearErrors,
      translate,
      ...rest,
    });
  };
}

function isFieldWatchedChanged(watch, changedFieldName = '') {
  return !!watch?.name?.find(
    (watchedFieldName) =>
      changedFieldName === watchedFieldName ||
      changedFieldName.includes(watchedFieldName)
  );
}

function callActionsIfNeed({
  watchedValues,
  actionsList,
  callAction,
  changedFieldName,
}) {
  actionsList.forEach(({ watch, type, payload }) => {
    if (
      (!changedFieldName && !isEmpty(watch)) ||
      isFieldWatchedChanged(watch, changedFieldName)
    ) {
      callAction({
        type,
        payload,
        watchedValues,
      });
    }
  });
}

export const InterceptorContext = createContext({});

export const InterceptorProvider = ({ children, actions, actionsHandlers, formDefaultValues }) => {
  const {
    clearErrors,
    setValue,
    getValues,
    watch,
    reset,
    formState: { defaultValues, isDirty },
  } = useFormContext();
  const { preloads } = useContext(zappy.PreloadsContext);
  const { changeAppStatus, changeResourceState, appState } =
    useContext(AppStateContext);

  const calculations = useCalculations();
  const setCalculationsInput = useCalculationsInput();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    getLocalStorage,
    saveToLocalStorage,
    removeFromLocalStorage,
    setLoadingKey,
  } = useLocalStorage('partialFormState');
  const {translate} = zappy.useTranslation();
  const [sendAnalyticsEvent] = useAnalyticsTracker();

  const shouldSetWatchValues = useRef(true);

  const actionsHandlersProps = useMemo(
    () => ({
      getValues,
      setValue,
      preloads,
      calculations,
      setCalculationsInput,
      navigate,
      location,
      reset,
      defaultValues,
      formDefaultValues,
      isDirty,
      getLocalStorage,
      saveToLocalStorage,
      removeFromLocalStorage,
      setLoadingKey,
      changeAppStatus,
      changeResourceState,
      appState,
      sendAnalyticsEvent,
      clearErrors,
      translate
    }),
    [
      getValues,
      setValue,
      preloads,
      calculations,
      setCalculationsInput,
      navigate,
      location,
      reset,
      isDirty,
      getLocalStorage,
      saveToLocalStorage,
      removeFromLocalStorage,
      setLoadingKey,
      changeAppStatus,
      changeResourceState,
      appState,
      sendAnalyticsEvent,
      clearErrors,
      translate
    ]
  );

  const callAction = useMemo(
    () => preSetActionsHandlers(actionsHandlersProps, actionsHandlers),
    [actionsHandlersProps, actionsHandlers]
  );

  const actionsList = useMemo(() => Object.values(actions || {}), [actions]);

  useEffect(() => {
    if (!isEmpty(preloads) && !isEmpty(calculations)) {
      const subscription = watch(
        (watchedValues, { name: changedFieldName }) => {
          callActionsIfNeed({
            actionsList,
            watchedValues,
            changedFieldName,
            callAction,
          });
        }
      );
      return () => subscription.unsubscribe();
    }
  }, [watch, preloads, calculations, actionsList]);

  useEffect(() => {
    if (
      shouldSetWatchValues.current &&
      !isEmpty(preloads) &&
      !isEmpty(calculations)
    ) {
      callActionsIfNeed({
        actionsList,
        watchedValues: getValues(),
        callAction,
      });
      shouldSetWatchValues.current = false;
    }
  }, [getValues, preloads, calculations, actionsList]);

  return (
    <InterceptorContext.Provider value={callAction}>
      {children}
    </InterceptorContext.Provider>
  );
};

InterceptorProvider.displayName = 'InterceptorProvider';
