import { DESKTOP_THRESHOLD } from "../../base";

const ELECTRICITY = {
  'app.output.template.routes.electricity.template': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.electricity.head',
          {
            displayQuery: 'mobileOrPrinting',
            className: 'pageHeader'
          },
        ],
        [
          'IconText',
          'app.output.template.routes.electricity.template.peakInfo',
        ],
        [
          'FormAlert',
          'app.output.template.routes.electricity.template.profileExceeds',
        ],
        [
          'Collapsible',
          'app.output.template.routes.electricity.template.collapsible.electricity',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
    },
  },
  'app.output.template.mobile.electricity.head': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.electricity.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.mobile.electricity.head.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.electricity.head.title',
        fallback: 'Electricity',
      },
    },
  },
  'app.output.template.routes.electricity.template.peakInfo': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.lightningFilled',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.electricity.template.peakInfo',
          dangerouslySetInnerHTML: true,
          fallback:
            'Your fleet will draw an estimated peak of <strong>{{peakKwh}}</strong> kW and use an estimated <strong>{{estimatedKwh}}</strong> kWh of electricity per month.',
        },
      },
    },
    schema: {
      default: {
        'margin-bottom': '1.5rem',
        marginTop: '32px',
      },
    },
  },
  'app.output.template.routes.electricity.template.profileExceeds': {
    _extends: 'app.common.iconText.exclamationMarkFilled',
    config: {
      icon: {
        color: 'var(--color-error)',
      },
      watch: {
        name: ['warnings'],
      },
      validate:
        '(function ({ warnings, peakKwh }) {return peakKwh < warnings?.electricity?.maxKwh })',
      values: {
        url: '',
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.electricity.template.profileExceeds',
          dangerouslySetInnerHTML: true,
          fallback:
            'Given all vehicle sets modeled for this site, the load profile exceeds <strong>{{warnings.electricity.maxKwh}} kW</strong>, a typical capacity constraint. <a href="{{url}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">Contact</a> your utility’s advisory services so your utility infrastructure can be upgraded to meet your needs.',
        },
      },
    },
    schema: {
      default: {
        'background-color': 'var(--error-background-color)',
        alignItems: 'center',
        'grid-column': '1/6',
        'margin-bottom': '1.5rem',
      },
    },
  },
  'app.output.template.routes.electricity.template.collapsible.electricity': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    components: {
      headerText:
        'app.output.template.routes.electricity.template.collapsible.electricity.text',
      content:
        'app.output.template.routes.electricity.template.collapsible.electricity.content',
    },
    schema: {
      default: {
        'padding-left': '10px',
      },
    },
  },
  'app.output.template.routes.electricity.template.collapsible.electricity.text':
    {
      _extends: 'app.output.template.routes.tabs.collapsible.text',
      config: {
        i18n: {
          id: 'i18n.output.electricity.headerText',
          fallback: 'Charging Equipment Loads',
        },
      },
      schema: {
        default: {
          'padding-left': '0px',
        },
      },
    },
  'app.output.template.routes.electricity.template.collapsible.electricity.content':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper',
          ],
        ],
      },
    },
  'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.selectors',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.electricity.template.siteLoadProfile.title',
          ],
          [
            'TranslatedText',
            'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.text',
          ],
          [
            'Chart',
            'app.output.template.routes.electricity.template.siteLoadProfile',
            {
              displayQuery: 'noAllDays',
            },
          ],
          [
            'Chart',
            'app.output.template.routes.electricity.template.siteLoadProfile.allDays',
            {
              displayQuery: 'allDays',
            },
          ],
          [
            'TranslatedText',
            'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.note',
          ],
          ['Chart', 'app.output.template.routes.electricity.template.battery'],
        ],
        displayQueries: {
          allDays: { '["props", "allDays"]': { $eq: true } },
          noAllDays: {
            '["props", "allDays"]': { $neq: true },
          },
        },
      },
      schema: {
        default: {
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',

          '@media screen and (max-width: 992px)': {
            gap: '32px',
          },
        },
      },
    },
  'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.selectors':
    {
      config: {
        content: [
          [
            'FormField',
            'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek',
          ],
          ['ContentContainer', 'app.output.select.vehicleSetSelector'],
        ],
      },
      schema: {
        default: {
          display: 'grid',
          'grid-template-columns': 'repeat(2, auto)',
          'justify-content': 'flex-start',
          'grid-column-gap': '12px',
        },
      },
    },
  'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek':
    {
      _extends: [
        'app.output.common.select.field',
        'app.output.print.common.notPrintable',
      ],
      config: {
        label: {
          htmlFor: 'electricityData.dayOfWeek',
          i18n: {
            id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.label',
            fallback: 'Day of the Week',
          },
        },
        input: {
          name: 'electricityData.dayOfWeek',
          options: {
            all: {
              value: 'all',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.all',
                fallback: 'All',
              },
            },
            sunday: {
              value: 'sunday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.sunday',
                fallback: 'Sunday',
              },
            },
            monday: {
              value: 'monday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.monday',
                fallback: 'Monday',
              },
            },
            tuesday: {
              value: 'tuesday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.tuesday',
                fallback: 'Tuesday',
              },
            },
            wednesday: {
              value: 'wednesday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.wednesday',
                fallback: 'Wednesday',
              },
            },
            thursday: {
              value: 'thursday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.thursday',
                fallback: 'Thursday',
              },
            },
            friday: {
              value: 'friday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.friday',
                fallback: 'Friday',
              },
            },
            saturday: {
              value: 'saturday',
              i18n: {
                id: 'i18n.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.dayOfWeek.select.saturday',
                fallback: 'Saturday',
              },
            },
          },
        },
      },
      schema: {
        default: {
          minWidth: 150,
        },
      },
    },
  'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.text':
    {
      _extends: 'app.output.print.common.notPrintable',
      config: {
        as: 'p',
        fontSize: 'var(--font-size)',
        i18n: {
          id: 'i18n.p.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.text',
          fallback:
            'The charging schedule from your vehicle set(s) increases electricity loads on your site. Calculations assume your electrified fleet will be separately-metered and exclude building loads.',
        },
      },
    },
  'app.output.template.routes.electricity.template.siteLoadProfile': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible',
    ],
    config: {
      'aria-labelledby':
        'app.output.template.routes.electricity.template.siteLoadProfile.title',
      type: 'bar',
      maintainAspectRatio: true,
      scaleOptions: {
        y: {
          suggestedMin: 0,
          formatOpts: {
            formatter: 'interpolate',
            text: '{{picked}} kWh',
          },
        },
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 12,
          },
        },
      },
      legend: {
        display: false,
      },
      labels: [
        { id: '12am', fallback: '12am' },
        { id: '1am', fallback: '1am' },
        { id: '2am', fallback: '2am' },
        { id: '3am', fallback: '3am' },
        { id: '4am', fallback: '4am' },
        { id: '5am', fallback: '5am' },
        { id: '6am', fallback: '6am' },
        { id: '7am', fallback: '7am' },
        { id: '8am', fallback: '8am' },
        { id: '9am', fallback: '9am' },
        { id: '10am', fallback: '10am' },
        { id: '11am', fallback: '11am' },
        { id: '12pm', fallback: '12pm' },
        { id: '1pm', fallback: '1pm' },
        { id: '2pm', fallback: '2pm' },
        { id: '3pm', fallback: '3pm' },
        { id: '4pm', fallback: '4pm' },
        { id: '5pm', fallback: '5pm' },
        { id: '6pm', fallback: '6pm' },
        { id: '7pm', fallback: '7pm' },
        { id: '8pm', fallback: '8pm' },
        { id: '9pm', fallback: '9pm' },
        { id: '10pm', fallback: '10pm' },
        { id: '11pm', fallback: '11pm' },
      ],
      pick: [
        {
          source: 'dailyData',
          label: {
            id: 'i18n.barChartWithValuesProps.label',
            fallback: 'Electric Vehicles',
          },
          backgroundColor: 'var(--chart-color-3)',
        },
      ],
    },
  },
  'app.output.template.routes.electricity.template.siteLoadProfile.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      id: 'app.output.template.routes.electricity.template.siteLoadProfile.title',
      i18n: {
        dangerouslySetInnerHTML: true,
        id: 'i18n.output.template.routes.electricity.template.siteLoadProfile.title',
        fallback: 'Site Load Profile (KWh) {{dayLabel}}',
      },
    },
  },
  'app.output.electricity.chart.responsive': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible',
    ],
    schema: {
      default: {
        height: '35vh',

        '@media (max-width: 992px)': {
          marginTop: '0px',
          height: '35vh',
        },
      },
    },
  },
  'app.output.template.routes.electricity.template.siteLoadProfile.allDays': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible'
    ],
    config: {
      maintainAspectRatio: true,
      type: 'bar',
      scaleOptions: {
        y: {
          suggestedMin: 0,
          formatOpts: {
            formatter: 'interpolate',
            text: '{{picked}} kWh',
          },
        },
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 12,
          },
        },
      },
      legend: {
        display: false,
      },
      labels: [
        { id: 'monday', fallback: 'Monday' },
        { id: 'tuesday', fallback: 'Tuesday' },
        { id: 'wednesday', fallback: 'Wednesday' },
        { id: 'thursday', fallback: 'Thursday' },
        { id: 'friday', fallback: 'Friday' },
        { id: 'saturday', fallback: 'Saturday' },
        { id: 'sunday', fallback: 'Sunday' },
      ],
      pick: [
        {
          source: 'allDaysData',
          label: {
            id: 'i18n.barChartWithValuesProps.label',
            fallback: 'Electric Vehicles',
          },
          backgroundColor: 'var(--chart-color-3)',
          barPercentage: 0.5,
        },
      ],
    },
  },
  'app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.note':
    {
      config: {
        as: 'p',
        fontSize: 'var(--font-size)',
        i18n: {
          id: 'i18n.app.output.template.routes.electricity.template.collapsible.electricity.content.wrapper.note',
          fallback:
            'Note that the peak usage occurs on Tuesday, Wednesday, Friday, and Sunday.',
        },
      },
    },
  'app.output.template.routes.electricity.template.battery': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible',
    ],
    config: {
      type: 'line',
      maintainAspectRatio: true,
      scaleOptions: {
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
          },
        },
        y: {
          min: 0,
          max: 1,
          ticks: {
            format: {
              style: 'percent',
            },
          },
        },
      },
      legend: {
        display: false,
      },
      sourceParent: 'weeklyBatteryData',
      labelsAttr: 'label',
      pick: [
        {
          pointRadius: 0,
          source: 'value',
          label: {
            id: 'i18n.barChartWithValuesProps.label',
            fallback: 'Electric Vehicles',
          },
          fill: true,
          backgroundColor: 'var(--chart-color-3)',
        },
      ],
    },
    components: {
      title: 'app.output.template.routes.electricity.template.battery.title',
    },
    schema: {
      default: {
        marginBottom: '32px',
      },
    },
  },
  'app.output.template.routes.electricity.template.battery.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.electricity.template.battery.title',
        fallback: 'Weekly Battery Schedule',
      },
    },
  },
};

export default ELECTRICITY;
