// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const InputWithToggleCheckbox = ({ design }: { design: string }) => {
  const {
    setValue,
    watch,
    getValues,
    formState: { defaultValues },
  } = useFormContext();
  const { components, schema } = zappy.useDesign(design);
  const { config: inputConfig } = zappy.useDesign(components.input);
  const { config: checkboxConfig, components: checkboxComponents } =
    zappy.useDesign(components.checkbox);
  const { name, defaultValueName } = inputConfig?.input;
  const { name: checkboxName, disablesInputWhenChecked = true } =
    checkboxConfig;
  const checkboxValue = watch(checkboxName, false);
  const isReadOnly = disablesInputWhenChecked ? checkboxValue : !checkboxValue;
  const defaultValue =
    get(defaultValues, defaultValueName || name) || getValues(defaultValueName);

  const handleCheckboxChanged = useCallback(
    ({ currentTarget: { checked } }) => {
      if (disablesInputWhenChecked && !checked) return;

      setValue(name, defaultValue, { shouldValidate: true });
    },
    [name, setValue]
  );
  // Fixing weird bug that submits the form while focusing on the input and pressing enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <zappy.FactoryComponent
      data-design={design}
      schema={schema}
      onKeyPress={handleKeyPress}
    >
      <zappy.FormField readOnly={isReadOnly} design={components.input} />
      <zappy.FactoryComponent design={components.checkbox}>
        <zappy.FormInput
          design={checkboxComponents.checkbox}
          type="checkbox"
          name={checkboxName}
          onChange={handleCheckboxChanged}
        />
        <zappy.ContentContainer design={checkboxComponents.label} />
      </zappy.FactoryComponent>
    </zappy.FactoryComponent>
  );
};

InputWithToggleCheckbox.displayName = 'InputWithToggleCheckbox';
