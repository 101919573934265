// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useLocation } from "react-router-dom";

export const FormStatsVehicle = ({ design, ...props }: { design: string }) => {
  const {
    config: { componentByPathname, kindByPathname, preloadsByKind, type },
  } = zappy.useDesign(design);

  const { pathname } = useLocation();

  const component = componentByPathname[pathname];
  const kind = kindByPathname[pathname];
  const preloads = preloadsByKind[kind];
  const elementKind = `${type}_${kind}`;

  return (
    <zappy.ContentContainer
      design={design}
      component={component}
      kind={kind}
      preloads={preloads}
      elementKind={elementKind}
      {...props}
      key={elementKind}
    />
  )
};

FormStatsVehicle.displayName = "FormStatsVehicle";
