// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useContext, useEffect } from "react";

export const SmartPreloader = ({ design }: { design: string }) => {
  const { addPreloads } = useContext(zappy.PreloadsContext);

  const { config } = zappy.useDesign(design);

  useEffect(() => {
    if (config?.preloads) {
      const preloadConfig = Object.keys(config.preloads).reduce((acc, key) => {
        const preload = config.preloads[key];
        let { url } = preload;

        acc[key] = {
          ...preload,
          url,
        };
        return acc;
      }, {});

      addPreloads(preloadConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return false;
};

SmartPreloader.displayName = "SmartPreloader";
