const LCFS = {
  'app.output.template.routes.lcfs.template.lcfs': {
    config: {
      role: 'tabpanel',
      content: [
        ['IconText', 'app.output.template.routes.lcfs.template.lcfs.dialog'],
        ['Chart', 'app.output.template.routes.lcfs.template.annualMaintenance'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'row-gap': '30px',
        '@media print': {
          pageBreakBefore: 'avoid',
        }
      },
    },
  },
  'app.output.template.routes.lcfs.template.lcfs.dialog': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.output.template.routes.lcfs.template.lcfs.dialog',
          dangerouslySetInnerHTML: true,
          fallback:
            'Switching to electricity will also allow you to generate Low Carbon Fuel Standard (LCFS) credits, which could generate <strong>${{totalCost}}</strong> per year.',
        },
      },
    },
  },
  'app.output.template.routes.lcfs.template.annualMaintenance': {
    config: {
      type: 'bar',
      scaleOptions: {
        y: {
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
      },
      labelsTemplate: {
        id: 'i18n.app.output.template.routes.lcfs.template.annualMaintenance.label.{{index}}',
        fallback: 'Year {{index}}',
        startingValue: 1,
      },
      pick: [
        {
          source: 'lcfsCosts',
          label: {
            id: 'i18n.barChartWithValuesProps.lcfs.label',
            fallback: 'LCFS',
          },
          backgroundColor: 'var(--chart-color-3)',
        },
      ],
    },
    components: {
      title: 'app.output.template.routes.lcfs.template.annualMaintenance.title',
    },
  },
  'app.output.template.routes.lcfs.template.annualMaintenance.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.lcfs.template.annualMaintenance.title',
        fallback: 'ESTIMATED LCFS REVENUES',
      },
    },
  },
};

export default LCFS;
