// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import isEmpty from 'lodash.isempty';
import { DetailedIncentivesView } from '@zappy-ride/ev-fleets';

export default function EvaluationsIncentives() {
  const { 'assumptionsData.incentives': evaluationsIncentives } =
    zappy.useWatch({ name: 'assumptionsData.incentives' });
  const { zipcode } = zappy.useWatch({ name: 'zipcode' });

  if (isEmpty(evaluationsIncentives)) return 'No incentives applied';
  return (
    <DetailedIncentivesView
      incentives={evaluationsIncentives}
      currentProject={{ project: { zipcode } }}
    />
  );
}

EvaluationsIncentives.displayName = 'EvaluationsIncentives';
