import { DESKTOP_THRESHOLD } from '../../base';

const CATALOG_VEHICLE_DETAILS = {
  'app.catalog.vehicles.details': {
    _extends: 'app.catalog.common.template',
    config: {
      basePath: '/catalog',
      content: [
        ['ContentContainer', 'app.catalog.vehicles.details.frame'],
        ['ContentContainer', 'app.catalog.common.botton'],
      ],
    },
  },

  'app.catalog.vehicles.details.frame': {
    _extends: 'app.catalog.common.frame',
    config: {
      content: [['VehicleDetails', 'app.catalog.vehicles.details.content']],
    },
  },
  'app.catalog.vehicles.details.content': {
    components: {
      back: 'app.catalog.vehicles.details.back.button',
      name: 'app.catalog.vehicles.details.name',
      price: 'app.catalog.vehicles.details.price',
      tableContainer: 'app.catalog.vehicles.details.tableContainer',
    },
    config: {
      preload: 'electric_vehicles',
      redirectToIfNoHistory: '/onboarding',
    },
    schema: {
      default: {
        display: 'grid',
        background: 'var(--disabled-color)',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'max-width': 'var(--max-width-page)',
          'grid-template-columns': '1fr auto',
          'grid-row-gap': '38px',
          margin: '40px auto',
        },

        '@media (max-width: 992px)': {
          margin: '16px auto',
        },
      },
    },
  },
  'app.catalog.vehicles.details.back.button': {
    _extends: 'app.common.link.button',
    config: {
      content: [
        ['Icon', 'app.catalog.vehicles.details.back.button.icon'],
        [
          'TranslatedText',
          'app.catalog.vehicles.details.back.button.translatedText',
        ],
      ],
      to: '/catalog/vehicles',
    },
    schema: {
      default: {
        'align-self': 'center',
        'justify-content': 'flex-start',
        display: 'flex',
        gridColumn: '1/-1',
      },
    },
  },
  'app.catalog.vehicles.details.back.button.icon': {
    config: {
      src: 'arrow-left',
      alt: 'Go Back',
      title: 'Go Back',
      size: 'md',
    },
    schema: {
      default: {
        'align-self': 'center',
        backgroundColor: 'black',
        'margin-right': '11px',
      },
    },
  },
  'app.catalog.vehicles.details.back.button.translatedText': {
    config: {
      i18n: {
        id: 'i18n.catalog.vehicle.details.back.button.translatedText',
        fallback: 'ALL VEHICLES',
      },
    },
    schema: {
      default: {
        'align-self': 'center',
        'font-size': '16px',
        color: 'black',
        'font-weight': '400',
        'line-height': '16px',
        'text-transform': 'uppercase',
      },
    },
  },
  'app.catalog.vehicles.details.name': {
    config: {
      content: [
        ['Formatter', 'app.catalog.vehicles.details.name.title'],
        ['ContentContainer', 'app.catalog.vehicles.details.name.iconText'],
        ['Formatter', 'app.catalog.vehicles.details.name.image'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '10px',
        'justify-content': 'flex-start',
        'justify-items': 'flex-start',

        '@media (max-width: 992px)': {
          'margin-top': '30px',
        },
      },
    },
  },
  'app.catalog.vehicles.details.name.title': {
    config: {
      as: 'h1',
      pick: 'name',
    },
    schema: {
      default: {
        'font-weight': '700',
        'font-size': '34px',
        'line-height': '39px',
        'text-align': 'left',
        'letter-spacing': '0.25px',
        color: 'var(--second-color)',
      },
    },
  },
  'app.catalog.vehicles.details.name.iconText': {
    _extends: 'app.common.iconText.fuel',
    config: {
      content: [
        ['Formatter', 'app.catalog.vehicles.details.name.icon'],
        ['i18nFormatter', 'app.catalog.vehicles.details.name.fuel'],
      ],
    },
  },
  'app.catalog.vehicles.details.name.icon': {
    _extends: 'app.catalog.vehicles.common.fuel.icon',
    schema: {
      default: {
        height: '32px',

        '@media (max-width: 992px)': {
          height: '24px',
        },
      },
    },
  },
  'app.catalog.vehicles.details.name.fuel': {
    config: {
      as: 'p',
      pick: 'fuel',
      converter: {
        PHEV: 'Hybrid Vehicle',
        BEV: 'Electric Vehicle',
      },
      i18nConverter: {
        PHEV: {
          text: 'Hybrid Vehicle',
          i18n: 'i18n.catalog.vehicle.details.name.fuel.PHEV',
        },
        BEV: {
          text: 'Electric Vehicle',
          i18n: 'i18n.catalog.vehicle.details.name.fuel.BEV',
        },
      },
      formatter: 'interpolate',
    },
    schema: {
      default: {
        'align-self': 'center',
        '@media (max-width: 992px)': {
          'font-size': 'var(--item-font-size-mobile)',
        },
      },
    },
  },
  'app.catalog.vehicles.details.name.image': {
    _extends: 'app.catalog.vehicles.details.common.image',
    schema: {
      default: {
        width: '417px',
      },
    },
  },
  'app.catalog.vehicles.details.price': {
    config: {
      content: [
        ['ContentContainer', 'app.catalog.vehicles.details.price.msrp'],
        ['Formatter', 'app.catalog.vehicles.details.price.button'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '8px',
        'justify-content': 'flex-end',
        'justify-items': 'center',
        'margin-top': 'auto',

        '@media (max-width: 992px)': {
          display: 'flex',
          'flex-wrap': 'wrap',
          'justify-content': 'center',
        },
      },
    },
  },
  'app.catalog.vehicles.details.price.msrp': {
    config: {
      content: [
        ['TranslatedText', 'app.catalog.vehicles.details.price.msrp.label'],
        ['Formatter', 'app.catalog.vehicles.details.price.msrp.value'],
      ],
    },
  },
  'app.catalog.vehicles.details.price.msrp.label': {
    _extends: 'app.catalog.vehicles.common.msrp.label',
    schema: {
      default: {
        color: 'var(--second-color)',
        'font-weight': 'var(--item-font-weight-value)',
        'font-size': '12px',
      },
    },
  },
  'app.catalog.vehicles.details.price.msrp.value': {
    _extends: 'app.catalog.vehicles.common.msrp.value',
    schema: {
      default: {
        'font-weight': '600',
        'font-size': '48px',

        '@media (max-width: 992px)': {
          'font-weight': 'var(--item-font-weight-value)',
        },
      },
    },
  },
  'app.catalog.vehicles.details.price.button': {
    _extends: ['app.common.button', 'app.common.link.button'],
    components: {
      translatedText:
        'app.catalog.vehicles.details.price.button.translatedText',
    },
    config: {
      mapper: {
        official_website_url: 'to',
      },
      target: '_blank',
    },
    schema: {
      default: {
        'align-self': 'center',
        'text-align': 'center',
        background: ' linear-gradient(180deg, #76BB40 0%, #2E6F0F 50.52%)',
        'border-radius': '50px',
        'min-width': '240px',
        padding: '14px',
        'font-weight': 'var(--item-font-weight-value)',

        '@media (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.catalog.vehicles.details.price.button.translatedText': {
    config: {
      i18n: {
        id: 'i18n.catalog.vehicle.details.price.button.translatedText',
        fallback: 'VISIT MANUFACTURER',
      },
    },
    schema: {
      default: {
        'font-size': '16px',
        color: 'white',
        'line-height': '16px',
        'font-weight': '400',
      },
    },
  },

  'app.catalog.vehicles.details.tableContainer': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.catalog.vehicles.details.tableContainer.header',
        ],
        [
          'Table',
          'app.catalog.vehicles.details.tableContainer.table',
          {
            targetSource: 'table1',
          },
        ],
        [
          'Table',
          'app.catalog.vehicles.details.tableContainer.table',
          {
            targetSource: 'table2',
            displayQuery: 'onRoad',
          },
        ],
        [
          'Table',
          'app.catalog.vehicles.details.tableContainer.table.offRoad',
          {
            targetSource: 'table2',
            displayQuery: 'offRoad',
          },
        ],
        [
          'Table',
          'app.catalog.vehicles.details.tableContainer.table',
          {
            targetSource: 'table3',
          },
        ],
        [
          'Table',
          'app.catalog.vehicles.details.tableContainer.table',
          {
            targetSource: 'table4',
          },
        ],
      ],
      displayQueries: {
        onRoad: { '["props", "is_offroad"]': { $neq: true } },
        offRoad: {
          '["props", "is_offroad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        width: '100%',
        display: 'flex',
        'flex-wrap': 'wrap',
        gridColumn: '1/-1',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        fontSize: '14px',

        '@media (max-width: 992px)': {
          'margin-top': '20px',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.header': {
    config: {
      as: 'h3',
      fontWeight: 'bold',
      i18n: {
        id: 'i18n.catalog.vehicle.details.tableContainer.header',
        fallback: 'Vehicle Specs',
      },
    },
    schema: {
      default: {
        width: '100%',
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table': {
    config: {
      rowsDesign: 'app.catalog.vehicles.details.tableContainer.table.rows',
      cellsDesign: 'app.catalog.vehicles.details.tableContainer.table.cells',
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          margin: '16px 12px 0 12px',
          'max-width': '25%',
          'flex-grow': '1',
        },

        '@media (max-width: 992px)': {
          width: '100%',
          'margin-top': '20px',
        },

        '&:last-child': {
          'margin-right': '0',
        },

        '&:nth-child(2)': {
          'margin-left': '0',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.offRoad': {
    _extends: 'app.catalog.vehicles.details.tableContainer.table',
    config: {
      rowsDesign:
        'app.catalog.vehicles.details.tableContainer.table.rows.offRoad',
      conditionalDesign: {
        '[*]': {
          condition:
            '(function({tableData, rowIndex, rowData}){ return rowData.tooltip !== null; })',
          design:
            'app.catalog.vehicles.details.tableContainer.table.rows.tooltip',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.rows.tooltip': {
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.catalog.vehicles.details.tableContainer.table.cells.label',
        ],
        [
          'Formatter',
          'app.formatter.auto.value.catalog.vehicleDetail.offRoad',
          {
            fallback: 'N/A',
            converter: {
              not_applied: 'N/A',
            },
          },
        ],
        [
          'IconText',
          'app.catalog.vehicles.details.tableContainer.table.rows.batterytooltip',
          {
            tooltip: {
              popover: {
                translatedText: {
                  _extends:
                    'app.output.template.vehicleFleetTableFuelAndElectricity.tooltipPopOverText',
                  i18n: {
                    id: 'i18n.catalog.vehicle.details.tableContainer.table.rows.batterytooltip',
                    fallback:
                      'Runtime and efficiency depend on usage intensity (load moved, acceleration etc). Medium duty usage is most common.',
                  },
                },
              },
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #AAA',
        height: '28px',
        '&:last-child': {
          borderBottom: 'none',
        },
        '& > td:first-child': {
          'max-width': '144px',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.rows.batterytooltip': {
    components: {
      tooltip:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip',
      header: null,
      text: null,
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.rows': {
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.catalog.vehicles.details.tableContainer.table.cells.label',
        ],
        [
          'i18nFormatter',
          'app.catalog.vehicles.details.tableContainer.table.cells.value',
          {
            fallback: 'N/A',
            converter: {
              not_applied: 'N/A',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid #AAA',
        height: '28px',
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.rows.offRoad': {
    _extends: 'app.catalog.vehicles.details.tableContainer.table.rows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.catalog.vehicles.details.tableContainer.table.cells.label',
        ],
        ['Formatter', 'app.formatter.auto.value.catalog.vehicleDetail.offRoad'],
      ],
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.cells': {
    schema: {
      default: {
        padding: '0 10px',
        height: '38px',
        lineHeight: '14px',

        '@media (max-width: 992px)': {
          width: '50%',
        },
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.cells.value': {
    _extends: 'app.common.formatter.auto.value',
    config: {
      as: 'p',
      autoPick: 'rowData[prop]',
      mapper: {
        'rowData[prop]': 'rowData[prop]',
        'rowData[val]': 'picked',
      },
    },
  },
  'app.catalog.vehicles.details.tableContainer.table.cells.label': {
    _extends: 'app.common.formatter.auto.label',
    config: {
      as: 'p',
      autoPick: 'rowData[prop]',
      mapper: {
        'rowData[prop]': 'rowData[prop]',
        'rowData[label]': 'picked',
      },
    },
    schema: {
      default: {
        fontWeight: 'bold',
      },
    },
  },
};

export default CATALOG_VEHICLE_DETAILS;
