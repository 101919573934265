import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { CalculationResultsGroup } from '@zappy-ride/library.calculations';
import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { VehicleSetData } from '../../../types/form.types';
import { getCurrencyConversionValue } from '../../../utils/localStorageUtils';

export const operation = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { vehicleSets, assumptionsData } = getValues();

  const moneyConversionFactor = getCurrencyConversionValue()

  const [perSet, perSetStatus] = getStateValue({
    lookupKey: ['perSet', 'status.operations.isReady'],
    states: ['operations'],
  }) as [Record<string, CalculationResultsGroup>, boolean];

  const [operationsYearly, operationsStatus] = getStateValue({
    lookupKey: ['total.yearly', 'status.operations'],
    states: ['operations'],
  }) as [any, boolean];

  const fossilFuelVehicles: Array<number> = [];
  const electricVehicles: Array<number> = [];

  const { fossilInsurance, electricInsurance } =
    operationsYearly?.reduce(
      (acc: any, element: any) => {
        acc.fossilInsurance += get(
          element,
          'operations.cost.fossil.insurance',
          0
        ) as number;
        acc.electricInsurance += get(
          element,
          'operations.cost.electric.insurance',
          0
        ) as number;

        fossilFuelVehicles.push(
          (get(element, 'operations.cost.fossil.maintenance', 0) as number) *
            moneyConversionFactor
        );
        electricVehicles.push(
          (get(element, 'operations.cost.electric.maintenance', 0) as number) *
            moneyConversionFactor
        );

        return acc;
      },
      { fossilInsurance: 0, electricInsurance: 0 }
    ) || {};

  const dataPerSet = (setId: string) => {
    const vehicleSet = perSet?.[setId];

    // cover missed update calculation list
    if (vehicleSet === undefined) return vehicleSet;

    const { fossil, electric } = vehicleSet?.yearly[0]?.operations.cost;

    let annualMiles = get(vehicleSet, 'annual.vehicles.miles', 1) || 1;
    const perMileFossil = get(fossil, 'maintenance', 0) / annualMiles;
    const perMileElectric = get(electric, 'maintenance', 0) / annualMiles;

    return {
      annualMiles: zappy.formatters.formatAsThousands(annualMiles),
      perMileFossil: zappy.formatters.formatAsCents(
        perMileFossil * moneyConversionFactor
      ),
      perMileElectric: zappy.formatters.formatAsCents(
        perMileElectric * moneyConversionFactor
      ),
    };
  };

  const vehicleSetsData: any = [];

  vehicleSets.forEach((vehicleSet: VehicleSetData) => {
    const data = dataPerSet(vehicleSet.id);
    const annualMiles = data?.annualMiles;
    const perMileFossil = data?.perMileFossil;
    const perMileElectric = data?.perMileElectric;

    if (data)
      vehicleSetsData.push({
        vehicleSetName: vehicleSet.vehicleSetName,
        name: vehicleSet.input.name,
        outputName: vehicleSet.output.name,
        annualMiles,
        perMileFossil,
        perMileElectric,
      });
  });

  return {
    status: {
      isLoading: !perSetStatus && !operationsStatus,
      missingRequiredInformation: !(calculationsStatus && inputsStatus),
    },
    data: {
      vehicleSetsData,
      fossilFuelVehicles,
      electricVehicles,
      annualInsuranceCosts: [
        {
          fossilInsurance: fossilInsurance / assumptionsData.lifespanYears,
          electricInsurance: electricInsurance / assumptionsData.lifespanYears,
        },
      ],
    },
  };
};

export const maintenanceCosts = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { vehicleSets, assumptionsData } = getValues();

  const moneyConversionFactor = getCurrencyConversionValue()

  const vehicleCategoryIsOffRoad = get(
    vehicleSets,
    '[0].input.is_offroad',
    false
  );

  const [miles, status] = getStateValue({
    lookupKey: ['total.annual.vehicles.miles', 'status.operations.isReady'],
    states: ['operations', 'vehicles'],
  }) as [number, boolean];

  const [
    lifetimeFossilMaintenanceCost,
    lifetimeElectricMaintenanceCost,
    maintenanceStatus,
  ] = getStateValue({
    lookupKey: [
      'total.cumulative[-1].operations.cost.fossil.maintenance',
      'total.cumulative[-1].operations.cost.electric.maintenance',
      'status.operations.isReady',
    ],
    states: ['operations'],
  }) as [number, number, boolean];

  const adjustedLifetimeFossilMaintenanceCost =
    lifetimeFossilMaintenanceCost * moneyConversionFactor;
  const adjustedLifetimeElectricMaintenanceCost =
    lifetimeElectricMaintenanceCost * moneyConversionFactor;

  const [hours = 1] = getStateValue({
    lookupKey: ['total.annual.vehicles.hours'],
    states: ['vehicles'],
  }) as [number];

  const maitenanceFossilPerYear =
    adjustedLifetimeFossilMaintenanceCost / assumptionsData.lifespanYears;
  const maitenanceElectricPerYear =
    adjustedLifetimeElectricMaintenanceCost / assumptionsData.lifespanYears;

  return {
    status: {
      isLoading: !status && !maintenanceStatus,
      missingRequiredInformation: false,
    },
    data: {
      vehicleCategoryIsOffRoad,
      emptyDynamicTables: [],
      maintenanceDataFossil: {
        perHour: maitenanceFossilPerYear / hours,
        perMile: maitenanceFossilPerYear / miles,
        perYear: maitenanceFossilPerYear,
        total: adjustedLifetimeFossilMaintenanceCost,
      },
      maintenanceDataElectric: {
        perHour: maitenanceElectricPerYear / hours,
        perMile: maitenanceElectricPerYear / miles,
        perYear: maitenanceElectricPerYear,
        total: adjustedLifetimeElectricMaintenanceCost,
      },
    },
  };
};
