import { DESKTOP_THRESHOLD } from '../../base';

/* eslint-disable no-template-curly-in-string */
const OUTPUT_CHARGING = {
  '#### OUTPUT CHARGING ####': '',
  'app.output.template.routes.charging.template': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.charging.head',
          {
            displayQueries: 'mobileOrPrinting',
            className: 'pageHeader',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.charging.template.tablesContainer',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.charging.chargingOverTime',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.charging.chargingOverTime.warnings',
        ],
      ],
    },
  },
  'app.output.template.mobile.charging.head': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.charging.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.mobile.charging.head.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.charging.head.title',
        fallback: 'Charging',
      },
    },
  },
  'app.output.template.routes.charging.template.tablesContainer': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.tables.titleBig',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.charging.template.tablesContainer.chargingFacilityNeeds',
              fallback: 'Charging and Facility Needs',
            },
          },
        ],
        ['IconText', 'app.output.template.routes.charging.template.chargeCost'],
        ['ContentContainer', 'app.output.select.vehicleSetSelector'],
        [
          'Collapsible',
          'app.output.template.routes.charging.template.collapsible',
        ],
        [
          'ContentContainer',
          'app.output.template.chargersAssignment.container',
        ],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'padding-top': '16px',
        },
        '&:first-child > span': {
          display: 'block',
          marginBottom: '30px',
          paddingLeft: '0',
        },
      },
    },
  },
  'app.output.template.routes.charging.template.chargeCost': {
    _extends: 'app.common.iconText',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/evergy-dollar.svg',
        alt: 'Charge Cost',
      },
      text: {
        i18n: {
          id: 'i18n.output.template.routes.charging.template.chargeCost.iconText.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'Total estimated charger cost <strong>${{totalCost}}</strong>',
        },
      },
    },
    schema: {
      default: {
        'margin-bottom': '1.5rem',

        '@media (max-width: 992px)': {
          'margin-top': '32px',
        },
      },
    },
  },
  'app.output.template.routes.charging.template.collapsible': {
    _extends: 'app.output.template.routes.tabs.collapsible',
    components: {
      headerText:
        'app.output.template.routes.charging.template.collapsible.text',
      headerContent:
        'app.output.template.routes.charging.template.collapsible.headerContent',
      content:
        'app.output.template.routes.charging.template.tablesContainer.collapsible.content',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          width: '100%',
          padding: '0',
          border: 'none',

          '& > [id="app.output.template.routes.charging.template.collapsible-header"]':
            {
              'border-bottom': 'none !important',
            },
        },
      },
    },
  },
  'app.output.template.routes.charging.template.collapsible.text': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.app.output.template.routes.charging.template.collapsible.text.chargers',
        fallback: 'Chargers',
      },
    },
  },
  'app.output.template.routes.charging.template.collapsible.headerContent': {
    config: {
      content: [
        [
          'i18nFormatter',
          '',
          {
            pick: 'vehicleCount',
            text: '{{picked}} Vehicle(s)',
            format: {
              formatter: 'interpolate',
            },
            i18n: 'i18n.app.output.template.routes.charging.template.collapsible.headerContent'
          },
        ],
      ],
    },
    schema: {
      default: {
        border: '1px transparent solid',
        margin: '0 16px 0',
      },
    },
  },
  'app.output.template.routes.charging.template.tablesContainer.collapsible.content':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.routes.charging.template.tablesContainer.collapsible.content.wrapper',
          ],
        ],
      },
    },
  'app.output.template.routes.charging.template.tablesContainer.collapsible.content.wrapper':
    {
      config: {
        content: [
          [
            'ContentContainer',
            'app.output.template.chargerTableContainer.tableSection',
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        },
      },
    },
  'app.output.template.chargerTableContainer.tableSection': {
    config: {
      content: [
        [
          'TableArray',
          'app.output.template.chargerTable.chargerSpecs.TablesArray',
          {
            targetSource: 'content',
            globalDataSource: 'globalData',
          },
        ],
      ],
    },
  },
  'app.output.template.chargerTable.chargerSpecs.TablesArray': {
    _extends: 'app.output.print.common.rowForPrint',
    components: {
      repeatingTable: 'app.output.template.chargerTable.chargerSpecs',
    },
    config: {
      tablesDesign: 'app.output.template.chargerTable.chargerSpecs',
      targetSource: 'chargerTable',
    },
    schema: {
      default: {
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
        gap: '60px',
        'padding-bottom': '28px',
        'flex-wrap': 'wrap',
        'align-items': 'stretch',

        '@media (max-width: 992px)': {
          'flex-direction': 'column',
          'align-items': 'center',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs': {
    _extends: [
      'app.output.common.tables',
      'app.common.skeleton',
      'app.output.common.table.catalog.card'
    ],
    components: {
      wrapper: 'app.output.template.chargerTable.chargerSpecs.table.wrapper',
    },
    config: {
      targetSource: 'data',
      globalDataSource: 'globalDataPerTable',
      component: 'Table',
      rowsDesign: 'app.output.template.chargerTable.chargerSpecs.rows',
      cellsDesign: 'app.output.template.chargerTable.chargerSpecs.cells',
      overwriteDesign: {
        '[0]': 'app.output.template.chargerTable.chargerSpecs.rowsHeader',
        '[*][1]':
          'app.output.template.chargerTable.chargerSpecs.custom.columnRight',
      },
      footerRows: [['app.output.template.chargerTable.chargerSpecs.footer']],
    },
    schema: {
      default: {
        fontFamily: 'Arial',
        fontSize: '14px',
        height: '100%',

        '@media screen and (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.footer': {
    config: {
      cells: [
        [
          'ActionComponent',
          'app.output.template.chargerTable.chargerSpecs.changeButton',
          {},
          {
            colSpan: 2,
            schema: { default: { verticalAlign: 'bottom' } },
          },
        ],
      ],
    },
  },
  'app.output.template.chargerTable.chargerSpecs.changeButton': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/setValue',
          payload: {
            name: 'catalogs.referenceId',
            func: '(function({ values }) { return values["globalData[id]"]; })',
          },
        },
        {
          type: 'form/setValue',
          payload: {
            name: 'catalogs.referenceIndex',
            func: '(function({ values }) { return values["globalData[index]"]; })',
          },
        },
        {
          type: 'form/navigateTo',
          payload: {
            to: '/catalog/chargers',
            from: '/output/charging',
          },
        },
      ],
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.changeButton.button.translatedText',
        ],
      ],
    },
  },
  'app.output.template.chargerTable.chargerSpecs.changeButton.button.translatedText':
    {
      _extends: ['app.common.skeleton', 'app.common.text.secondary'],
      config: {
        fontSize: '1rem',
        i18n: {
          id: 'i18n.output.template.chargerTable.chargerSpecs.changeButton.button.translatedText',
          fallback: 'CHANGE',
        },
      },
    },
  'app.output.template.chargerTable.chargerSpecs.table.wrapper': {
    _extends: 'app.output.template.tables.wrapper',
    schema: {
      default: {
        position: 'relative',
        maxWidth: '284px',
        minWidth: '230px',
        width: '45%',

        '@media screen and (max-width: 992px)': {
          width: '100%',
          maxWidth: 'inherit',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.rowsHeader': {
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.chargerTable.chargerSpecs.rowsHeader.content',
          {},
          {
            colSpan: 2,
          },
        ],
      ],
    },
    schema: {
      default: {
        borderTop: '0px solid #ACB1BA',
        height: '28px',
        '& > td': {
          border: 'none',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.rowsHeader.content': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.mainHeader.generic',
          {
            displayQuery: {
              '["props", "globalData[archetype]"]': { $eq: true },
            },
          },
        ],
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.mainHeader.commercial',
          {
            displayQuery: {
              '["props", "globalData[commercial]"]': { $eq: true },
            },
          },
        ],
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.secondaryHeader',
        ],
        [
          'ContentContainer',
          'app.output.template.chargerTable.chargerSpecs.badges',
        ],
        [
          'Formatter',
          'app.output.template.chargerTable.chargerSpecs.rowsImage',
          {
            mapper: { 'globalData[image]': 'src', 'globalData[label]': 'alt' },
          },
        ],
        [
          'ContentContainer',
          'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity.wrapper',
        ],
      ],
    },
  },
  'app.output.template.chargerTable.chargerSpecs.mainHeader.generic': {
    config: {
      as: 'p',
      i18n: {
        id: '{{globalData[translatedLabel][i18n][id]}}',
        fallback: '{{globalData[translatedLabel][i18n][fallback]}}',
      },
    },
    schema: {
      default: {
        fontSize: '16px',
        fontWeight: 'bold',
        maxWidth: '220px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '@media (max-width: 992px)': {
          whiteSpace: 'inherit',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.mainHeader.commercial': {
    _extends:
      'app.output.template.chargerTable.chargerSpecs.mainHeader.generic',
    config: {
      as: 'p',
      i18n: {
        id: '{{globalData[translatedLabel][i18n][id]}}',
        fallback: '{{globalData[translatedLabel][i18n][fallback]}}',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.secondaryHeader': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.output.template.chargerTable.chargerSpecs.rowsHeader.secondaryHeader',
        fallback: '{{globalData[label]}}',
      },
    },
    schema: {
      default: {
        fontSize: '14px',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.badges': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.chargerTable.chargerSpecs.approved',
          {
            displayQuery: {
              '["props", "globalData[approved]"]': { $eq: true },
            },
          },
        ],
        [
          'i18nFormatter',
          'app.output.template.chargerTable.chargerSpecs.ports',
          { pick: 'globalData[ports]' },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.ports': {
    _extends: 'app.common.formatter.auto.value',
    config: {
      as: 'p',
      autoPick: 'ports',
      pick: 'globalData[ports]',
      mapper: {
        'globalData[ports]': 'picked',
      },
    },
    schema: {
      default: {
        fontSize: '11px',
        backgroundColor: '#2A2A2A',
        borderRadius: '4px',
        padding: '2px 4px',
        color: '#FFFFFF',
        width: 'fit-content',
      },
    },
  },

  'app.output.template.chargerTable.chargerSpecs.approved': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.output.template.chargerTable.chargerSpecs.approved',
        fallback: 'Evergy Qualified',
      },
    },
    schema: {
      default: {
        fontSize: '11px',
        backgroundColor: 'var(--primary-color)',
        borderRadius: '4px',
        padding: '2px 4px',
        color: '#FFFFFF',
        width: 'fit-content',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.rowsImage': {
    config: {
      component: 'Logo',
    },
    schema: {
      default: {
        margin: 'auto',
        height: '172px',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.icons': {
    config: {
      component: 'Icon',
      as: 'img',
    },
    schema: {
      default: {
        height: '18px',
        width: '18px',
        flex: 0,
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.rows': {
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.chargerTable.chargerSpecs.formatter.label',
        ],
        [
          'i18nFormatter',
          'app.output.template.chargerTable.chargerSpecs.formatter.values',
        ],
      ],
    },
    schema: {
      default: {
        borderTop: '1px solid #ACB1BA',
        height: '28px',
        '&:first-child > td': {
          border: '1px solid red',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.cells': {
    schema: {
      default: {
        padding: '0 10px',
        fontWeight: 'normal',
        borderTop: '1px solid #ACB1BA',
        width: '50%',

        '@media (max-width: 992px)': {
          'word-break': 'break-all',
        },
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.custom.columnRight': {
    _extends: 'app.output.template.chargerTable.chargerSpecs.cells',
    schema: {
      default: {
        textAlign: 'left',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity.label':
    {
      schema: {
        default: {
          textAlign: 'left',
        },
      },
    },
  'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity.wrapper':
    {
      config: {
        content: [
          [
            'TranslatedText',
            'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity.label',
            {
              fontWeight: 'bold',
              paddingRight: '20px',
              i18n: {
                id: 'i18n.app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity.wrapper.connectivity',
                fallback: 'Connectivity',
              },
            },
          ],
          [
            'ContentContainer',
            'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity',
          ],
        ],
      },
      schema: {
        default: {
          padding: '22px 0',
        },
      },
    },
  'app.output.template.chargerTable.chargerSpecs.rowsConnectivity.connectivity':
    {
      config: {
        content: [
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: '3g',
              alt: '3G Connectivity',
              displayQuery: {
                '["props", "globalData[has3g]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: '4g',
              alt: '4G Connectivity',
              displayQuery: {
                '["props", "globalData[has4G]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: '5g',
              alt: '5G Connectivity',
              displayQuery: {
                '["props", "globalData[has5G]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: 'lte',
              alt: 'LTE Connectivity',
              displayQuery: {
                '["props", "globalData[hasLTE]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: 'wifi',
              alt: 'WIFI Connectivity',
              displayQuery: {
                '["props", "globalData[hasWifi]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: 'ethernet',
              alt: 'Ethernet Connectivity',
              displayQuery: {
                '["props", "globalData[hasEthernet]"]': { $eq: true },
              },
            },
          ],
          [
            'Formatter',
            'app.output.template.chargerTable.chargerSpecs.icons',
            {
              src: 'bluetooth',
              alt: 'Bluetooth Connectivity',
              displayQuery: {
                '["props", "globalData[hasBluetooth]"]': { $eq: true },
              },
            },
          ],
          [
            'TranslatedText',
            'app.output.template.chargerTable.chargerSpecs.noConnectivity',
            {
              displayQuery: {
                '["props", "globalData[hasConnectivity]"]': { $eq: false },
              },
            },
          ],
        ],
      },
      schema: {
        default: {
          display: 'flex',
          gap: '12px',
          marginTop: '4px',
        },
      },
    },
  'app.output.template.chargerTable.chargerSpecs.noConnectivity': {
    config: {
      i18n: {
        id: 'i18n.output.template.chargerTable.chargerSpecs.noConnectivity',
        fallback: 'N/A',
      },
    },
    schema: {
      default: {
        lineHeight: '18px',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.formatter.label': {
    config: {
      pick: 'rowData[prop]',
      autoFormats: {
        cost: {
          text: 'Estimated Cost',
          i18n: "i18n.app.formatter.autoFormats.label.estimatedCost"
        },
        portKw: {
          text: 'Port kW',
        },
        amps: {
          text: 'Amps',
        },
        plugTypes: {
          text: 'Plug Type(s)',
          i18n: "i18n.app.formatter.autoFormats.label.plugType"
        },
        cordLength: {
          text: 'Cable Length',
          i18n: "i18n.app.formatter.autoFormats.label.cableLength"
        },
        ports: {
          text: 'Ports',
          i18n: "i18n.app.formatter.autoFormats.label.ports"
        },
        formFactor: {
          text: 'Form Factor',
          i18n: "i18n.app.formatter.autoFormats.label.formFactor"
        },
        outlets: {
          text: 'Outlet(s)',
          i18n: "i18n.app.formatter.autoFormats.label.outlets"
        },
        ocpp: {
          text: 'OCPP',
        },
        oscp: {
          text: 'OSCP',
        },
        toGrid: {
          text: 'Vehicle to Grid',
          i18n: "i18n.app.formatter.autoFormats.label.vehicleToGrid"
        },
        warranty: {
          text: 'Warranty',
          i18n: "i18n.app.formatter.autoFormats.label.warranty"
        },
        certifications: {
          text: 'Certifications',
          i18n: "i18n.app.formatter.autoFormats.label.certifications"
        },
        chargerType: {
          text: 'Charger Type',
          i18n: "i18n.app.formatter.autoFormats.label.chargerType"
        },
      },
    },
    schema: {
      default: {
        fontWeight: 'bold',
        lineHeight: '1em',
        padding: '8px 0',
      },
    },
  },
  'app.output.template.chargerTable.chargerSpecs.formatter.values': {
    config: {
      pick: 'rowData[val]',
      autoPick: 'rowData[prop]',
      locale: 'en-US',
      fallback: 'N/A',
      autoFormats: {
        cost: {
          formatter: 'formatAsDollars',
        },
        portKw: {
          formatter: 'interpolate',
          text: '{{picked}} kW',
          fallback: 'N/A',
        },
        amps: {
          formatter: 'interpolate',
          text: '{{picked}} amps',
          fallback: 'N/A',
        },
        cordLength: {
          formatter: 'interpolate',
          text: '{{picked}} ft³',
          fallback: 'N/A',
        },
        outlets: {
          formatter: 'interpolate',
          fallback: 'N/A',
        },
        ocpp: {
          formatter: 'interpolate',
          text: '{{picked}}',
          fallback: 'N/A',
        },
        oscp: {
          formatter: 'interpolate',
          text: '{{picked}}',
          fallback: 'N/A',
        },
        toGrid: {
          converter: {
            true: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.yes',
              fallback: 'Yes',
            },
            false: 'No',
          },
        },
        warranty: {
          formatter: 'interpolate',
          text: '{{picked}} Year(s)',
          i18n: "i18n.app.formatter.autoFormats.values.warranty"
        },
        chargerType: {
          converter: {
            level_1: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.level1',
              fallback: 'Level 1',
            },
            level_2: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.level2',
              fallback: 'Level 2',
            },
            dc_fast_charging: 'DCFC',
          },
        },
      },
    },
    schema: {
      default: {
        lineHeight: '1em',
        padding: '8px 0',
      },
    },
  },

  //CHARGER ASSIGNMENT TABLE
  'app.output.template.chargersAssignment.container': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.operation.template.tablesTitle',
          {
            i18n: {
              id: 'i18n.app.output.template.chargersAssignment.container.label',
              fallback: 'Charger Assignment',
            },
          },
        ],
        [
          'Table',
          'app.output.template.chargersAssignment.table',
          { targetSource: 'chargingWindows' },
        ],
      ],
    },
    schema: {
      default: {
        marginTop: '32px',
      },
    },
  },
  'app.output.template.chargersAssignment.table': {
    _extends: 'app.output.common.tables',
    config: {
      component: 'Table',
      headerRows: [['app.output.template.chargersAssignment.table.headerRows']],
      rowsDesign: 'app.output.template.chargersAssignment.table.rowsDesign',
      conditionalDesign: {
        '[*]': {
          condition:
            '(function({tableData, rowIndex, rowData}){ return !rowIndex; })',
          design:
            'app.output.template.chargersAssignment.table.rowsDesignInitialRow',
        },
      },
    },
  },
  'app.output.template.chargersAssignment.table.headerRows': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.chargersAssignment.table.headerRows.chargerType',
              fallback: 'Charger Type',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.chargersAssignment.table.headerRows.time',
              fallback: 'Time',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        fontWeight: 'bold',
        fontSize: '14px',
        height: '40px',
        '& > td': {
          padding: '8px 0',
        },
      },
    },
  },
  'app.output.template.chargersAssignment.table.rowsDesignInitialRow': {
    _extends: 'app.output.template.chargersAssignment.table.rowsDesign',
    schema: {
      default: {
        fontSize: '14px',
        height: '40px',
      },
    },
  },
  'app.output.template.chargersAssignment.table.rowsDesign': {
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.chargersAssignment.table.rowsDesign.combinedLabel',
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.chargersAssignment.table.rowsDesign.time',
              fallback: '{{rowData[i18Time]}}',
            }
          }

        ],
      ],
    },
    schema: {
      default: {
        borderTop: '1px solid #e0e0e0',
        fontSize: '14px',
        height: '40px',
      },
    },
  },
  'app.output.template.chargersAssignment.table.rowsDesign.combinedLabel': {
    config: {
      content: [
        [
          'i18nFormatter',
          '',
          {
            pick: 'rowData[chargerLabel]',
            format: {
              formatter: 'interpolate',
              text: '{{picked}} | ',
            },
          },
        ],
        [
          'i18nFormatter',
          'app.common.formatter.auto.ports',
          { pick: 'rowData[ports]' },
        ],
        [
          'i18nFormatter',
          '',
          {
            pick: 'rowData[count]',
            format: {
              formatter: 'interpolate',
              text: ' | Minimum Req: {{picked}}',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
      },
    },
  },
  'app.output.template.chargersAssignment.table.rowsDesign.combinedLabel.charger':
    {
      config: {
        format: {
          formatter: 'interpolate',
        },
      },
    },
  // #### CHARGING OVER TIME LINE GRAPH ####
  'app.output.template.routes.charging.chargingOverTime': {
    config: {
      content: [
        ['Chart', 'app.output.template.routes.charging.chargingOverTime.chart'],
        [
          'TranslatedText',
          'app.output.template.routes.charging.chargingOverTime.note',
        ],
      ],
    },
  },
  'app.output.template.routes.charging.chargingOverTime.chart': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible',
    ],
    components: {
      title: 'app.output.template.routes.charging.chargingOverTime.chart.title',
    },
    config: {
      type: 'line',
      maintainAspectRatio: false,
      scaleOptions: {
        y: {
          min: 0,
          max: 1,
          ticks: {
            format: {
              style: 'percent',
            },
          },
        },
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 12,
          },
        },
      },
      legend: {
        display: false,
      },
      labels: [
        { id: '12am', fallback: '12 am' },
        { id: '1am', fallback: '1 am' },
        { id: '2am', fallback: '2 am' },
        { id: '3am', fallback: '3 am' },
        { id: '4am', fallback: '4 am' },
        { id: '5am', fallback: '5 am' },
        { id: '6am', fallback: '6 am' },
        { id: '7am', fallback: '7 am' },
        { id: '8am', fallback: '8 am' },
        { id: '9am', fallback: '9 am' },
        { id: '10am', fallback: '10 am' },
        { id: '11am', fallback: '11 am' },
        { id: '12pm', fallback: '12 pm' },
        { id: '1pm', fallback: '1 pm' },
        { id: '2pm', fallback: '2 pm' },
        { id: '3pm', fallback: '3 pm' },
        { id: '4pm', fallback: '4 pm' },
        { id: '5pm', fallback: '5 pm' },
        { id: '6pm', fallback: '6 pm' },
        { id: '7pm', fallback: '7 pm' },
        { id: '8pm', fallback: '8 pm' },
        { id: '9pm', fallback: '9 pm' },
        { id: '10pm', fallback: '10 pm' },
        { id: '11pm', fallback: '11 pm' },
      ],
      pick: [
        {
          label: {
            id: 'i18n.barChart.electric.label',
            fallback: 'Electric Vehicles',
          },
          source: 'dailyChargeSchedule',
          backgroundColor: 'var(--chart-color-3)',
          fill: true,
          pointRadius: 0,
        },
      ],
    },
    schema: {
      default: {
        position: 'relative',
        height: '40vh',
        'margin-bottom': '70px',
      },
    },
  },
  'app.output.template.routes.charging.chargingOverTime.chart.title': {
    _extends: 'app.output.template.routes.tables.titleBig',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.charging.chargingOverTime.chart.title',
        fallback: 'Charging Over Time',
      },
    },
  },
  'app.output.template.routes.charging.chargingOverTime.note': {
    config: {
      i18n: {
        id: 'i18n.output.template.routes.charging.chargingOverTime.note',
        fallback:
          'Note: The graph assumes daily mileage is averaged across all hours of the day when the vehicle is not available to charge. Actual mileage and battery state will differ.',
      },
    },
  },
  'app.output.template.routes.charging.chargingOverTime.warnings': {
    config: {
      content: [
        ['FormAlert', 'app.onboarding.charging.form.fields.noteInfo'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteWarning'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteAlert'],
      ],
    },
    schema: {
      default: {
        marginTop: '25px',
      },
    },
  },

  //Temporary commented
  // 'app.output.template.routes.charging.chargingOverTime.warnings': {
  //   config: {
  //     content: [
  //       [
  //         'FormAlert',
  //         'app.output.template.routes.charging.chargingOverTime.warnings.thresholdError',
  //       ],
  //       [
  //         'FormAlert',
  //         'app.output.template.routes.charging.chargingOverTime.warnings.thresholdInfo',
  //       ],
  //     ],
  //   },
  //   schema: {
  //     default: {
  //       marginTop: '25px',
  //     },
  //   },
  // },
  // 'app.output.template.routes.charging.chargingOverTime.warnings.thresholdError':
  //   {
  //     _extends:
  //       'app.onboarding.charging.form.fields.chargingWindow.depletedError',
  //     config: {
  //       text: {
  //         i18n: {
  //           id: 'i18n.iconText.text',
  //           dangerouslySetInnerHTML: true,
  //           fallback:
  //             'Over the course of a day your vehicle will hit the charge of {{minimumStateChargePercentage}}% with 23 miles remaining. Consider changing your charging window, or selecting a more powerful charger',
  //         },
  //       },
  //       header: {
  //         i18n: {
  //           id: 'i18n.iconText.caption',
  //           fallback: '{{minimumStateChargePercentage}}%',
  //         },
  //       },
  //       watch: {
  //         name: ['minimumStateChargePercentage', 'stateChargeThreshold'],
  //       },
  //       validate:
  //         '(function ({ minimumStateChargePercentage, stateChargeThreshold }) {return minimumStateChargePercentage >= stateChargeThreshold })',
  //     },
  //   },
  // 'app.output.template.routes.charging.chargingOverTime.warnings.thresholdInfo':
  //   {
  //     _extends:
  //       'app.output.template.routes.charging.chargingOverTime.warnings.thresholdError',
  //     config: {
  //       text: {
  //         i18n: {
  //           id: 'i18n.iconText.text',
  //           dangerouslySetInnerHTML: true,
  //           fallback:
  //             'Over the course of a day your vehicle will hit the charge of {{minimumStateChargePercentage}}% with 23 miles remaining.',
  //         },
  //       },
  //       icon: {
  //         color: 'var(--color-info)',
  //       },
  //       header: {
  //         color: 'var(--color-info)',
  //       },
  //       validate:
  //         '(function ({ minimumStateChargePercentage, stateChargeThreshold }) {return minimumStateChargePercentage < stateChargeThreshold })',
  //     },
  //     schema: {
  //       default: {
  //         backgroundColor: 'var(--info-background-color)',
  //       },
  //     },
  //   },
};

export default OUTPUT_CHARGING;
