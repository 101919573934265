import { DESKTOP_THRESHOLD } from '../../base';

const ONBOARDING_INTRO = {
  'app.onboarding.intro': {
    _extends: 'app.page.common',
    config: {
      content: [
        ['TranslatedText', 'app.onboarding.intro.heading'],
        ['TranslatedText', 'app.onboarding.intro.description'],
        ['ContentContainer', 'app.onboarding.intro.iconText'],
        ['ContentContainer', 'app.onboarding.intro.button'],
        [
          'ContentContainer',
          'app.onboarding.intro.link.default',
          { displayQuery: 'termsAndConditionsDisabled' },
        ],
        [
          'TermsAndConditionsModal',
          'app.onboarding.intro.termsandconditions.intro.link.modal',
          { displayQuery: 'termsAndConditionsEnabled' },
        ],
      ],
      watch: {
        name: ['confirmTermsAndConditions'],
      },
      displayQueries: {
        termsAndConditionsEnabled: {
          '["watched", "confirmTermsAndConditions"]': { $eq: true },
        },
        termsAndConditionsDisabled: {
          '["watched", "confirmTermsAndConditions"]': { $neq: true },
        },
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'grid-row-gap': '1.63vh',
        },
      },
    },
  },
  'app.onboarding.intro.termsandconditions.intro.link.modal': {
    _extends: 'app.common.termconditions.modal',
    components: {
      trigger: 'app.onboarding.intro.link',
      confirm: 'app.onboarding.intro.skip.confirm',
    },
  },
  'app.onboarding.intro.termsandconditions.intro.button.modal': {
    _extends: 'app.common.termconditions.modal',
    components: {
      trigger: 'app.onboarding.intro.button',
      confirm: 'app.onboarding.intro.button.confirm',
    },
  },
  'app.onboarding.intro.button.confirm': {
    _extends: 'app.common.termconditions.modal.confirm',
    config: {
      to: '../info',
    },
  },
  'app.onboarding.intro.skip.confirm': {
    _extends: 'app.common.termconditions.modal.confirm',
    config: {
      to: '/output',
    },
  },
  'app.onboarding.intro.heading': {
    _extends: 'app.onboarding.common.heading',
    config: {
      i18n: {
        id: 'i18n.onboarding.intro.heading',
        fallback: 'Going electric starts with understanding your needs',
      },
    },
  },
  'app.onboarding.intro.description': {
    _extends: 'app.onboarding.common.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.intro.description',
        fallback:
          'We need to understand your requirements so we can recommend the appropriate vehicle, charger and identify incentives.',
      },
    },
  },
  'app.onboarding.intro.iconText': {
    config: {
      content: [
        ['IconText', 'app.onboarding.intro.iconText.left'],
        ['IconText', 'app.onboarding.intro.iconText.middle'],
        ['IconText', 'app.onboarding.intro.iconText.right'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-column-gap': '5.56vw',
          'grid-template-columns': 'repeat(3, 1fr)',
          margin: '7.2vh 0',
        },
        'grid-row-gap': '5.32vh',
        marginBottom: '0.81vh',
      },
    },
  },
  'app.onboarding.intro.iconText.common': {
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '1.02vh',
        'justify-content': 'center',
        'justify-items': 'center',

        '@media (max-width: 992px)': {
          'grid-row-gap': '2.86vh',
        },
      },
    },
  },
  'app.onboarding.intro.iconText.common.icon': {
    schema: {
      default: {
        height: '161px',
        width: '248px',
      },
    },
  },
  'app.onboarding.intro.iconText.common.translatedText': {
    config: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  },
  'app.onboarding.intro.iconText.left': {
    _extends: 'app.onboarding.intro.iconText.common',
    components: {
      icon: 'app.onboarding.intro.iconText.left.icon',
      text: 'app.onboarding.intro.iconText.left.translatedText',
    },
  },
  'app.onboarding.intro.iconText.left.icon': {
    _extends: 'app.onboarding.intro.iconText.common.icon',
    config: {
      src: 'https://assets.zappyride.com/img/icons/pse/pse-organization.svg',
      as: 'img',
      alt: 'Tell us about your organization',
    },
  },
  'app.onboarding.intro.iconText.left.translatedText': {
    _extends: 'app.onboarding.intro.iconText.common.translatedText',
    config: {
      i18n: {
        id: 'i18n.onboarding.intro.iconText.left.translatedText',
        fallback: 'Tell us about your organization',
      },
    },
  },
  'app.onboarding.intro.iconText.middle': {
    _extends: 'app.onboarding.intro.iconText.common',
    components: {
      icon: 'app.onboarding.intro.iconText.middle.icon',
      text: 'app.onboarding.intro.iconText.middle.translatedText',
    },
  },
  'app.onboarding.intro.iconText.middle.icon': {
    _extends: 'app.onboarding.intro.iconText.common.icon',
    config: {
      src: 'https://assets.zappyride.com/img/icons/pse/pse-tellus.svg',
      as: 'img',
      alt: 'Tell us about your vehicles',
    },
  },
  'app.onboarding.intro.iconText.middle.translatedText': {
    _extends: 'app.onboarding.intro.iconText.common.translatedText',
    config: {
      i18n: {
        id: 'i18n.onboarding.intro.iconText.middle.translatedText',
        fallback: 'Tell us about your vehicles',
      },
    },
  },
  'app.onboarding.intro.iconText.right': {
    _extends: 'app.onboarding.intro.iconText.common',
    components: {
      icon: 'app.onboarding.intro.iconText.right.icon',
      text: 'app.onboarding.intro.iconText.right.translatedText',
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          margin: '1.67vh 0',
        },

        '@media (max-width: 992px)': {
          paddingBottom: '8px',
          'border-bottom': '1px solid #D9D9D6',
        },
      },
    },
  },
  'app.onboarding.intro.iconText.right.icon': {
    _extends: 'app.onboarding.intro.iconText.common.icon',
    config: {
      src: 'https://assets.zappyride.com/img/icons/pse/pse-save.svg',
      as: 'img',
      alt: 'See how much you can save',
    },
  },
  'app.onboarding.intro.iconText.right.translatedText': {
    _extends: 'app.onboarding.intro.iconText.common.translatedText',
    config: {
      i18n: {
        id: 'i18n.onboarding.intro.iconText.right.translatedText',
        fallback: 'See how much you can save',
      },
    },
  },
  'app.onboarding.intro.button': {
    _extends: [
      'app.common.button.primary',
      'app.onboarding.common.footer.button',
    ],
    config: {
      to: '../info',
      content: [
        ['TranslatedText', 'app.onboarding.intro.button.translatedText'],
      ],
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          margin: '1.67vh 0',
        },
      },
    },
  },
  'app.onboarding.intro.button.translatedText': {
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.onboarding.intro.button.translatedText',
        fallback: 'Create Your First Vehicle Set',
      },
    },
  },
  'app.onboarding.intro.link': {
    config: {
      component: 'Link',
      content: [['TranslatedText', 'app.onboarding.intro.link.translatedText']],
    },
    schema: {
      default: {
        color: 'var(--link-color)',
        'text-decoration': 'none',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          marginTop: '1.67vh',
          marginBottom: '4.73vh',
        },
        '@media (max-width: 992px)': {
          marginBottom: '2.45vh',
        },
      },
      hover: {
        textDecoration: 'underline',
      },
    },
  },
  'app.onboarding.intro.link.default': {
    _extends: 'app.onboarding.intro.link',
    config: {
      to: '/output',
    },
  },
  'app.onboarding.intro.link.translatedText': {
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.onboarding.intro.link.translatedText',
        fallback: "I'll do this later",
      },
      schema: {
        default: {
          '@media (max-width: 992px)': {
            'font-size': '18px',
          },
        },
      },
    },
  },
};

export default ONBOARDING_INTRO;
