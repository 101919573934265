import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import get from 'lodash.get';
import zappy from '@zappy-ride/library.react.components';

export const lcfsCosts = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  const [operationsYearly, operationsStatus] = getStateValue({
    lookupKey: ['total.yearly', 'status.operations'],
    states: ['operations'],
  }) as [any, boolean];

  const lcfsCosts: Array<number> = [];

  operationsYearly?.forEach((element: any) => {
    lcfsCosts.push(Math.abs(get(element, 'incentives.lcfs', 0)) as number);
  });

  let totalCost = zappy.formatters.formatAsThousands(Math.max(...lcfsCosts));

  return {
    status: {
      isLoading: !operationsStatus,
      missingRequiredInformation: !(calculationsStatus && inputsStatus),
    },
    data: {
      lcfsCosts,
      totalCost,
    },
  };
};
