import zappy from '@zappy-ride/library.react.components';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type RenderByRouteProps = [string, string, any];
export const RenderByRoute = ({ design }: { design: string }) => {
  const { pathname } = useLocation();
  const {
    config: { routes },
  } = zappy.useDesign(design) as { config: { routes: RenderByRouteProps[] } };

  const components = useMemo(() => {
    const routesToRender = routes.filter(([route]) => pathname === route);

    return routesToRender.map(([_, design, factoryConfig]) => {
      return { design, factoryConfig };
    });
  }, [pathname, routes]);

  return components.map(({ design, factoryConfig }) => (
    <zappy.FactoryComponent
      design={design}
      data-design={design}
      {...factoryConfig}
    />
  ));
};

RenderByRoute.displayName = 'RenderByRoute';
