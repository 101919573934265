/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/no-anonymous-default-export */
export default {
  'app.output.template.routes.overview.template': {
    role: 'tabpanel',
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.overview.head',
          {
            displayQuery: 'mobile',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.overview.template.cards',
          {
            className: 'cardsContainer',
          }
        ],
        [
          'Chart',
          'app.output.template.routes.overview.template.cumulativeCostsOverTime',
        ],
        [
          'Chart',
          'app.output.template.routes.overview.template.totalCostsByType',
        ],
        [
          'ContentContainer',
          'app.output.template.routes.overview.template.savings',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.overview.template.savings.mobile',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
      watch: {
        name: ['device'],
      },
      displayQueries: {
        mobile: {
          '["watched", "device"]': {
            $eq: 'mobile',
          },
        },
        desktop: {
          '["watched", "device"]': {
            $eq: 'desktop',
          },
        },
      },
    },
  },
  'app.output.template.mobile.overview.head': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.overview.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',
      },
    },
  },
  'app.output.template.mobile.overview.head.title': {
    config: {
      fontSize: '22px',
      fontWeight: 'var(--font-weight)',
      i18n: {
        id: 'i18n.output.template.mobile.overview.head.title',
        fallback: 'Overview',
      },
    },
  },

  '##### OUTPUT OVERVIEW CHARTS #####': '',
  'app.output.template.routes.overview.template.cumulativeCostsOverTime': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
    ],
    config: {
      type: 'line',
      scaleOptions: {
        y: {
          suggestedMin: 0,
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
      },
      labelsTemplate: {
        id: 'i18n.output.template.routes.overview.template.cumulativeCostsOverTime.yearAxisLabel',
        fallback: 'Year {{index}}',
        startingValue: 1,
      },
      pick: [
        {
          source: 'cumulativeElectricCosts',
          borderColor: 'var(--chart-color-4)',
          borderWidth: 5,
          backgroundColor: 'var(--chart-color-4)',
          label: {
            id: 'i18n.output.template.routes.overview.template.cumulativeCostsOverTime.evLegendLabel',
            fallback: 'Electric Vehicles',
          },
        },
        {
          source: 'cumulativeFossilCosts',
          borderColor: 'var(--chart-color-3)',
          borderWidth: 5,
          backgroundColor: 'var(--chart-color-3)',
          label: {
            id: 'i18n.output.template.routes.overview.template.cumulativeCostsOverTime.iceLegendLabel',
            fallback: 'Fossil Fuel Vehicles',
          },
        },
      ],
    },
    components: {
      title:
        'app.output.template.routes.overview.template.cumulativeCostsOverTime.title',
      legend:
        'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend',
    },
  },
  'app.output.template.routes.overview.template.cumulativeCostsOverTime.title':
    {
      _extends: 'app.output.template.routes.tabs.collapsible.text',
      config: {
        i18n: {
          id: 'i18n.output.template.routes.overview.template.cumulativeCostsOverTime.title',
          fallback: 'Cumulative Costs Over Time',
        },
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType': {
    _extends: [
      'app.output.common.chart.responsive',
      'app.output.print.common.canvas',
      'app.output.print.common.samePageWhenPossible',
    ],
    config: {
      type: 'bar',
      maintainAspectRatio: true,
      toggleableTypes: {
        bar: {
          i18n: {
            id: 'i18n.output.template.routes.overview.template.totalCostsByType.bar',
            fallback: 'BAR CHART',
          },
        },
        waterfall: {
          i18n: {
            id: 'i18n.output.template.routes.overview.template.totalCostsByType.waterfall',
            fallback: 'WATERFALL',
          },
        },
      },
      scaleOptions: {
        y: {
          formatOpts: {
            formatter: 'formatAsDollars',
          },
        },
      },
      sourceParent: 'totalCosts',
      labelsAttr: 'label',
      summary: {
        label: 'Summary',
        backgroundColor: 'var(--chart-color-5)',
      },
      pick: [
        {
          source: 'fossil',
          label: {
            id: 'i18n.output.template.routes.overview.template.totalCostsByType.fossil.label',
            fallback: 'Fossil Fuel Vehicles',
          },
          backgroundColor: 'var(--chart-color-3)',
        },
        {
          source: 'electric',
          label: {
            id: 'i18n.output.template.routes.overview.template.totalCostsByType.electric.label',
            fallback: 'Electric Vehicles',
          },
          backgroundColor: 'var(--chart-color-4)',
        },
      ],
    },
    components: {
      title:
        'app.output.template.routes.overview.template.totalCostsByType.title',
      toggle:
        'app.output.template.routes.overview.template.totalCostsByType.toggle',
      legend:
        'app.output.template.routes.overview.template.totalCostsByType.legend',
    },
    schema: {
      default: {
        marginTop: '2em',
      },
    },
  },
  'app.output.template.routes.overview.template.totalCostsByType.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      i18n: {
        id: 'i18n.output.template.routes.overview.template.totalCostsByType.title',
        fallback: 'Total Costs',
      },
    },
  },
  'app.output.template.routes.overview.template.totalCostsByType.toggle': {
    _extends: 'app.output.print.common.notPrintable',
    components: {
      input:
        'app.output.template.routes.overview.template.totalCostsByType.toggle.input',
      label:
        'app.output.template.routes.overview.template.totalCostsByType.toggle.label',
    },
    config: {
      type: 'radio',
    },
    schema: {
      default: {
        float: 'right',
        marginBottom: '.5em',

        '@media (max-width: 992px)': {
          display: 'flex',
          marginTop: '24px',
          'justify-content': 'center',
          width: '100%',
          height: '35px',
        },
      },
    },
  },
  'app.output.template.routes.overview.template.totalCostsByType.toggle.input':
    {
      schema: {
        default: {
          display: 'none',
        },
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.toggle.label':
    {
      schema: {
        default: {
          'user-select': 'none',
          cursor: 'pointer',
          border: '1px solid #004E9A',
          padding: '.5em 2em',
          color: '#004E9A',
          'background-color': '#FFFFFF',
          '&:first-of-type': {
            'border-radius': '.7em 0 0 .7em',
          },
          '&:last-of-type': {
            'border-radius': '0 .7em .7em 0',
          },

          '@media (max-width: 992px)': {
            'font-size': 'var(--font-size-mobile)',
          },
        },
        hover: {
          'background-color': '#004E9A',
          color: '#FFFFFF',
        },
        active: {
          color: '#FFFFFF',
          'background-color': '#004E9A',
        },
      },
    },
  'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend': {
    config: {
      content: [
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend.electricVehicles',
        ],
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend.fossilVehicles',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        'justify-content': 'end',
        width: '100%',
      },
    },
  },
  'app.output.template.routes.overview.template.totalCostsByType.legend': {
    config: {
      content: [
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.totalCostsByType.legend.electricVehicles',
        ],
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.totalCostsByType.legend.fossilVehicles',
        ],
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.totalCostsByType.legend.evCheaper',
        ],
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.totalCostsByType.legend.fossilCheaper',
        ],
        [
          'ChartLegendElement',
          'app.output.template.routes.overview.template.totalCostsByType.legend.evSavings',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        'justify-content': 'end',
        width: '100%',
      },
    },
  },
  'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend.electricVehicles':
    {
      _extends: 'app.common.legendItem',
      config: {
        i18n: {
          id: 'i18n.waterfallChart.legend.electricVehicles',
          fallback: 'Electric Vehicles',
        },
        color: 'var(--chart-color-4)',
      },
    },
  'app.output.template.routes.overview.template.cumulativeCostsOverTime.legend.fossilVehicles':
    {
      _extends: 'app.common.legendItem',
      config: {
        i18n: {
          id: 'i18n.waterfallChart.legend.fossilVehicles',
          fallback: 'Fossil Fuel Vehicles',
        },
        color: 'var(--chart-color-3)',
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.legend.electricVehicles':
    {
      _extends: 'app.common.legendItem',
      config: {
        showOnly: 'bar',
        i18n: {
          id: 'i18n.waterfallChart.legend.electricVehicles',
          fallback: 'Electric Vehicles',
        },
        color: 'var(--chart-color-4)',
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.legend.fossilVehicles':
    {
      _extends: 'app.common.legendItem',
      config: {
        showOnly: 'bar',
        i18n: {
          id: 'i18n.waterfallChart.legend.fossilVehicles',
          fallback: 'Fossil Fuel Vehicles',
        },
        color: 'var(--chart-color-3)',
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.legend.evCheaper':
    {
      _extends: 'app.common.legendItem',
      config: {
        showOnly: 'waterfall',
        i18n: {
          id: 'i18n.waterfallChart.legend.evCheaper',
          fallback: 'EV is cheaper',
        },
        color: 'var(--chart-color-3)',
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.legend.fossilCheaper':
    {
      _extends: 'app.common.legendItem',
      config: {
        showOnly: 'waterfall',
        i18n: {
          id: 'i18n.waterfallChart.legend.fossilCheaper',
          fallback: 'Fossil is cheaper',
        },
        color: 'var(--chart-color-4)',
      },
    },
  'app.output.template.routes.overview.template.totalCostsByType.legend.evSavings':
    {
      _extends: 'app.common.legendItem',
      config: {
        showOnly: 'waterfall',
        i18n: {
          id: 'i18n.waterfallChart.legend.evSavings',
          fallback: 'EV savings',
        },
        color: 'var(--chart-color-5)',
      },
    },
  '##### OUTPUT OVERVIEW TO CARDS #####': '',
  'app.output.template.routes.overview.template.cards': {
    config: {
      content: [
        [
          'IconText',
          'app.output.template.routes.overview.template.card.breakeven',
          { displayQuery: 'breakeven' },
        ],
        [
          'IconText',
          'app.output.template.routes.overview.template.card.nonBreakeven',
          { displayQuery: 'nonBreakeven' },
        ],
        [
          'IconText',
          'app.output.template.routes.overview.template.card.oneBreakeven',
          { displayQuery: 'oneBreakeven' },
        ],
        [
          'IconText',
          'app.output.template.routes.overview.template.card.savings',
        ],
      ],
      watch: {
        name: ['breakevenYear'],
      },
      displayQueries: {
        breakeven: { '["watched", "breakevenYear"]': { $gt: 1 } },
        nonBreakeven: { '["watched", "breakevenYear"]': { $eq: 0 } },
        oneBreakeven: { '["watched", "breakevenYear"]': { $eq: 1 } },
      },
    },
    schema: {
      default: {
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'space-between',
        gap: '25px',

        '@media (max-width: 992px)': {
          marginTop: '32px',
          'flex-direction': 'column',
        },
      },
    },
  },
  'app.output.template.routes.overview.template.card': {
    _extends: 'app.common.iconText',
    schema: {
      default: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'justify-content': 'space-between',
        flex: 1,
        'text-align': 'center',
      },
    },
    config: {
      icon: {
        schema: {
          default: {
            'margin-bottom': '10px',
          },
        },
      },
      text: {
        schema: {
          default: {
            'margin-top': '5px',
          },
        },
      },
    },
  },
  'app.output.template.routes.overview.template.card.breakeven': {
    _extends: 'app.output.template.routes.overview.template.card',
    components: {
      icon: 'app.common.icon.lightningFilled',
    },
    config: {
      header: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.breakeven.header',
          dangerouslySetInnerHTML: true,
          fallback: '<strong>After {{breakevenYear}} years </strong>',
        },
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.breakeven.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'The cumulative cost of electric vehicles becomes cheaper than the cost of an equivalent fossil fuel fleet.',
        },
        schema: {
          default: {
            'line-height': '120%',
          },
        },
      },
    },
  },
  'app.output.template.routes.overview.template.card.nonBreakeven': {
    _extends: 'app.output.template.routes.overview.template.card.breakeven',
    config: {
      header: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.nonBreakeven.header',
          fallback: 'No Breakeven',
        },
      },
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.nonBreakeven.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'The cumulative cost of electric vehicles is not expected to potentially become cheaper than the cost of an equivalent fossil fuel fleet.',
        },
      },
    },
  },
  'app.output.template.routes.overview.template.card.oneBreakeven': {
    _extends: 'app.output.template.routes.overview.template.card.breakeven',
    config: {
      header: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.oneBreakeven.header',
          dangerouslySetInnerHTML: true,
          fallback: '<strong>After {{breakevenYear}} year </strong>',
        },
      },
    },
  },
  'app.output.template.routes.overview.template.card.savings': {
    _extends: 'app.output.template.routes.overview.template.card',
    components: {
      icon: 'app.common.icon.carFilled',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.card.savings.text',
          dangerouslySetInnerHTML: true,
          fallback:
            "You're looking at <br /><strong> ${{lifetimeSavings}} in savings</strong><br /> over the life of the vehicles.",
        },
      },
    },
  },
  '##### OUTPUT OVERVIEW TABLE (SAVINGS) #####': '',

  '#### TABLE MOBILE ####': '',
  'app.output.template.routes.overview.template.savings.TablesArray': {
    components: {
      repeatingTable:
        'app.output.template.routes.overview.template.savings.savingsSpecs',
    },
    config: {
      tablesDesign:
        'app.output.template.routes.overview.template.savings.savingsSpecs',
      targetSource: 'dataMobile',
    },
    schema: {
      default: {
        display: 'flex',
        alignItems: 'baseline',
        width: '100%',
        gap: '32px',
        'padding-bottom': '28px',
        'flex-wrap': 'wrap',
        'align-items': 'stretch',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.savingsSpecs': {
    _extends: ['app.output.common.tables', 'app.common.skeleton'],
    config: {
      targetSource: 'data',
      component: 'Table',
      rowsDesign: 'app.output.template.savings.savingsSpecs.rows',
      cellsDesign: 'app.output.template.savings.savingsSpecs.cells',
    },
    schema: {
      default: {
        fontFamily: 'Arial',
        fontSize: 'var(--font-size-mobile)',
        height: '100%',
        'line-height': '19px',
      },
    },
  },
  'app.output.template.savings.savingsSpecs.cells': {
    schema: {
      default: {
        padding: '0 10px',
        fontWeight: 'normal',
        borderTop: '1px solid var(--border-color)',
        width: '65%',
      },
    },
  },
  'app.output.template.savings.savingsSpecs.rows': {
    config: {
      cells: [
        [
          'Formatter',
          'app.output.template.savings.savingsSpecs.formatter.label',
        ],
        [
          'Formatter',
          'app.output.template.savings.savingsSpecs.formatter.values',
        ],
      ],
    },
    schema: {
      default: {
        borderTop: '1px solid var(--border-color)',
        height: '42px',

        '&:first-child': {
          border: 'none',
        },

        '&:first-child > td': {
          border: 'none',
        },
      },
    },
  },
  'app.output.template.savings.savingsSpecs.formatter.label': {
    config: {
      pick: 'rowData[prop]',
    },
    schema: {
      default: {
        fontWeight: 'bold',
      },
    },
  },
  'app.output.template.savings.savingsSpecs.formatter.values': {
    config: {
      pick: 'rowData[val]',
      autoPick: 'rowData[prop]',
      locale: 'en-US',
      fallback: 'N/A',
      autoFormats: {
        'Electric Vehicles': {
          formatter: 'formatAsDollars',
        },
        'Fossil Vehicles': {
          formatter: 'formatAsDollars',
        },
        'EV Savings': {
          formatter: 'formatAsDollars',
        },
      },
    },
  },

  'app.output.template.routes.overview.template.savings.mobile': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.overview.template.savings.title',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings.title',
              fallback: 'Total Costs',
            },
          },
        ],
        [
          'TableArray',
          'app.output.template.routes.overview.template.savings.TablesArray',
          {
            targetSource: 'dataMobile',
          },
        ],
      ],
    },
    schema: {
      default: {
        marginTop: '32px',
      },
    },
  },
  '#### END TABLE MOBILE ####': '',

  'app.output.template.routes.overview.template.savings': {
    _extends: ['app.output.print.common.samePageWhenPossible'],
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.overview.template.savings.title',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings',
              fallback: 'Electric Vehicle Fleet Savings',
            },
          },
        ],
        [
          'Table',
          'app.output.template.routes.overview.template.savings.table',
          { targetSource: 'tableContent', globalDataSource: 'globalData' },
        ],
      ],
    },
    schema: {
      default: {
        'margin-top': '50px',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.title': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      as: 'p',
    },
    schema: {
      default: {
        marginBottom: '32px',
        '@media (max-width: 992px)': {
          marginBottom: '20px',
        },
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table': {
    config: {
      component: 'Table',
      rowsDesign:
        'app.output.template.routes.overview.template.savings.table.rows',
      cellsDesign:
        'app.output.template.routes.overview.template.savings.table.cells',
      headerRows: [
        [
          'app.output.template.routes.overview.template.savings.table.headerRow',
        ],
      ],
      footerRows: [
        [
          'app.output.template.routes.overview.template.savings.table.footerRow',
        ],
      ],
      overwriteDesign: {
        '[0][0]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.cellsGasTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback: 'Fuel costs over the lifetime of the project',
                    },
                  },
                },
              },
            },
          ],
        },
        '[2][1]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.labelsTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'The cost of charger installation as defined in site location assumptions in the left column',
                    },
                  },
                },
              },
            },
          ],
        },
        '[3][1]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.labelsTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'Maintenance cost is based on based on vehicle type (e.g. sedan, cargo van, box truck…) and fuel (electricity, gasoline or diesel). Maintenance costs increase over time, as vehicles get older',
                    },
                  },
                },
              },
            },
          ],
        },
        '[4][1]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.labelsTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'Insurance cost based on the type of vehicle (e.g. sedan, cargo van, box truck), Estimated Price and state. Includes liability insurance and $500,000 comprehensive & collision insurance)',
                    },
                  },
                },
              },
            },
          ],
        },
        '[6][1]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.labelsTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback: 'Purchase cost of vehicles',
                    },
                  },
                },
              },
            },
          ],
        },
        '[7][1]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.labelsTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'Resale value of vehicles at the end of the last year of operation',
                    },
                  },
                },
              },
            },
          ],
        },
        '[8][0]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.cellsGasTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'Incentives reducing the cost of vehicle or charger purchase or charger installation costs. Detailed incentive estimates are provided in the incentives tab above',
                    },
                  },
                },
              },
            },
          ],
        },
        '[9][0]': {
          cell: [
            'IconText',
            'app.output.template.routes.overview.template.savings.table.cellsGasTooltip',
            {
              tooltip: {
                popover: {
                  translatedText: {
                    _extends:
                      'app.output.template.routes.overview.template.savings.table.tooltipPopOverText',
                    i18n: {
                      id: 'i18n.output.template.summary.lifetimeSavings.tooltip',
                      fallback:
                        'Low Carbon Fuel Standard revenues. Applies only to projects in California. Projects can receive carbon credits based on their kWh of electricity used for electric vehicles, grid intensity (CO2 emissions per kWh) and the estimated LCFS credit price',
                    },
                  },
                },
              },
            },
          ],
        },
      },
      conditionalDesign: {
        '[*]': {
          condition:
            '(function({tableData, rowIndex, rowData}){ return (rowData.label === tableData[rowIndex-1]?.label && rowData.label !== tableData[rowIndex + 1]?.label && !!rowData.subLabel) || !rowData.subLabel })',
          design:
            'app.output.template.routes.overview.template.savings.table.rowsWithSubLabel',
          default: '',
        },
      },
    },
    schema: {
      default: {
        'font-size': 'var(--font-size-mobile)',
        width: '100%',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table.rows': {
    config: {
      cells: [
        [
          'TranslatedText',
          'app.output.template.routes.overview.template.savings.table.cells.header.bold',
          {
            i18n: {
              id: '{{rowData[customLabel][i18n][id]}}',
              fallback: '{{rowData[customLabel][i18n][fallback]}}',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: '{{rowData[translatedSublabel][i18n][id]}}',
              fallback: '{{rowData[translatedSublabel][i18n][fallback]}}',
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsDollars',
            pick: 'rowData[electric]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            pick: 'rowData[fossil]',
            formatter: 'formatAsDollars',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsDollars',
            pick: 'rowData[savings]',
          },
        ],
      ],
    },
    schema: {
      default: {
        borderBottom: '1px solid transparent',
        height: '48px',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table.rowsWithSubLabel':
    {
      _extends:
        'app.output.template.routes.overview.template.savings.table.rows',
      schema: {
        default: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        },
      },
    },
  'app.output.template.routes.overview.template.savings.table.cells': {
    schema: {
      default: {},
    },
  },
  'app.output.template.routes.overview.template.savings.table.headerRow': {
    config: {
      cells: [
        [
          '',
          '',
          {},
          {
            colSpan: 2,
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings.table.headerRow.electricVehicles',
              fallback: 'Electric Vehicle(s)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.tableProvider.table.tHeader.row1.cell4app.output.template.routes.overview.template.savings.table.headerRow.fossilVehicles',
              fallback: 'Fossil Vehicle(s)',
            },
          },
        ],
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings.table.headerRow.evSavings',
              fallback: 'EV Savings',
            },
          },
        ],
      ],
    },
    schema: {
      default: {
        fontWeight: 'bold',
        borderTop: '1px solid rgba(0, 0, 0, 0.06)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        height: '48px',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table.footerRow': {
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings.table.footerRow.total',
              fallback: 'Total',
            },
          },
          {
            colSpan: 2,
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsDollars',
            pick: 'globalData[totalElectric]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsDollars',
            pick: 'globalData[totalFossil]',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.tables.cellsFormatted',
          {
            formatter: 'formatAsDollars',
            pick: 'globalData[totalSavings]',
          },
        ],
      ],
    },
    schema: {
      default: {
        fontWeight: 'bold',
        height: '48px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table.cells.header.bold':
    {
      schema: {
        default: {
          fontWeight: 'bold',
        },
      },
    },
  'app.output.template.routes.overview.template.savings.table.tooltipPopOverText':
    {
      schema: {
        default: {
          color: 'white',
        },
      },
    },
  'app.output.template.routes.overview.template.savings.table.tooltipText': {},
  'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip':
    {
      components: {
        activator:
          'app.output.template.summary.iconText.common.tooltip.activator',
        popover: 'app.output.template.summary.iconText.common.tooltip.popover',
      },
    },
  'app.output.template.routes.overview.template.savings.table.tooltipHeader': {
    components: {
      tooltip:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip',
      header: null,
      text: 'app.output.template.routes.overview.template.savings.table.tooltipText',
      icon: null,
    },
    schema: {
      default: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
      },
    },
  },
  'app.output.template.routes.overview.template.savings.table.cellsGasTooltip':
    {
      _extends:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader',
      config: {
        text: {
          _extends:
            'app.output.template.routes.overview.template.savings.table.tooltipText',
          i18n: {
            id: '{{rowData[translatedLabel][i18n][id]}}',
            fallback: '{{rowData[translatedLabel][i18n][fallback]}}',
          },
        },
      },
      schema: {
        default: {
          fontWeight: 'bold',
        },
      },
    },
  'app.output.template.routes.overview.template.savings.table.labelsTooltip': {
    _extends:
      'app.output.template.routes.overview.template.savings.table.tooltipHeader',
    config: {
      text: {
        _extends:
          'app.output.template.routes.overview.template.savings.table.tooltipText',
        i18n: {
          id: '{{rowData[translatedSublabel][i18n][id]}}',
          fallback: '{{rowData[translatedSublabel][i18n][fallback]}}',
        },
      },
    },
  },
};
