// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useContext, useMemo } from 'react';
import { getOrganizationTypesGroup } from '../../utils/organizationTypes';

export const FilterProjectOrganizationType = (props) => {
  return <ProjectOrganizationType {...props} fieldName='filters.organizationType'> 
  </ProjectOrganizationType>;
};

export const ProjectOrganizationType = ({
  design,
  fieldName = 'organizationType',
}: {
  design: string;
}) => {
  const { preloads } = useContext(zappy.PreloadsContext);
  const { config, components, schema } = zappy.useDesign(design);

  const watchedValue = zappy.useWatch({ name: fieldName });

  const organizationType = watchedValue[fieldName];

  const options = preloads?.[config?.preload];
  const { value, i18n, description } = options?.[organizationType] || {};

  const checkboxDesign = useMemo(() => {
    const matchOrganizationType = getOrganizationTypesGroup(organizationType);
    if (matchOrganizationType) {
      return `${components.checkbox}_${matchOrganizationType}`;
    }
    return null;
  }, [components.checkbox, organizationType]);

  return (
    <zappy.FactoryComponent design={design} schema={schema}>
      <zappy.FormField design={components.field} />
      {value && !config.hideSelection && (
        <>
          <zappy.TranslatedText i18n={i18n} design={components.infoValue} />
          <zappy.TranslatedText
            i18n={description}
            design={components.infoDescription}
          />
        </>
      )}
      {checkboxDesign &&
        components.checkbox && <zappy.FormField design={checkboxDesign} />}
    </zappy.FactoryComponent>
  );
};

ProjectOrganizationType.displayName = 'ProjectOrganizationType';
FilterProjectOrganizationType.displayName = 'FilterProjectOrganizationType';

