// @ts-nocheck
import { useContext, useEffect, useState } from 'react';
import { FinanceTab } from '@zappy-ride/ev-fleets';
import { Suspense } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useCalculationSelectors } from '../../../../selectors/calculations';
import { InterceptorContext } from '../../../../contexts/Interceptor';
import { cloneDeep } from 'lodash';
import { UPDATE_CALCULATIONS_INPUTS } from '../../../../contexts/Interceptor/actions';
/**
 * Suspense is required.
 */
export const OutputFinancials = () => {
  const [{ data, status }] = useCalculationSelectors('financials');
  const { getValues, setValue } = useFormContext();
  const callAction = useContext(InterceptorContext);
  const { vehicleSets } = getValues();
  const [initialized, setInitialized] = useState(false)

  const formContext = useForm()

  const handleApplyCalculations = (sets: Set[], setId: string) => {
    const vehicleSetsWithFinancials = sets.map(
      ({id, finance, financeResult}) => {
        const vehicleSet = cloneDeep(vehicleSets.find((set) => set.id === id));
        vehicleSet.finance = finance;
        vehicleSet.financeResult =financeResult;
        vehicleSet.replacementVehicle = vehicleSet.input;
        vehicleSet.existingVehicle = vehicleSet.output;
        return vehicleSet;
      }
    );

    setValue('vehicleSets', vehicleSetsWithFinancials);
    setValue('editingVehicleSetIndex', vehicleSetsWithFinancials.findIndex(v=>v.id === setId));
    callAction({ type: UPDATE_CALCULATIONS_INPUTS });
    setTimeout(()=>{  
      window.globalLoading(true)
    })
  };

  useEffect(()=>{
    if(!initialized && !status.isLoading && !status.missingRequiredInformation && vehicleSets?.length > 0){
      formContext.reset(data.componentData)
      setInitialized(true)
    }
  }, [status, vehicleSets, initialized])

  useEffect(()=>{
    formContext.setValue('lifespanYears', data.componentData.lifespanYears)
  }, [data.componentData.lifespanYears, formContext])

  return (
    <Suspense fallback={<>Loading...</>}>
      <FormProvider {...formContext}>
        {!initialized ? <>Loading...</> : 
          <FinanceTab 
            applyButtonCallback={handleApplyCalculations}
            financeFormProps={{
              financeTypes: ['cash', 'finance', 'lease']
            }} 
          />
         }
      </FormProvider>
    </Suspense>
  );
};

OutputFinancials.displayName = 'OutputFinancials';
