import { VehicleLabelFormatters } from '../types/vehicles.types';

export function prepareI18nFallback(value?: string, separator = "_") {
  if (!value) return value;
  return value
    .replaceAll(" ", separator)
    .split(separator)
    .map((text) => text.replace(/^.{1}/, text[0].toUpperCase()))
    .join(" ");
}

const acronymFormatter = (value = '') => value.toUpperCase();
const bevFormatter = (value = '') => value.replace(/bev/gi, 'BEV');

const capitalizeFormatter = (value = '') => {
  let words = value.split(' ');
  let capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
};

const chargerFormatter = (value = '') => {
  const label = capitalizeFormatter(value);
  return label.replace(/KW/gi, 'kW');
}

export const vehicleLabelFormatters: VehicleLabelFormatters = {
  acronymFormatter,
  bevFormatter,
  capitalizeFormatter,
  chargerFormatter,
};
