// @ts-nocheck
import { ThemeOptions, createTheme } from '@mui/material/styles';
import get from 'lodash.get';
import merge from 'lodash.merge';
import set from 'lodash.set';
import { unbrandedOptions } from '@zappy-ride/ev-fleets';
import { darken, lighten } from '@mui/material';

const mappingSource = [
  ['--primary-color', 'palette.primary.main'],
  ['--primary-dark-color', 'palette.primary.dark'],
  ['--second-color', 'palette.secondary.main'],
  ['--chart-color-1', 'palette.charts.color1.main'],
  ['--chart-color-2', 'palette.charts.color2.main'],
  ['--chart-color-3', 'palette.charts.color3.main'],
  ['--chart-color-4', 'palette.charts.color4.main'],
  ['--chart-color-5', 'palette.charts.color5.main'],
  ['--color-error', 'palette.error.main'],
  ['--color-info', 'palette.info.main'],
  // ['--font-family', 'typography.fontFamily'],
  // ['--font-size', 'typography.fontSize'],
];

export function createAndMapTheme(zappyTheme: any) {
  if (!zappyTheme) return {};

  const rootOptions = zappyTheme.globals[':root'];
  const defaultZappyOptions = {
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: 30,
          },
        },
      },
    },
  } satisfies ThemeOptions;
  const zappyThemeMappingToMui = mappingSource.reduce(
    (acc, [zappyKey, muiKey]) => {
      const zappyValue = get(rootOptions, zappyKey);
      if (!zappyValue) return acc;

      acc = set(acc, muiKey, zappyValue);
      return acc;
    },
    {}
  );

  // TODO: This should be done automatically in the foundation when creating new palette colors
  function componentToHex(c) {
    var hex = Number(c).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  function rgbStringToHex(rgb) {
    try {
      var a = rgb.split('(')[1].split(')')[0];
      a = a.split(',');

      return (
        '#' +
        componentToHex(a[0].trim()) +
        componentToHex(a[1].trim()) +
        componentToHex(a[2].trim())
      );
    } catch (e) {
      return rgb;
    }
  }

  const generateDarkAndLightColors = (path) => {
    const main = get(zappyThemeMappingToMui, `${path}.main`);
    return {
      light: rgbStringToHex(lighten(main, 0.3)),
      main,
      dark: rgbStringToHex(darken(main, 0.3)),
    };
  };

  const allOptions = merge(
    {},
    unbrandedOptions,
    defaultZappyOptions,
    zappyThemeMappingToMui,
    zappyTheme.mui,
    {
      palette: {
        charts: {
          color3: generateDarkAndLightColors('palette.charts.color3'),
          color4: generateDarkAndLightColors('palette.charts.color4'),
        },
      },
    }
  );
  return createTheme(allOptions);
}
