// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';

export const CatalogLoadingState = ({
  listItem,
  limit = 1,
  shouldBeVisible = false,
}) => {
  return !shouldBeVisible
    ? null
    : Array.from({ length: limit }).map((_, index) => (
        <zappy.FactoryComponent
          key={`skeleton-${index}`}
          schema={listItem.schema}
        >
          <zappy.FactoryContent config={listItem.config} isLoading />
        </zappy.FactoryComponent>
      ));
};

CatalogLoadingState.displayName = 'CatalogLoadingState';
