import { Buffer } from 'buffer';
import pako from 'pako';
import { v4 as uuidv4 } from 'uuid';
import { fetchCurrency } from './currency';

export const saveToLocalStorage = (value: any, key: string) => {
  try {
    const compressedData = compressData(value);
    localStorage.setItem(key, compressedData);
    return compressedData;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const getDecompressedLocalData = (keyOrData: string) => {
  return decompressData(localStorage.getItem(keyOrData) || keyOrData || '');
};

export const removeFromLocalStorage = (key: string) => {
  if (!key) return;
  localStorage.removeItem(key);
};

export const createLink = (data: any) => {
  return `${window.location.origin}?sharedProject=${data}`;
};

export const decompressData = (compressedData: string) => {
  try {
    const compressed = decodeURIComponent(compressedData.trim());
    const uncompressed =
      pako.inflate(Buffer.from(compressed, 'base64'), { to: 'string' }) || '';
    return uncompressed ? JSON.parse(uncompressed) : null;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

export const compressData = (uncompressedData: any) => {
  const dataAsString = JSON.stringify(uncompressedData);
  const compressed = pako.deflate(dataAsString, { level: 9 });
  const base64 = Buffer.from(compressed).toString('base64');
  return encodeURIComponent(base64);
};

export const getSessionId = () => {
  let sessionId = localStorage.getItem('session-id');

  if (!sessionId) {
    const newSessionId = uuidv4();

    localStorage.setItem('session-id', newSessionId);
    sessionId = newSessionId;
  }

  return sessionId;
};

export const loadAndStoreCurrencyConversion = async (currencyConversion?: string) => {
  const value = !currencyConversion
   ? 1
   : await fetchCurrency(currencyConversion);

  localStorage.setItem('currency-conversion-value', String(value));
};

export const getCurrencyConversionValue = () => {
  let value = localStorage.getItem('currency-conversion-value');

  if (!value) {
    return 1;
  }

  return parseFloat(value);
};

export const getLocalLang = () => {
  return localStorage.getItem('local-lang');
}

export const setLocalLang = (lang: string) => {
  localStorage.setItem('local-lang', lang);
}
