// @ts-nocheck
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import isEqual from "lodash.isequal";

export const useResetFiltersForCatalogs = (catalogName = '') => {
  const { setValue, getValues } = useFormContext();
  const [originalFilters, setOriginalFilters] = useState({});

  /**
   * Reset filters to original values
   */
  const saveFilters = useCallback(() => {
    setOriginalFilters({
      ...getValues(`catalogs.${catalogName}`)
    });
  }, [ getValues]);

  const resetOriginalValues = useCallback(() => {
    setValue(`catalogs.${catalogName}`, { ...originalFilters });
  }, [originalFilters, setValue]);

  // Used to wait for the filters to be updated
  const fieldValidator = useCallback(async (field, expectedValue, maxAttempts = 20, defaultTimeout = 5) => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(() => {
        setValue(`catalogs.vehicles.${field}`, expectedValue);
        if (attempts >= maxAttempts) {
          clearInterval(interval);
          reject();
        }
        if (isEqual(getValues(`catalogs.${catalogName}.${field}`), expectedValue)) {
          clearInterval(interval);
          setTimeout(() => {
            resolve();
          }, defaultTimeout * 10);
        }
        attempts++;
      }, defaultTimeout);
    });
  }, [ originalFilters]);

  return {
    saveFilters,
    resetOriginalValues,
    fieldValidator,
    originalFilters,
  };
}
