// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { useCalculationSelectors } from "../../selectors/calculations";
import isEmpty from "lodash.isempty";
import {
  API_REQUEST_SET_PROJECT,
  FORM_ADD_VEHICLE_SET
} from "../../contexts/Interceptor/actions";
import { AppStateContext } from "../../contexts/AppState";
import { useFormContext } from "react-hook-form";
import { InterceptorContext } from "../../contexts/Interceptor";
import { useHasTabs } from '../../hooks/useHasTabs';
import { Paper } from '@mui/material';

type PrintTabsProps = {
  design: string;
}

const PrintingTab = ({ design }: { design: string }) => {
  const {config} = zappy.useDesign(design);
  const [component, designForComponent] = config.content[0];
  return <zappy.FactoryComponent design={designForComponent} component={component}/>;
}

export const PrintTabs = ({ design }: PrintTabsProps) => {
  const { schema, config, components } = zappy.useDesign(design);
  const { config: printableRoutesConfig } = zappy.useDesign(config.printableRoutes);
  const { summary, filters, container, tab: tabElements } = components;
  const hasTab = useHasTabs();
  const { routes } = printableRoutesConfig
  const printTriggered = useRef(false);

  const printableRoutes = routes.filter((route) => hasTab(route.path))

  const callAction = useContext(InterceptorContext);
  const { getValues, setValue } = useFormContext();
  const {
    appState: {
      isReady,
      isLoadedFromLocalStorage,
      isOutputPreloadsReady,
      isProjectReady,
    },
  } = useContext(AppStateContext);

  const [{ data, status }] = useCalculationSelectors('summary');
  const setProject = useRef(false);

  useLayoutEffect(() => {
    setValue('filters.zipcode', getValues('zipcode'));
    if (isProjectReady && isReady && isOutputPreloadsReady) return;

    window.globalLoading(true);
  }, []);

  useEffect(() => {
    if (
      isProjectReady ||
      !isReady ||
      setProject.current ||
      !isOutputPreloadsReady
    )
      return;

    if (isEmpty(getValues('vehicleSets')) && !isLoadedFromLocalStorage) {
      callAction({ type: FORM_ADD_VEHICLE_SET });
    }

    setProject.current = true;
    callAction({ type: API_REQUEST_SET_PROJECT });
  }, [
    isReady,
    isLoadedFromLocalStorage,
    isOutputPreloadsReady,
    isProjectReady,
  ]);

  useEffect(()=>{
    if (status?.isLoading) return;
    //expand all MUI accordions that is closed
    document.querySelector('#root > div').classList.add('print');
    document.querySelector('meta[name="viewport"]').content = 'width=993, initial-scale=1';
    document.querySelectorAll('.MuiAccordionSummary-expandIconWrapper').forEach(e=>{
      if(!e.className.includes('Mui-expanded')){
        e.dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        }));
      }
    })
    if (printTriggered.current || status.isLoading) {
      return;
    }
    printTriggered.current = true;
    // Allow time for the page to render before printing
    const to = setTimeout(() => {
      window.print();
      clearTimeout(to);
    }, 2500)
  }, [status, printTriggered])

  const calculationsStatus =
    status.isLoading || status.missingRequiredInformation || !status.isPending;
  if (isEmpty(data) || status?.isLoading) return false;


  return (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <Paper>
        <zappy.ContentContainer
          design={summary}
          values={{ ...data, isLoading: calculationsStatus }}
        />
      </Paper>

      <zappy.FactoryComponent design={container}>
        <zappy.ContentContainer design={filters} />
        {printableRoutes.map((route) => {
          return (
            <zappy.FactoryComponent design={tabElements} key={route.design}>
              <PrintingTab design={route.design} />
            </zappy.FactoryComponent>
          )
        })}
      </zappy.FactoryComponent>
    </zappy.FactoryComponent>
  )
};

PrintTabs.displayName = 'PrintTabs';