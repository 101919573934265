import {
  CalculationStatus,
  IncentivesInfo,
  CalculationResultsInfo,
  FuelInfo,
  ChargersInfo,
  OperationsInfo,
  VehiclesInfo,
} from '@zappy-ride/library.calculations';
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import zappy from '@zappy-ride/library.react.components';
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import get from 'lodash.get';
import { totalSavingMobileTable } from '../../../presenters/vehicles';
import { useFormContext } from 'react-hook-form';
import { useHasTabs } from '../../../hooks/useHasTabs';
import { useTranslation } from '@zappy-ride/library.react.components/dist/abstractions/hooks/useTranslation';

interface TranslateProps {
  id: string;
  values?: { [key: string]: unknown };
  fallback?: string;
}

type translateType = (translate: TranslateProps) => string;

export const getCosts = (
  source: any,
  isLastYear: boolean,
  includeIncentives?: boolean,
  hasLcfsTab?: boolean,
  translate?: translateType
  ) => {
  const tr = translate || (({ fallback }) => fallback);
  return {
    fuel: {
      label: tr({
        id: 'i18n.app.output.template.routes.overview.chart.label.fuel',
        fallback: 'Fuel',
      }),
      electric: get(source, 'fuel.cost.electric.total'),
      fossil: get(source, 'fuel.cost.fossil.total'),
    },
    chargers: {
      label: tr({
        id: 'i18n.app.output.template.routes.overview.chart.label.infrastructure',
        fallback: 'Infrastructure',
      }),
      electric: get(source, 'chargers.total', 0),
      fossil: 0,
    },
    ...(includeIncentives && {
      incentives: {
        label: tr({
          id: 'i18n.app.output.template.routes.overview.chart.label.incentives',
          fallback: 'Incentives',
        }),
        electric: get(source, !hasLcfsTab ? 'incentives.others' : 'incentives.total', 0),
        fossil: 0,
      },
    }),
    vehicles: {
      label: tr({
        id: 'i18n.app.output.template.routes.overview.chart.label.vehicles',
        fallback: 'Vehicles',
      }),
      electric:
        get(source, 'vehicles.cost.electric.purchase', 0) +
        (isLastYear ? get(source, 'vehicles.cost.electric.resale', 0) : 0),
      fossil:
        get(source, 'vehicles.cost.fossil.purchase', 0) +
        (isLastYear ? get(source, 'vehicles.cost.fossil.resale', 0) : 0),
    },
    operations: {
      label: tr({
        id: 'i18n.app.output.template.routes.overview.chart.label.operations',
        fallback: 'Operations',
      }),
      electric: get(source, 'operations.cost.electric.total'),
      fossil: get(source, 'operations.cost.fossil.total'),
    },
  };
};

export const getCostsMap = (incentivesType: string, hasLcfsTab: boolean, translate?: translateType) => {
  const includeIncentives = incentivesType === 'evaluators';
  return (ele: any, idx: number, array: CalculationResultsInfo[]) => getCosts(
    ele, idx === array?.length - 1, includeIncentives, hasLcfsTab, translate
  );
}

export const overview = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const cumulative = getStateValue({
    lookupKey: 'total.cumulative',
    states: 'all',
  }) as CalculationResultsInfo[];

  const status = getStateValue({
    lookupKey: 'status',
  }) as CalculationStatus;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasTab = useHasTabs();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { translate } = useTranslation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setValue, getValues } = useFormContext();
  const { assumptionsData } = getValues();
  const getCosts = getCostsMap(assumptionsData.incentivesType, hasTab('lcfs'), translate);

  // is loading till all status came as true
  const isLoading =
    Object.keys(status)
      .filter((key) => key !== 'calculations' && key !== 'inputs')
      .filter((key) => !status[key as keyof CalculationStatus].isReady).length >
    0;

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation =
    Object.keys(status)
      .filter((key) => key === 'calculations' || key === 'inputs')
      .filter((key) => !status[key as keyof CalculationStatus].isReady).length >
    0;

  const cumulativeCosts = cumulative?.map(getCosts);

  const cumulativeFossilCosts = cumulativeCosts?.map((ele) =>
  sum(Object.keys(ele).map((key) => ele[key as keyof typeof ele]?.fossil) || 0)
  );

  const cumulativeElectricCosts = cumulativeCosts?.map((ele) =>
    sum(Object.keys(ele).map((key) => ele[key as keyof typeof ele]?.electric) || 0)
  );

  const totalCosts = cumulativeCosts?.at(-1);

  const breakevenYear =
    cumulativeFossilCosts?.findIndex(
      (ele, idx) => ele >= cumulativeElectricCosts[idx]
    ) + 1;

  const lifetimeSavings = zappy.formatters.formatAsThousands(
    sum([
      cumulativeFossilCosts?.at(-1),
      -(cumulativeElectricCosts?.at(-1) || 0),
    ])
  );

  const overviewTableSelector = overviewTable(getStateValue, assumptionsData.incentivesType)?.data || {};
  setValue('breakevenYear', breakevenYear);

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      cumulativeFossilCosts,
      cumulativeElectricCosts,
      totalCosts: totalCosts || {},
      breakevenYear,
      lifetimeSavings,
      tableContent: overviewTableSelector.content || [],
      globalData: overviewTableSelector.globalData || {},
      dataMobile: totalSavingMobileTable(overviewTableSelector),
    },
  };
};

export const overviewTable = (
  getStateValue: getStateValueType,
  incentivesType = 'evaluators'
): getStateValueTypeResults => {
  const status = getStateValue({
    lookupKey: 'status',
  }) as CalculationStatus;

  // have to get from cumulative cause have no annual
  const [fuelInfo, chargersInfo, operationInfo, vehiclesInfo, incentivesInfo = {}] =
    getStateValue({
      lookupKey: [
        'fuel',
        'chargers',
        'operations',
        'vehicles',
        ...(incentivesType === 'evaluators' ? ['incentives'] : [])
      ],
      path: 'total.cumulative[-1]',
    }) as [
      FuelInfo,
      ChargersInfo,
      OperationsInfo,
      VehiclesInfo,
      IncentivesInfo
    ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasTab = useHasTabs();

  const { vehicleSets, assumptionsData, filters } = getValues();

  // is loading till all status came as true
  const isLoading =
    Object.keys(status)
      .filter((key) => key !== 'calculations' && key !== 'inputs')
      .filter((key) => !status[key as keyof CalculationStatus].isReady).length >
    0;

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation =
    Object.keys(status)
      .filter((key) => key === 'calculations' || key === 'inputs')
      .filter((key) => !status[key as keyof CalculationStatus].isReady).length >
    0;

  const registrationFees = () => {
    if (isEmpty(vehicleSets)) return 0;

    return (
      vehicleSets.reduce((total: number, el: any) => {
        return (
          total +
          assumptionsData.lifespanYears *
            el.finance?.annualFees *
            el.vehicleCount
        );
      }, 0) || 0
    );
  };

  const { hideInsuranceUI } = assumptionsData;

  const content = [
    {
      label: 'Fuel',
      translatedLabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.fuel',
          fallback: 'Fuel',
        },
      },
      translatedSublabel: {
        i18n: {
          id: '',
          fallback: '',
        },
      },
      electric: get(fuelInfo, 'cost.electric.total', ''),
      fossil: get(fuelInfo, 'cost.fossil.total', ''),
      savings: get(fuelInfo, 'savings.total', ''),
    },
    {
      label: 'Infrastructure',
      translatedLabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.infrastructure',
          fallback: 'Infrastructure',
        },
      },
      subLabel: 'Chargers',
      translatedSublabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.chargers',
          fallback: 'Chargers',
        },
      },
      electric: get(chargersInfo, 'purchase', ''),
      fossil: null,
    },
    {
      label: 'Infrastructure',
      translatedLabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.infrastructure',
          fallback: 'Infrastructure',
        },
      },
      subLabel: 'Installation',
      translatedSublabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.installation',
          fallback: 'Installation',
        },
      },
      electric: get(chargersInfo, 'installation', ''),
      fossil: null,
    },
    {
      label: 'Operations',
      translatedLabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.operations',
          fallback: 'Operations',
        },
      },
      subLabel: assumptionsData?.incentivesType === 'evaluators' ? 'Maintenance' : '',
      translatedSublabel: {
        i18n: {
          id: 'i18n.app.output.template.routes.overview.template.savings.table.label.maintenance',
          fallback: 'Maintenance',
        },
      },
      electric: get(operationInfo, 'cost.electric.maintenance', ''),
      fossil: get(operationInfo, 'cost.fossil.maintenance', ''),
    },
  ]
    .concat(
      hideInsuranceUI || assumptionsData?.incentivesType !== 'evaluators'
        ? []
        : {
            label: 'Operations',
            translatedLabel: {
              i18n: {
                id: 'i18n.app.output.template.routes.overview.template.savings.table.label.operations',
                fallback: 'Operations',
              },
            },
            subLabel: 'Insurance',
            translatedSublabel: {
              i18n: {
                id: 'i18n.app.output.template.routes.overview.template.savings.table.label.insurance',
                fallback: 'Insurance',
              },
            },
            electric: get(operationInfo, 'cost.electric.insurance', ''),
            fossil: get(operationInfo, 'cost.fossil.insurance', ''),
          }
    )
    .concat(
      hasTab('financing')
        ? {
            label: 'Operations',
            translatedLabel: {
              i18n: {
                id: 'i18n.app.output.template.routes.overview.template.savings.table.label.operations',
                fallback: 'Operations',
              },
            },
            subLabel: 'Registration Fees',
            translatedSublabel: {
              i18n: {
                id: 'i18n.app.output.template.routes.overview.template.savings.table.label.registrationFees',
                fallback: 'Registration Fees',
              },
            },
            electric: registrationFees(),
            fossil: registrationFees(),
          }
        : []
    )
    .concat([
      {
        label: 'Vehicles',
        translatedLabel: {
          i18n: {
            id: 'i18n.app.output.template.routes.overview.template.savings.table.label.vehicles',
            fallback: 'Vehicles',
          },
        },
        subLabel: 'Purchase',
        translatedSublabel: {
          i18n: {
            id: 'i18n.app.output.template.routes.overview.template.savings.table.label.purchase',
            fallback: 'Purchase',
          },
        },
        electric: get(vehiclesInfo, 'cost.electric.purchase', 0),
        fossil: get(vehiclesInfo, 'cost.fossil.purchase', 0),
      },
      {
        label: 'Vehicles',
        translatedLabel: {
          i18n: {
            id: 'i18n.app.output.template.routes.overview.template.savings.table.label.vehicles',
            fallback: 'Vehicles',
          },
        },
        subLabel: 'Resale',
        translatedSublabel: {
          i18n: {
            id: 'i18n.app.output.template.routes.overview.template.savings.table.label.resale',
            fallback: 'Resale',
          },
        },
        electric: get(vehiclesInfo, 'cost.electric.resale', 0),
        fossil: get(vehiclesInfo, 'cost.fossil.resale', 0),
      },
    ])
    .concat(
      assumptionsData?.incentivesType === 'evaluators'
        ? {
          label: 'Incentives',
          translatedLabel: {
            i18n: {
              id: 'i18n.app.output.template.routes.overview.template.savings.table.label.incentives',
              fallback: 'Incentives',
            },
          },
          subLabel: '',
          translatedSublabel: {
            i18n: {
              id: '',
              fallback: '',
            },
          },
          electric: get(incentivesInfo, 'others', 0),
          fossil: null,
        }
        : []
    )
    .concat(
      hasTab('lcfs')
        ? {
            label: 'LCFS',
            translatedLabel: {
              i18n: {
                id: 'i18n.app.output.template.routes.overview.template.savings.table.label.lcfs',
                fallback: 'LCFS',
              },
            },
            subLabel: '',
            translatedSublabel: {
              i18n: {
                id: '',
                fallback: '',
              },
            },
            electric: get(incentivesInfo, 'lcfs', 0),
            fossil: null,
          }
        : []
    )
    .map((obj, index, array) => {
      return {
        ...obj,
        customLabel:
          array[index - 1]?.label === obj.label
            ? {
                i18n: {
                  id: '',
                  fallback: '',
                },
              }
            : obj.translatedLabel,
        savings:
          obj.savings ||
          (((obj.fossil as number) || 0) as number) -
            ((obj.electric as number) || 0),
      };
    });

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      content,
      globalData: content.reduce(
        (acc, el) => {
          acc.totalFossil += (el['fossil'] as number) || 0;
          acc.totalElectric += (el['electric'] as number) || 0;
          acc.totalSavings += (el['savings'] as number) || 0;
          return acc;
        },
        {
          totalFossil: 0,
          totalElectric: 0,
          totalSavings: 0,
        }
      ),
    },
  };
};
