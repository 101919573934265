import {
  PROJECT_API_URL,
  CONSUMER_API,
  CONSUMER_API_KEY,
} from '../config';
import { Project } from '../types/project.types';
import { getSessionId } from '../utils/localStorageUtils';

export function fetchProject(projectPayload: Project) {
  return fetch(PROJECT_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ project: projectPayload }),
  }).then(
    (res) => {
      if (!res.ok) {
        console.error("Something went wrong fetching project");
        return false;
      }
      return res.json();
    },
    (e) => {
      console.error(`Something went wrong fetching project ${e}`);
      return false;
    }
  );
}

export function sendConsumerData(
  localstorageData: any = {},
  additionalData = {}
) {
  const customer = window.location.hostname;

  const sessionId = getSessionId();
  const payload = {
    ...localstorageData,
    ...additionalData,
    input_id: sessionId,
    integration_id: 'EV_FLEETS',
    customer: customer,
  };

  return fetch(CONSUMER_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': CONSUMER_API_KEY,
    },
    body: JSON.stringify(payload),
  }).then(
    (res) => {
      if (!res.ok) {
        console.error('Something went wrong sending consumer data');
      }
    },
    (e) => {
      console.error(`Something went wrong sending consumer data ${e}`);
    }
  );
}
