const VEHICLE_SETS = {
  'app.output.vehicleSets': {
    _extends: 'app.output.common.collapsible',
    config: {
      open: true,
    },
    components: {
      headerText: 'app.output.vehicleSets.headerText',
      content: 'app.output.vehicleSets.content',
    },
  },
  'app.output.vehicleSets.content.dynamic': {
    config: {
      content: [
        ['ContentContainer', 'app.output.vehicleSets.content.dynamic.header'],
        ['', 'app.common.divider.horizontal'],
        ['ContentContainer', 'app.output.vehicleSets.content.dynamic.details'],
      ],
    },
    schema: {
      default: {
        border: '1px solid var(--color-gray-500)',
        borderRadius: '10px',
        padding: '15px',
        marginBottom: '1rem',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header': {
    config: {
      content: [
        ['Formatter', 'app.output.vehicleSets.content.dynamic.header.image'],
        [
          'ContentContainer',
          'app.output.vehicleSets.content.dynamic.header.description',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'auto 1fr',
        'grid-column-gap': '1em',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header.image': {
    config: {
      component: 'Logo',
      mapper: {
        'input.image_url': 'src',
        'input.name': 'alt',
      },
      props: {
        src: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
    schema: {
      default: {
        'max-width': '100px',
        'justify-self': 'center',
        'align-self': 'center',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header.description': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.header.setName',
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.header.countAndName',
        ],
        [
          'ActionComponent',
          'app.output.vehicleSets.content.dynamic.header.editButton',
        ],
      ],
    },
  },
  'app.output.vehicleSets.content.dynamic.header.setName': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.header.setName',
        fallback: '{{vehicleSetName}}',
      },
    },
    schema: {
      default: {
        fontWeight: 600,
        marginBottom: 0,
        marginTop: 0,
        lineHeight: '1.2em',
        display: 'block',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header.countAndName': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.header.countAndName',
        fallback: '{{vehicleCount}} {{input.name}}',
      },
    },
    schema: {
      default: {
        marginBottom: 0,
        lineHeight: '1.2em',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header.editButton.action': {
    config: {
      component: 'Button',
      eventName: 'onClick',
      actions: [
        {
          type: 'form/editVehicleSet',
        },
      ],
    },
  },
  'app.output.vehicleSets.content.dynamic.header.editButton': {
    _extends: [
      'app.output.vehicleSets.content.dynamic.header.editButton.action',
      'app.common.button',
      'app.common.button.secondary',
    ],
    config: {
      content: [
        [
          'Icon',
          'app.output.vehicleSets.content.dynamic.header.editButton.icon',
        ],
      ],
    },
    schema: {
      default: {
        float: 'right',
        width: 'auto',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.header.editButton.icon': {
    _extends: 'app.common.icon.secondary',
    config: {
      src: 'pencil',
      size: 'md',
    },
    schema: {
      default: {
        height: '18px',
        width: '18px',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.dailyMileage.label',
          { displayQuery: 'onRoad' },
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.dailyMileage.value',
          { displayQuery: 'onRoad' },
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.dailyUsageHours.label',
          { displayQuery: 'offRoad' },
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.dailyUsageHours.value',
          { displayQuery: 'offRoad' },
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.daysOperating.label',
        ],
        [
          'i18nFormatter',
          'app.output.vehicleSets.content.dynamic.details.daysOperating.value',
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.content.dynamic.details.DCFC.label',
        ],
        [
          'ChargingWindowFormatter',
          'app.output.vehicleSets.content.dynamic.details.DCFC.value',
        ],
      ],
      watch: {
        name: ['input.is_offroad'],
      },
      displayQueries: {
        onRoad: { '["watched", "input.is_offroad"]': { $neq: true } },
        offRoad: {
          '["watched", "input.is_offroad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr 1fr',
        'grid-template-rows': '1fr 1fr 1fr',
        gap: '10px 10px',

        '@media (max-width: 992px)': {
          'grid-template-rows': 'inherit',
        },
      },
    },
  },

  'app.output.vehicleSets.content.dynamic.details.mobile': {
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },

  'app.output.vehicleSets.content.dynamic.details.type.label': {
    _extends: 'app.output.vehicleSets.content.dynamic.details.mobile',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.type.label',
        fallback: 'Type',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.type.value': {
    _extends: 'app.output.common.vehicleSets.content.dynamic.details.value',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.type.value',
        fallback: '{{input.subtype}}',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.dailyMileage.label': {
    _extends: 'app.output.vehicleSets.content.dynamic.details.mobile',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.dailyMileage.label',
        fallback: 'Daily Mileage',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.dailyMileage.value': {
    _extends: 'app.output.common.vehicleSets.content.dynamic.details.value',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.dailyMileage.value',
        fallback: '{{avgDistancePerDay}}',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.dailyUsageHours.label': {
    _extends: 'app.output.vehicleSets.content.dynamic.details.mobile',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.dailyUsageHours.label',
        fallback: 'Daily Usage Hours',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.dailyUsageHours.value': {
    _extends: 'app.output.common.vehicleSets.content.dynamic.details.value',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.dailyUsageHours.value',
        fallback: '{{hoursPerWorkdayMedium}}',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.daysOperating.label': {
    _extends: 'app.output.vehicleSets.content.dynamic.details.mobile',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.daysOperating.label',
        fallback: 'Days Operating',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.daysOperating.value': {
    _extends: [
      'app.output.common.vehicleSets.content.dynamic.details.value',
      'app.common.formatter.auto.value'
    ],
    config: {
      as: 'p',
      pick: 'workdays',
      text: '{{picked}}',
      converter: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
      },
      formatter: 'interpolate',
    },
  },
  'app.output.vehicleSets.content.dynamic.details.DCFC.label': {
    _extends: 'app.output.vehicleSets.content.dynamic.details.mobile',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.dynamic.details.DCFC.label',
        fallback: 'Charging Window(s)',
      },
    },
  },
  'app.output.vehicleSets.content.dynamic.details.DCFC.value': {
    _extends: 'app.output.common.vehicleSets.content.dynamic.details.value',
    config: {
      as: 'p',
      pick: 'chargingWindows',
      text: '{{picked}}',
      converter: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
      },
      formatter: 'interpolate',
      multipleWindowsText: {
        i18n: {
          id: 'i18n.output.vehicleSets.content.dynamic.details.DCFC.value',
          fallback: 'Multiple time slots',
        },
      },
    },
  },
  'app.output.vehicleSets.content.addButton': {
    _extends: [
      'app.common.button.secondary',
      'app.common.button.small',
      'app.output.vehicleSets.content.dynamic.header.editButton.action',
    ],
    config: {
      title: 'Add New Vehicle Set',
      content: [
        ['TranslatedText', 'app.output.vehicleSets.content.addButton.text'],
      ],
    },
    schema: {
      default: {
        width: '100%',
        display: 'block',
        'text-align': 'center',
        'margin-bottom': '1em',
      },
    },
  },
  'app.output.vehicleSets.content.addButton.text': {
    _extends: 'app.common.text.secondary',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.addButton.text',
        fallback: '+ ADD NEW VEHICLE SET',
      },
    },
  },
  'app.output.vehicleSets.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.overview.filters.vehicleSet.headerText',
        fallback: 'Vehicle Sets',
      },
    },
  },
  'app.output.vehicleSets.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        ['FormVehicleSets', 'app.output.vehicleSets.content.dynamic'],
        ['ActionComponent', 'app.output.vehicleSets.content.addButton'],
      ],
    },
  },
  'app.output.vehicleSets.edit': {
    config: {
      as: 'main',
      content: [
        ['ContentContainer', 'app.output.vehicleSets.edit.header'],
        ['Collapsible', 'app.output.vehicleSets.edit.collapsible.vehicleSet'],
        ['Collapsible', 'app.output.vehicleSets.edit.collapsible.vehicleUsage'],
        [
          'Collapsible',
          'app.output.vehicleSets.edit.collapsible.chargingBehavior',
        ],
        ['', 'app.output.vehicleSets.edit.separator'],
        ['ContentContainer', 'app.output.vehicleSets.edit.footer'],
      ],
      scrollTop: false,
    },
    schema: {
      default: {
        width: '80vw',
        margin: '0 auto',
        'margin-bottom': '2em',
      },
    },
  },
  'app.output.vehicleSets.edit.separator': {
    _extends: 'app.common.divider.horizontal',
    schema: {
      default: {
        marginTop: 0,
      },
    },
  },
  'app.output.vehicleSets.edit.header': {
    config: {
      content: [
        ['ContentContainer', 'app.output.vehicleSets.edit.header.backButton'],
        ['TranslatedText', 'app.output.vehicleSets.edit.header.title'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        padding: '2em 0',
      },
    },
  },
  'app.output.vehicleSets.edit.header.backButton': {
    config: {
      to: '/output',
      role: 'button',
      component: 'Link',
      content: [
        ['IconText', 'app.output.vehicleSets.edit.header.backButton.iconText'],
      ],
    },
    schema: {
      default: {
        'align-self': 'center',
      },
    },
  },
  'app.output.vehicleSets.edit.header.backButton.iconText': {
    _extends: 'app.onboarding.intro.iconText.common',
    components: {
      icon: 'app.output.vehicleSets.edit.header.backButton.iconText.icon',
      text: 'app.output.vehicleSets.edit.header.backButton.iconText.translatedText',
    },
    schema: {
      default: {
        display: 'flex',
      },
    },
  },
  'app.output.vehicleSets.edit.header.backButton.iconText.icon': {
    _extends: 'app.onboarding.intro.iconText.common.icon',
    config: {
      src: 'caret-down',
      size: 'md',
    },
    schema: {
      default: {
        backgroundColor: 'var(--color-info)',
        width: '18px',
        height: '22px',
        transform: 'rotate(90deg)',
      },
    },
  },
  'app.output.vehicleSets.edit.header.backButton.iconText.translatedText': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.header.backButton.iconText.translatedText',
        fallback: 'BACK',
      },
    },
    schema: {
      default: {
        color: 'var(--color-info)',
        'font-size': '1rem',
        'font-weight': 400,
        'margin-left': '0.5em',
      },
    },
  },
  'app.output.vehicleSets.edit.header.title': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.header.title',
        fallback: 'Edit Vehicle Set',
      },
    },
    schema: {
      default: {
        width: '100%',
        textAlign: 'center',
        fontSize: '2rem',
      },
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleSet': {
    _extends: 'app.output.common.collapsible',
    config: {
      open: true,
    },
    components: {
      headerText:
        'app.output.vehicleSets.edit.collapsible.vehicleSet.headerText',
      content: 'app.output.vehicleSets.edit.collapsible.vehicleSet.content',
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleSet.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.collapsible.vehicleSet.headerText',
        fallback: 'Vehicle Sets',
      },
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleSet.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.vehicleSet',
        ],
        [
          'TranslatedText',
          'app.onboarding.selection.form.sections.routes.header.text',
        ],
        [
          'ContentContainer',
          'app.onboarding.selection.form.sections.evModel.editVehicleSet',
        ],
        ['ContentContainer', 'app.onboarding.selection.form.sections.stats'],

        ['ContentContainer', 'app.onboarding.selection.form.sections.edition'],
      ],
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleUsage': {
    _extends: 'app.output.common.collapsible',
    components: {
      headerText:
        'app.output.vehicleSets.edit.collapsible.vehicleUsage.headerText',
      content: 'app.output.vehicleSets.edit.collapsible.vehicleUsage.content',
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleUsage.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.collapsible.vehicleUsage.headerText',
        fallback: 'Vehicle Usage',
      },
    },
  },
  'app.output.vehicleSets.edit.collapsible.vehicleUsage.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        [
          'ContentContainer',
          'app.onboarding.usage.form.fields.milesPerWorkday&personalMilesPaidFor',
        ],
        [
          'FormField',
          'app.onboarding.usage.form.fields.personalMilesPerWorkday',
        ],
        ['', 'app.onboarding.usage.form.fields.divider'],
        ['FormVehicleInOperation', 'app.onboarding.usage.form.fields.workdays'],
        [
          'FormVehicleInOperation',
          'app.onboarding.usage.form.fields.workmonths',
        ],
        ['FormAlert', 'app.onboarding.usage.form.fields.formAlert'],
      ],
    },
  },
  'app.output.vehicleSets.edit.collapsible.chargingBehavior': {
    _extends: 'app.output.common.collapsible',
    components: {
      headerText:
        'app.output.vehicleSets.edit.collapsible.chargingBehavior.headerText',
      content:
        'app.output.vehicleSets.edit.collapsible.chargingBehavior.content',
    },
  },
  'app.output.vehicleSets.edit.collapsible.chargingBehavior.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.collapsible.chargingBehavior.headerText',
        fallback: 'Charging Behavior',
      },
    },
  },
  'app.output.vehicleSets.edit.collapsible.chargingBehavior.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        [
          'ChargingWindow',
          'app.onboarding.charging.form.fields.chargingWindow',
        ],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteInfo'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteWarning'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteAlert'],
      ],
    },
  },
  'app.output.vehicleSets.edit.footer': {
    config: {
      content: [
        [
          'ActionComponent',
          'app.output.vehicleSets.edit.footer.deleteButton',
          {
            displayQuery: 'isUpdating',
          },
        ],
        ['ActionComponent', 'app.output.vehicleSets.edit.footer.cancelButton'],
        ['ActionComponent', 'app.output.vehicleSets.edit.footer.updateButton'],
      ],
      watch: 'editingVehicleSetIndex',
      displayQueries: {
        isUpdating: {
          '["watched", "editingVehicleSetIndex"]': {
            $neq: null,
          },
        },
      },
    },
    schema: {
      default: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
      },
    },
  },
  'app.output.vehicleSets.edit.footer.deleteButton': {
    components: {
      button: 'app.output.vehicleSets.edit.footer.deleteButton.iconText',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/removeVehicleSet',
        },
      ],
      component: 'SmartNextButton',
      disabled:
        '(function({ vehicleSets }) { return vehicleSets.length <= 1; })',
    },
  },
  'app.output.vehicleSets.edit.footer.cancelButton': {
    _extends: ['app.common.button.secondary'],
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/cancelVehicleSetEdit',
        },
      ],
      component: 'Link',
      to: '/output',
      i18n: {
        id: 'i18n.output.vehicleSets.edit.footer.cancelButton.text',
        fallback: 'CANCEL',
      },
    },
    schema: {
      default: {
        paddingLeft: '2em',
        paddingRight: '2em',
      },
    },
  },
  'app.output.vehicleSets.edit.footer.deleteButton.iconText': {
    config: {
      component: 'Button',
      content: [
        [
          'Icon',
          'app.output.vehicleSets.edit.footer.deleteButton.iconText.icon',
        ],
        [
          'TranslatedText',
          'app.output.vehicleSets.edit.footer.deleteButton.iconText.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        padding: '1em 2em 1em 0',
        width: 'auto',
      },
      disabled: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
  'app.output.vehicleSets.edit.footer.deleteButton.iconText.icon': {
    _extends: 'app.onboarding.intro.iconText.common.icon',
    config: {
      src: 'trash',
      size: 'md',
    },
    schema: {
      default: {
        backgroundColor: 'var(--color-error)',
        width: '18px',
        height: '22px',
      },
    },
  },
  'app.output.vehicleSets.edit.footer.deleteButton.iconText.translatedText': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.footer.deleteButton.iconText.translatedText',
        fallback: 'DELETE VEHICLE SET',
      },
    },
    schema: {
      default: {
        color: 'var(--color-error)',
        'font-size': '1rem',
        'font-weight': 400,
        'margin-left': '0.5em',
      },
      hover: {
        textDecoration: 'underline',
      },
    },
  },
  'app.output.vehicleSets.edit.footer.updateButton': {
    components: {
      button: 'app.output.vehicleSets.edit.footer.updateButton.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/addVehicleSet',
        },
      ],
      component: 'SmartNextButton',
      disabled: '(function (_, isValid) { return !isValid; })',
    },
  },
  'app.output.vehicleSets.edit.footer.updateButton.button': {
    _extends: 'app.onboarding.common.footer.smartButton.next.button',
    config: {
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.output.vehicleSets.edit.footer.updateButton.button.text',
        ],
      ],
    },
  },
  'app.output.vehicleSets.edit.footer.updateButton.button.text': {
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.edit.footer.updateButton.button.text',
        fallback: 'SAVE CHANGES',
      },
    },
  },
};

export default VEHICLE_SETS;
