// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import merge from 'lodash.merge';

export const ChargingWindowFormatter = ({
  design,
  values,
  watchedValues,
}: any) => {
  const { config } = zappy.useDesign(design);
  const { formatHours } = zappy.formatters;
  const mergedValues = merge({}, values, watchedValues);
  const currentChargingWindows = mergedValues.chargingWindows;

  if (!currentChargingWindows) return null;
  if (currentChargingWindows.length > 1)
    return (
      <zappy.TranslatedText
        data-design={design}
        textAlign="left"
        {...config.multipleWindowsText}
      />
    );
  const { start, finish } = currentChargingWindows[0];
  let parsedValues = '';
  if (config.format === 'duration')
    parsedValues = `${finish > start ? finish - start : start - finish} hrs`;
  else parsedValues = `${formatHours(start)}-${formatHours(finish)}`;

  return (
    <zappy.TranslatedText
      data-design={design}
      i18n={{ id: `${design}.value`, fallback: parsedValues }}
    />
  );
};

ChargingWindowFormatter.displayName = 'ChargingWindowFormatter';
