// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useEffect, useMemo, useState } from 'react';
import { outputToggleNavigation } from '../../../../contexts/Interceptor/actions';
import { useLocation, useNavigate } from 'react-router-dom';

export const OutputTabs = ({ design }: { design: string }) => {
  const {
    components: {
      nonActiveLink,
      activeLink,
      link,
      previous,
      next,
      linksWrapper,
    },
    config: { options, pageSize, initPage = 0, watch },
    schema,
  } = zappy.useDesign(design);

  const [page, setPage] = useState(initPage);
  const navigate = useNavigate();

  const watchedValues = zappy.useWatch(watch);
  const { pathname } = useLocation();

  const pageOffset = page * pageSize;
  const sliceIndex = pageOffset + pageSize;

  const optionsFiltered = useMemo(() => {
    return options.filter(
      ({ displayQuery }) =>
        !displayQuery ||
        (displayQuery &&
          zappy.runQuery(undefined, displayQuery, { watched: watchedValues }))
    );
  }, [watchedValues]);

  useEffect(() => {
    const index = optionsFiltered.findIndex(
      (option) => option.to === pathname
    );

    if (index < 0) {
      setPage(initPage);
      navigate(optionsFiltered[initPage].to);
    } else {
      setPage(Math.floor(index / pageSize));
    }
  }, [optionsFiltered]);

  const pageOptions = optionsFiltered.slice(pageOffset, sliceIndex);

  return (
    <zappy.FactoryComponent data-design={design} schema={schema}>
      {!!pageOffset && (
        <zappy.Icon
          design={previous}
          role="button"
          onClick={() => setPage(page - 1)}
        />
      )}
      <zappy.FactoryComponent
        design={linksWrapper}
        role="tablist"
        aria-label="Output Tabs"
      >
        {pageOptions.map(({ to, label: fallback, i18nKey }) => {
          const id = i18nKey || `i18n.${design}.${fallback
            .toLowerCase()
            .replace(' ', '-')}`;
          const i18n = { id, fallback };
          const active = pathname.includes(
            to.replace('..', '')
          );
          return (
            <zappy.Link
              onClick={outputToggleNavigation}
              key={to}
              to={to}
              components={{
                translatedText: active ? activeLink : nonActiveLink,
              }}
              design={link}
              i18n={i18n}
              active={active}
              role="tab"
            />
          );
        })}
      </zappy.FactoryComponent>
      {sliceIndex < optionsFiltered.length && (
        <zappy.Icon
          design={next}
          role="button"
          onClick={() => setPage(page + 1)}
        />
      )}
    </zappy.FactoryComponent>
  );
};

OutputTabs.displayName = 'OutputTabs';
