// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";

export const Pagination = ({ design, onNext, onPrevious, shouldBeVisible, ...pagination }) => {
  const { schema, components } = zappy.useDesign(design);

  return !shouldBeVisible ? null : (
    <zappy.FactoryComponent schema={schema}>
      <zappy.TranslatedText
        design={components.translatedText}
        values={pagination}
      />
      <zappy.Icon
        design={components.previous}
        onClick={onPrevious}
        disabled={pagination.page === 1}
      />
      <zappy.Icon
        design={components.next}
        onClick={onNext}
        disabled={pagination.page === pagination.total}
      />
    </zappy.FactoryComponent>
  );
};

Pagination.displayName = "Pagination";
