/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';
import { useEffect } from "react";

export const SmartNextButton = ({
  design,
  onClick,
  watchedValues,
  isLoading,
}: {
  design: string;
}) => {
  const {
    getValues,
    trigger,
    // isValid is used by eval function
    formState,
  } = useFormContext();
  const { errors, isValid } = formState

  const { config = {}, components } = zappy.useDesign(design);

  // Used by eval function
  const formValues = getValues();

  const { disabled, ...props } = config;

  const disabledProp =
    (config.disabled &&
      eval(`${config.disabled}(formValues, isValid, errors)`)) ||
    (config.disabledQuery &&
      zappy.runQuery(undefined, config.disabledQuery, {
        form: formValues,
        watched: watchedValues,
      })) ||
    (config.disabledDirtyField && !formState.dirtyFields[config.disabledDirtyField]);

  useEffect(() => {
    if (errors && config.disabled) {
      setTimeout(() => {
        trigger();
      } , 50);
    }
  }, [disabledProp, watchedValues]);

  return (
    <zappy.ContentContainer
      design={components.button}
      disabled={disabledProp}
      onClick={onClick}
      isLoading={isLoading}
      {...props}
    />
  );
};

SmartNextButton.displayName = 'SmartNextButton';
