// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import get from 'lodash.get';
import { Location } from '../../types/location.types';

export function getAssumptionsFromLocations(
  location: Location,
  assumptionsData
) {
  /**
   * Code underneath is meant to be used when we make diesel price dynamic by vehicle
   */

  const regionalDieselTax = get(location, 'regional_fuel_cost.[0].diesel_tax');

  const nationalDieselTax = get(location, 'national_fuel_cost.[0].diesel_tax');

  const dieselPrice = get(
    location,
    'regional_fuel_cost.[0].diesel',
    assumptionsData.dieselPrice
  );

  const offroadDieselPrice = (
    dieselPrice -
    (regionalDieselTax / 100 + nationalDieselTax / 100)
  ).toFixed(3);

  const carbonCreditsPrice = get(
    location,
    'carbon_credits.[0].price',
    assumptionsData.carbonCreditsPrice
  );

  const electricityEmissions = get(
    location,
    'regional_electricity.[0].emissions.emissions_co2',
    assumptionsData.electricityEmissions
  );

  const gasolinePrice = get(
    location,
    'regional_fuel_cost.[0].gasoline',
    assumptionsData.gasolinePrice
  );

  const electricityPrice =
    get(
      location,
      'regional_fuel_cost.[0].commercial_electricity',
      assumptionsData.electricityPrice * 100
    ) / 100;

  const state = get(location, 'region', assumptionsData.state);

  return {
    carbonCreditsPrice,
    electricityEmissions,
    gasolinePrice,
    dieselPrice,
    offroadDieselPrice,
    electricityPrice,
    customRatePrice: electricityPrice,
    state,
  };
}
