import { CalculationResultsGroup } from '@zappy-ride/library.calculations';
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { useFormContext } from 'react-hook-form';
import { getOnRoadComparisonData } from '../../../presenters/calculations/calculations';
import get from 'lodash.get';
import zappy from "@zappy-ride/library.react.components";
import { selectedSetOrFirst } from "./chargers";

export const vehicleComparisonTable = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const watchedValues = zappy.useWatch({ name: "selectedSetId" }) as { [key: string]: any };
  const { vehicleSets = [], selectedSetId } = getValues();

  const [calculationsStatus] = getStateValue({
    lookupKey: ['calculations.isReady'],
    path: 'status',
  }) as [boolean];

  const perSet = getStateValue({
    lookupKey: 'perSet',
    states: ['vehicles', 'fuel'],
  }) as Record<string, CalculationResultsGroup>;

  const fe = selectedSetId || Object.keys(perSet || {})[0];
  const currentSetCalculations = perSet?.[fe] as { [key: string]: any };
  const currentSet = selectedSetOrFirst(selectedSetId, vehicleSets);

  const totalFossilFuelCost = get(currentSetCalculations, 'yearly[0].fuel.cost.fossil.total', 0) as number;
  const totalElectricFuelCost = get(currentSetCalculations, 'yearly[0].fuel.cost.electric.electricity', 0) as number;
  const savingsTotal = get(currentSetCalculations, 'yearly[0].fuel.savings.total', 0) as number;
  const totalMileage = (get(currentSetCalculations, 'yearly[0].vehicles.miles') || 1) as number;
  const totalHours = (get(currentSetCalculations, 'yearly[0].vehicles.hours') || 1) as number;

  const annualData = {
    millage: totalMileage,
    hours: totalHours,
    savingsTotal: savingsTotal,
    perMileGasoline: totalFossilFuelCost / totalMileage,
    perHourGasoline: totalFossilFuelCost / totalHours,
    perMileDiesel: totalFossilFuelCost / totalMileage,
    perHourDiesel: totalFossilFuelCost / totalHours,
    perMileElectric: totalElectricFuelCost / totalMileage,
    perHourElectric: totalElectricFuelCost / totalHours,
    annualTotalFossil: totalFossilFuelCost,
    annualTotalElectric: totalElectricFuelCost,
  };
  const isOffRoad = getValues('vehicleSets[0].input.is_offroad') || false;
  const vehicleFuelData = getOnRoadComparisonData(currentSet?.output || {}, annualData, isOffRoad);
  const vehicleElectricData = getOnRoadComparisonData(currentSet?.input || {}, annualData, isOffRoad);

  return {
    status: {
      isLoading: !calculationsStatus,
      missingRequiredInformation: !calculationsStatus,
    },
    data: {
      isOffRoad,
      comparisonData: {
        contentFossil: [],
        contentElectric: [],
      },
      comparisonGlobalDataInput: vehicleElectricData,
      comparisonGlobalDataOutput: vehicleFuelData,
      selectedSetId: watchedValues?.selectedSetId,
    },
  };
};
