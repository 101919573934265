import { DESKTOP_THRESHOLD } from '../../base';

const ONBOARDING_CHARGING = {
  'app.onboarding.charging': {
    _extends: 'app.page.common',
    config: {
      content: [
        ['TranslatedText', 'app.onboarding.charging.heading'],
        ['TranslatedText', 'app.onboarding.charging.description'],
        ['ContentContainer', 'app.onboarding.charging.form'],
      ],
    },
  },
  'app.onboarding.charging.form': {
    _extends: 'app.onboarding.common.form',
    config: {
      as: 'form',
      content: [
        ['ContentContainer', 'app.onboarding.charging.form.fields'],
        ['ContentContainer', 'app.onboarding.charging.form.footer'],
      ],
    },
  },
  'app.onboarding.charging.form.footer': {
    _extends: 'app.onboarding.common.form.footer',
    config: {
      content: [
        ['ContentContainer', 'app.onboarding.charging.form.footer.button.back'],
        [
          'ActionComponent',
          'app.onboarding.charging.form.footer.smartButton',
          { displayQuery: 'termsAndConditionsDisabled' },
        ],
        [
          'TermsAndConditionsModal',
          'app.onboarding.charging.termsandconditions.modal',
          { displayQuery: 'termsAndConditionsEnabled' },
        ],
      ],
      watch: {
        name: ['confirmTermsAndConditions'],
      },
      displayQueries: {
        termsAndConditionsEnabled: {
          '["watched", "confirmTermsAndConditions"]': { $eq: true },
        },
        termsAndConditionsDisabled: {
          '["watched", "confirmTermsAndConditions"]': { $neq: true },
        },
      },
    },
  },
  'app.onboarding.charging.termsandconditions.modal': {
    _extends: 'app.common.termconditions.modal',
    config: {
      confirm: {
        component: 'ActionComponent',
        i18n: {
          id: 'i18n.common.termconditions.modal.confirm',
          fallback: 'Accept',
        },
      },
    },
    components: {
      trigger: 'app.onboarding.charging.form.footer.terms.button.trigger',
      confirm: 'app.onboarding.charging.form.footer.terms.button.accept',
    },
  },
  'app.onboarding.charging.form.footer.button.back': {
    _extends: 'app.onboarding.common.footer.button.back',
    config: {
      to: '../usage',
    },
  },
  'app.onboarding.charging.form.footer.terms.button.trigger': {
    _extends: 'app.onboarding.common.footer.smartButton.next',
    components: {
      button: 'app.onboarding.charging.form.footer.smartButton.button',
    },
    config: {
      component: 'SmartNextButton',
      disabled:
        "(function (_, isValid, errors) { return !!errors['chargingWindows']; })",
    },
  },
  'app.onboarding.charging.form.footer.smartButton': {
    _extends: 'app.onboarding.common.footer.smartButton.next',
    components: {
      button: 'app.onboarding.charging.form.footer.smartButton.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'view/loading',
          payload: {
            isLoading: true,
          },
        },
        {
          type: 'form/navigateTo',
          payload: {
            to: '/output',
          },
        },
      ],
      component: 'SmartNextButton',
      disabled:
        "(function (_, isValid, errors) { return !!errors['chargingWindows']; })",
    },
  },
  'app.onboarding.charging.form.footer.terms.button.accept': {
    _extends: 'app.common.termconditions.modal.confirm',
    config: {
      component: 'Button',
      eventName: 'onClick',
      actions: [
        {
          type: 'view/loading',
          payload: {
            isLoading: true,
          },
        },
        {
          type: 'form/navigateTo',
          payload: {
            to: '/output',
          },
        },
      ],
    },
  },
  'app.onboarding.charging.form.footer.smartButton.button': {
    _extends: 'app.onboarding.common.footer.smartButton.next.button',
    config: {
      component: 'Button',
    },
  },
  'app.onboarding.charging.heading': {
    _extends: 'app.onboarding.common.heading',
    config: {
      i18n: {
        id: 'i18n.onboarding.charging.heading',
        fallback: 'Charging Behavior',
      },
    },
  },
  'app.onboarding.charging.description': {
    _extends: 'app.onboarding.common.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.charging.description',
        fallback:
          'Think ahead to when you expect to charge your new electrified fleet. Enter each daily window of time you expect to charge your vehicles and where, and we will recommend ideal charger type(s). You can pick specific charger makes and models later.',
      },
    },
  },
  'app.onboarding.charging.form.fields': {
    config: {
      content: [
        ['ContentContainer', 'app.onboarding.charging.form.fields.info'],
        ['ContentContainer', 'app.onboarding.charging.form.fields.selection'],
        [
          'ChargingWindow',
          'app.onboarding.charging.form.fields.chargingWindow',
          { displayQuery: 'onRoad' },
        ],
        [
          'ChargingWindow',
          'app.onboarding.charging.form.fields.chargingWindowOffRoad',
          { displayQuery: 'offRoad' },
        ],
        [
          'ContentContainer',
          'app.onboarding.charging.form.fields.formAlerts.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'ContentContainer',
          'app.onboarding.charging.form.fields.formAlerts.offRoad',
          { displayQuery: 'offRoad' },
        ],
      ],
      watch: {
        name: ['input.is_offroad'],
      },
      displayQueries: {
        onRoad: { '["watched", "input.is_offroad"]': { $neq: true } },
        offRoad: {
          '["watched", "input.is_offroad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',

        'align-items': 'flex-start',
        backgroundColor: 'white',
        padding: '4.93vh 7.64vw',
        'min-height': '37vh',
        width: '80vw',
        'grid-template-columns': 'auto',
        'grid-column-gap': '16.24vw',

        '@media (max-width: 992px)': {
          'grid-row-gap': '32px',
          padding: '32px 0px',
          width: '100%',
        },
      },
    },
  },

  // FORM ALERTS CONTAINERS
  'app.onboarding.charging.form.fields.formAlerts.onRoad': {
    config: {
      content: [
        ['FormAlert', 'app.onboarding.charging.form.fields.noteInfo'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteWarning'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteAlert'],
      ],
    },
  },
  'app.onboarding.charging.form.fields.formAlerts.offRoad': {
    config: {
      content: [
        ['FormAlert', 'app.onboarding.charging.form.fields.noteInfoOffRoad'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteWarningOffRoad'],
        ['FormAlert', 'app.onboarding.charging.form.fields.noteAlertOffRoad'],
      ],
    },
  },

  'app.onboarding.charging.form.fields.info': {
    config: {
      content: [
        ['Icon', 'app.onboarding.charging.form.fields.info.icon'],
        ['TranslatedText', 'app.onboarding.charging.form.fields.info.text'],
      ],
    },
    schema: {
      default: {
        display: 'none',
        'background-color': 'var(--info-background-color)',
        'grid-column-gap': '16px',
        'grid-template-columns': 'auto 1fr',
        'min-height': '90px',
        padding: '0 28px',
        'align-items': 'center',
        'border-radius': '5px',
        'margin-bottom': '20px',

        '@media (max-width: 992px)': {
          'margin-bottom': '0px',
          padding: '16px 24px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.info.icon': {
    config: {
      component: 'Icon',
      src: 'electrical-services',
    },
    schema: {
      default: {
        backgroundColor: 'var(--color-info)',
        height: '26px',
        width: '26px',
        margin: '3px',
        'align-self': 'center',
      },
    },
  },
  'app.onboarding.charging.form.fields.info.text': {
    config: {
      element: 'p',
      textAlign: 'left',
      color: 'var(--text-gray-normal)',
      scale: '3',
      values: {
        url: 'http://some-url.com',
      },
      i18n: {
        id: 'i18n.onboarding.charging.fields.info.text',
        dangerouslySetInnerHTML: true,
        fallback:
          'Our <a href="{{url}}" style="color: var(--color-info);text-decoration: underline;" target="_blank">Business EV Rate Plan</a> can help you lower your energy bills and further reduce your carbon footprint. When you avoid charging during <strong>peak times (M-F 2pm-8pm)</strong>, we\'ll pass on the savings to you. <strong>On weekends and holidays</strong>, you always save.',
      },
    },
  },
  'app.onboarding.charging.form.fields.selection': {
    config: {
      content: [
        ['Formatter', 'app.onboarding.charging.form.fields.selection.image'],
        [
          'ContentContainer',
          'app.onboarding.charging.form.fields.selection.description',
        ],
      ],
    },
    schema: {
      default: {
        border: '1px solid grey',
        'border-radius': '5px',
        display: 'grid',
        padding: '16px',
        'margin-bottom': '20px',
        'grid-template-columns': '10em 1fr',
        'grid-column-gap': '32px',

        '@media (max-width: 992px)': {
          'grid-template-columns': 'auto 1fr',
          'grid-column-gap': '16px',
          'margin-bottom': '0px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.selection.image': {
    config: {
      component: 'Logo',
      watch: {
        name: ['input.image_url', 'input.name'],
      },
      mapper: {
        'input.image_url': 'src',
        'input.name': 'alt',
      },
      converter: {
        undefined: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
    schema: {
      default: {
        width: 'auto',
        height: '70px',
        'justify-self': 'center',
        'align-self': 'flex-end',

        '@media (max-width: 992px)': {
          'align-self': 'center',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.selection.description': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.charging.form.fields.selection.description.title',
        ],
        [
          'Formatter',
          'app.onboarding.charging.form.fields.selection.description.value',
        ],
      ],
    },
    schema: {
      default: {
        margin: 'auto 0',

        '@media (max-width: 992px)': {
          display: 'grid',
          'grid-row-gap': '8px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.selection.description.title': {
    config: {
      element: 'p',
      textAlign: 'left',
      color: 'var(--text-gray-normal)',
      scale: '3',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.selection.description.title',
        fallback: 'Selecting charger(s) for each:',
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'font-weight': 'var(--item-font-weight-value)',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.selection.description.value': {
    config: {
      as: 'p',
      watch: {
        name: ['input.name'],
      },
      pick: 'input.name',
    },
    schema: {
      default: {
        'font-weight': '600',

        '@media (max-width: 992px)': {
          'font-weight': 'var(--font-weight)',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow': {
    config: {
      preload: 'charging_window_hours',
      name: 'chargingWindows',
      defaultChargerAccess: 1,
    },
    components: {
      start: 'app.onboarding.charging.form.fields.chargingWindow.start',
      finish: 'app.onboarding.charging.form.fields.chargingWindow.finish',
      chargerId:
        'app.onboarding.charging.form.fields.chargingWindow.chargerType',
      access:
        'app.onboarding.charging.form.fields.chargingWindow.chargerAccess',
      addButton:
        'app.onboarding.charging.form.fields.chargingWindow.addWindowButton',
      removeButton:
        'app.onboarding.charging.form.fields.chargingWindow.removeButton',
      overlapError:
        'app.onboarding.charging.form.fields.chargingWindow.overlapError',
      depletedError:
        'app.onboarding.charging.form.fields.chargingWindow.depletedError',
    },
    schema: {
      default: {
        display: 'flex',
        'flex-wrap': 'wrap',
        'margin-bottom': '16px',
      },

      row: {
        default: {
          display: 'grid',
          width: '100%',
          'align-items': 'center',

          [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
            'grid-template-columns': 'repeat(4, 3fr) auto',
            'grid-column-gap': '1.5em',
            'grid-row-gap': '1.5em',
            marginBottom: '1.5em',
          },

          '@media (max-width: 992px)': {
            'grid-template-areas':
              "'start end remove' 'type type type' 'access access access'",
            width: '100%',
            'grid-column-gap': '16px',
            'grid-row-gap': '16px',

            'padding-bottom': '19px',
            'border-bottom': '1px solid var(--border-color)',
            'margin-bottom': '32px',
          },
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindowOffRoad': {
    _extends: 'app.onboarding.charging.form.fields.chargingWindow',
    components: {
      depletedError:
        'app.onboarding.charging.form.fields.chargingWindow.depletedErrorOffRoad',
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.start': {
    components: {
      label: 'app.onboarding.charging.form.fields.chargingWindow.start.label',
      input: 'app.onboarding.charging.form.fields.chargingWindow.start.select',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'grid-area': 'start',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.start.select': {
    _extends: 'app.common.select',
    config: {
      component: 'FormSelect',
      preload: 'charging_window_hours',
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.start.label': {
    config: {
      fontSize: '0.9rem',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.chargingWindow.start.label',
        fallback: 'Start Time',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.finish': {
    components: {
      label: 'app.onboarding.charging.form.fields.chargingWindow.finish.label',
      input: 'app.onboarding.charging.form.fields.chargingWindow.finish.select',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'grid-area': 'end',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.finish.select': {
    _extends: 'app.common.select',
    config: {
      component: 'FormSelect',
      preload: 'charging_window_hours',
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.finish.label': {
    config: {
      fontSize: '0.9rem',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.chargingWindow.finish.label.text',
        fallback: 'End Time',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerType': {
    components: {
      label:
        'app.onboarding.charging.form.fields.chargingWindow.chargerType.label',
      input:
        'app.onboarding.charging.form.fields.chargingWindow.chargerType.select',
      error: 'app.common.field.error.message',
    },
    config: {
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerType',
            fallback: 'Charger Type is required',
          },
        },
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'grid-area': 'type',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerType.select': {
    _extends: 'app.common.select',
    config: {
      defaultValue: 'skip_auto_set',
      component: 'FormVehicleAttrSelector',
      preload: 'chargers',
      preloadAttrs: {
        attrForValue: 'charger_id',
        attrForLabel: 'label',
        labelFormatter: 'chargerFormatter'
      },
      placeholder: {
        i18n: {
          id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerType.select.placeholder',
          fallback: 'Select Charger',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerType.label': {
    config: {
      fontSize: '0.9rem',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerType.label.text',
        fallback: 'Charger Type',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerType.description':
    {
      config: {
        fontSize: '0.8rem',
        i18n: {
          id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerType.select.description',
          fallback: 'Type/Output',
        },
      },
    },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess': {
    components: {
      label:
        'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.label',
      input:
        'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.select',
      tooltip:
        'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr',
        'grid-template-rows': 'min-content',
        'justify-items': 'start',
        'grid-template-areas': '"label label" "input tooltip"',

        '@media (max-width: 992px)': {
          'grid-area': 'access',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.select': {
    _extends: 'app.common.select',
    config: {
      component: 'FormSelect',
      options: {
        0: {
          value: 0,
          i18n: {
            id: 'i18n.chargerAccess.public',
            fallback: 'Public [Fleet owned]',
          },
        },
        1: {
          value: 1,
          i18n: {
            id: 'i18n.chargerAccess.private',
            fallback: 'Private [Fleet owned]',
          },
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.label': {
    config: {
      fontSize: '0.9rem',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerAccess.label.text',
        fallback: 'Charger access',
      },
    },
    schema: {
      default: {
        'grid-area': 'label',
        'line-height': '1.7em',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip': {
    _extends: 'app.common.tooltip',
    components: {
      popover:
        'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip.popover',
    },
    schema: {
      default: {
        'margin-top': '-1.5em',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip.popover':
    {
      _extends: 'app.common.tooltip.popover',
      config: {
        position: 'W',
        content: [
          [
            'TranslatedText',
            'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip.popover.translatedText',
          ],
        ],
      },
      schema: {
        default: {
          fontFamily: 'var(--font-family)',
          width: '30%',
          'margin-left': '-1rem',
          'box-shadow': '-4px 4px 4px 2px #2222',
        },
      },
    },
  'app.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip.popover.translatedText':
    {
      config: {
        element: 'span',
        fontSize: '1rem',
        i18n: {
          id: 'i18n.onboarding.charging.form.fields.chargingWindow.chargerAccess.tooltip.popover.translatedText',
          dangerouslySetInnerHTML: true,
          fallback:
            '<ul><li><strong>Private Fleet Owned</strong> - a charger at your depot or on your private premises that is not accessible to the public to charge their vehicles.</li><li><strong>Public - Fleet Owned</strong> - a charger you own that you let the public use to charge their vehicles (can make you eligible for more incentives and/or incentives may be higher amounts)</li></ul>',
        },
      },
    },
  'app.onboarding.charging.form.fields.chargingWindow.addWindowButton': {
    _extends: 'app.common.button.secondary',
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.charging.form.fields.chargingWindow.addWindowButton.text',
        ],
      ],
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-column': '1 / 6',
          width: '250px',
        },

        '@media (max-width: 992px)': {
          'grid-column': 'span 2',
        },
      },
      disabled: {
        border: 'none',
        backgroundColor: 'var(--disabled-background-color)',
        color: 'var(--disabled-color)',
        'pointer-events': 'none',
        cursor: 'not-allowed',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.addWindowButton.text': {
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.onboarding.charging.form.fields.chargingWindow.addWindowButton.text',
        fallback: '+ Add Charging Window',
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.removeButton': {
    _extends: 'app.common.button.secondary',
    config: {
      content: [
        [
          'Icon',
          'app.onboarding.charging.form.fields.chargingWindow.removeButton.icon',
        ],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'justify-self': 'center',
          'grid-area': 'remove',
          height: '60px',
          width: '60px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.removeButton.icon': {
    _extends: 'app.common.icon.secondary',
    config: {
      src: 'trash',
      alt: 'Delete',
      size: 'md',
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.overlapError': {
    _extends: 'app.common.iconTextWithCaption',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      icon: {
        color: 'var(--color-error)',
      },
      watch: {
        name: ['chargingWindows'],
      },
      validate: '(function ({ chargingWindows }) { return false; })',
      text: {
        i18n: {
          id: 'i18n.onboarding.charging.form.fields.chargingWindow.overlapError',
          dangerouslySetInnerHTML: true,
          fallback:
            '<strong>Warning:</strong> Multiple chargers are in use at the same time for this vehicle set.',
        },
      },
    },
    schema: {
      default: {
        'background-color': 'var(--error-background-color)',
        alignItems: 'center',
        'grid-column': '1/6',
      },
    },
  },

  // ON-ROAD FORM ALERTS
  'app.onboarding.charging.form.fields.noteInfo': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      watch: {
        name: [
          'input',
          'avgDistancePerDay',
          'defaultChargingWindow',
          'chargingWindows',
          'stateChargeThreshold',
          'kWhPerDayNeeded',
        ],
      },
      validate:
        '(function ({chargingWindows, defaultChargingWindow}) { const isValid = chargingWindows && defaultChargingWindow && (chargingWindows.length > 1 || (chargingWindows[0].start !== defaultChargingWindow.start || chargingWindows[0].finish !== defaultChargingWindow.finish || chargingWindows[0].chargerId !== defaultChargingWindow.chargerId)); return isValid;})',
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.noteInfo.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'A charger has been recommended assuming an <strong>6pm - 6am</strong> charging window for your <strong>{{input.name}}</strong> driving {{avgDistancePerDay}} miles per day that require charging <strong>{{kWhPerDayNeeded}} kWh in 8 hours</strong>. With this configuration, the minimum state of charge will be <strong>{{stateChargeThreshold}}%</strong>.',
        },
      },
    },
    schema: {
      default: {
        'margin-bottom': '1.5rem',

        '@media (max-width: 992px)': {
          'margin-bottom': '0px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.noteWarning': {
    _extends: 'app.common.iconTextWithCaption',
    config: {
      icon: {
        src: 'speed-indicator',
        color: '#F78D31',
      },
      watch: {
        name: ['minimumStateChargePercentage', 'stateChargeThreshold'],
      },
      validate:
        '(function ({ minimumStateChargePercentage, stateChargeThreshold }) { return minimumStateChargePercentage < 0 || minimumStateChargePercentage > stateChargeThreshold })',
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.noteWarning',
          fallback:
            'With this configuration, the minimum state of charge across the week will be {{minimumStateChargePercentage}}%. Are you sure the charging times, charging level(s), and daily miles are a conservative estimate?',
        },
      },
      header: {
        i18n: {
          id: 'i18n.iconText.caption',
          fallback: '{{minimumStateChargePercentage}}%',
        },
      },
    },
    schema: {
      default: {
        backgroundColor: 'var(--warning-background-color)',
        'margin-bottom': '1.5rem',

        '@media (max-width: 992px)': {
          'margin-bottom': '0px',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.depletedError': {
    _extends: 'app.common.iconTextWithCaption',
    config: {
      icon: {
        src: 'speed-indicator',
        color: 'var(--color-error)',
      },
      validate: '(function () { return false; })',
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.chargingWindow.depletedError',
          dangerouslySetInnerHTML: true,
          fallback:
            '<strong>Warning:</strong> With this configuration, the batteries will be depleted. Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
        },
      },
      header: {
        i18n: {
          id: 'i18n.iconText.caption',
          fallback: 'Empty',
        },
        color: 'var(--color-error)',
      },
    },
    schema: {
      default: {
        backgroundColor: 'var(--error-background-color)',
        'grid-column': '1 / -1',
      },
    },
  },

  // OFF-ROAD FORM ALERTS
  'app.onboarding.charging.form.fields.noteInfoOffRoad': {
    _extends: 'app.onboarding.charging.form.fields.noteInfo',
    config: {
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.noteInfoOffRoad.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'A charger has been recommended assuming an <strong>6pm - 6am</strong> charging window for your <strong>{{input.name}}</strong> driving {{avgDistancePerDay}} hours per day that require charging {{kWhPerDayNeeded}} kWh in 12 hours. With this configuration, the minimum state of charge will be <strong>{{stateChargeThreshold}}%</strong>.',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.noteWarningOffRoad': {
    _extends: 'app.onboarding.charging.form.fields.noteWarning',
    config: {
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.noteWarningOffRoad',
          fallback:
            'With this configuration, the minimum state of charge across the week will be {{minimumStateChargePercentage}}%. Are you sure the charging times, charging level(s), and daily hours are a conservative estimate?',
        },
      },
    },
  },
  'app.onboarding.charging.form.fields.chargingWindow.depletedErrorOffRoad': {
    _extends:
      'app.onboarding.charging.form.fields.chargingWindow.depletedError',
    config: {
      text: {
        i18n: {
          id: 'i18n.app.onboarding.charging.form.fields.chargingWindow.depletedErrorOffRoad',
          dangerouslySetInnerHTML: true,
          fallback:
            '<strong>Warning:</strong> With this configuration, the batteries will be depleted. Please add more charging times, increase the charging level(s), and/or decrease the daily hours.',
        },
      },
    },
  },
};

export default ONBOARDING_CHARGING;
