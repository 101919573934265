import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';

export const TermsAndConditionsModal = ({ design }: { design: string }) => {
  const { getValues } = useFormContext();
  const clientName = getValues('clientName');

  return (
    <zappy.PrimaryModal
      design={design}
      data-design={design}
      //@ts-ignore
      values={{ clientName }}
    />
  );
};

TermsAndConditionsModal.displayName = 'TermsAndConditionsModal';
