// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import zappy from '@zappy-ride/library.react.components';
import { useMemo, useState } from 'react';
import { InterceptorContext } from '../../contexts/Interceptor';
import { SET_DEFAULT_INPUT_OUTPUT } from '../../contexts/Interceptor/actions';
import { useFormContext } from 'react-hook-form';

export const ProjectVehicleSelectionTabs = ({ design }: { design: string }) => {
  const {
    schema,
    components,
    config: { content, as },
  } = zappy.useDesign(design);
  const callAction = useContext(InterceptorContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [path, setPath] = useState('');
  const { setValue } = useFormContext();

  const { defaultVehicles } = zappy.useWatch({ name: 'defaultVehicles' });

  const handleClick = (to) => () => {
    setPath(to);
    setIsOpen(true);
  };

  const actions = useMemo(
    () => ({
      onConfirm: () => {
        const { ice, ev } = defaultVehicles || {}
        setValue('defaultVehicles.ready', false);
        callAction({
          type: SET_DEFAULT_INPUT_OUTPUT,
          payload: { ev, ice, forceUpdate: true, path },
        });
        setIsOpen(false);
        navigate(path);
      },
      onCancel: () => setIsOpen(false),
    }),
    [path, navigate, defaultVehicles]
  );

  return (
    <zappy.FactoryComponent schema={schema} as={as} design={undefined}>
      {isOpen && <zappy.Modal design={components.modal} actions={actions} />}
      {content.map(
        ([componentName, componentDesign, { activePathname, to }]) => (
          <zappy.FactoryComponent
            key={componentDesign}
            component={componentName}
            design={componentDesign}
            active={pathname === activePathname}
            onClick={handleClick(to)}
          />
        )
      )}
    </zappy.FactoryComponent>
  );
};

ProjectVehicleSelectionTabs.displayName = 'ProjectVehicleSelectionTabs';
