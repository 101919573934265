import { VehicleSet } from '@zappy-ride/library.calculations/dist/calcs/Base/Base.types';
import { useFormContext } from 'react-hook-form';

export const useGetCurrentOrFirstVehicleSet = () => {
  const { getValues } = useFormContext();
  const [selectedSetId, vehicleSets] = getValues([
    'selectedSetId',
    'vehicleSets',
  ]) as [string, Array<VehicleSet>];
  return vehicleSets.find(({ id }) => id === selectedSetId) || vehicleSets[0];
};
