// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';
import zappy from '@zappy-ride/library.react.components';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import { CompactIncentivesView } from '@zappy-ride/ev-fleets';

export default function CommercialIncentives() {
  const { preloads } = useContext(zappy.PreloadsContext);
  const commercialIncentives = get(preloads, 'commercial_incentives', []);
  const { zipcode } = zappy.useWatch({ name: 'zipcode' });

  if (isEmpty(commercialIncentives)) return 'No incentives applied';
  return (
    <CompactIncentivesView
      incentives={commercialIncentives}
      zipcode={zipcode}
    />
  );
}

CommercialIncentives.displayName = 'CommercialIncentives';
