import { DESKTOP_THRESHOLD } from '../../base';

const ONBOARDING_USAGE = {
  'app.onboarding.usage': {
    _extends: 'app.page.common',
    config: {
      content: [
        ['TranslatedText', 'app.onboarding.usage.heading'],
        ['TranslatedText', 'app.onboarding.usage.description'],
        ['ContentContainer', 'app.onboarding.usage.form'],
      ],
    },
  },
  'app.onboarding.usage.form': {
    config: {
      as: 'form',
      content: [
        ['ContentContainer', 'app.onboarding.usage.form.fields'],
        ['ContentContainer', 'app.onboarding.usage.form.footer'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          width: '100%',
          'margin-top': '32px',
        },
      },
    },
  },
  'app.onboarding.usage.form.fields': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.onboarding.usage.form.fields.milesPerWorkday&personalMilesPaidFor',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.onboarding.usage.form.fields.personalMilesPerWorkday',
          { displayQuery: 'show' },
        ],
        [
          'FormField',
          'app.onboarding.usage.form.fields.hoursPerWorkdayMedium',
          { displayQuery: 'offRoad' },
        ],
        ['', 'app.onboarding.usage.form.fields.divider'],
        ['FormVehicleInOperation', 'app.onboarding.usage.form.fields.workdays'],
        [
          'FormVehicleInOperation',
          'app.onboarding.usage.form.fields.workmonths',
        ],
        [
          'FormAlert',
          'app.onboarding.usage.form.fields.formAlert',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormAlert',
          'app.onboarding.usage.form.fields.formAlertOffRoad',
          { displayQuery: 'offRoad' },
        ],
      ],
      watch: {
        name: ['input.is_offroad', 'input.is_commercial_only', 'input.type'],
      },
      displayQueries: {
        onRoad: { '["watched", "input.is_offroad"]': { $neq: true } },
        offRoad: {
          '["watched", "input.is_offroad"]': { $eq: true },
        },
        show: {
          $and: [
            { '["watched", "input.is_offroad"]': { $neq: true } },
            {
              $and: [
                { '["watched", "input.is_commercial_only"]': { $neq: true } },
                { '["watched", "input.type"]': { $neq: 'Passenger' } },
              ],
            },
          ],
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'align-items': 'flex-start',
        backgroundColor: 'white',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '16.24vw',
          width: '80vw',
          padding: '4.93vh 7.64vw',
          'min-height': '37vh',
        },

        '@media (max-width: 992px)': {
          'grid-row-gap': '37px',
        },
      },
    },
  },

  'app.onboarding.usage.form.fields.avgDistancePerDay': {
    config: {
      watch: {
        name: ['milesPerWorkday', 'personalMilesPerWorkday'],
      },
      calculation: {
        name: 'avgDistancePerDay',
        func: '(function({ milesPerWorkday, personalMilesPerWorkday }) { return milesPerWorkday + personalMilesPerWorkday; })',
      },
    },
  },

  'app.onboarding.usage.form.fields.formAlert': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      watch: {
        name: ['milesPerWorkday', 'personalMilesPerWorkday', 'input'],
      },
      validate:
        '(function ({ milesPerWorkday, personalMilesPerWorkday, input }) { return ((milesPerWorkday || 0) + (personalMilesPerWorkday || 0)) <= input?.total_range; })',
      text: {
        i18n: {
          id: 'i18n.app.onboarding.usage.form.fields.formAlert',
          fallback:
            'If vehicle usage exceeds {{input.total_range}} miles per day then they will need to be charged up more than once per day (e.g. in the middle of the day). Alternatively you may want to reduce the number of vehicles of this type on the previous screen so only vehicles used for under {{input.total_range}} milles per day are switched to EVs.',
        },
      },
    },
    schema: {
      default: {
        'grid-column': '1 / -1',
      },
    },
  },
  'app.onboarding.usage.form.fields.formAlertOffRoad': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
    config: {
      watch: {
        name: ['hoursPerWorkdayMedium', 'input'],
      },
      validate:
        '(function ({ hoursPerWorkdayMedium, input }) { return (hoursPerWorkdayMedium || 0) <= input?.total_range; })',
      text: {
        i18n: {
          id: 'i18n.app.onboarding.usage.form.fields.formAlertOffRoad',
          fallback:
            'If vehicle usage exceeds {{input.total_range}} hours per day then they will need to be charged up more than once per day (e.g. in the middle of the day). Alternatively you may want to reduce the number of vehicles of this type on the previous screen so only vehicles used for under {{input.total_range}} hours per day are switched to EVs.',
        },
      },
    },
    schema: {
      default: {
        'grid-column': '1 / -1',
      },
    },
  },

  'app.onboarding.common.vehicleInOperation': {
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '24px',
        'grid-column': '1 / -1',
        marginBottom: '24px',
      },
    },
  },
  'app.onboarding.common.vehicleInOperation.button.input': {
    _extends: 'app.onboarding.common.selectTags.tag',
    schema: {
      default: {
        'border-color': 'var(--primary-color)',
        margin: 0,
        width: '200px',
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths': {
    _extends: 'app.onboarding.common.vehicleInOperation',
    components: {
      checkbox: 'app.onboarding.usage.form.fields.workmonths.checkbox',
      button: 'app.onboarding.usage.form.fields.workmonths.button',
      selectTags: 'app.onboarding.usage.form.fields.workmonths.selectTags',
    },
    config: {
      watch: {
        name: 'allMonths',
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths.selectTags': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.usage.form.fields.workmonths.selectTags.input',
      description: null,
    },
    config: {
      label: {
        htmlFor: 'workmonths',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.workmonths.selectTags.label',
          fallback: 'Have custom months of operation?',
        },
      },
      input: {
        type: 'tags',
        component: 'FormSelect',
        name: 'workmonths',
        placeholder: null,
        length: 12,
        preload: 'operation_months',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.app.onboarding.usage.form.fields.workmonths.selectTags.required',
            fallback: 'At least one month in operation must be selected',
          },
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths.selectTags.input': {
    _extends: 'app.onboarding.common.selectTags.input',
    components: {
      option:
        'app.onboarding.usage.form.fields.workmonths.selectTags.input.option',
    },
    config: {
      name: 'workmonths',
    },
  },
  'app.onboarding.usage.form.fields.workmonths.selectTags.input.option': {
    _extends: 'app.onboarding.common.selectTags.tag',
    components: {
      typography: 'app.onboarding.common.selectTags.tag.typography',
    },
  },
  'app.onboarding.usage.form.fields.workmonths.button': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.usage.form.fields.workmonths.button.input',
    },
    config: {
      label: {
        htmlFor: 'workmonthsToggle',
        i18n: {
          id: 'i18n.app.onboarding.usage.form.fields.workmonths.button',
          fallback: 'Have custom months of operation?',
        },
      },
      input: {
        component: 'Button',
        name: 'workmonthsToggle',
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths.button.input': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.usage.form.fields.workmonths.button.text',
        ],
      ],
    },
    schema: {
      default: {
        width: '200px',
        '@media (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths.button.text': {
    _extends: 'app.common.text.secondary',
    config: {
      fontSize: '14px',
      i18n: {
        id: 'i18n.app.onboarding.usage.form.fields.workmonths.button.text',
        fallback: 'SPECIFY MONTHS',
      },
    },
  },
  'app.onboarding.usage.form.fields.workmonths.checkbox.input': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      component: 'FormInput',
      text: {
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.workmonths.checkbox.input',
          fallback: 'All months',
        },
      },
      name: 'allMonths',
    },
  },
  'app.onboarding.usage.form.fields.workmonths.checkbox': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.usage.form.fields.workmonths.checkbox.input',
      description: null,
    },
    config: {
      label: {
        htmlFor: 'allMonths',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.workmonths.checkbox.label',
          fallback: 'Months in operation',
        },
      },
      input: {
        name: 'allMonths',
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays': {
    _extends: 'app.onboarding.common.vehicleInOperation',
    components: {
      checkbox: 'app.onboarding.usage.form.fields.workdays.checkbox',
      button: 'app.onboarding.usage.form.fields.workdays.button',
      selectTags: 'app.onboarding.usage.form.fields.workdays.selectTags',
    },
    config: {
      watch: {
        name: 'weekdaysOnly',
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          marginBottom: '0px',
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays.selectTags': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.usage.form.fields.workdays.selectTags.input',
      description: null,
    },
    config: {
      label: {
        htmlFor: 'workdays',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.workdays.selectTags.label',
          fallback: 'Have custom days of operation?',
        },
      },
      input: {
        type: 'tags',
        component: 'FormSelect',
        name: 'workdays',
        placeholder: null,
        length: 7,
        preload: 'operation_days',
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.app.onboarding.usage.form.fields.workdays.selectTags.required',
            fallback: 'At least one day in operation must be selected',
          },
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays.selectTags.input': {
    _extends: 'app.onboarding.common.selectTags.input',
    components: {
      option:
        'app.onboarding.usage.form.fields.workdays.selectTags.input.option',
    },
    config: {
      name: 'workdays',
    },
  },
  'app.onboarding.usage.form.fields.workdays.selectTags.input.option': {
    _extends: 'app.onboarding.common.selectTags.tag',
    components: {
      typography: 'app.onboarding.common.selectTags.tag.typography',
    },
  },
  'app.onboarding.usage.form.fields.workdays.button': {
    _extends: 'app.common.field',
    components: {
      description: null,
      input: 'app.onboarding.usage.form.fields.workdays.button.input',
    },
    config: {
      label: {
        htmlFor: 'workdaysToggle',
        i18n: {
          id: 'i18n.app.onboarding.usage.form.fields.workdays.button',
          fallback: 'Have custom days of operation?',
        },
      },
      input: {
        component: 'Button',
        name: 'workdaysToggle',
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays.button.input': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.usage.form.fields.workdays.button.text',
        ],
      ],
    },
    schema: {
      default: {
        width: '200px',
        margin: 0,
        padding: 0,
        '@media (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays.button.text': {
    _extends: 'app.common.text.secondary',
    config: {
      fontSize: '14px',
      i18n: {
        id: 'i18n.app.onboarding.usage.form.fields.workdays.button.text',
        fallback: 'SPECIFY DAYS',
      },
    },
  },
  'app.onboarding.usage.form.fields.workdays.checkbox.input': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      component: 'FormInput',
      text: {
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.workdays.checkbox.input',
          fallback: 'Weekdays only',
        },
      },
      name: 'weekdaysOnly',
    },
  },
  'app.onboarding.usage.form.fields.workdays.checkbox': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.usage.form.fields.workdays.checkbox.input',
      description: null,
    },
    config: {
      label: {
        htmlFor: 'weekdaysOnly',
        i18n: {
          id: 'i18n.app.onboarding.usage.form.fields.workdays.checkbox',
          fallback: 'Days in operation',
        },
      },
      input: {
        name: 'weekdaysOnly',
      },
    },
  },

  'app.onboarding.usage.form.fields.divider': {
    _extends: 'app.onboarding.selection.form.sections.stats.ev.right.divider',
    schema: {
      default: {
        margin: '48px 0',

        '@media (max-width: 992px)': {
          margin: '0px',
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.milesPerWorkday&personalMilesPaidFor': {
    config: {
      content: [
        ['FormField', 'app.onboarding.usage.form.fields.milesPerWorkday'],
        [
          'FormInput',
          'app.onboarding.usage.form.fields.personalMilesPaidFor',
          { displayQuery: 'notCommercialOnly' },
        ],
      ],
      watch: {
        name: ['input.is_commercial_only', 'input.type'],
      },
      displayQueries: {
        notCommercialOnly: {
          $and: [
            { '["watched", "input.is_commercial_only"]': { $neq: true } },
            { '["watched", "input.type"]': { $neq: 'Passenger' } },
          ],
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.personalMilesPaidFor': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.personalMiles',
          fallback: 'Include Personal Miles',
        },
      },
      name: 'personalMilesPaidFor',
    },
    schema: {
      default: {
        'margin-top': '16px',
      },
    },
  },
  'app.onboarding.usage.form.fields.milesPerWorkday': {
    _extends: 'app.common.field',
    config: {
      label: {
        htmlFor: 'milesPerWorkday',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.milesPerWorkday',
          fallback: 'Average Business Miles Per Vehicle (Per Day)',
        },
      },
      input: {
        type: 'number',
        component: 'FormInput',
        name: 'milesPerWorkday',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.milesPerWorkday.placeholder',
            fallback: 'Enter Average Business Miles Per Vehicle (Per Day)',
          },
        },
      },
      description: null,
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.milesPerWorkday.required',
            fallback:
              'Average business miles per vehicle (per day) is required',
          },
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.personalMilesPerWorkday': {
    _extends: 'app.common.field',
    components: {
      description: null,
    },
    config: {
      watch: {
        name: 'personalMilesPaidFor',
      },
      hiddenValue: 0,
      shouldHide:
        '(function ({ personalMilesPaidFor }) { return !personalMilesPaidFor })',
      label: {
        htmlFor: 'personalMilesPerWorkday',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.personalMilesPerWorkday',
          fallback: 'Average Personal Miles (Per Day) *',
        },
      },
      input: {
        type: 'number',
        component: 'FormInput',
        name: 'personalMilesPerWorkday',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.personalMilesPerWorkday.placeholder',
            fallback: 'Enter Average Personal Miles (Per Day)',
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.milesPerWorkday.required',
            fallback: 'Average personal miles (per day) is required',
          },
        },
      },
    },
  },
  'app.onboarding.usage.form.fields.hoursPerWorkdayMedium': {
    _extends: 'app.common.field',
    config: {
      label: {
        htmlFor: 'hoursPerWorkdayMedium',
        i18n: {
          id: 'i18n.onboarding.usage.form.fields.hoursPerWorkdayMedium',
          fallback: 'Hours of use per day',
        },
      },
      input: {
        type: 'number',
        component: 'FormInput',
        name: 'hoursPerWorkdayMedium',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.hoursPerWorkdayMedium.placeholder',
            fallback: 'Enter Average Hours of User (Per Day)',
          },
        },
      },
      description: null,
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.onboarding.usage.form.fields.hoursPerWorkdayMedium.required',
            fallback:
              'Average business miles per vehicle (per day) is required',
          },
        },
      },
    },
    schema: {
      default: {
        '& label::after': {
          content: '" *"',
          color: 'var(--color-error)',
          display: 'inline',
        },
      },
    },
  },
  'app.onboarding.usage.form.footer': {
    _extends: 'app.onboarding.common.form.footer',
    config: {
      content: [
        ['ContentContainer', 'app.onboarding.usage.footer.button.back'],
        ['SmartNextButton', 'app.onboarding.usage.footer.smartButton'],
      ],
      watch: {
        name: ['workdays', 'workmonths'],
      },
    },
  },
  'app.onboarding.usage.footer.button.back': {
    _extends: 'app.onboarding.common.footer.button.back',
    config: {
      to: '../selection',
    },
  },
  'app.onboarding.usage.footer.smartButton': {
    _extends: 'app.onboarding.common.footer.smartButton.next',
    config: {
      to: '../charging',
      disabled:
        '(function ({workdays, workmonths}, isValid) { return workdays.length === 0 || workmonths.length === 0 || !isValid;})',
    },
  },
  'app.onboarding.usage.heading': {
    _extends: 'app.onboarding.common.heading',
    config: {
      i18n: {
        id: 'i18n.onboarding.usage.heading',
        fallback: 'Vehicle Usage',
      },
    },
  },
  'app.onboarding.usage.description': {
    _extends: 'app.onboarding.common.description',
    config: {
      i18n: {
        id: 'i18n.onboarding.usage.description',
        fallback:
          'This information drives range and charging requirements determining EV and charger recommendations. Please fill out based on the conventional gas/diesel vehicle you are replacing or planning to replace with your electric vehicle.',
      },
    },
  },
};

export default ONBOARDING_USAGE;
