// @ts-nocheck

export const convertFrom24HsToAMPM = (value: number) => {
  if (typeof value !== "number") return value;

  if (value > 12) return `${(value - 12).toString().padStart(2, "0")}:00 PM`;
  return `${value.toString().padStart(2, "0")}:00 AM`;
};

export const convertFrom24HsToHours = (_value: number) => {
  const value = parseInt(_value);
  if (value >= 12){
    return `${(value === 12 ? value : value - 12).toString()}pm`;
  }
  if (value > 0 && value < 12) return `${value.toString()}am`;
  return `12am`;
};

export const convertFromAMPMto24Hs = (value: string) => {
  if (typeof value === "number") return value;

  const hours = Number(value.match(/^(\d+)/)?.[1]);
  if (hours > 12) return hours;

  const AMPM = value.match(/\s(.*)$/)?.[1];
  if (AMPM === "PM" && hours < 12) return hours + 12;
  return hours;
};

export const convertObjectArrayToHourArray = (elements, keys) => {
  return elements.reduce((acc, f) => {
    const [firstAttr, secondAttr] = keys;

    const start = convertFromAMPMto24Hs(f[firstAttr]);
    const end = convertFromAMPMto24Hs(f[secondAttr]) || 24;
    const length = start > end ? Math.abs(start - end - 24) : end - start;

    const range = [];
    for (let i = 0; i <= length; i += 1) {
      const nextValue = start + i;
      range.push(nextValue > 23 ? nextValue - 24 : nextValue);
    }

    return acc.concat(range);
  }, []);
};
