import { useState, useEffect, memo } from 'react';
import Lottie from 'lottie-react';

export const LottieAnimation = memo(
  ({ content }: { content?: string }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: content,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return <Lottie {...defaultOptions} />;
  },
  (prev: any, next: any) => {
    if (!prev.content && !!next.content) {
      return false;
    }
    return true;
  }
);

export default ({ uri, fallback }: { uri: string; fallback: string }) => {
  const [content, setContent] = useState();

  const fetchSpinner = (uri: string, isFallback: boolean = false) => {
    fetch(uri)
      .then((res) => res.json())
      .then((res) => {
        setContent(res);
      })
      .catch((err) => {
        if(!isFallback){
          fetchSpinner(fallback, true);
        }
      });
  };

  useEffect(() => {
    fetchSpinner(uri);
  }, []);

  return <LottieAnimation content={content} />;
};
