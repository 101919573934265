// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { VehicleSetData } from '../../types/form.types';
import { Location } from '../../types/location.types';
import { convertToVehicleSet } from '../calculations/calculations';
import moment from 'moment-timezone';
import { TotalKwhParams } from '@zappy-ride/library.calculations/dist/calcs/Base/Base.types';
import { isMilitaryRelated, isPublicSchoolDistrict } from '../../utils/organizationTypes';

export function projectSite(
  { power_supplier, region }: Location,
  { zipcode, forMultipleSites: for_multiple_sites, inADAC }: VehicleSetData
) {
  return {
    address: {
      postcode: `${zipcode}`,
      power_supplier: {
        name: power_supplier?.[0]?.name,
      },
      region,
    },
    for_multiple_sites,
    in_a_dac: inADAC === 'Yes' ? true : false,
  };
}

export function projectOrganization({
  organizationType: organization_type,
  filters: {
    isPrioritySchoolDistrict: is_priority_district,
    isMilitary: is_military,
  },
}: VehicleSetData) {
  return {
    organization_type,
    is_priority_district: !isPublicSchoolDistrict(organization_type) ? false : is_priority_district,
    is_military: !isMilitaryRelated(organization_type) ? false : is_military,
    is_tax_exempt: false,
    is_public: false,
  };
}

const workAroundChargerType = {
  AC: 'level_2',
  DC: 'dc_fast_charging',
};
export function projectCharger(
  { vehicleCount: count, chargingWindows }: VehicleSetData,
  chargerWindowIndex: number
) {
  const {
    kw: charger_power_level,
    chargerType,
    charger_type,
    certified_by,
    certifications,
    ports: number_of_connections,
    price: purchase_price,
    charger_id,
    networked,
    connector_types,
    load_balancing,
    load_balancing_protocol,
    home_outlet
  } = chargingWindows[chargerWindowIndex].charger;

  return {
    count,
    details: {
      charger_power_level,
      charger_type,
      is_energy_star_certified: certified_by?.includes('Energy Star'),
      number_of_connections,
      purchase_price,
      type: 'charger',
      certifications: certified_by,
      socket_types: connector_types,
      load_balancing: load_balancing_protocol || [],
      installation_type: home_outlet !== null
          ? (home_outlet?.includes('Hardwired')
              ? 'hardwired': 'plug-in')
          : null
    },
    identifier: charger_id,
    is_networked: networked,
    is_open_to_public: false,
  };
}

export function projectVehicleSet({
  vehicleSet,
  vehicleSetData,
  totalKwhFn,
}: {
  vehicleSet: VehicleSetData;
  vehicleSetData: VehicleSetData;
  totalKwhFn: (props: TotalKwhParams) => number;
}) {
  const {
    vehicleCount: count,
    vehicleSetName: name,
    input,
    output,
  } = vehicleSet;

  const { lifespanYears: years_of_operation, year } =
    vehicleSetData.assumptionsData;

  const ev = input.kind === 'ev' ? input : output;
  const incremental_cost = input.kind === 'ev' ? input.msrp - output.msrp : output.msrp - input.msrp;

  const {
    fuel,
    handle,
    msrp,
    form_factor,
    type: vehicle_type,
    battery_capacity,
    make = 'Custom',
    model = '',
    model_raw = '',
    model_year = '',
    subtype,
    trim = '',
    weight_class,
    gvwr,
  } = ev;

  const set = convertToVehicleSet({
    ...vehicleSetData,
    ...vehicleSet,
  });

  const annual_kwh = totalKwhFn({ input: set, year });

  return {
    name,
    count,
    details: {
      annual_kwh,
      battery_capacity,
      make,
      model: model_raw || model,
      model_year,
      msrp,
      subtype: subtype?.toLowerCase() || subtype,
      trim,
      weight_class,
      years_of_operation,
      type: 'vehicle',
      fuel: fuel.toLowerCase(),
      vehicle_type,
      form_factor: form_factor === 'Crossover' ? 'SUV' : form_factor,
      purchase_price: msrp,
      replaces_ice_vehicle: true,
      identifier: handle,
      lease_length: null,
      eligible_for_ca_rebate: false,
      gvwr,
      incremental_cost
    },
    type: 'vehicle',
  };
}

export function prepareProject(
  location: Location,
  vehicleSetData: VehicleSetData,
  vehicleSets: Array<VehicleSetData>,
  totalKwhFn: (props: TotalKwhParams) => number
) {
  const { assumptionsData } = vehicleSetData;
  const {
    lifespanYears,
    chargerInstallationCost,
    customChargerInstallationCost,
    customChargerInstallationCostToggler,
  } = assumptionsData;

  const chargers = vehicleSets.reduce((acc, setData) => {
    const value = acc.concat(
      setData.chargingWindows.map((_, index) => projectCharger(setData, index))
    );
    return value;
  }, []);

  // const isTransitParkingFacility = vehicleSets.some(
  //   ({ replacementVehicle }) => {
  //     return replacementVehicle.formFactor === "transit_bus";
  //   }
  // );

  let year = assumptionsData.year;

  if (moment(year, 'Y').isLeapYear()) {
    year += 1;
  }

  const enhancedVehicleSetData = {
    ...vehicleSetData,
    assumptionsData: {
      ...assumptionsData,
      year,
    },
  };

  const input = vehicleSets.map((vehicleSet) =>
    convertToVehicleSet({
      ...vehicleSetData,
      ...vehicleSet,
    })
  );

  const annual_kwh = totalKwhFn({ input, year });

  const customInstallationCost = parseInt(customChargerInstallationCost);

  // sometimes chargerInstallationCost is null even if the user has checked the custom cost
  // but the customInstallationCost has the calculated value
  // that's why the || operator is used
  let projected_cost = chargerInstallationCost || customInstallationCost;

  if (customChargerInstallationCostToggler) {
    projected_cost = customInstallationCost;
  }

  return {
    project_type: 'fleets',
    projected_cost,
    // locationEnum: isTransitParkingFacility
    //   ? "transit_parking_facility"
    //   : "workplace_facility",
    estimated_installation_costs: projected_cost,
    locationEnum: 'workplace_facility',
    years_of_operation: lifespanYears,
    chargers,
    organization: projectOrganization(vehicleSetData),
    site: projectSite(location, vehicleSetData),
    vehicles: vehicleSets.map((vehicleSet) =>
      projectVehicleSet({
        totalKwhFn,
        vehicleSet,
        vehicleSetData: enhancedVehicleSetData,
      })
    ),
    annual_kwh,
  };
}

type CustomEmissionsDataType = {
  dieselEmissionsinLbsPerGal: number;
  gasolineEmissionsInLbsPerGal: number;
  electricityEmissions: number;
  isDiesel: boolean;
}
export const customEmissionsForDefaultAssumptions = ({
  dieselEmissionsinLbsPerGal,
  gasolineEmissionsInLbsPerGal,
  electricityEmissions,
  isDiesel,
}:CustomEmissionsDataType) => ({
  customElectric: electricityEmissions,
  customIce: isDiesel
    ? dieselEmissionsinLbsPerGal
    : gasolineEmissionsInLbsPerGal,
  isDiesel,
  dieselEmissionsinLbsPerGal,
  gasolineEmissionsInLbsPerGal,
})
