import {
  CalculationResultsInfo,
  FuelInfo,
  CalculationResultsGroup,
  RateCostInfo,
  VehiclesInfo,
  FuelCostInfo,
} from '@zappy-ride/library.calculations';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { FormVehicleDataSet, fuelTableType } from '../../../types/tables.types';
import { getVehicleFleetData } from '../../../presenters/calculations/calculations';
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import zappy from '@zappy-ride/library.react.components';

export const fuelCosts = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { 'assumptionsData.selectedRateId': selectedRateId } = zappy.useWatch({
    name: ['assumptionsData.selectedRateId'],
  }) as any;

  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean, boolean, boolean];

  const [annualFuelInfo, cumulativeFuelInfo, annualMiles, fuelStatus] =
    getStateValue({
      lookupKey: [
        'total.annual.fuel',
        'total.cumulative',
        'total.annual.vehicles.miles',
        'status.fuel.isReady',
      ],
      states: ['fuel', 'vehicles'],
    }) as [FuelInfo, Array<CalculationResultsInfo>, number, boolean];

  const lifetimeFuelInfo = cumulativeFuelInfo?.at(-1)?.fuel;
  const rateIndex = annualFuelInfo?.electricRatesInfo?.findIndex((rate) => rate?.rate?.name === selectedRateId);
  const totalCost = get(annualFuelInfo, 'cost.electric.total');
  const annualElectricFuelCost = (
    get(annualFuelInfo,
      `electricRatesInfo[${rateIndex}].totalCost`,
      totalCost)
  ) as number;
  const annualFossilFuelCost = get(annualFuelInfo, 'cost.fossil.total');
  const annualFuelSavings = get(annualFuelInfo, 'savings.total');

  const electricRatesInfo = get(annualFuelInfo, 'electricRatesInfo');

  const enableSimpleRate = getValues('rateSelection.enableSimpleRate');

  const ratesInfo = electricRatesInfo
    ?.filter((r) => (enableSimpleRate ? true : r.rate.name !== 'simple'))
    ?.reduce<Record<string, unknown>>((acc, { rate, cost }) => {
      acc[rate.name] = {
        ...cost,
        label: rate.longName,
        otherCost: cost.otherCost || 0,
      };
      return acc;
    }, {});

  const estimatedAnnualAverageCost =
    ratesInfo?.[selectedRateId];

  const lifetimeElectricFuelCost = get(
    lifetimeFuelInfo,
    'cost.electric.total',
    0
  );
  const lifetimeFossilFuelCost = get(lifetimeFuelInfo, 'cost.fossil.total', 0);
  const lifetimeFuelSavings = get(lifetimeFuelInfo, 'savings.total', 0);

  const yearlyFuelCost = cumulativeFuelInfo?.map((el, idx) => {
    return {
      annualElectricFuelCost,
      annualFossilFuelCost,
      annualFuelSavings,
    };
  });

  const totalMileage = annualMiles * yearlyFuelCost?.length;
  const fossilFuelCostPerMile = annualFossilFuelCost / annualMiles;
  const electricFuelCostPerMile = annualElectricFuelCost / annualMiles;

  const rates = getValues('assumptionsData.rates');


  // is loading till all status came as true
  const isLoading = !fuelStatus;

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation = !(calculationsStatus && inputsStatus);

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      annualElectricFuelCost: zappy.formatters.formatAsThousands(
        annualElectricFuelCost
      ),
      annualFossilFuelCost:
        zappy.formatters.formatAsThousands(annualFossilFuelCost),
      annualFuelSavings: zappy.formatters.formatAsThousands(annualFuelSavings),
      lifetimeElectricFuelCost: zappy.formatters.formatAsThousands(
        lifetimeElectricFuelCost
      ),
      lifetimeFossilFuelCost: zappy.formatters.formatAsThousands(
        lifetimeFossilFuelCost
      ),
      lifetimeFuelSavings:
        zappy.formatters.formatAsThousands(lifetimeFuelSavings),
      yearlyFuelCost,
      totalMileage: zappy.formatters.formatAsThousands(totalMileage),
      fossilFuelCostPerMile,
      electricFuelCostPerMile,
      estimatedAnnualAverageCost,
      ratesInfo: ratesInfo || {},
      selectedRateDescriptionFormatted: get(rates, [
        selectedRateId,
        'descriptionFormatted',
      ]),
      selectedRateName: get(rates, [selectedRateId, 'longName']),
    },
  };
};

export const fuelCostsTable = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { vehicleSets = [], assumptionsData = {} } = getValues();

  const [fuelStatus, vehiclesStatus, calculationsStatus, inputsStatus] =
    getStateValue({
      lookupKey: [
        'fuel.isReady',
        'vehicles.isReady',
        'calculations.isReady',
        'inputs.isReady',
      ],
      path: 'status',
    }) as [boolean, boolean, boolean, boolean];

  const perSet = getStateValue({
    lookupKey: 'perSet',
    states: ['vehicles', 'fuel'],
  }) as Record<string, CalculationResultsGroup>;

  const getFuelCostDataPerSet = (setId: string) => {
    const vehicleSet = perSet?.[setId];
    return {
      vehicleCount: get(vehicleSet, 'annual.vehicles.count'),
      annualMiles: get(vehicleSet, 'annual.vehicles.miles'),
      annualHours: get(vehicleSet, 'annual.vehicles.hours'),
      electricAnnualFuelCost: get(
        vehicleSet,
        'annual.fuel.cost.electric.total'
      ),
      fossilAnnualFuelCost: get(vehicleSet, 'annual.fuel.cost.fossil.total'),
    };
  };

  const contentElectricFuelFleetTotal: fuelTableType & { hours: number } = {
    setName: 'Electric Fleet Total',
    fuelCost: null,
    distance: 0,
    hours: 0,
    efficiency: null,
    count: 0,
    totalCost: 0,
    type: 'electric',
  };

  const { contentFossilFuelFleet, contentElectricFuelFleet } =
    vehicleSets.reduce(
      (acc: Record<string, Array<unknown>>, vehicleSet: FormVehicleDataSet) => {
        const fuelCostData = getFuelCostDataPerSet(vehicleSet.id);
        acc.contentFossilFuelFleet.push(
          getVehicleFleetData(
            vehicleSet,
            fuelCostData,
            vehicleSet?.output?.fuel,
            assumptionsData
          )
        );
        const electricFleetData = getVehicleFleetData(
          vehicleSet,
          fuelCostData,
          vehicleSet?.input?.fuel,
          assumptionsData
        );

        //Total Fleets Electric Data
        contentElectricFuelFleetTotal.distance +=
          electricFleetData.distance || 0;
        contentElectricFuelFleetTotal.count += electricFleetData.count || 1;
        contentElectricFuelFleetTotal.totalCost +=
          electricFleetData.totalCost || 0;
        contentElectricFuelFleetTotal.hours += electricFleetData.hours || 0;
        acc.contentElectricFuelFleet.push(electricFleetData);
        return acc;
      },
      {
        contentFossilFuelFleet: [],
        contentElectricFuelFleet: [],
      }
    );

  const [costs, vehicles] = getStateValue({
    lookupKey: ['fuel.cost', 'vehicles'],
    path: 'total.cumulative[-1]',
  }) as [FuelCostInfo, VehiclesInfo];

  const totalFossilFuelCost = get(costs, 'fossil.total', 0);
  let totalElectricFuelCost = get(costs, 'electric.electricity', 0);
  const totalMileage = get(vehicles, 'miles', 0);

  if (assumptionsData.isExternalRateEngine) {
    const annualFuelCost = getStateValue({
      lookupKey: 'total.annual.fuel.cost.electric.total',
    }) as number;
    contentElectricFuelFleetTotal.totalCost = annualFuelCost;
    totalElectricFuelCost = annualFuelCost * assumptionsData.lifespanYears;
  }

  const isOffRoad = getValues('vehicleSets[0].input.is_offroad') || false;
  const isLoading =
    (!calculationsStatus && !inputsStatus && !vehiclesStatus && !fuelStatus) ||
    costs === undefined;

  return {
    status: {
      isLoading,
      missingRequiredInformation: isLoading,
    },
    data: {
      isOffRoad,
      contentFossilFuelFleet,
      contentElectricFuelFleet,
      contentElectricFuelFleetTotal: [contentElectricFuelFleetTotal],
      isCustomRateEngine: assumptionsData.isExternalRateEngine,
      globalData: {
        millage: totalMileage,
        savingsTotal: totalFossilFuelCost - totalElectricFuelCost,
        perMileFossil: totalFossilFuelCost / (totalMileage || 1),
        perMileElectric: totalElectricFuelCost / (totalMileage || 1),
      },
      globalFossil: {
        lifetimeTotal: totalFossilFuelCost,
        annualTotal: totalFossilFuelCost / (assumptionsData.lifespanYears || 1),
      },
      globalElectric: {
        lifetimeTotal: totalElectricFuelCost,
        annualTotal:
          totalElectricFuelCost / (assumptionsData.lifespanYears || 1),
      },
    },
  };
};

export const ratesTable = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  const [rateInfo] = getStateValue({
    lookupKey: ['total.annual.fuel.cost.electric.rateInfo'],
  }) as [RateCostInfo];
  const totalRates = Object.values(rateInfo || {}).reduce(
    (acc, obj) => acc + obj,
    0
  );

  // is loading till all status came as true
  const isLoading = !calculationsStatus && !inputsStatus;

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation = !(calculationsStatus && inputsStatus);

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      ratesTable: [],
      ratesTableInfo: {
        fixedCharges: rateInfo?.fixedCost,
        energyCharges: rateInfo?.energyCost,
        demandCharges: rateInfo?.demandCost,
        otherCharges: rateInfo?.otherCost,
        totalCharges: totalRates,
      },
    },
  };
};
