// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";

import { prepareGenericVehicles, prepareVehicles } from "./vehicles";

export * from "./vehicles";

zappy.PreloadsProvider.registerPresenter("vehicles.presenter", prepareVehicles);
zappy.PreloadsProvider.registerPresenter(
  "generic.presenter",
  prepareGenericVehicles
);
