/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';
import zappy from '@zappy-ride/library.react.components';
import { InterceptorContext } from '../../contexts/Interceptor';

export const ActionComponent = ({ design, ...props }: { design: string }) => {
  const {
    config: { eventName, actions, component, ...factoryConfig },
  } = zappy.useDesign(design);
  const callAction = useContext(InterceptorContext);

  const eventProps = {
    [eventName]: (...args) => {
      actions?.forEach((action) => {
        callAction({
          ...args,
          ...action,
          ...props,
        });
      });
    },
  };

  return (
    <zappy.FactoryComponent
      design={design}
      component={component}
      {...props}
      {...eventProps}
      {...factoryConfig}
      data-design={`${design}.action`}
    />
  );
};

ActionComponent.displayName = 'ActionComponent';
