/* eslint-disable no-template-curly-in-string */

import { DESKTOP_THRESHOLD, TABS } from '../../base';

/* eslint-disable import/no-anonymous-default-export */
export default {
  'app.output': {
    config: {
      as: 'main',
      id: 'main',
      content: [
        ['OutputTemplate', 'app.output.template'],
        ['ContentContainer', 'app.output.template.mobile.navigation'],
        ['ActionComponent', 'app.output.template.contactUs'],
      ],
      scrollTop: true,
    },
    schema: {
      default: {
        '&.display-navigation': {
          '@media (max-width: 992px)': {
            '[data-design="app.output.template.mobile.navigation"]': {
              display: 'flex',
            },
          },
        },

        background:
          'linear-gradient(0deg, rgba(172, 177, 186, 0.25), rgba(172, 177, 186, 0.25)), #FFFFFF;',
      },
    },
  },
  'app.output.template': {
    components: {
      summary: 'app.output.template.summary',
      filters: 'app.output.template.filters',
      routes: 'app.output.template.routes',
      footer: 'app.template.footer',
      container: 'app.output.template.container',
    },
    schema: {
      default: {
        margin: '24px',

        '@media (max-width: 992px)': {
          margin: '16px',
          '&.display-navigation': {
            display: 'none',
          },
        },

        '&.display-filters': {
          '@media (max-width: 992px)': {
            '[data-design="app.output.template.filters"]': {
              display: 'block',
            },
            '[data-design="app.output.template.routes.layout.header"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.overview"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.fuelCosts"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.electricity"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.charging"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.vehicles"]': {
              display: 'none',
            },
            '[data-design="app.output.template.routes.incentives"]': {
              display: 'none',
            },
            '[data-design="app.output.template.filters.mobile.button.back.action"]':
              {
                display: 'flex',
              },
          },
        },
      },
    },
  },
  'app.output.template.contactUs': {
    config: {
      watch: {
        name: ['sessionID'],
      },
      eventName: 'onClick',
      actions: [
        {
          type: 'form/analyticsTrack',
          payload: {
            category: 'Lead Generation',
            action: 'Clicked Contact Us',
            label: 'Contact Us',
          },
        },
      ],
      component: 'Link',
      to: '/?sessionID={{sessionID}}',
      content: [['Tooltip', 'app.output.template.contactUs.tooltip']],
      target: '_blank',
      'aria-label': 'Contact us will open in a new tab',
    },
    schema: {
      default: {
        position: 'fixed',
        zIndex: 1000,
        bottom: '85px',
        left: 'auto',
        right: '4vw',

        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '64px',
        height: '64px',
        'border-radius': '50%',
        background: 'var(--primary-color)',
      },
    },
  },
  'app.output.template.contactUs.tooltip': {
    _extends: 'app.common.tooltip',
    components: {
      activator: 'app.output.template.contactUs.tooltip.activator',
      popover: 'app.output.template.contactUs.tooltip.popover',
    },
    schema: {
      default: {
        'margin-left': '0px',
      },
    },
  },
  'app.output.template.contactUs.tooltip.activator': {
    config: {
      component: 'Icon',
      src: 'https://assets.zappyride.com/img/icons/evergy/contactUs-notBackground.svg',
      as: 'img',
      alt: 'Talk to an expert',
      to: '',
    },
    schema: {
      default: {
        height: '26px',
      },
    },
  },
  'app.output.template.contactUs.tooltip.popover': {
    _extends: 'app.common.tooltip.popover',
    config: {
      position: 'N',
      alwaysOn: true,
      content: [
        [
          'TranslatedText',
          'app.output.template.contactUs.tooltip.popover.text',
        ],
      ],
    },
    schema: {
      default: {
        padding: '4px 3px',
        width: 120,
        'text-align': 'center',
        zIndex: 1001,
        marginTop: -20,
      },
    },
  },
  'app.output.template.contactUs.tooltip.popover.text': {
    config: {
      element: 'span',
      i18n: {
        id: 'i18n.template.footer.contactUs.tooltip.popover.text',
        fallback: 'Need Help? Talk to an Expert',
      },
    },
  },

  'app.output.template.mobile.navigation': {
    config: {
      content: [
        ['OutputTabs', 'app.output.template.routes.layout.header.tabs.mobile'],
        ['ActionComponent', 'app.output.template.mobile.navigation.close'],
      ],
    },
    schema: {
      default: {
        display: 'none',
        padding: '16px',
        'align-items': 'center',
        'flex-direction': 'column',
        'background-color': 'white',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        'margin-left': 'auto',
        'margin-right': 'auto',
        width: '100%',
        height: '100%',
        'padding-top': '64px',
      },
    },
  },

  'app.output.template.mobile.navigation.close': {
    components: {
      button: 'app.output.template.mobile.navigation.close.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'output/toggleNavigation',
        },
      ],
      component: 'SmartNextButton',
    },
  },

  'app.output.template.mobile.navigation.close.button': {
    config: {
      component: 'Button',
      content: [
        ['Icon', 'app.output.template.mobile.navigation.close.button.icon'],
      ],
    },
    schema: {
      default: {
        width: '40px',
        padding: '0px',
        justifySelf: 'end',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.mobile.navigation.close.button.icon': {
    config: {
      component: 'Icon',
      src: 'https://assets.zappyride.com/img/icons/evergy/close.svg',
      as: 'img',
    },
  },

  // #### R O U T E S ####
  'app.output.template.routes': {
    config: {
      basePath: '/',
      index: '/output/overview',
      content: [
        ['HeaderRoutesFooterLayout', 'app.output.template.routes.layout'],
      ],
      routes: [
        {
          path: 'overview',
          design: 'app.output.template.routes.overview',
        },
        {
          path: 'fuelCosts',
          design: 'app.output.template.routes.fuelCosts',
        },
        {
          path: 'electricity',
          design: 'app.output.template.routes.electricity',
        },
        {
          path: 'incentives',
          design: 'app.output.template.routes.incentives',
        },
        {
          path: 'charging',
          design: 'app.output.template.routes.charging',
        },
        {
          path: 'operations',
          design: 'app.output.template.routes.operations',
        },
        {
          path: 'vehicles',
          design: 'app.output.template.routes.vehicles',
        },
        {
          path: 'emissions',
          design: 'app.output.template.routes.emissions',
        },
        {
          path: 'lcfs',
          design: 'app.output.template.routes.lcfs',
        },
        {
          path: 'financing',
          design: 'app.output.template.routes.financials',
        },
      ],
    },
  },
  'app.output.template.routes.layout': {
    components: {
      header: 'app.output.template.routes.layout.header',
    },
    schema: {
      default: {
        display: 'flex',
        'flex-direction': 'column',
      },
    },
  },
  'app.output.template.routes.layout.header': {
    config: {
      content: [
        ['OutputTabs', 'app.output.template.routes.layout.header.tabs'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.routes.layout.header.tabs': {
    components: {
      linksWrapper:
        'app.output.template.routes.layout.header.tabs.linksWrapper',
      link: 'app.output.template.routes.layout.header.tabs.link',
      nonActiveLink:
        'app.output.template.routes.layout.header.tabs.nonActiveLink',
      activeLink: 'app.output.template.routes.layout.header.tabs.activeLink',
      previous: 'app.output.template.routes.layout.header.tabs.icon.previous',
      next: 'app.output.template.routes.layout.header.tabs.icon.next',
    },
    config: {
      pageSize: 3,
      initPage: 0,
      watch: {
        name: ['zipcode'],
      },
      options: TABS,
    },
    schema: {
      default: {
        display: 'flex',
        'margin-bottom': '24px',
        padding: '0 24px',
        position: 'relative',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.linksWrapper': {
    schema: {
      default: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-around',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.mobile': {
    _extends: 'app.output.template.routes.layout.header.tabs',
    components: {
      link: 'app.output.template.routes.layout.header.tabs.link.mobile',
      linksWrapper:
        'app.output.template.routes.layout.header.tabs.mobile.linksWrapper',
    },
    config: {
      pageSize: 8,
      initPage: 0,
    },
    schema: {
      default: {
        width: '100%',
        'flex-direction': 'column',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.mobile.linksWrapper': {
    _extends: 'app.output.template.routes.layout.header.tabs.linksWrapper',
    schema: {
      default: {
        flexDirection: 'column',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.icon': {
    config: {
      src: 'caret-down',
      size: 'sm',
    },
    schema: {
      default: {
        position: 'absolute',
        'background-color': '#2A2A2A',
        margin: 'auto',
        bottom: 0,
        top: 0,
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.icon.previous': {
    _extends: 'app.output.template.routes.layout.header.tabs.icon',
    config: {
      title: 'Previous',
    },
    schema: {
      default: {
        left: 0,
        transform: 'rotate(0.25turn)',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.icon.next': {
    _extends: 'app.output.template.routes.layout.header.tabs.icon',
    config: {
      title: 'Next',
    },
    schema: {
      default: {
        right: 0,
        transform: 'rotate(-0.25turn)',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.link.mobile': {
    _extends: 'app.output.template.routes.layout.header.tabs.link',
    schema: {
      default: {
        display: 'flex',

        height: '66px',
        'align-items': 'center',
        'justify-content': 'center',
        'text-align': 'center',
        'border-bottom': '2px solid #E5E5E5',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.link': {
    schema: {
      default: {
        padding: '8px 0',
        display: 'inline-block',
      },
      active: {
        'border-bottom': '2px solid #76BB40',
      },
    },
  },
  'app.output.template.routes.layout.header.tabs.activeLink': {
    config: {
      as: 'h1',
    },
  },
  'app.output.template.routes.layout.header.tabs.nonActiveLink': {
    config: {
      as: 'span',
    },
  },
  'app.output.template.routes.electricity': {
    config: {
      role: 'tabpanel',
      content: [
        [
          'OutputElectricity',
          'app.output.template.routes.electricity.template',
        ],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.overview': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputOverview', 'app.output.template.routes.overview.template'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.fuelCosts': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputFuelCosts', 'app.output.template.routes.fuelCosts.template'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.charging': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputCharging', 'app.output.template.routes.charging.template'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.operations': {
    config: {
      role: 'tabpanel',
      content: [
        [
          'OutputOperation',
          'app.output.template.routes.operations.template.operations',
        ],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.vehicles': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputVehicles', 'app.output.template.routes.vehicles.template'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.emissions': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputEmissions', 'app.output.template.routes.emissions.template'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.lcfs': {
    config: {
      role: 'tabpanel',
      content: [
        ['OutputLCFS', 'app.output.template.routes.lcfs.template.lcfs'],
      ],
      scrollTop: false,
    },
  },
  'app.output.template.routes.financials': {
    config: {
      role: 'tabpanel',
      content: [['OutputFinancials', '']],
      scrollTop: false,
    },
  },
  'app.output.template.routes.fuelCosts.template': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.fuelCosts.template.fleetElectricityCosts',
        ],
      ],
    },
  },
  'app.output.common.collapsible': {
    components: {
      toggleButton: 'app.output.common.collapsible.toggleButton',
    },
    schema: {
      default: {
        "&:last-child > button[aria-expanded='false']": {
          'border-bottom': '1px solid transparent',
        },
      },
      contentWrapper: {
        default: {
          margin: '1rem 0',
        },
      },
    },
  },
  'app.output.common.collapsible.toggleButton': {
    schema: {
      default: {
        'border-top': '1px solid var(--color-gray-500)',
        padding: '1em 0.5em',
        "&[aria-expanded='true']": {
          'border-bottom': '1px solid var(--color-gray-500)',
        },
      },
    },
  },
  'app.output.common.collapsible.content': {
    schema: {
      default: {
        margin: '1rem 0',
      },
    },
  },
  'app.output.common.select.field': {
    _extends: 'app.common.field',
    components: {
      input: 'app.output.common.select',
    },
    config: {
      input: {
        component: 'FormSelect',
      },
    },
  },
  'app.output.common.select': {
    _extends: 'app.common.select',
    components: {
      selectButton: 'app.output.common.select.selectButton',
    },
  },
  'app.output.common.select.selectButton': {
    _extends: 'app.common.select.selectButton',
    components: {
      typography: 'app.output.common.select.selectButton.typography',
    },
    schema: {
      default: {
        'min-height': '2.5rem',
      },
    },
  },
  'app.output.common.select.selectButton.typography': {
    _extends: 'app.common.select.selectButton.typography',
    config: {
      fontWeight: '300',
    },
  },
  'app.output.common.multipleSelect.field': {
    _extends: 'app.output.common.select.field',
    components: {
      input: 'app.output.common.multipleSelect',
    },
  },
  'app.output.common.multipleSelect': {
    _extends: 'app.output.common.select',
    components: {
      options: 'app.output.common.multipleSelect.options',
    },
    config: {
      multiple: {
        i18n: {
          id: 'i18n.common.multipleSelect.multipleSelection',
          fallback: 'Multiple selected',
        },
      },
      placeholder: {
        id: 'i18n.common.multipleSelect.placeholder',
        fallback: 'All',
      },
    },
  },
  'app.output.common.multipleSelect.options': {
    _extends: 'app.common.select.options',
    components: {
      option: 'app.output.common.multipleSelect.options.option',
    },
  },
  'app.output.common.multipleSelect.options.option': {
    _extends: 'app.common.select.options.option',
    components: {
      multipleCheckbox:
        'app.output.common.multipleSelect.options.option.multipleCheckbox',
    },
  },
  'app.output.common.multipleSelect.options.option.multipleCheckbox': {
    _extends: 'app.onboarding.common.checkbox',
    components: {
      text: null,
    },
  },

  'app.output.common.field': {
    _extends: 'app.common.field',
    components: {
      input: 'app.output.common.field.input',
    },
  },
  'app.output.common.field.input': {
    _extends: 'app.common.field.input',
    schema: {
      default: {
        'min-height': '2.5rem',
        padding: '6.4px 12.8px',
      },
    },
  },
  'app.output.common.selectWithTooltip': {
    _extends: 'app.common.field',
    components: {
      input: 'app.output.common.select',
      tooltip: 'app.common.tooltip',
    },
    config: {
      input: {
        component: 'FormSelect',
      },
    },
    schema: {
      default: {
        'grid-template-areas':
          '"label label" "input tooltip" "description description"',
      },
    },
  },
  'app.output.common.fieldWithTooltip': {
    _extends: 'app.output.common.field',
    components: {
      tooltip: 'app.common.tooltip',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr',
        'grid-template-rows': 'min-content',
        'justify-items': 'start',
        'grid-template-areas':
          '"label label" "input tooltip" "error error" "description description"',
      },
    },
  },
  'app.output.filters.assumptions': {
    _extends: 'app.output.common.collapsible',
    components: {
      headerText: 'app.output.filters.assumptions.headerText',
      content: 'app.output.filters.assumptions.content',
    },
    schema: {
      default: {
        '[data-design="app.output.filters.assumptions"]': {
          display: 'flex',
          'flex-direction': 'column',
          'row-gap': '10px',
        },
      },
    },
  },
  'app.output.filters.assumptions.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.overview.filters.assumptions.headerText',
        fallback: 'Assumptions',
      },
    },
  },
  'app.output.filters.assumptions.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        ['FormField', 'app.output.filters.assumptions.content.vehicleLifespan'],
        [
          'FilterProjectOrganizationType',
          'app.output.filters.assumptions.content.organizationType',
        ],
        ['FormField', 'app.output.filters.assumptions.content.publicEntity'],
        ['FormField', 'app.output.filters.assumptions.content.taxExempt'],
      ],
    },
  },
  'app.output.filters.assumptions.content.vehicleLifespan': {
    _extends: 'app.output.common.selectWithTooltip',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.lifespanYears',
        i18n: {
          id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.label',
          fallback: 'Vehicle Lifespan (Years)',
        },
      },
      input: {
        name: 'filters.assumptionsData.lifespanYears',
        options: {
          1: {
            value: 1,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.1',
              fallback: '1 years',
            },
          },
          2: {
            value: 2,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.2',
              fallback: '2 years',
            },
          },
          3: {
            value: 3,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.3',
              fallback: '3 years',
            },
          },
          4: {
            value: 4,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.4',
              fallback: '4 years',
            },
          },
          5: {
            value: 5,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.5',
              fallback: '5 years',
            },
          },
          6: {
            value: 6,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.6',
              fallback: '6 years',
            },
          },
          7: {
            value: 7,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.7',
              fallback: '7 years',
            },
          },
          8: {
            value: 8,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.8',
              fallback: '8 years',
            },
          },
          9: {
            value: 9,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.9',
              fallback: '9 years',
            },
          },
          10: {
            value: 10,
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.vehicleLifespan.select.10',
              fallback: '10 years',
            },
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.filters.assumptions.content.vehicleLifespan.tooltip',
              fallback:
                'The vehicle lifetime is the expected length of time you plan to drive the vehicle. This value is used to calculate potential savings and potential operations costs.',
            },
          },
        },
      },
    },
  },
  'app.output.filters.assumptions.content.organizationType': {
    _extends: 'app.onboarding.info.form.organizationType',
    config: {
      hideSelection: true,
    },
    components: {
      field: 'app.output.filters.assumptions.content.organizationType.field',
    },
  },
  'app.output.filters.assumptions.content.organizationType.field': {
    _extends: 'app.onboarding.info.form.organizationType.field',
    components: {
      input:
        'app.output.filters.assumptions.content.organizationType.field.select',
      tooltip: 'app.common.tooltip',
    },
    config: {
      description: null,
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.filters.fuelCosts.content.orgType.tooltip',
              fallback:
                'The organization type to identify potential third party incentives.',
            },
          },
        },
      },
    },
  },
  'app.output.filters.assumptions.content.organizationType.field.select': {
    _extends: 'app.output.common.select',
    config: {
      component: 'FormSelect',
      name: 'filters.organizationType',
      preload: 'organization_types',
    },
  },
  'app.output.filters.assumptions.content.publicEntity': {
    _extends: 'app.common.select.yesnoWithTooltip',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.publicEntity',
        i18n: {
          id: 'i18n.output.overview.filters.assumptions.content.publicEntity.label',
          fallback: 'Public Entity',
        },
      },
      input: {
        name: 'filters.assumptionsData.publicEntity',
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.publicEntity.tooltip',
              fallback:
                'Used to indicate if your business is a public entity, the entity type is used to identify potential third party incentives.',
            },
          },
        },
      },
    },
  },
  'app.output.filters.assumptions.content.taxExempt': {
    _extends: 'app.common.select.yesnoWithTooltip',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.taxExempt',
        i18n: {
          id: 'i18n.output.overview.filters.assumptions.content.taxExempt.label',
          fallback: 'Tax Exempt',
        },
      },
      input: {
        name: 'filters.assumptionsData.taxExempt',
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.taxExempt.tooltip',
              fallback:
                'Used to indicate if your business is a tax exempt entity, the tax exempt field is used to identify potential third party incentives.',
            },
          },
        },
      },
    },
  },
  'app.output.filters.siteLocation': {
    _extends: 'app.output.common.collapsible',
    components: {
      headerText: 'app.output.filters.siteLocation.headerText',
      content: 'app.output.filters.siteLocation.content',
    },
    schema: {
      default: {
        '[data-design="app.output.filters.siteLocation"]': {
          display: 'flex',
          'flex-direction': 'column',
          'row-gap': '10px',
        },
      },
    },
  },
  'app.output.filters.siteLocation.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.overview.filters.siteLocation.headerText',
        fallback: 'Site Locations',
      },
    },
  },
  'app.output.filters.siteLocation.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        [
          'InputWithToggleCheckbox',
          'app.output.filters.siteLocation.content.zipCode',
        ],
        [
          'FormField',
          'app.output.filters.siteLocation.content.inADAC',
          { displayQuery: 'inADACByRegion' },
        ],
        [
          'InputWithToggleCheckbox',
          'app.output.filters.siteLocation.content.chargerInstallationCost',
        ],
        [
          'InputWithToggleCheckbox',
          'app.output.filters.siteLocation.content.emissions',
        ],
        [
          'InputWithToggleCheckbox',
          'app.output.filters.siteLocation.content.emissionsIce',
        ],
      ],
      watch: {
        name: ['assumptionsData.state'],
      },
      displayQueries: {
        inADACByRegion: {
          '["watched", "assumptionsData.state"]': { $eq: 'California' },
        },
      },
    },
  },
  'app.output.filters.siteLocation.content.zipCode': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox',
    components: {
      input: 'app.output.filters.siteLocation.content.zipCode.input',
      checkbox: 'app.output.filters.siteLocation.content.zipCode.checkbox',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '8px',
      },
    },
  },
  'app.output.filters.siteLocation.content.zipCode.input': {
    _extends: 'app.output.common.fieldWithTooltip',
    components: {
      input:
        'app.output.filters.siteLocation.content.chargerInstallationCost.input.input',
    },
    config: {
      watch: {
        name: ['powerSuppliers'],
      },
      label: {
        htmlFor: 'filters.zipcode',
        i18n: {
          id: 'i18n.app.output.filters.siteLocation.content.zipCode.input',
          fallback: 'Zipcode',
        },
      },
      input: {
        component: 'FormInput',
        name: 'filters.zipcode',
        placeholder: {
          i18n: {
            id: 'i18n.onboarding.info.form.onboardingZipcode.input.placeholder',
            fallback: '99999 or A9A 9A9',
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.pattern',
            fallback: 'Zip Code is required',
          },
        },
        pattern: {
          value:
            '/^$|^\\d{5}(\\d{4}|-\\d{4})$|^\\d{5}$|(^[a-zA-Z][0-9][a-zA-Z]( |)(|[0-9][a-zA-Z][0-9])$)/',
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.pattern',
            fallback: 'Not a valid zipcode',
          },
        },
        postcodeBySupplier: {
          i18n: {
            id: 'i18n.formProvider.form.field.input.errors.area',
            fallback:
              'Zip code is outside the service area of {{powerSuppliers}}. Only projects located within this service area are supported by this tool',
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            values: {
              eiaURL:
                'https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a',
              gasPricesUrl: 'https://gasprices.aaa.com/',
            },
            i18n: {
              id: 'i18n.onboarding.info.form.onboardingZipcode.input.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'Your location drives electricity and fuel costs. Electricity costs are sourced from <a href="{{eiaURL}}"  target="_blank">US Energy Information Administration</a>. Fuel costs are sourced from <a href="{{gasPricesUrl}}" target="_blank">AAA</a>.',
            },
          },
        },
      },
    },
    schema: {
      default: {
        'grid-row-gap': 0,
      },
    },
  },
  'app.output.filters.siteLocation.content.zipCode.checkbox': {
    _extends: 'app.onboarding.info.form.onboardingZipcode.forMultipleSites',
    config: {
      name: 'forMultipleSites',
    },
    schema: {
      default: {
        marginTop: 0,
      },
    },
  },
  'app.output.filters.siteLocation.content.inADAC': {
    _extends: 'app.common.select.yesnoWithTooltip',
    config: {
      label: {
        htmlFor: 'filters.inADAC',
        i18n: {
          id: 'i18n.output.overview.filters.assumptions.content.inADAC.label',
          fallback: 'In a DAC',
        },
      },
      input: {
        name: 'filters.inADAC',
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.content.inADAC.tooltip',
              fallback:
                'Disadvantaged Community (DAC) locations may be eligible to receive additional third party incentives.',
            },
          },
        },
      },
    },
  },
  'app.output.filters.siteLocation.content.chargerInstallationCost': {
    components: {
      input:
        'app.output.filters.siteLocation.content.chargerInstallationCost.input',
      checkbox:
        'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '8px',
      },
    },
  },
  'app.output.filters.siteLocation.content.chargerInstallationCost.input': {
    _extends: 'app.output.common.fieldWithTooltip',
    components: {
      input:
        'app.output.filters.siteLocation.content.chargerInstallationCost.input.input',
    },
    config: {
      watch: {
        name: [
          'filters.assumptionsData.customChargerInstallationCost',
          'filters.assumptionsData.customChargerInstallationCostFormatted',
          'assumptionsData.dcfcChargersInstallationCost',
          'assumptionsData.dcfcChargersInstallationCostFormatted',
          'assumptionsData.level2ChargersInstallationCost',
          'assumptionsData.level2ChargersInstallationCostFormatted',
        ],
        mapper: {
          'filters.assumptionsData.customChargerInstallationCost':
            'chargerInstallationCost',
          'filters.assumptionsData.customChargerInstallationCostFormatted':
            'chargerInstallationCostFormatted',
          'assumptionsData.dcfcChargersInstallationCost':
            'dcfcChargersInstallationCost',
          'assumptionsData.dcfcChargersInstallationCostFormatted':
            'dcfcChargersInstallationCostFormatted',
          'assumptionsData.level2ChargersInstallationCost':
            'level2ChargersInstallationCost',
          'assumptionsData.level2ChargersInstallationCostFormatted':
            'level2ChargersInstallationCostFormatted',
        },
      },
      skipReset: true,
      label: {
        htmlFor:
          'filters.assumptionsData.customChargerInstallationCostFormatted',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.label',
          fallback: 'Charger installation cost ($)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'filters.assumptionsData.customChargerInstallationCostFormatted',
        defaultValueName: 'dcfcChargersInstallationCost',
        placeholder: {
          i18n: {
            id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.input.placeholder',
            fallback: 'Type in your charger installation cost',
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            values: {
              url: 'https://www.energy.ca.gov/programs-and-topics/programs/clean-transportation-program/california-electric-vehicle',
            },
            i18n: {
              id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'The cost of installing the chargers including panels, trenching, and cabling etc. from the meter to the charger. Based on CALeVIP reported numbers, the weighted average cost to install each level 2 charger port is {{level2ChargersInstallationCostFormatted}} and each DC fast charger port is {{dcfcChargersInstallationCostFormatted}}. Source: <a href="{{url}}" target="_blank">California Energy Commission, July 2022</a>',
            },
          },
        },
      },
      validations: {
        pattern: {
          value: '/^-?(?:0|[1-9]\\d{0,2}(?:,?\\d{3})*)(?:\\.\\d+)?$/',
          i18n: {
            id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.input.error',
            fallback:
              'Not a valid cost value. Examples: 50,000 or 50000 or 50,000.00',
          },
        },
      },
    },
    schema: {
      default: {
        'grid-row-gap': 0,
      },
    },
  },
  'app.output.filters.siteLocation.content.chargerInstallationCost.input.input':
    {
      _extends: 'app.output.common.field.input',
      schema: {
        readonly: {
          opacity: 0.6,
        },
      },
    },
  'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox',
    components: {
      checkbox:
        'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.input',
      label:
        'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.label',
    },
    config: {
      name: 'filters.assumptionsData.customChargerInstallationCostToggler',
      disablesInputWhenChecked: false,
    },
    schema: {
      default: {
        marginTop: '0',
        alignItems: 'self-end',
      },
    },
  },
  'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.input':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.input',
      schema: {
        default: {
          marginTop: '0',
        },
      },
    },
  'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.label':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.label',
      config: {
        as: 'label',
        htmlFor: 'filters.assumptionsData.customChargerInstallationCostToggler',
        content: [
          [
            'TranslatedText',
            'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.label.value',
          ],
        ],
      },
    },
  'app.output.filters.siteLocation.content.chargerInstallationCost.checkbox.label.value':
    {
      config: {
        fontSize: '.875rem',
        fontWeight: 'bold',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.checkbox.label.value',
          fallback: 'Custom installation costs',
        },
      },
    },

  // Custom Electricity Emissions
  'app.output.filters.siteLocation.content.emissions': {
    components: {
      input:
        'app.output.filters.siteLocation.content.emissions.input',
      checkbox:
        'app.output.filters.siteLocation.content.emissions.checkbox',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '8px',
      },
    },
  },
  'app.output.filters.siteLocation.content.emissions.input': {
    _extends: 'app.output.common.fieldWithTooltip',
    components: {
      input:
        'app.output.filters.siteLocation.content.emissions.input.input',
      tooltip: null,
    },
    config: {
      watch: {
        name: [
          'filters.customEmissions.customElectric',
        ],
        mapper: {
          'filters.customEmissions.customElectric':
            'customElectricityEmissions',
        }
      },
      skipReset: true,
      label: {
        htmlFor:
          'filters.customEmissions.customElectric',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.emissions.label',
          fallback: 'Electricity Emissions (lbs CO₂/kWh)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'filters.customEmissions.customElectric',
        defaultValueName: 'customElectricityEmissions',
        type: 'number',
        placeholder: {
          i18n: {
            id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.input.placeholder',
            fallback: 'Type in your CO₂ projected emissions',
          },
        },
      },
      validations: {
        min: {
          value: 1,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.min',
            fallback: 'Efficiency must be between 1 and 1000',
          },
        },
        max: {
          value: 1000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.efficiency.max',
            fallback: 'Efficiency must be between 1 and 1000',
          },
        },
      },
    },
    schema: {
      default: {
        'grid-row-gap': 0,
      },
    },
  },
  'app.output.filters.siteLocation.content.emissions.input.input':
    {
      _extends: 'app.output.common.field.input',
      schema: {
        readonly: {
          opacity: 0.6,
        },
      },
    },
  'app.output.filters.siteLocation.content.emissions.checkbox': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox',
    components: {
      checkbox:
        'app.output.filters.siteLocation.content.emissions.checkbox.input',
      label:
        'app.output.filters.siteLocation.content.emissions.checkbox.label',
    },
    config: {
      name: 'filters.customEmissions.toggleElectric',
      disablesInputWhenChecked: false,
    },
    schema: {
      default: {
        marginTop: '0',
        alignItems: 'self-end',
      },
    },
  },
  'app.output.filters.siteLocation.content.emissions.checkbox.input':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.input',
      schema: {
        default: {
          marginTop: '0',
        },
      },
    },
  'app.output.filters.siteLocation.content.emissions.checkbox.label':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.label',
      config: {
        as: 'label',
        htmlFor: 'filters.customEmissions.toggleElectric',
        content: [
          [
            'TranslatedText',
            'app.output.filters.siteLocation.content.emissions.checkbox.label.value',
          ],
        ],
      },
    },
  'app.output.filters.siteLocation.content.emissions.checkbox.label.value':
    {
      config: {
        fontSize: '.875rem',
        fontWeight: 'bold',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.emissions.checkbox.label.value',
          fallback: 'Custom electricity emissions',
        },
      },
    },

  // Custom ICE Emissions
  'app.output.filters.siteLocation.content.emissionsIce': {
    components: {
      input:
        'app.output.filters.siteLocation.content.emissionsIce.input',
      checkbox:
        'app.output.filters.siteLocation.content.emissionsIce.checkbox',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '8px',
      },
    },
  },
  'app.output.filters.siteLocation.content.emissionsIce.input': {
    _extends: 'app.output.common.fieldWithTooltip',
    components: {
      input:
        'app.output.filters.siteLocation.content.emissionsIce.input.input',
      tooltip: null,
    },
    config: {
      watch: {
        name: [
          'filters.customEmissions.customIce',
        ],
        mapper: {
          'filters.customEmissions.customIce':
            'customIceEmissions',
        }
      },
      skipReset: true,
      label: {
        htmlFor:
          'filters.customEmissions.customIce',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.emissionsIce.label',
          fallback: 'ICE CO₂ Emissions (lbs CO₂/gal)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'filters.customEmissions.customIce',
        defaultValueName: 'customIceEmissions',
        type: 'number',
        placeholder: {
          i18n: {
            id: 'i18n.output.overview.filters.siteLocation.content.chargerInstallationCost.input.placeholder',
            fallback: 'Type in your CO₂ projected emissions',
          },
        },
      },
      validations: {
        min: {
          value: 1,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.emissions.min',
            fallback: 'Emissions must be between 1 and 1000',
          },
        },
        max: {
          value: 1000,
          i18n: {
            id: 'i18n.onboarding.selection.form.sections.edition.emissions.max',
            fallback: 'Emissions must be between 1 and 1000',
          },
        },
      },
    },
    schema: {
      default: {
        'grid-row-gap': 0,
      },
    },
  },
  'app.output.filters.siteLocation.content.emissionsIce.input.input':
    {
      _extends: 'app.output.common.field.input',
      schema: {
        readonly: {
          opacity: 0.6,
        },
      },
    },
  'app.output.filters.siteLocation.content.emissionsIce.checkbox': {
    _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox',
    components: {
      checkbox:
        'app.output.filters.siteLocation.content.emissionsIce.checkbox.input',
      label:
        'app.output.filters.siteLocation.content.emissionsIce.checkbox.label',
    },
    config: {
      name: 'filters.customEmissions.toggleIce',
      disablesInputWhenChecked: false,
    },
    schema: {
      default: {
        marginTop: '0',
        alignItems: 'self-end',
      },
    },
  },
  'app.output.filters.siteLocation.content.emissionsIce.checkbox.input':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.input',
      schema: {
        default: {
          marginTop: '0',
        },
      },
    },
  'app.output.filters.siteLocation.content.emissionsIce.checkbox.label':
    {
      _extends: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.label',
      config: {
        as: 'label',
        htmlFor: 'filters.customEmissions.toggleIce',
        content: [
          [
            'TranslatedText',
            'app.output.filters.siteLocation.content.emissionsIce.checkbox.label.value',
          ],
        ],
      },
    },
  'app.output.filters.siteLocation.content.emissionsIce.checkbox.label.value':
    {
      config: {
        fontSize: '.875rem',
        fontWeight: 'bold',
        i18n: {
          id: 'i18n.output.overview.filters.siteLocation.content.emissionsIce.checkbox.label.value',
          fallback: 'Custom CO₂ emissions',
        },
      },
    },

  'app.output.filters.fuelCosts': {
    _extends: 'app.output.common.collapsible',
    components: {
      headerText: 'app.output.filters.fuelCosts.headerText',
      content: 'app.output.filters.fuelCosts.content',
    },
    schema: {
      default: {
        '[data-design="app.output.filters.fuelCosts"]': {
          display: 'flex',
          'flex-direction': 'column',
          'row-gap': '10px',
        },
      },
    },
  },
  'app.output.filters.fuelCosts.headerText': {
    config: {
      i18n: {
        id: 'i18n.output.overview.filters.fuelCosts.headerText',
        fallback: 'Fuel Costs',
      },
    },
  },

  'app.output.filters.fuelCosts.content.utilitySelect': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.selectedUtilityName',
        i18n: {
          id: 'i18n.oapp.output.filters.fuelCosts.content.utilitySelect.label',
          fallback: 'Utility',
        },
      },
      input: {
        name: 'filters.assumptionsData.selectedUtilityName',
        component: 'FormVehicleAttrSelector',
        preload: 'power_suppliers',
        preloadAttrs: {
          attrForValue: 'name',
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content': {
    _extends: 'app.output.common.collapsible.content',
    config: {
      content: [
        [
          'FormField',
          'app.output.filters.fuelCosts.content.dieselPrice.onRoad',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.output.filters.fuelCosts.content.dieselPrice.offRoad',
          { displayQuery: 'offRoad' },
        ],
        ['FormField', 'app.output.filters.fuelCosts.content.gasolinePrice'],
        ['FormField', 'app.output.filters.fuelCosts.content.utilitySelect'],
        [
          'ContentContainer',
          'app.output.filters.fuelCosts.content.electricityPrice.wrapper',
        ],
      ],
      watch: {
        name: ['vehicleSets[0].input.is_offroad'],
      },
      displayQueries: {
        onRoad: {
          '["watched","vehicleSets[0].input.is_offroad"]': {
            $eq: false,
          },
        },
        offRoad: {
          '["watched","vehicleSets[0].input.is_offroad"]': {
            $eq: true,
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.dieselPrice': {
    _extends: 'app.output.common.fieldWithTooltip',
    config: {
      watch: {
        name: 'vehicleSets',
      },
      shouldHide:
        "(function ({ vehicleSets }) { if (!vehicleSets || !vehicleSets.length) { return true; } return !vehicleSets.some((v) => v.output.kind === 'ice') })",
      description: null,
      input: {
        type: 'number',
        component: 'FormInput',
        placeholder: {
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.dieselPrice.placeholder',
            fallback: 'Enter Diesel Price ($/Gal)',
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            values: {
              url: 'https://gasprices.aaa.com/',
            },
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.dieselPrice.required',
            fallback: 'Diesel price is required',
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.dieselPrice.onRoad': {
    _extends: 'app.output.filters.fuelCosts.content.dieselPrice',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.dieselPrice',
        i18n: {
          id: 'i18n.output.filters.fuelCosts.content.dieselPrice',
          fallback: 'Diesel Price ($/Gal)',
        },
      },
      input: {
        name: 'filters.assumptionsData.dieselPrice',
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.filters.fuelCosts.content.dieselPrice.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'Based on current average fuel costs for your state. Diesel price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. Source: <a href="{{url}}" target="_blank">AAA</a>',
            },
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.dieselPrice.offRoad': {
    _extends: 'app.output.filters.fuelCosts.content.dieselPrice',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.offroadDieselPrice',
        i18n: {
          id: 'i18n.output.filters.fuelCosts.content.dieselPrice',
          fallback: 'Off Road Diesel Price ($/Gal)',
        },
      },
      input: {
        name: 'filters.assumptionsData.offroadDieselPrice',
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            i18n: {
              id: 'i18n.output.filters.fuelCosts.content.dieselPrice.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'Based on current average fuel costs for your state. Diesel price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. Source: <a href="{{url}}" target="_blank">AAA</a> - excludes federal and state on road taxes',
            },
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.gasolinePrice': {
    _extends: 'app.output.common.fieldWithTooltip',
    config: {
      watch: {
        name: 'vehicleSets',
      },
      shouldHide:
        "(function ({ vehicleSets }) { if (!vehicleSets || !vehicleSets.length) { return true; } return !vehicleSets.some((v) => v.output.kind === 'ice') })",
      label: {
        htmlFor: 'filters.assumptionsData.gasolinePrice',
        i18n: {
          id: 'i18n.output.filters.fuelCosts.content.gasolinePrice',
          fallback: 'Gasoline Price ($/Gal)',
        },
      },
      description: null,
      input: {
        type: 'number',
        component: 'FormInput',
        name: 'filters.assumptionsData.gasolinePrice',
        placeholder: {
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.gasolinePrice.placeholder',
            fallback: 'Enter Gasoline Price ($/Gal)',
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            values: {
              url: 'https://gasprices.aaa.com/',
            },
            i18n: {
              id: 'i18n.output.filters.fuelCosts.content.gasolinePrice.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'Based on current average fuel prices in your state. Gasoline price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. Source: <a href="{{url}}" target="_blank">AAA</a>',
            },
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.gasolinePrice.required',
            fallback: 'Gasoline price is required',
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.electricityPrice.wrapper': {
    config: {
      content: [
        ['FormField', 'app.output.filters.fuelCosts.content.electricityPrice'],
        [
          'ContentContainer',
          'app.output.filters.fuelCosts.content.electricityPrice.description',
        ],
      ],
    },
  },
  'app.output.filters.fuelCosts.content.electricityPrice': {
    _extends: 'app.output.common.fieldWithTooltip',
    config: {
      label: {
        htmlFor: 'assumptionsData.electricityPrice',
        i18n: {
          id: 'i18n.output.filters.fuelCosts.content.electricityPrice',
          fallback: 'Electricity Cost ($/kWh)',
        },
      },
      input: {
        component: 'FormInput',
        name: 'assumptionsData.selectedRateName',
        disabled: true,
        placeholder: {
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.electricityPrice.placeholder',
            fallback: 'Select Rate Option',
          },
        },
      },
      tooltip: {
        popover: {
          position: 'E',
          translatedText: {
            values: {
              url: 'https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a',
            },
            i18n: {
              id: 'i18n.output.filters.fuelCosts.content.electricityPrice.tooltip',
              dangerouslySetInnerHTML: true,
              fallback:
                'Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. Source: <a href="{{url}}" target="_blank">US Energy Information Administration</a>',
            },
          },
        },
      },
    },
  },
  'app.output.filters.fuelCosts.content.electricityPrice.description': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.common.field.description',
          {
            i18n: {
              id: 'i18n.app.output.filters.fuelCosts.content.electricityPrice.description',
              dangerouslySetInnerHTML: true,
              fallback: 'See the ',
            },
          },
        ],
        [
          'Link',
          '',
          {
            components: {
              translatedText: 'app.common.field.description.link',
            },
            to: '/output/fuelCosts',
            i18n: {
              id: 'i18n.app.output.filters.fuelCosts.content.electricityPrice.description_Fuel Cost Tab',
              dangerouslySetInnerHTML: true,
              fallback: 'Fuel Cost Tab',
            },
          },
        ],
        [
          'TranslatedText',
          'app.common.field.description',
          {
            i18n: {
              id: 'i18n.app.output.filters.fuelCosts.content.electricityPrice.description_ for more details',
              dangerouslySetInnerHTML: true,
              fallback: ' for more details',
            },
          },
        ],
      ],
    },
  },
  'app.output.filters.fuelCosts.content.publicChargerElectricityCost': {
    _extends: 'app.output.common.field',
    config: {
      label: {
        htmlFor: 'filters.assumptionsData.publicChargerElectricityCost',
        i18n: {
          id: 'i18n.output.filters.fuelCosts.content.publicChargerElectricityCost',
          fallback: 'Public Charger Electricity Cost ($/kWh)',
        },
      },
      description: null,
      input: {
        type: 'number',
        component: 'FormInput',
        name: 'filters.assumptionsData.publicChargerElectricityCost',
        placeholder: {
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.publicChargerElectricityCost.placeholder',
            fallback: 'Enter Public Charger Electricity Cost',
          },
        },
      },
      validations: {
        required: {
          value: true,
          i18n: {
            id: 'i18n.output.filters.fuelCosts.content.publicChargerElectricityCost.required',
            fallback: 'Public Charger Electricity Cost is required',
          },
        },
      },
    },
  },
  'app.output.template.tabs': {
    config: {
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.overview.template.savings.tableContainer',
        ],
        ['TranslatedText', 'app.output.template.tabs.content'],
      ],
    },
    schema: {
      default: {
        'background-color': 'white',
        'border-radius': '5px',
        padding: '24px',
      },
    },
  },
  'app.output.template.tabs.content': {
    config: {
      i18n: {
        id: 'i18n.output.template.tabs.content',
        fallback: 'Tabs content goes here',
      },
    },
  },
  'app.output.template.container': {
    schema: {
      default: {
        display: 'grid',
        'background-color': 'white',
        'border-radius': '5px',
        'justify-content': 'center',
        'grid-template-columns': '260px 812px',
        'grid-column-gap': '4.4vw',
        padding: '32px 24px',

        '@media (max-width: 992px)': {
          display: 'flex',
          padding: '16px',
          'grid-template-columns': 'inherit',
          'grid-column-gap': 'inherit',
        },
      },
    },
  },
  'app.output.template.fiters.header.text': {
    config: {
      i18n: {
        id: 'i18n.output.template.fiters.header.text',
        fallback: 'Inputs & Assumptions',
      },
    },
    schema: {
      default: {
        fontSize: '22px',
        fontWeight: '400',
      },
    },
  },
  'app.output.template.headline': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        'padding-left': '0px',
        margin: '16px 0',
        'font-size': '22px',
        'font-weight': 'normal',
      },
    },
  },
  'app.output.template.filters': {
    config: {
      as: 'aside',
      content: [
        ['ActionComponent', 'app.output.template.filters.mobile.button.back'],
        ['Collapsible', 'app.output.vehicleSets'],
        ['Collapsible', 'app.output.filters.assumptions'],
        ['Collapsible', 'app.output.filters.siteLocation'],
        ['Collapsible', 'app.output.filters.fuelCosts'],
        ['ContentContainer', 'app.output.template.filters.actions'],
        ['ContentContainer', 'app.output.template.filters.reports'],
        ['ContentContainer', 'app.output.disclaimer'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
          width: '100%',
        },
        '@media print': {
          display: 'block',
        },
      },
    },
  },
  'app.output.disclaimer': {
    config: {
      watch: {
        name: ['clientName'],
      },
      content: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.output.disclaimer.text',
              fallback:
                'This website contains links to third-party external websites. {{clientName}} does not endorse or accept any liability for content or products sold on any third-party websites. {{clientName}} has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party external websites. By clicking on links, you expressly relieve {{clientName}} from any and all liability arising from your use of third-party external website or from the content of any third-party external website. {{clientName}} cannot guarantee the accuracy of third-party product information provided.',
            },
          },
        ],
      ],
    },
  },
  'app.output.template.filters.mobile.button.back': {
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'output/toggleFilters',
        },
      ],
      component: 'Button',
      content: [
        ['Icon', 'app.output.template.fiters.back.icon'],
        ['TranslatedText', 'app.output.template.fiters.header.text'],
      ],
    },
    schema: {
      default: {
        textAlign: 'left',
        padding: '12px 0',
        display: 'none',
        'column-gap': '8px',

        // Desktop
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.fiters.back.icon': {
    config: {
      src: 'https://assets.zappyride.com/img/icons/evergy/arrow_back.svg',
      as: 'img',
      size: '20px',
    },
  },
  'app.output.template.filters.copy': {
    components: {
      button: 'app.output.template.filters.copy.button',
    },
  },
  'app.output.template.filters.copy.button': {
    _extends: [
      'app.output.template.filters.actionComponent.smartButton.button',
      'app.common.button.secondary',
      'app.common.button.small',
    ],
    config: {
      content: [
        ['Icon', 'app.output.template.filters.copy.button.icon'],
        ['TranslatedText', 'app.output.template.filters.copy.button.text'],
      ],
    },
    schema: {
      default: {
        gap: '8px',
      },
    },
  },
  'app.output.template.filters.copy.button.icon': {
    _extends: 'app.output.template.filters.reports.common.icon',
    config: {
      src: 'share',
    },
  },
  'app.output.template.filters.copy.button.text': {
    _extends: 'app.common.text.secondary',
    config: {
      i18n: {
        id: 'i18n.output.template.filters.copy.button.text',
        fallback: 'SHARE',
      },
    },
  },
  'app.output.template.filters.actions': {
    config: {
      content: [
        [
          'SmartNextButton',
          'app.output.template.filters.restore',
          { action: 'onRestoreClick' },
        ],
        [
          'SmartNextButton',
          'app.output.template.filters.apply',
          { action: 'onApplyClick' },
        ],
        ['PrimaryModal', 'app.output.template.filters.startOver.modal'],
      ],
      watch: {
        name: ['filters'],
      },
    },
    schema: {
      default: {
        'grid-template-columns': 'repeat(2,1fr)',
        'grid-column-gap': '8px',
        display: 'grid',
        'margin-top': '8px',
        'margin-bottom': '1em',
      },
    },
  },

  'app.output.template.filters.reports': {
    config: {
      content: [
        ['PrimaryModal', 'app.output.sharelink.modal'],
        ['ActionComponent', 'app.output.vehicleSets.content.printButton'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
      },
    },
  },

  'app.output.template.filters.startOver.modal': {
    _extends: 'app.common.primaryModal',
    config: {
      header: {
        id: 'i18n.app.output.template.filters.startOver.modal.header',
        fallback: 'Start Over',
      },
      cancel: {
        id: 'i18n.app.output.template.filters.startOver.modal.close',
        fallback: 'Close',
      },
      confirm: {
        component: 'ActionComponent',
        i18n: {
          id: 'i18n.app.output.template.filters.startOver.modal.confirm',
          fallback: 'Confirm',
        },
      },
      text: null,
      content: 'app.output.template.filters.startOver.modal.content',
    },
    components: {
      cancel: 'app.common.primaryModal.cancel',
      trigger: 'app.output.template.filters.startOver.button',
      content: 'app.output.template.filters.startOver.modal.content',
      confirm: 'app.output.template.filters.startover.modal.confirm',
    },
  },
  'app.output.template.filters.startover.modal.confirm': {
    _extends: ['app.common.button.primary', 'app.common.button.small'],
    config: {
      component: 'Button',
      eventName: 'onClick',
      actions: [
        {
          type: 'form/startOver',
        },
      ],
    },
    schema: {
      default: {
        'background-color': '#F44336 !important',
        '& > span': {
          color: 'white',
        },
        borderColor: '#F44336 !important',
        justifyContent: 'center',
      },
    },
  },
  'app.output.template.filters.startOver.modal.content': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.filters.startOver.modal.content.text',
        ],
      ],
    },
  },
  'app.output.template.filters.startOver.modal.content.text': {
    _extends:
      'app.onboarding.selection.form.sections.routes.header.tabs.modal.actions.common.button.text',
    config: {
      element: 'p',
      i18n: {
        id: 'i18n.startOver.modal.content.text',
        fallback:
          'Start over will reset all your inputs. Are you sure you want to continue?',
      },
    },
    schema: {
      default: {
        margin: '16px 0',
      },
    },
  },
  'app.output.template.filters.actionComponent.smartButton': {
    config: {
      disabledDirtyField: 'filters'
    },
  },
  'app.output.template.filters.actionComponent.smartButton.button': {
    _extends: ['app.common.button.small'],
    config: {
      component: 'Button',
    },
  },
  'app.output.template.filters.startOver.button': {
    _extends: 'app.output.template.filters.restore.button',
    config: {
      content: [
        ['Icon', 'app.output.template.filters.startOver.button.icon'],
        ['TranslatedText', 'app.output.template.filters.startOver.button.text'],
      ],
    },
    schema: {
      default: {
        marginTop: '1.5em',
        gridColumn: '1 / -1',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',

        '@media (max-width: 992px)': {
          display: 'flex ',
          marginTop: 'inherit',
        },
      },
    },
  },
  'app.output.template.filters.startOver.button.icon': {
    _extends: 'app.common.icon.secondary',
    config: {
      src: 'refresh',
      size: 'md',
    },
    schema: {
      default: {
        'margin-right': '12px',
      },
    },
  },
  'app.output.template.filters.startOver.button.text': {
    _extends: 'app.common.text.secondary',
    config: {
      i18n: {
        id: 'i18n.app.output.template.filters.startOver.button.text',
        fallback: 'START OVER',
      },
    },
  },
  'app.output.template.filters.restore': {
    _extends: 'app.output.template.filters.actionComponent.smartButton',
    components: {
      button: 'app.output.template.filters.restore.button',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.filters.restore.button': {
    _extends: [
      'app.output.template.filters.actionComponent.smartButton.button',
      'app.common.button.secondary',
      'app.common.button.small',
    ],
    config: {
      content: [
        ['TranslatedText', 'app.output.template.filters.restore.button.text'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.filters.restore.button.text': {
    _extends: 'app.common.text.secondary',
    config: {
      i18n: {
        id: 'i18n.app.output.template.filters.restore.button.text',
        fallback: 'CANCEL',
      },
    },
  },
  'app.output.template.filters.apply': {
    _extends: 'app.output.template.filters.actionComponent.smartButton',
    components: {
      button: 'app.output.template.filters.apply.button',
    },
    config: {
      disabled:
        '(function (_, __, errors) { return Object.keys(errors).length; })',
    },
  },
  'app.output.template.filters.apply.button': {
    _extends: [
      'app.output.template.filters.actionComponent.smartButton.button',
      'app.common.button.primary',
      'app.common.button.small',
    ],
    config: {
      content: [
        ['TranslatedText', 'app.output.template.filters.apply.button.text'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.output.template.filters.apply.button.text': {
    _extends: 'app.common.text.primary',
    config: {
      i18n: {
        id: 'i18n.output.template.filters.apply.button.text',
        fallback: 'APPLY',
      },
    },
  },
  'app.output.template.routes.tabs.collapsible': {
    _extends: 'app.output.common.collapsible',
    config: {
      open: true,
    },
    schema: {
      default: {
        border: '1px solid #E4E4E4',
        'border-radius': '4px',
        padding: '0 15px 0 15px',
        "&> button[aria-expanded='false'], & > button[aria-expanded='true']": {
          border: 'none',
        },
      },
      contentWrapper: {
        default: {
          border: 'none',
          '& > div': {
            border: 'none',
          },
        },
      },
    },
  },
  'app.output.common.tables': {
    schema: {
      default: {
        width: '100%',
      },
    },
  },
  'app.output.common.table.catalog.card': {
    schema: {
      default: {
        '& tfoot > tr > td': {
          paddingTop: '16px',
        },
      },
    }
  },
  'app.output.template.routes.tables.cellsFormatted': {
    config: {
      as: 'p',
      converter: {
        null: 'N/A',
      },
    },
  },
  'app.output.template.routes.tables.cellsWithCalculatedFormat': {
    _extends: 'app.output.template.routes.tables.cellsFormatted',
    config: {
      as: 'p',
      pick: 'custom[calculated]',
    },
  },
  'app.output.template.routes.tables.title': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        margin: '10px 0 32px 10px',
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
  },
  'app.output.template.routes.tables.titleBig': {
    _extends: 'app.output.template.routes.tabs.collapsible.text',
    config: {
      as: 'p',
    },
    schema: {
      default: {
        'padding-left': '10px',
        margin: '16px 0',
      },
    },
  },
  'app.output.template.routes.tables.note': {
    config: {
      as: 'p',
    },
    schema: {
      default: {
        margin: '0 0 4px 10px',
        fontSize: '11px',
        fontWeight: 'normal',
        padding: '0',
      },
    },
  },
  'app.output.template.routes.tabs.collapsible.text': {
    schema: {
      default: {
        'font-size': '22px',
        'font-weight': 'normal',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'padding-left': '15px',
        },
      },
    },
  },
  'app.output.template.routes.tabs.fuel.tables.common': {
    schema: {
      default: {
        fontWeight: 'bold',
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #CCC',
      },
    },
  },
  // TABLES AND CELLS
  'app.output.template.tables.cells.normal': {
    schema: {
      default: {
        'font-size': '14px',
        'line-height': '14px',
      },
    },
  },
  'app.output.template.tables.cells.bold': {
    schema: {
      default: {
        fontWeight: 'bold',
        paddingRight: '20px',
        'font-size': '14px',
        'line-height': '14px',
      },
    },
  },
  'app.output.template.tables.wrapper': {
    schema: {
      default: {
        border: '1px solid #ACB1BA',
        'border-radius': '4px',
        padding: '16px',
      },
    },
  },
  'app.output.select.vehicleSetSelector': {
    config: {
      content: [
        ['ContentContainer', 'app.output.common.select.vehicleSetSelector'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'justify-content': 'flex-start',
        'grid-column-gap': '12px',

        '@media (max-width: 992px)': {
          display: 'flex',
        },
      },
    },
  },

  // COMMON FIELDS AND SELECTS
  // ---- VEHICLE SET SELECTOR
  'app.output.common.select.vehicleSetSelector': {
    config: {
      content: [
        [
          'SetSelector',
          'app.output.common.select.vehicleSetSelector.component',
        ],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.output.common.select.vehicleSetSelector.component': {
    config: {
      minToDisplay: 1,
    },
    components: {
      field: 'app.output.common.select.vehicleSetSelector.field',
    },
  },
  'app.output.common.select.vehicleSetSelector.field': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        htmlFor: 'selectedSetId',
        i18n: {
          id: 'i18n.output.common.select.vehicleSetSelector.field.label',
          fallback: 'Vehicle Set',
        },
      },
      input: {
        name: 'selectedSetId',
      },
    },
  },
  'app.output.common.vehicleSets.content.dynamic.details.value': {
    schema: {
      default: {
        display: 'block',
        'max-width': '151px',
        'word-wrap': 'break-word',

        '@media (max-width: 992px)': {
          'font-size': 'var(--font-size-mobile)',
        },
      },
    },
  },
  'app.output.common.chart.responsive': {
    schema: {
      default: {
        '@media screen and (max-width: 992px)': {
          marginTop: '32px',
          position: 'relative',
          width: '80vw',
        },
      },
    },
  },

  'app.output.template.mobile.common.head.button.assumptions': {
    components: {
      button:
        'app.output.template.mobile.common.head.button.assumptions.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'output/toggleFilters',
        },
      ],
      component: 'SmartNextButton',
    },
  },
  'app.output.template.mobile.common.head.button.assumptions.button': {
    config: {
      component: 'Button',
      content: [
        [
          'Icon',
          'app.output.template.mobile.common.head.button.assumptions.button.icon',
        ],
      ],
    },
    schema: {
      default: {
        width: '40px',
        padding: '0px',
        justifySelf: 'end',
      },
    },
  },
  'app.output.template.mobile.common.head.button.assumptions.button.icon': {
    config: {
      component: 'Icon',
      src: 'https://assets.zappyride.com/img/icons/evergy/inputs_assumptions_icon.svg',
      as: 'img',
    },
  },
  'app.output.template.mobile.common.head': {
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.output.sharelink.modal': {
    _extends: 'app.common.primaryModal',
    config: {
      header: {
        id: 'i18n.app.output.sharelink.header',
        fallback: 'Share link',
      },
      cancel: {
        id: 'i18n.app.output.sharelink.cancel',
        fallback: 'Close',
      },
      confirm: null,
    },
    components: {
      trigger: 'app.output.template.filters.copy.button',
      content: 'app.output.sharelink.modal.content',
    },
  },
  'app.output.sharelink.modal.content': {
    config: {
      content: [['CopyShareLink', 'app.output.sharelink']],
    },
  },
  'app.output.sharelink': {
    components: {
      contentWrapper: 'app.output.sharelink.wrapper',
      input: 'app.output.sharelink.input',
      button: 'app.output.sharelink.button.copy',
      successText: 'app.output.sharelink.success',
    },
    config: {
      input: {
        name: 'share-link-value',
      },
    },
    schema: {
      default: {
        marginBottom: '10px',
      },
    },
  },
  'app.output.sharelink.wrapper': {
    schema: {
      default: {
        alignItems: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        gap: '10px',
      },
    },
  },
  'app.output.sharelink.success': {
    config: {
      element: 'p',
      i18n: {
        id: 'i18n.common.sharelink.success.text',
        fallback: 'Link successfully copied to your clipboard!',
      },
    },
    schema: {
      default: {
        margin: '16px 0',
      },
    },
  },
  'app.output.sharelink.input': {
    _extends: 'app.common.field.input',
    config: {
      name: 'share-link-value',
      readOnly: true,
      component: 'FormInput',
    },
    schema: {
      default: {
        height: '50px',
        minHeight: '50px',
      },
    },
  },
  'app.output.sharelink.button.copy': {
    config: {
      content: [['TranslatedText', 'app.output.sharelink.button.copy.text']],
    },
    schema: {
      default: {
        color: 'white',
        'background-color': 'var(--primary-color)',
        border: '1px solid var(--primary-color)',
        justifyContent: 'center',
        width: '20%',
        flexGrow: 1,
      },
    },
  },
  'app.output.sharelink.button.copy.text': {
    config: {
      i18n: {
        id: 'i18n.common.sharelink.actions.copy.text',
        fallback: 'Copy',
      },
    },
  },
  'app.output.vehicleSets.content.printButton': {
    _extends: 'app.output.vehicleSets.content.addButton',
    config: {
      actions: [
        {
          type: 'print/preview',
        },
      ],
      title: 'Print',
      content: [
        ['Icon', 'app.output.vehicleSets.content.printButton.icon'],
        ['TranslatedText', 'app.output.vehicleSets.content.printButton.text'],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
      },
    },
  },

  'app.output.vehicleSets.content.printButton.icon': {
    _extends: 'app.output.template.filters.reports.common.icon',
    config: {
      src: 'print',
    },
  },
  'app.output.vehicleSets.content.printButton.text': {
    _extends: 'app.common.text.secondary',
    config: {
      i18n: {
        id: 'i18n.output.vehicleSets.content.printButton.text',
        fallback: 'PRINT',
      },
    },
  },
  'app.output.template.filters.reports.common.icon': {
    _extends: 'app.common.icon.secondary',
    schema: {
      default: {
        height: '24px',
        maxWidth: '24px',
      },
    },
  },
};
