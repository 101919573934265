// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { useCalculationSelectors } from '../../../../selectors/calculations';

export const OutputCharging = ({ design }: { design: string }) => {
  const { schema } = zappy.useDesign(design);
  const [{ data, status }, { data: tableData }, { data: assignmentsData }] = useCalculationSelectors(
    'charger',
    'chargerTable',
    'chargerAssignment'
  );

  if (status.isLoading) return 'Loading';
  const combinedData = { ...data, ...tableData, ...assignmentsData };
  if (status.missingRequiredInformation)
    return 'Missing required info for calculations';

  return (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <zappy.ContentContainer design={design} values={combinedData} />
    </zappy.FactoryComponent>
  );
};

OutputCharging.displayName = 'OutputCharging';
