import { omit } from 'lodash';
import zappy from '@zappy-ride/library.react.components';
import { useState, useEffect, createRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { compressData, createLink } from '../../utils/localStorageUtils';

export const CopyShareLink = ({ design }: { design: string }) => {
  const { getValues, setValue } = useFormContext();
  const { schema, components } = zappy.useDesign(design);
  const [linkCopied, setCopiedText] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const inputRef = createRef<HTMLInputElement>();

  const setFocus = () => {
    if (!inputRef.current) return;
    inputRef.current.select();
  };

  useEffect(() => {
    setFocus();
  }, [inputRef]);

  const getShareableLink = () => {
    const values = omit(getValues(), ['catalogs', 'constants', 'assumptionsData.incentives']);
    const compressedData = compressData(values);
    return createLink(compressedData);
  };

  const prepareLink = () => {
    const shareableLink = getShareableLink();

    setShareLink(shareableLink);
    setValue('share-link-value', shareableLink);
  };

  useEffect(() => {
    prepareLink();

    return () => {
      setValue('share-link-value', '');
    };
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setCopiedText(true);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <zappy.FactoryComponent
      design={design}
      schema={schema}
      data-design={design}
    >
      <zappy.FactoryComponent design={components.contentWrapper}>
        <zappy.FormInput
          ref={inputRef}
          design={components.input}
          //@ts-ignore
          onClick={handleFocus}
        />
        <zappy.Button design={components.button} onClick={copyToClipboard} />
      </zappy.FactoryComponent>
      {linkCopied && <zappy.TranslatedText design={components.successText} />}
    </zappy.FactoryComponent>
  );
};

CopyShareLink.displayName = 'CopyShareLink';
