const MEDIA_PRINT = '@media print';
const PRINT = {
  'app.output.template.routes.print': {
    config: {
      as: 'main',
      basePath: '/print/output',
      content: [
        [
          'ContentContainer',
          'app.output.template.routes.print.content',
        ],
      ],
    },
    schema: {
      default: {
        width: '100%',
        maxWidth: '100%',
        padding: '1.2vw 0',

        '& aside': {
          display: 'block',
          '& button[type="button"]': {
            display: 'none',
          },
        },
        '@media all': {
          width: '993px',
          minWidth: '993px',
          maxWidth: '993px',

          '& .cardsContainer': {
            flexDirection: 'row',
          },
          '& .pageHeader': {
            '& > button': {
              display: 'none',
            },
          },
          '& .noPrintable': {
            display: 'none',
          },
          'div[data-design*="app.output.template.routes.incentives.content"] div[role="region"] > .MuiGrid-root > div:first-child .MuiBox-root': {
            '-webkit-line-clamp': 'inherit',
          },
        },
      },
    }
  },
  'app.output.template.routes.print.content': {
    config: {
      content: [
        [
          'PrintTabs',
          'app.output.template.routes.print.printComponent',
        ],
        [
          'IconText',
          'app.output.template.routes.print.content.message',
          {
            displayQuery: 'printingNotAvailable',
          },
        ]
      ],
      watch: {
        name: ['printing'],
      },
      displayQueries: {
        printingNotAvailable: {
          '["watched", "printing"]': {
            $eq: false,
          },
        },
      },
    },
  },
  'app.output.template.routes.print.content.message': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.output.fuelCost.computer.icon',
    },
    config: {
      text: {
        i18n: {
          id: 'i18n.iconText.text',
          dangerouslySetInnerHTML: true,
          fallback:
            'For the best printing experience, please try printing this on a desktop device.',
        },
      },
    },
    schema: {
      default: {
        'margin-bottom': '1.5rem',
        'margin-top': '32px',
      },
    },
  },

  'app.output.template.routes.print.printComponent': {
    components: {
      summary: 'app.output.template.summary.print',
      filters: 'app.output.template.filters',
      routes: 'app.output.template.routes',
      footer: 'app.template.footer',
      container: 'app.output.template.print.container',
      tab: 'app.output.template.print.tab',
    },
    config: {
      printableRoutes: 'app.output.template.routes',
    }
  },

  'app.output.template.print.tab': {
    schema: {
      default: {
        position: 'relative',
        '&:not(:last-child)': {
          '@media print': {
            breakAfter: 'page',
          }
        }
      }
    }
  },

  'app.output.template.print.container': {
    _extends: 'app.output.template.container',
    schema: {
      default: {
        gridTemplateColumns: '280px 550px',
        padding: '0',
        gridColumnGap: '40px',
        display: 'grid',
        '& > div': {
          position: 'relative',
          '&:nth-child(2)': {
            gridColumn: 2,
          },
          gridColumn: '1/3',
        },
        '@media all': {
          display: 'grid',
          gridTemplateColumns: '280px 550px',
          gridColumnGap: '40px',
          '& > div': {
            '&:nth-child(2)': {
              gridColumn: 2,
            },
            gridColumn: '1/3',
          },
        }
      }
    }
  },

  'app.output.template.summary.print': {
    _extends: 'app.output.template.summary',
    schema: {
      default: {
        '@media all': {
          display: 'block',
        },
        '& > div': {
          '& p[role="heading"]': {
            fontSize: '18px',
          }
        },
      }
    }
  },

  'app.output.print.common.canvas': {
    schema: {
      default: {
        [MEDIA_PRINT]: {
          '& canvas': {
            marginBottom: '20px',
            height: 'auto !important',
            width: '100% !important',
          },
        }
      }
    }
  },
  'app.output.print.common.rowForPrint': {
    schema: {
      default: {
        [MEDIA_PRINT]: {
          flexDirection: 'row !important',
        }
      }
    }
  },
  'app.output.print.common.samePageWhenPossible': {
    schema: {
      default: {
        [MEDIA_PRINT]: {
          breakAfter: 'auto',
          breakInside: 'avoid',
        }
      }
    }
  },
  'app.output.print.common.notPrintable': {
    schema: {
      default: {
        [MEDIA_PRINT]: {
          display: 'none !important'
        }
      }
    }
  },
  'app.output.print.common.table': {
    schema: {
      default: {
        [MEDIA_PRINT]: {
          flexDirection: 'row',
        }
      }
    }
  },
}

export default PRINT;
