// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef } from 'react';
import zappy from '@zappy-ride/library.react.components';
import { useFormContext } from 'react-hook-form';
import { useVehiclePreloads } from '../../utils/vehicleSelectionHelpers';

export const FormInputVehicle = ({ design, children, preloads: _preloads }: { design: string }) => {
  const { schema, config } = zappy.useDesign(design);
  const { setValue, getValues } = useFormContext();

  const {
    vehiclesList,
    genericList,
    areListsEmpty
  } = useVehiclePreloads(_preloads)

  const { handle: selectedHandle } = zappy.useWatch({ name: 'handle' });
  const subType = getValues('subtype');
  const defaultVehicles = getValues('defaultVehicles');

  const previousSubType = useRef(null);
  useEffect(() => {
    const selectionIsDirty = getValues('selectionIsDirty');
    const isLoaded = getValues(`${config.kind}.image_url`);
    if (areListsEmpty || !defaultVehicles || !isLoaded || !selectedHandle)  return;

      const vehicle = [...genericList, ...vehiclesList].find(
        ({ handle }) => handle === selectedHandle
      );
      if(!vehicle) {
        return;
      }

      setValue(config.kind, vehicle, {
        shouldDirty: true,
        shouldValidate: true,
      });
  }, [selectedHandle]);

  useEffect(() => {
    const selectionIsDirty = getValues('selectionIsDirty');
    if (previousSubType.current === subType) return;
    if (!previousSubType.current) {
      previousSubType.current = subType;
      return;
    }

    if (!selectionIsDirty) setValue('selectionIsDirty', true);
  }, [subType]);

  return (
    <zappy.FactoryComponent schema={schema} active design={undefined}>
      {children}
    </zappy.FactoryComponent>
  );
};

FormInputVehicle.displayName = 'FormInputVehicle';
