const OUTPUT_VEHICLES = {
  'app.output.template.routes.vehicles.template': {
    _extends: 'app.common.device.selector',
    config: {
      role: 'tabpanel',
      content: [
        [
          'ContentContainer',
          'app.output.template.mobile.vehicles.head',
          {
            displayQuery: 'mobileOrPrinting',
            className: 'pageHeader'
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.template.sectionTitle',
        ],
        ['ContentContainer', 'app.output.select.vehicleSetSelector'],
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.template.sectionTables',
        ],
        [
          'TranslatedText',
          'app.output.template.mobile.vehicles.botton.note',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
    },
  },
  'app.output.template.mobile.vehicles.head': {
    config: {
      content: [
        ['TranslatedText', 'app.output.template.mobile.vehicles.head.title'],
        [
          'ActionComponent',
          'app.output.template.mobile.common.head.button.assumptions',
        ],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'repeat(2, auto)',
        'align-items': 'center',
        'border-bottom': '1px solid var(--border-color)',
        'padding-bottom': '16px',
      },
    },
  },
  'app.output.template.mobile.vehicles.head.title': {
    config: {
      i18n: {
        id: 'i18n.output.template.mobile.vehicles.head.title',
        fallback: 'Vehicles',
      },
    },
    schema: {
      default: {
        fontSize: '22px',
        fontWeight: 'var(--font-weight)',
      },
    },
  },

  'app.output.template.mobile.vehicles.botton.note': {
    config: {
      i18n: {
        id: 'i18n.output.template.mobile.vehicles.botton.note',
        fallback: 'Standard Vehicle Specs',
      },
    },
    schema: {
      default: {
        fontSize: 'var(--font-size-mobile)',
        fontWeight: 'var(--font-weight)',
        'font-style': 'italic',
      },
    },
  },
  // TITLE
  'app.output.template.routes.vehicles.template.sectionTitle': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.output.template.routes.vehicles.template.sectionTitle.title',
        ],
        [
          'TranslatedText',
          'app.output.template.routes.vehicles.template.sectionTitle.subtitle',
        ],
      ],
    },
    schema: {
      default: {
        marginBottom: '30px',
        marginTop: '32px',
      },
    },
  },
  'app.output.template.routes.vehicles.template.sectionTitle.title': {
    config: {
      as: 'p',
      i18n: {
        id: 'i18n.app.output.template.routes.vehicles.template.sectionTitle.title',
        fallback: 'Vehicle Comparison',
      },
    },
    schema: {
      default: {
        fontSize: '22px',
        marginBottom: '0',
      },
    },
  },
  'app.output.template.routes.vehicles.template.sectionTitle.subtitle': {
    config: {
      i18n: {
        id: 'i18n.app.output.template.routes.vehicles.template.sectionTitle.subtitle',
        fallback:
          'Select a vehicle set below to compare costs, incentives and specifications of fossil fuel and EV equivalents.',
      },
    },
    schema: {
      default: {
        fontSize: '14px',
      },
    },
  },
  'app.output.template.routes.vehicles.template.sectionTables': {
    _extends: [
      'app.output.print.common.rowForPrint',
    ],
    config: {
      content: [
        [
          'Table',
          'app.output.template.routes.vehicles.template.table.onRoad',
          {
            targetSource: 'comparisonData.contentElectric',
            globalDataSource: 'comparisonGlobalDataInput',
            displayQuery: 'isOnRoad',
          },
        ],
        [
          'Table',
          'app.output.template.routes.vehicles.template.table.onRoad',
          {
            targetSource: 'comparisonData.contentFossil',
            globalDataSource: 'comparisonGlobalDataOutput',
            displayQuery: 'isOnRoad',
          },
        ],
        [
          'Table',
          'app.output.template.routes.vehicles.template.table.offRoad',
          {
            targetSource: 'comparisonData.contentElectric',
            globalDataSource: 'comparisonGlobalDataInput',
            displayQuery: 'isOffRoad',
          },
        ],
        [
          'Table',
          'app.output.template.routes.vehicles.template.table.offRoad',
          {
            targetSource: 'comparisonData.contentFossil',
            globalDataSource: 'comparisonGlobalDataOutput',
            displayQuery: 'isOffRoad',
          },
        ],
      ],
      displayQueries: {
        isOnRoad: { '["props", "isOffRoad"]': { $neq: true } },
        isOffRoad: { '["props", "isOffRoad"]': { $eq: true } },
      },
    },
    schema: {
      default: {
        display: 'flex',
        gap: '20px',

        //mobile
        '@media (max-width: 992px)': {
          'flex-direction': 'column',
          marginBottom: '62px',
        },
      },
    },
  },

  'app.output.template.routes.vehicles.template.table.onRoad': {
    _extends: 'app.output.common.tables',
    components: {
      wrapper: 'app.output.template.tables.wrapper',
    },
    config: {
      component: 'Table',
      bodyRows: [
        ['app.output.template.routes.vehicles.comparisonTable.row1'],
        ['app.output.template.routes.vehicles.comparisonTable.rowSection1'],
        ['app.output.template.routes.vehicles.comparisonTable.row2'],

        ['app.output.template.routes.vehicles.comparisonTable.row3.onRoad'],
        ['app.output.template.routes.vehicles.comparisonTable.row4.onRoad'],
        ['app.output.template.routes.vehicles.comparisonTable.row5.onRoad'],

        ['app.output.template.routes.vehicles.comparisonTable.rowSection2'],
        ['app.output.template.routes.vehicles.comparisonTable.row6'],
        ['app.output.template.routes.vehicles.comparisonTable.row7'],
        ['app.output.template.routes.vehicles.comparisonTable.row8'],
        ['app.output.template.routes.vehicles.comparisonTable.row9'],
      ],
    },
  },
  'app.output.template.routes.vehicles.template.table.offRoad': {
    _extends: 'app.output.common.tables',
    components: {
      wrapper: 'app.output.template.tables.wrapper',
    },
    config: {
      bodyRows: [
        ['app.output.template.routes.vehicles.comparisonTable.row1'],
        ['app.output.template.routes.vehicles.comparisonTable.rowSection1'],

        //FOR FOSSIL FUEL ON-ROAD
        ['app.output.template.routes.vehicles.comparisonTable.row2'],

        ['app.output.template.routes.vehicles.comparisonTable.row3.offRoad'],
        ['app.output.template.routes.vehicles.comparisonTable.row4.offRoad'],
        ['app.output.template.routes.vehicles.comparisonTable.row5.offRoad'],
      ],
    },
  },

  'app.output.template.routes.vehicles.template.dataRows': {
    config: {
      displayQueries: {
        isElectric: { '["props", "isElectric"]': { $eq: true } },
        isFossil: { '["props", "isElectric"]': { $eq: false } },
        isDiesel: { '["props", "isDiesel"]': { $eq: true } },
        isGasoline: { '["props", "isGasoline"]': { $eq: true } },
      },
    },
    schema: {
      default: {
        fontSize: '14px',
        lineHeight: '14px',
        borderTop: '1px solid #ACB1BA',
        '& > td': {
          padding: '10px 0',
        },
        '& > td:nth-child(1)': {
          width: '161px',
        },
        '& > td:nth-child(2)': {
          width: '196px',
        },
      },
    },
  },
  'app.output.template.routes.vehicles.template.sectionRows': {
    schema: {
      default: {
        '& > td': {
          padding: '15px 0',
        },
        '& > td > span': {
          margin: '30px 0 0',
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
  },
  'app.output.template.routes.vehicles.template.formatters.value': {
    _extends: 'app.common.formatter.auto.value',
    config: {
      as: 'p',
    },
  },
  'app.output.template.routes.vehicles.template.formatters.labels': {
    _extends: 'app.common.formatter.auto.label',
    config: {
      as: 'p',
    },
    schema: {
      default: {
        paddingRight: '20px',
        fontWeight: 'bold',
      },
    },
  },

  'app.output.template.routes.vehicles.comparisonTable.row1': {
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.comparisonTable.row1.header',
          {},
          {
            colSpan: 2,
          },
        ],
      ],
    },
    schema: {
      default: {
        textAlign: 'center',
      },
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.rowSection1': {
    _extends: 'app.output.template.routes.vehicles.template.sectionRows',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.vehicles.comparisonTable.onRoad.rowSection1',
              fallback: 'Fuel Cost',
            },
          },
          {
            colSpan: 2,
          },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip.popover': {
    _extends: 'app.common.tooltip.popover',
    config: {
      content: [
        ['TranslatedText', 'app.common.field.error', {
          i18n: {
            id: 'i18n.app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip',
            fallback: 'Because electricity fuel costs cannot be calculated for each vehicle set when using a market rate (due to demand and other factors), and only for your site meter in total, we estimate the average cost of fueling this vehicle set based on the fraction of the site’s total electrical load it contributes.',
          }
        }],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip':{
    _extends: 'app.common.tooltip',
    components:{
      popover: 'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip.popover'
    }
  },
  'app.output.template.routes.vehicles.comparisonTable.row2.annualTotalElectric': {
    config: {
      content: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          {
            autoPick: 'annualTotalElectric', 
            schema: {
              default: {
                paddingRight: '0'
              }
            }
          },
        ],
        [
          'Tooltip',
          'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip',
        ]
      ]
    },
    schema: {
      default: {
        display: 'flex',
        paddingRight: '20px'
      }
    }
  },
  'app.output.template.routes.vehicles.comparisonTable.row2': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.comparisonTable.row2.annualTotalElectric',
          {},
          { displayQuery: 'isElectric' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            formatter: 'formatAsDollars',
            pick: 'globalData[annualTotalElectric]',
          },
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'annualTotalFossil' },
          { displayQuery: 'isFossil' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            formatter: 'formatAsDollars',
            pick: 'globalData[annualTotalFossil]',
          },
          { displayQuery: 'isFossil' },
        ],
      ],
    },
  },

  // ON-ROAD ROWS
  'app.output.template.routes.vehicles.comparisonTable.row3.onRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'electric_efficiency' },
          { displayQuery: 'isElectric' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'electricEfficiencyMPGe',
            pick: 'globalData[miles_per_galon_e]',
          },
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'fossil_fuel_efficiency' },
          { displayQuery: 'isFossil' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'fossil_fuel_efficiency',
            pick: 'globalData[fossil_fuel_efficiency]',
          },
          { displayQuery: 'isFossil' },
        ],
      ],
    },
  },

  'app.output.template.routes.vehicles.comparisonTable.row4.onRoad.perMileElectric':{
    config: {
      content: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          {
            autoPick: 'perMileElectric', 
            schema: {
              default: {
                paddingRight: '0'
              }
            }
          },
        ],
        [
          'Tooltip',
          'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip',
        ]
      ]
    },
    schema: {
      default: {
        display: 'flex',
        paddingRight: '20px'
      }
    }
  },
  'app.output.template.routes.vehicles.comparisonTable.row4.onRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.comparisonTable.row4.onRoad.perMileElectric',
          {},
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'perMileElectric',
            pick: 'globalData[perMileElectric]',
          },
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'perMileDiesel' },
          { displayQuery: 'isDiesel' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'perMileGasoline' },
          { displayQuery: 'isGasoline' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'perMileDiesel',
            pick: 'globalData[perMileDiesel]',
          },
          { displayQuery: 'isFossil' },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row5.onRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'millage' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'millage',
            pick: 'globalData[millage]',
          },
        ],
      ],
    },
  },

  // OFF-ROAD ROWS
  'app.output.template.routes.vehicles.comparisonTable.row3.offRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'electric_efficiency' },
          { displayQuery: 'isElectric' },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.template.efficiencyContainer',
          {},
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'fossil_fuel_efficiency' },
          { displayQuery: 'isFossil' },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.template.efficiencyContainer',
          {},
          { displayQuery: 'isFossil' },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row4.offRoad.perHourElectric':{
    config: {
      content: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          {
            autoPick: 'electricCostPerHour',
            schema: {
              default: {
                paddingRight: '0'
              }
            }
          },
        ],
        [
          'Tooltip',
          'app.output.template.routes.vehicles.comparisonTable.electricCostEstimation.tooltip',
        ]
      ]
    },
    schema: {
      default: {
        display: 'flex',
        paddingRight: '20px'
      }
    }
  },
  'app.output.template.routes.vehicles.comparisonTable.row4.offRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.comparisonTable.row4.offRoad.perHourElectric',
          {},
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'perMileElectric',
            pick: 'globalData[perHourElectric]',
          },
          { displayQuery: 'isElectric' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'fossilFuelCostPerHour' },
          { displayQuery: 'isFossil' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'perMileDiesel',
            pick: 'globalData[perHourDiesel]',
          },
          { displayQuery: 'isFossil' },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row5.offRoad': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'annualTotalHours' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'hours',
            pick: 'globalData[hours]',
          },
        ],
      ],
    },
  },

  // ON-ROAD ROWS-SPECS
  'app.output.template.routes.vehicles.comparisonTable.rowSection2': {
    _extends: 'app.output.template.routes.vehicles.template.sectionRows',
    config: {
      cells: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.app.output.template.routes.vehicles.comparisonTable.onRoad.rowSection2',
              fallback: 'Specs',
            },
          },
          {
            colSpan: 2,
          },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row6': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'cargo_volume_max' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'cargo_volume_max',
            pick: 'globalData[cargo_volume_max]',
          },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row7': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'seats_max' },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'seats_max',
            pick: 'globalData[seats_max]',
          },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row8': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'headroom' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'headroom',
            pick: 'globalData[headroom]',
          },
        ],
      ],
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row9': {
    _extends: 'app.output.template.routes.vehicles.template.dataRows',
    config: {
      cells: [
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.labels',
          { autoPick: 'legroom' },
        ],
        [
          'i18nFormatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'legroom',
            pick: 'globalData[legroom]',
          },
        ],
      ],
    },
  },

  'app.output.template.routes.vehicles.template.efficiencyContainer': {
    config: {
      content: [
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'offRoad_fuel_efficiency',
            pick: 'globalData[electric_efficiency]',
            displayQuery: 'isElectric',
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.template.formatters.value',
          {
            autoPick: 'offRoad_fuel_efficiency',
            pick: 'globalData[fossil_fuel_efficiency]',
            displayQuery: 'isFossil',
          },
        ],
        [
          'IconText',
          'app.output.template.routes.vehicles.template.efficiencyTooltip',
          {
            tooltip: {
              popover: {
                translatedText: {
                  _extends:
                    'app.output.template.routes.vehicles.template.tooltipPopOverForEfficiency',
                  i18n: {
                    id: 'i18n.output.template.routes.vehicles.template.efficiencyTooltip.text',
                    fallback:
                      'Based on the usage hours for all vehicles in the set',
                  },
                },
              },
            },
          },
        ],
      ],
      displayQueries: {
        isElectric: { '["props", "globalData[isElectric]"]': { $eq: true } },
        isFossil: { '["props", "globalData[isElectric]"]': { $eq: false } },
      },
    },
    schema: {
      default: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      },
    },
  },
  'app.output.template.routes.vehicles.template.efficiencyContainer.text': {
    component: 'Formatter',
    config: {
      as: 'span',
      formatter: 'formatAsThousands',
      pick: 'rowData[distance]',
    },
  },
  'app.output.template.routes.vehicles.template.efficiencyTooltip': {
    components: {
      tooltip:
        'app.output.template.routes.overview.template.savings.table.tooltipHeader.tooltip',
      header: null,
      text: null,
    },
  },
  'app.output.template.routes.vehicles.template.tooltipPopOverForEfficiency': {
    schema: {
      default: {
        color: 'white',
        fontSize: '14px',
      },
    },
  },

  'app.output.template.routes.vehicles.comparisonTable.row1.header': {
    config: {
      content: [
        [
          'Formatter',
          'app.output.template.routes.vehicles.comparisonTable.vehicleImages',
          {
            mapper: {
              'globalData[image]': 'src',
              'globalData[name]': 'alt',
            },
          },
          {
            colSpan: 2,
          },
        ],
        [
          'Formatter',
          '',
          {
            as: 'p',
            mapper: {
              'globalData[year]': 'year',
              'globalData[model]': 'model',
            },
            text: '{{year}} {{model}}',
            formatter: 'interpolate',
          },
        ],
        [
          'ContentContainer',
          'app.output.template.routes.vehicles.comparisonTable.row1.header.fuel',
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.row1.header.fuel': {
    config: {
      content: [
        [
          'Formatter',
          'app.output.template.routes.vehicles.comparisonTable.fuel.fuelIcons',
          {
            src: 'lightning',
            displayQuery: {
              '["props", "globalData[fuelType]"]': { $eq: 'electric' },
            },
          },
        ],
        [
          'Formatter',
          'app.output.template.routes.vehicles.comparisonTable.fuel.fuelIcons',
          {
            src: 'gas-station',
            displayQuery: {
              '["props", "globalData[fuelType]"]': { $neq: 'electric' },
            },
          },
        ],
        [
          'Formatter',
          'app.common.formatter.auto.FuelTypes',
          { pick: 'globalData[fuelType]' },
        ],
      ],
    },
    schema: {
      default: {
        display: 'flex',
        textTransform: 'capitalize',
        gap: '8px',
      },
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.fuel.fuelIcons': {
    config: {
      component: 'Icon',
      as: 'img',
    },
    schema: {
      default: {
        height: '18px',
        width: '18px',
        flex: 0,
      },
    },
  },
  'app.output.template.routes.vehicles.comparisonTable.vehicleImages': {
    config: {
      component: 'Logo',
      as: 'img',
      format: {
        fallback: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
    schema: {
      default: {
        height: '98px',
        display: 'inline-block',
        margin: '10px 40px',
      },
    },
  },

  'app.common.formatter.auto.FuelTypes': {
    config: {
      as: 'p',
      format: {
        formatter: 'interpolate',
        text: '{{picked}}',
      },
      converter: {
        BEV: 'Electric',
        Electricity: 'Electric',
        DV: 'Diesel',
        GV: 'Gasoline',
        PHEV: 'Gasoline',
        FCEV: 'Hydrogen',
        NGV: 'Natural Gas',
        LPGV: 'Liquid Petroleum Gas',
        CNGV: 'Compressed Natural Gas',
      },
    },
  },
};

export default OUTPUT_VEHICLES;
