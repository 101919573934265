// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import isEmpty from "lodash.isempty";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const FormHiddenCalculation = ({ design }: { design: string }) => {
  const {
    config: { calculation, watch },
  } = zappy.useDesign(design);

  const { setValue } = useFormContext();
  const watchedValues = zappy.useWatch(watch);

  useEffect(() => {
    if (!isEmpty(watchedValues)) {
      // eslint-disable-next-line no-eval
      const value = eval(`${calculation.func}(watchedValues)`);
      setValue(calculation.name, value, { shouldTouch: true });
    }
  }, [watchedValues, calculation, setValue]);

  return false;
};

FormHiddenCalculation.displayName = "FormHiddenCalculation";
