const ONBOARDING_MAIN = {
  'app.onboarding': {
    config: {
      basePath: '/onboarding',
      index: '/onboarding/intro',
      content: [
        ['HeaderRoutesFooterLayout', 'app.onboarding.template'],
        ['ContentContainer', 'app.output.template.contactUs'],
      ],
      routes: [
        {
          path: 'intro',
          design: 'app.onboarding.intro',
        },
        {
          path: 'info',
          design: 'app.onboarding.info',
        },
        {
          path: 'selection/*',
          design: 'app.onboarding.selection',
        },
        {
          path: 'usage',
          design: 'app.onboarding.usage',
        },
        {
          path: 'charging',
          design: 'app.onboarding.charging',
        },
      ],
      scrollTop: false,
    },
  },
};

export default ONBOARDING_MAIN;
