import { DESKTOP_THRESHOLD, TABS } from '../base';

const MISC = {
  app: {
    config: {
      basePath: '/',
      index: '/onboarding',
      favicon:
        'https://images.squarespace-cdn.com/content/v1/60c3c0c4e6193a3137d44071/4e744583-279a-44a7-b69e-cdb6a94c6034/favicon.ico?format=100w',
      title: 'PSE Fleets',
      description: 'PSE Fleets Calculator',
      content: [['HeaderRoutesFooterLayout', 'app.template']],
      scrollTop: false,
      routes: [
        {
          path: 'onboarding',
          design: 'app.onboarding',
        },
        {
          path: 'output/*',
          design: 'app.output',
        },
        {
          path: 'catalog/vehicles',
          design: 'app.catalog.vehicles',
        },
        {
          path: 'catalog/vehicles/:handle',
          design: 'app.catalog.vehicles.details',
        },
        {
          path: 'catalog/chargers',
          design: 'app.catalog.chargers',
        },
        {
          path: 'vehicleSet/edit',
          design: 'app.output.vehicleSets.edit',
        },
        {
          path: 'print/output',
          design: 'app.output.template.routes.print',
        },
      ],
    },
  },
  'app.page.common': {
    _extends: 'app.page.common.schema',
    config: {
      as: 'main',
      id: 'main',
    },
  },
  'app.page.common.schema': {
    schema: {
      default: {
        'border-radius': '5px',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        margin: '0px 24px 24px 24px',
        'background-color': 'white',

        '@media (max-width: 992px)': {
          padding: '1.63vh',
          margin: '0px 16px 16px 16px',
        },
      },
    },
  },
  // TEMPLATE
  'app.template': {
    components: {
      header: 'app.template.header',
      footer: 'app.template.footer',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-rows': 'auto 1fr auto',
        'min-height': '100vh',
        width: '100%',
        '&.print': {
          '& > header': {
            display: 'block important!',
          },
          '& > footer': {
            breakAfter: 'page',
          },
        },
        '@media all': {
          width: '100% !important',
          minWidth: '100%',
        },
      },
    },
  },
  'app.template.header': {
    _extends: 'app.common.device.selector',
    config: {
      content: [
        ['Logo', 'app.template.header.logo', { displayQuery: 'noLang' }],
        ['Formatter', 'app.common.logo.language', { displayQuery: 'lang' }],
        [
          'ActionComponent',
          'app.template.header.button.menu',
          { displayQuery: 'show' },
        ],
        ['ExternalLinksModal', 'app.common.externallinks.modal'],
        ['FormField', 'app.template.header.languageSelector', { displayQuery: 'lang' }],
      ],
      as: 'header',
      watch: {
        name: ['url', 'device', 'assumptionsData.langEnabled', 'assumptionsData.logoLanguage', 'languageSelected'],
      },
      displayQueries: {
        show: {
          $and: [
            {
              '["watched", "url"]': {
                $nincludes: 'onboarding',
              },
            },
            {
              '["watched", "device"]': {
                $eq: 'mobile',
              },
            },
          ],
        },
        lang: {
          '$and': [
            {
              '["watched", "assumptionsData.langEnabled"]': {
                $eq: true,
              }
            },
            {
              '["watched", "assumptionsData.logoLanguage"]': {
                $neq: false,
              }
            }
          ],
        },
        noLang: {
          '["watched", "assumptionsData.langEnabled"]': {
            $neq: true,
          },
        }
      },
    },
    schema: {
      default: {
        backgroundColor: 'white',
        'border-bottom': '1px solid #DCDCDC',
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        padding: '2.5vh 1.4vh',
        cursor: 'default',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',

        '@media (max-width: 992px)': {
          padding: '2.45vh',
          display: 'grid',
          'grid-template-columns': 'repeat(2,auto)',
        },
      },
    },
  },
  'app.template.header.languageSelector': {
    _extends: 'app.output.common.selectWithTooltip',
    components: {
      tooltip: null,
    },
    config: {
      input: {
        name: 'languageSelected',
        options: {
          'eng': {
            value: 'eng',
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.language.1',
              fallback: 'English',
            },
          },
          'fr': {
            value: 'fr',
            i18n: {
              id: 'i18n.output.overview.filters.assumptions.language.2',
              fallback: 'French',
            },
          },
        },
      },
    },
    schema: {
      default: {
        minWidth: 200,
        paddingRight: 28,
      }
    }
  },
  'app.template.layout.header': {
    _extends: 'app.common.device.selector',
    config: {
      content: [
        ['Header', 'app.template.foundation.header'],
        ['ExternalLinksModal', 'app.common.externallinks.modal'],
      ],
      as: 'header',
      watch: {
        name: ['url', 'device'],
      },
      displayQueries: {
        show: {
          $and: [
            {
              '["watched", "url"]': {
                $nincludes: 'onboarding',
              },
            },
            {
              '["watched", "device"]': {
                $eq: 'mobile',
              },
            },
          ],
        },
      },
    },
  },
  'app.template.foundation.header': {
    config: {
      watch: {
        name: ['zipcode'],
      },
      logo: {
        src: 'https://assets.zappyride.com/img/icons/pse/pse-logo.svg',
        to: '/onboarding/intro',
      },
      menuItems: TABS.map((tab) => ({
        ...tab,
        text: tab.label,
      })),
    },
  },
  'app.template.header.button.menu': {
    components: {
      button: 'app.template.header.button.menu.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'output/toggleNavigation',
        },
      ],
      component: 'SmartNextButton',
    },
    schema: {
      default: {
        'justify-self': 'end',
      },
    },
  },
  'app.template.header.button.menu.button': {
    config: {
      component: 'Button',
      content: [['Icon', 'app.template.header.button.menu.button.icon']],
    },
    schema: {
      default: {
        width: '40px',
        padding: '0px',
        justifySelf: 'end',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.template.header.button.menu.button.icon': {
    config: {
      component: 'Icon',
      src: 'https://assets.zappyride.com/img/icons/evergy/hamburger_icon.svg',
      as: 'img',
    },
  },
  'app.template.header.logo': {
    config: {
      src: 'https://images.squarespace-cdn.com/content/v1/60c3c0c4e6193a3137d44071/8d670981-19b0-4f91-8d0a-4003e3277ca0/Site+Logo.png?format=1500w',
      alt: 'Company Logo',
    },
    schema: {
      default: {
        height: '32px',
        width: '140px',
        display: 'inline-block',
        'align-self': 'center',

        '@media (max-width: 992px)': {
          height: '30px',
          width: '100px',
        },
      },
    },
  },
  'app.template.footer': {
    config: {
      content: [
        ['ContentContainer', 'app.template.footer.container'],
      ],
      as: 'footer',
      watch: {
        name: ['url', 'device', 'assumptionsData.langEnabled'],
      },
    },
    schema: {
      default: {
        background: 'var(--primary-background-color)',
      },
    },
  },
  'app.template.footer.container': {
    config: {
      content: [
        ['TranslatedText', 'app.template.footer.poweredBy.translatedText'],
        ['TranslatedText', 'app.template.footer.info'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        rowGap: '24px',
        padding: '12px 24px 36px',
        background: 'var(--primary-background-color)',
      },
    },
  },
  'app.template.footer.poweredBy': {
    components: {
      translatedText: 'app.template.footer.poweredBy.translatedText',
      icon: 'app.template.footer.poweredBy.logo',
    },
    schema: {
      default: {
        'min-width': '192px',
        'grid-column-gap': '0.8rem',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.template.footer.poweredBy.translatedText': {
    config: {
      i18n: {
        id: 'i18n.template.footer.poweredBy',
        fallback: 'powered by',
      },
      color: 'var(--text-primary-color-light)',
      scale: '2',
      lineHeight: '1.25',
    },
  },
  'app.template.footer.poweredBy.logo': {
    config: {
      src: 'https://assets.zappyride.com/img/icons/unbranded/jdpower-zappy-logo.svg',
      alt: 'Powered by JDPower ZappyRide',
      height: '20',
      width: '120',
      style: {
        margin: 'auto',
      },
    },
  },
  'app.template.footer.info': {
    config: {
      i18n: {
        id: 'i18n.template.footer.info',
        fallback:
          'Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information',
      },
      color: 'var(--text-primary-color-light)',
      scale: '2',
      lineHeight: '1.25',
    },
  },

  // COMMON
  'app.common.input.skeleton': {
    schema: {
      skeleton: {
        background:
          'linear-gradient(-90deg, #f6f6f6 0%, #bbbbbb 50%, #f6f6f6 100%)',
        'pointer-events': 'none',
        animation: 'slidein 1.2s ease-in-out infinite',
        'background-size': '400% 400%',

        '@keyframes slidein': {
          '0%': {
            'background-position': 'left',
          },
          '100%': {
            'background-position': 'right',
          },
        },
      },
    },
  },
  'app.common.portal.fullPage.contentCentered': {
    schema: {
      default: {
        display: 'flex',
        position: 'fixed',
        height: '100vh',
        width: '100%',
        'background-color': 'rgba(0, 0, 0, 0.2)',
        'overflow-y': 'auto',
        padding: '40px',
        top: 0,
        left: 0,
      },
    },
  },
  // ##### COMMON RADIO GROUP #####

  'app.common.template.radioButtonGroup.option': {
    _extends: 'app.common.field',
    components: {
      input: 'app.common.template.radioButtonGroup.option.input',
      label: 'app.common.template.radioButtonGroup.option.label',
    },
    schema: {
      default: {
        display: 'grid',
        direction: 'rtl',
        'justify-items': 'end',
        gap: '0px',
        'column-gap': '10px',
        'grid-template-areas': null,
        'align-items': 'center',
      },
    },
  },
  'app.common.template.radioButtonGroup.option.input': {
    config: {
      component: 'FormInput',
      type: 'radio',
    },
    schema: {
      default: {
        display: 'block',
        width: '24px',
        height: '24px',
        'accent-color': '#004E9A',
      },
    },
  },
  'app.common.template.radioButtonGroup.option.label': {
    config: {
      as: 'label',
    },
    schema: {
      default: {
        direction: 'ltr',
      },
    },
  },
  // ##### END COMMON RADIO GROUP #####
  'app.common.modal.button': {
    schema: {
      default: {
        padding: '12px 16px',
      },
    },
  },
  'app.common.icon': {
    config: {
      component: 'Icon',
    },
    schema: {
      default: {
        backgroundColor: 'var(--primary-color)',
        height: '26px',
        width: '26px',
        'min-height': '26px',
        'max-height': '26px',
        'align-self': 'center',
        'justify-self': 'center',
      },
    },
  },
  'app.common.icon.exclamationMarkFilled': {
    _extends: 'app.common.icon',
    config: {
      src: 'info',
    },
    schema: {
      default: {
        transform: 'rotate(180deg)',
      },
    },
  },
  'app.common.icon.lightningFilled': {
    _extends: 'app.common.icon',
    config: {
      src: 'lightning',
    },
  },
  'app.common.icon.electricServicesFilled': {
    _extends: 'app.common.icon',
    config: {
      src: 'electrical-services',
    },
  },
  'app.common.icon.carFilled': {
    _extends: 'app.common.icon',
    config: {
      src: 'car',
    },
  },
  'app.common.iconText.text': {
    config: {
      element: 'p',
      color: 'var(--text-primary-color-normal)',
      scale: '3',
    },
  },
  'app.common.iconText.icon': {
    config: {
      component: 'Icon',
    },
    schema: {
      default: {
        height: '26px',
        width: '26px',
        'align-self': 'center',
      },
    },
  },
  'app.common.iconText.exclamationMarkFilled': {
    _extends: 'app.common.iconText',
    components: {
      icon: 'app.common.icon.exclamationMarkFilled',
    },
  },
  'app.common.iconText': {
    components: {
      icon: 'app.common.icon',
      header: null,
      text: 'app.common.iconText.text',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'auto 1fr',
        'grid-column-gap': '24px',
        padding: '16px 24px',
        'background-color': 'var(--info-background-color)',
        'border-radius': '5px',
      },
    },
  },
  'app.common.iconTextWithCaption.text': {
    _extends: 'app.common.iconText.text',
    schema: {
      default: {
        'grid-row': '1 / -1',
        'grid-column': '2',
      },
    },
  },
  'app.common.iconTextWithCaption.header': {
    config: {
      element: 'p',
      color: 'var(--text-primary-color-bold)',
      textAlign: 'center',
    },
    schema: {
      default: {
        'grid-column': '1',
        'grid-row': '2',
        fontSize: '10px',
        fontWeight: 700,
        'align-self': 'flex-start',
      },
    },
  },
  'app.common.iconTextWithCaption': {
    _extends: 'app.common.iconText',
    components: {
      header: 'app.common.iconTextWithCaption.header',
      text: 'app.common.iconTextWithCaption.text',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-rows': 'repeat(2, auto)',
        'grid-template-columns': 'auto 1fr',
        'grid-column-gap': '24px',
        'grid-row-gap': '0px',
        'align-items': 'center',
        '& > div': {
          'align-self': 'flex-end',
        },
      },
    },
  },

  'app.common.divider.horizontal': {
    schema: {
      default: {
        height: '1px',
        'background-color': 'var(--color-gray-500)',
        width: '100%',
        margin: '1em 0',
      },
    },
  },
  'app.common.field': {
    components: {
      label: 'app.common.field.label',
      input: 'app.common.field.input',
      description: 'app.common.field.description',
      error: 'app.common.field.error.message',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr',
        'grid-template-areas': '"label" "input" "description"',
        'justify-items': 'start',
        'text-align': 'left',
        color: '#1A1A1A',
        'grid-row-gap': '6px',
      },
      disabled: {
        opacity: 0.4,
      },
    },
  },
  'app.common.field.error.message': {
    config: {
      fontSize: '0.6875rem',
      element: 'span',
      color: 'red',
    },
    schema: {
      default: {
        'grid-area': 'description',
      },
    },
  },
  'app.common.field.error': {
    schema: {
      error: {
        'border-color': 'red',
        color: 'red',
      },
    },
  },
  'app.common.field.label': {
    _extends: 'app.common.field.error',
    config: {
      fontSize: '0.875rem',
      element: 'label',
    },
    schema: {
      default: {
        'grid-area': 'label',
      },
    },
  },
  'app.common.field.input': {
    _extends: 'app.common.field.error',
    schema: {
      default: {
        padding: '20px 12px',
        'background-color': '#FFFFFF',
        border: '1px solid #B9BCC0',
        width: '100%',
        'min-height': '4.4rem',
        outline: 'none',
        'grid-area': 'input',
      },
      skeleton: {
        background:
          'linear-gradient(-90deg, #f6f6f6 0%, #bbbbbb 50%, #f6f6f6 100%)',
        'pointer-events': 'none',
        animation: 'slidein 1.2s ease-in-out infinite',
        'background-size': '400% 400%',

        '@keyframes slidein': {
          '0%': {
            'background-position': 'left',
          },
          '100%': {
            'background-position': 'right',
          },
        },
      },
      disabled: {
        opacity: 0.4,
      },
      focus: {
        'outline-color': 'Highlight',
        'outline-style': 'auto',
        'outline-width': '1px',
      },
    },
  },
  'app.common.field.description': {
    _extends: 'app.common.field.error',
    config: {
      fontSize: '0.6875rem',
      element: 'span',
      color: '#707070',
    },
    schema: {
      default: {
        'grid-area': 'description',
      },
    },
  },
  'app.common.field.description.link': {
    _extends: 'app.common.field.description',
    config: {
      color: 'var(--color-info)',
      'text-decoration': 'underline',
    },
  },
  'app.common.fieldWithTooltip': {
    _extends: 'app.output.common.field',
    components: {
      tooltip: 'app.common.tooltip',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '1fr',
        'grid-template-rows': 'min-content',
        'justify-items': 'start',
        'grid-template-areas':
          '"label label" "input tooltip" "error error" "description description"',
      },
    },
  },
  'app.common.tooltip': {
    config: {
      event: 'hover',
    },
    components: {
      activator: 'app.common.tooltip.activator',
      popover: 'app.common.tooltip.popover',
    },
    schema: {
      default: {
        'grid-area': 'tooltip',
        display: 'flex',
        marginLeft: '0.4rem',
      },
    },
  },
  'app.common.tooltip.activator': {
    config: {
      component: 'Icon',
      as: 'mask',
      src: 'https://assets.zappyride.com/img/icons/unbranded/info-icon.svg',
    },
    schema: {
      default: {
        height: '14px',
        width: '14px',
        margin: 'auto',
        'align-self': 'center',
      },
      error: {
        'background-color': 'red',
      },
      hover: {
        cursor: 'pointer',
      },
    },
  },
  'app.common.tooltip.popover': {
    _extends: 'app.common.field.error',
    config: {
      position: 'E',
      content: [
        ['TranslatedText', 'app.common.tooltip.popover.translatedText'],
      ],
    },
    schema: {
      default: {
        padding: '8px 16px',
        'border-radius': '5px',
        'box-shadow': '0 0 10px 5px #2222',
        'max-width': '400px',
        'font-size': '14px',
        color: 'var(--text-primary-inverted-color)',
        backgroundColor: 'var(--primary-dark-color)',

        a: {
          color: 'var(--color-dark-link)',
          textDecoration: 'underline',
        },
      },
    },
  },
  'app.common.tooltip.popover.translatedText': {
    _extends: 'app.common.field.error',
    config: {
      element: 'span',
      i18n: {
        id: 'i18n.tooltipIcon.popover.translatedText',
        fallback: 'This here goes tooltip text',
      },
    },
  },
  'app.common.select': {
    components: {
      selectButton: 'app.common.select.selectButton',
      options: 'app.common.select.options',
    },
    config: {
      placeholder: {
        id: 'i18n.common.select.placeholder',
        fallback: 'Select option',
      },
    },
    schema: {
      default: {
        width: '100%',
      },
    },
  },
  'app.common.select.options': {
    components: {
      option: 'app.common.select.options.option',
    },
    schema: {
      default: {
        backgroundColor: 'white',
        'max-height': '30vh',
        'overflow-y': 'auto',
      },
    },
  },
  'app.common.select.options.option': {
    components: {
      typography: 'app.common.select.options.option.typography',
    },
    schema: {
      default: {
        backgroundColor: 'white',
      },
    },
  },
  'app.common.select.options.option.typography': {
    config: {
      scale: '3',
      fontWeight: '400',
      color: '#666666',
    },
    schema: {
      hover: {
        fontWeight: '700',
      },
    },
  },
  'app.common.select.selectButton': {
    _extends: 'app.common.field.input',
    components: {
      typography: 'app.common.select.selectButton.typography',
      icon: 'app.common.select.selectButton.icon',
    },
    schema: {
      default: {
        'align-items': 'center',
        display: 'grid',
        cursor: 'pointer',
        'grid-template-columns': '1fr auto',
        padding: '0.4rem 0.8rem',
        outline: 'none',
        width: '100%',
      },
      placeholder: {
        color: 'lightgray',
      },
      skeleton: {
        '& > *': {
          display: 'none',
        },
      },
      focus: {
        'outline-color': 'Highlight',
        'outline-style': 'auto',
        'outline-width': '1px',
      },
    },
  },
  'app.common.select.selectButton.typography': {
    config: {
      color: '#1A1A1A',
      scale: '3',
      fontWeight: '700',
    },
  },
  'app.common.select.selectButton.icon': {
    config: {
      src: 'caret-down',
    },
    schema: {
      default: {
        width: '14px',
        height: '14px',
        backgroundColor: '#1A1A1A',
        'align-self': 'auto',
      },
      active: {
        transform: 'rotate(0.5turn)',
      },
    },
  },

  'app.common.select.yesno': {
    _extends: 'app.common.field',
    components: {
      input: 'app.common.select.yesno.input',
    },
  },
  'app.common.select.yesnoWithTooltip': {
    _extends: 'app.common.select.yesno',
    components: {
      tooltip: 'app.common.tooltip',
    },
    schema: {
      default: {
        'grid-template-areas':
          '"label label" "input tooltip" "description description"',
      },
    },
  },
  'app.common.select.yesno.input': {
    _extends: 'app.output.common.select',
    config: {
      component: 'FormSelect',
      options: {
        No: {
          value: 'No',
          i18n: {
            id: 'i18n.app.common.select.yesno.no',
            fallback: 'No',
          },
        },
        Yes: {
          value: 'Yes',
          i18n: {
            id: 'app.common.select.yesno.yes',
            fallback: 'Yes',
          },
        },
      },
    },
  },
  'app.common.skeleton': {
    schema: {
      skeleton: {
        'background-color': 'lightgray',
        display: 'block',
        overflow: 'hidden',
        background:
          'linear-gradient(-90deg, #f6f6f6 0%, #d3d3d3 50%, #f6f6f6 100%)',
        animation: 'slidein 1.2s ease-in-out infinite',
        'background-size': '400% 400%',
        '@keyframes slidein': {
          '0%': {
            'background-position': 'left',
          },
          '100%': {
            'background-position': 'right',
          },
        },
      },
    },
  },
  'app.common.iconText.fuel': {
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'auto 1fr',
        'grid-column-gap': '8px',
        'background-color': '#EAECEE',
        padding: '6px 12px',
        'border-radius': '5px',
        'align-self': 'flex-end',
        'align-items': 'center',
      },
    },
  },
  'app.common.greeButton': {
    _extends: 'app.common.skeleton',
    schema: {
      default: {
        background: 'linear-gradient(180deg, #76BB40 0%, #2E6F0F 50.52%)',
        color: 'white',
        'text-transform': 'uppercase',
        'border-radius': '50px',
        width: '190px',
        height: '46px',
        display: 'block',
        'text-align': 'center',
      },
    },
  },
  'app.common.legendItem': {
    schema: {
      default: {
        display: 'flex',
        marginRight: '1em',
        marginTop: '1em',
        fontSize: '12px',
      },
    },
  },

  //FORMATTER
  'app.common.formatter.auto.label': {
    config: {
      autoFormats: {
        gvwr: {
          text: 'GVWR',
        },
        cargo_volume_max: {
          text: 'Cargo Volume',
          i18n: 'i18n.app.formatter.auto.label.cargo_volume_max',
        },
        ac_charging_power: {
          text: 'Maximum AC Charge Rate',
          i18n: 'i18n.app.formatter.auto.label.ac_charging_power',
        },
        dc_charging_power: {
          text: 'Maximum DC Charge Rate',
          i18n: 'i18n.app.formatter.auto.label.dc_charging_power',
        },
        ac_time_to_full_charge: {
          text: 'Time to Charge AC',
          i18n: 'i18n.app.formatter.auto.label.ac_time_to_full_charge',
        },
        dc_time_to_full_charge: {
          text: 'Time to Charge DC',
          i18n: 'i18n.app.formatter.auto.label.dc_time_to_full_charge',
        },
        miles_per_galon_e: {
          text: 'Electric Efficiency',
          i18n: 'i18n.app.formatter.auto.label.miles_per_galon_e',
        },
        fossil_fuel_efficiency: {
          text: 'Fossil Efficiency',
          i18n: 'i18n.app.formatter.auto.label.fossil_fuel_efficiency',
        },
        electric_efficiency: {
          text: 'Electric Efficiency',
          i18n: 'i18n.app.formatter.auto.label.electric_efficiency',
        },
        annualTotalFossil: {
          text: 'Annual Fossil Fuel Cost',
          i18n: 'i18n.app.formatter.auto.label.annualTotalFossil',
        },
        annualTotalElectric: {
          text: 'Annual Electricity Cost',
          i18n: 'i18n.app.formatter.auto.label.annualTotalElectric',
        },
        annualTotalHours: {
          text: 'Total Hours Per Year',
          i18n: 'i18n.app.formatter.auto.label.annualTotalHours',
        },
        fossilFuelCostPerHour: {
          text: 'Fossil Fuel Cost Per Hour (Average)',
          i18n: 'i18n.app.formatter.auto.label.fossilFuelCostPerHour',
        },
        electricCostPerHour: {
          text: 'Electric Cost Per Hour (Average)',
          i18n: 'i18n.app.formatter.auto.label.electricCostPerHour',
        },
        seats_max: {
          text: 'Seating',
          i18n: 'i18n.app.formatter.auto.label.seats_max',
        },
        legroom: {
          text: 'Legroom',
          i18n: 'i18n.app.formatter.auto.label.legroom',
        },
        headroom: {
          text: 'Headroom',
          i18n: 'i18n.app.formatter.auto.label.headroom',
        },
        perMileElectric: {
          text: 'Electricity Cost Per Mile',
          i18n: 'i18n.app.formatter.auto.label.perMileElectric',
        },
        perMileDiesel: {
          text: 'Diesel Cost Per Mile',
          i18n: 'i18n.app.formatter.auto.label.perMileDiesel',
        },
        perMileGasoline: {
          text: 'Gasoline Cost Per Mile',
          i18n: 'i18n.app.formatter.auto.label.perMileGasoline',
        },
        millage: {
          text: 'Total Miles Per Year',
          i18n: 'i18n.app.formatter.auto.label.millage',
        },
      },
    },
  },
  'app.formatter.auto.value.catalog.vehicleDetail.offRoad': {
    _extends: 'app.common.formatter.auto.value',
    config: {
      as: 'p',
      autoPick: 'rowData[prop]',
      mapper: {
        'rowData[prop]': 'rowData[prop]',
        'rowData[val]': 'picked',
      },
      locale: 'en-US',
      autoFormats: {
        total_range: {
          formatter: 'interpolate',
          text: '{{picked}} hours',
          fallback: 'N/A',
        },
        electric_efficiency: {
          formatter: 'interpolate',
          text: '{{picked}} kWh/hr',
          fallback: 'N/A',
        },
      },
    },
  },
  'app.common.formatter.auto.value': {
    config: {
      locale: 'en-US',
      autoFormats: {
        type: {
          formatter: 'formatAsStartCase',
        },
        gvwr: {
          formatter: 'formatAsNumber',
          maximumFractionDigits: 0,
        },
        availability: {
          formatter: 'formatAsStartCase',
          fallback: 'N/A',
        },
        total_range: {
          formatter: 'interpolate',
          text: '{{picked}} mi',
          fallback: 'N/A',
          i18n: 'i18n.app.formatter.autoFormats.values.total_range'
        },
        electric_range: {
          formatter: 'interpolate',
          text: '{{picked}} mi',
          fallback: 'N/A',
          i18n: 'i18n.app.formatter.autoFormats.values.electric_range'
        },
        fuel: {
          converter: {
            gas: 'Gasoline',
            diesel: 'Diesel',
            PHEV: 'Hybrid',
            BEV: 'Electric',
          },
          converter_i18n: {
            gas: {
              id: 'i18n.catalog.vehicle.details.name.fuel.gas',
              fallback: 'Gasoline',
            },
            diesel: {
              id: 'i18n.catalog.vehicle.details.name.fuel.diesel',
              fallback: 'Diesel',
            },
            PHEV: {
              id: 'i18n.catalog.vehicle.details.name.fuel.phev',
              fallback: 'Hybrid',
            },
            BEV: {
              id: 'i18n.catalog.vehicle.details.name.fuel.BEV',
              fallback: 'Electricue',
            },
          }
        },
        battery_capacity: {
          formatter: 'interpolate',
          text: '{{picked}} kWh',
          fallback: 'N/A',
        },
        cargo_volume_max: {
          formatter: 'interpolate',
          text: '{{picked}} ft³',
          i18n: 'i18n.app.formatter.autoFormats.values.cargo_volume_max',
          fallback: 'N/A',
        },
        payload: {
          formatter: 'interpolate',
          text: '{{picked}} lbs',
          fallback: 'N/A',
        },
        ac_charging_power: {
          formatter: 'interpolate',
          text: '{{picked}} kW',
          fallback: 'N/A',
        },
        dc_charging_power: {
          formatter: 'interpolate',
          text: '{{picked}} kW',
          fallback: 'N/A',
        },
        ac_time_to_full_charge: {
          formatter: 'formatAsDurationInHoursAndMinutes',
          fallback: 'N/A',
        },
        dc_time_to_full_charge: {
          formatter: 'formatAsDurationInHoursAndMinutes',
          fallback: 'N/A',
        },
        offroad_electric_efficiency: {
          partials: [
            {
              pick: 'rowData[val]',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 2,
                as: 'rowData[val]',
              },
            },
          ],
          text: '{{picked}} kWh/hr',
          formatter: 'interpolate',
          fallback: 'N/A',
        },
        fossil_fuel_efficiency: {
          partials: [
            {
              pick: 'fossil_fuel_efficiency',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 0,
                as: 'fossil_fuel_efficiency',
              },
            },
          ],
          text: '{{picked}} MPG',
          formatter: 'interpolate',
          fallback: 'N/A',
          i18n: 'i18n.app.formatter.autoFormats.values.fossil_fuel_efficiency',
        },
        miles_per_galon_e: {
          partials: [
            {
              pick: 'rowData[val]',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 0,
                as: 'rowData[val]',
              },
            },
          ],
          text: '{{picked}} MPGe',
          formatter: 'interpolate',
          fallback: 'N/A',
        },
        msrp: {
          formatter: 'formatAsDollars',
          converter: {
            undefined: 'N/A',
          },
        },
        millage: {
          partials: [
            {
              pick: 'useParentPick',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 0,
                as: 'useParentPick',
              },
            },
          ],
          formatter: 'interpolate',
          text: '{{picked}} mi',
          i18n: 'i18n.app.formatter.autoFormats.values.millage',
        },
        hours: {
          partials: [
            {
              pick: 'useParentPick',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 2,
                as: 'useParentPick',
              },
            },
          ],
          formatter: 'interpolate',
          text: '{{picked}} hours',
        },
        perMileElectric: {
          formatter: 'formatAsCents',
        },
        perMileGasoline: {
          formatter: 'formatAsCents',
        },
        perMileDiesel: {
          formatter: 'formatAsCents',
        },
        annualTotalElectric: {
          formatter: 'formatAsDollars',
        },
        annualTotalFossil: {
          formatter: 'formatAsDollars',
        },
        legroom: {
          formatter: 'interpolate',
          text: '{{picked}} ft³',
          fallback: 'N/A',
          i18n: 'i18n.app.formatter.autoFormats.values.legroom',
        },
        headroom: {
          formatter: 'interpolate',
          text: '{{picked}} ft³',
          fallback: 'N/A',
          i18n: 'i18n.app.formatter.autoFormats.values.headroom',
        },
        seats_max: {
          formatter: 'formatAsNumber',
          fallback: 'N/A',
        },
        offRoad_fuel_efficiency: {
          partials: [
            {
              pick: 'useParentPick',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 2,
                as: 'useParentPick',
              },
            },
          ],
          formatter: 'interpolate',
          text: '{{picked}} Gal/Hr',
          i18n: 'i18n.app.formatter.autoFormats.values.offRoad_fuel_efficiency',
        },
        electricEfficiencyMPGe: {
          partials: [
            {
              pick: 'useParentPick',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 2,
                as: 'useParentPick',
              },
            },
          ],
          formatter: 'interpolate',
          text: '{{picked}} MPGe',
        },
        workdays: {
          formatter: 'interpolate',
          text: '{{picked}}',
          converter: {
            0: {
              i18n: 'i18n.operationDays.sunday.value',
              fallback: 'Sun',
            },
            1: {
              i18n: 'i18n.operationDays.monday.value',
              fallback: 'Mon',
            },
            2: {
              i18n: 'i18n.operationDays.tuesday.value',
              fallback: 'Tue',
            },
            3: {
              i18n: 'i18n.operationDays.wednesday.value',
              fallback: 'Wed',
            },
            4: {
              i18n: 'i18n.operationDays.thursday.value',
              fallback: 'Thu',
            },
            5: {
              i18n: 'i18n.operationDays.friday.value',
              fallback: 'Fri',
            },
            6: {
              i18n: 'i18n.operationDays.saturday.value',
              fallback: 'Sat',
            }
          },
        },
        ports: {
          formatter: 'interpolate',
          text: '{{picked}}',
          converter: {
            1: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.singlePort',
              fallback: 'Single Port',
            },
            2: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.dualPort',
              fallback: 'Dual Port',
            },
            3: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
              fallback: 'Multi Port',
            },
            4: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
              fallback: 'Multi Port',
            },
            5: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
              fallback: 'Multi Port',
            },
            6: {
              i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
              fallback: 'Multi Port',
            }
          },
        },
      },
    },
  },
  'app.common.formatter.auto.ports': {
    config: {
      format: {
        formatter: 'interpolate',
        text: '{{picked}}',
      },
      converter: {
        1: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.singlePort',
          fallback: 'Single Port',
        },
        2: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.dualPort',
          fallback: 'Dual Port',
        },
        3: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
          fallback: 'Multi Port',
        },
        4: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
          fallback: 'Multi Port',
        },
        5: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
          fallback: 'Multi Port',
        },
        6: {
          i18n: 'i18n.app.formatter.autoFormats.values.converter.multiPort',
          fallback: 'Multi Port',
        }
      },
    },
  },
  'app.common.fields.row': {
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '24px',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-template-columns': 'repeat(2, 1fr)',
          'grid-column-gap': '16.24vw',
        },
      },
    },
  },
  'app.common.modal': {
    schema: {
      default: {
        margin: 'auto',
        padding: '32px',
        'background-color': 'white',
        width: '490px',
        'box-shadow': '0px 0px 20px 2px rgba(0, 0, 0, 0.5)',
      },
    },
  },
  'app.common.modal.actions': {
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': '255px 148px',
        'justify-content': 'space-between',
      },
    },
  },

  'app.common.logo.language': {
    _extends: 'app.template.header.logo',
    config: {
      component: 'Logo',
      as: 'img',
      mapper: {
        'assumptionsData.logoLanguage': 'src',
      },
      watch: {
        name: ['assumptionsData.logoLanguage'],
      },
    },
    schema: {
      default: {
        width: 'auto',
      },
      skeleton: {
        display: 'none',
      },
    },
  },

  'app.common.heading': {
    config: {
      element: 'h1',
      fontSize: '2.125rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    schema: {
      default: {
        marginBottom: '2.5vh',
        marginTop: '4.73vh',

        '@media (max-width: 992px)': {
          'max-width': '100%',
          'font-size': '32px',
          marginTop: '24px',
          marginBottom: '16px',
        },
      },
    },
  },
  'app.common.description': {
    config: {
      element: 'p',
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
  'app.common.device.selector': {
    config: {
      watch: {
        name: ['device', 'printing'],
      },
      displayQueries: {
        mobile: {
          '["watched", "device"]': {
            $eq: 'mobile',
          },
        },
        desktop: {
          '["watched", "device"]': {
            $eq: 'desktop',
          },
        },
        mobileOrPrinting: {
          $or: [
            { '["watched", "device"]': { $eq: 'mobile' } },
            { '["watched", "printing"]': { $eq: true } },
          ],
        },
        desktopOrPrinting: {
          $or: [
            { '["watched", "device"]': { $eq: 'desktop' } },
            { '["watched", "printing"]': { $eq: true } },
          ],
        },
        printing: {
          '["watched", "printing"]': { $eq: true },
        },
        notPrinting: {
          '["watched", "printing"]': { $eq: false },
        },
        mobileNotPrinting: {
          $and: [
            { '["watched", "device"]': { $eq: 'mobile' } },
            { '["watched", "printing"]': { $eq: false } },
          ],
        },
        desktopNotPrinting: {
          $and: [
            { '["watched", "device"]': { $eq: 'desktop' } },
            { '["watched", "printing"]': { $eq: false } },
          ],
        },
      },
    },
  },
  'app.common.externallinks.modal': {
    _extends: 'app.common.primaryModal',
    config: {
      header: {
        id: 'i18n.common.externallinks.modal.title',
        fallback: 'Leaving',
      },
      cancel: {
        id: 'i18n.common.externallinks.modal.cancel',
        fallback: 'Cancel',
      },
      confirm: {
        id: 'i18n.common.externallinks.modal.confirm',
        fallback: 'Confirm',
      },
      content: 'app.common.externallinks.modal.content',
    },
    components: {
      trigger: null,
      content: 'app.common.externallinks.modal.content',
    },
  },
  'app.common.externallinks.modal.content': {
    config: {
      content: [
        ['TranslatedText', 'app.common.externallinks.modal.content.text'],
      ],
    },
  },
  'app.common.externallinks.modal.content.text': {
    config: {
      element: 'p',
      i18n: {
        id: 'i18n.common.externallinks.modal.content.text',
        fallback:
          "Are you sure you want to navigate away from {{clientName}}'s website?\n{{clientName}} holds no liability for content outside its website.",
      },
    },
    schema: {
      default: {
        margin: '16px 0',
      },
    },
  },
  'app.common.primaryModal': {
    config: {
      header: {
        id: 'i18n.modal.primary.header',
        fallback: 'Title',
      },
      cancel: {
        id: 'i18n.modal.primary.cancel',
        fallback: 'Cancel',
      },
      confirm: {
        component: 'Button',
        i18n: {
          id: 'i18n.modal.primary.cancel',
          fallback: 'Confirm',
        },
      },
    },
    components: {
      portal: 'app.common.portal.fullPage.contentCentered',
      header: 'app.common.primaryModal.header',
      actions: 'app.common.primaryModal.actions',
      confirm: 'app.common.primaryModal.confirm',
      cancel: 'app.common.primaryModal.cancel',
    },
    schema: {
      default: {
        margin: 'auto',
        padding: '32px',
        'background-color': 'white',
        width: '490px',
        'box-shadow': '0px 0px 20px 2px rgba(0, 0, 0, 0.5)',
      },
    },
  },
  'app.common.primaryModal.header': {
    schema: {
      default: {
        'border-bottom': 'var(--input-border)',
        'padding-bottom': '16px',
        'margin-bottom': '16px',
        fontWeight: 700,
      },
    },
  },
  'app.common.primaryModal.cancel': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
  },
  'app.common.primaryModal.confirm': {
    _extends: ['app.common.button.primary', 'app.common.button.small'],
    schema: {
      default: {
        width: '100%',
      },
    },
  },
  'app.common.primaryModal.actions': {
    schema: {
      default: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
      },
    },
  },
  'app.common.termconditions.modal': {
    _extends: 'app.common.primaryModal',
    config: {
      header: {
        id: 'i18n.common.termconditions.modal.title',
        fallback: 'Terms and Conditions',
      },
      cancel: {
        id: 'i18n.common.termconditions.modal.cancel',
        fallback: 'Cancel',
      },
      confirm: {
        component: 'Link',
        role: 'button',
        content: ['TranslatedText', 'app.common.termconditions.modal.confirm'],
      },
      content: 'app.common.termconditions.modal.content',
    },
    components: {
      trigger: null,
      content: 'app.common.termconditions.modal.content',
    },
  },
  'app.common.termconditions.modal.confirm': {
    _extends: 'app.common.primaryModal.confirm',
    config: {
      i18n: {
        id: 'i18n.common.termconditions.modal.confirm',
        fallback: 'Accept',
      },
    },
  },
  'app.common.termconditions.modal.content': {
    config: {
      content: [
        [
          'TranslatedText',
          'app.common.termconditions.modal.content.clientName',
        ],
        [
          'TranslatedText',
          'app.common.termconditions.modal.content.text',
          {
            values: {
              title: '*Pursuant Name*',
              url: 'http://www.fakeendpoint.com',
            },
            i18n: {
              dangerouslySetInnerHTML: true,
              id: 'i18n.common.termconditions.modal.content.text',
              fallback:
                ' is providing you access to the {{title}} pursuant to these terms and conditions. By clicking “Accept” below, you agree to <a href="{{url}}" class="link" target="_blank" style="color: var(--color-info);text-decoration: underline;">these terms and conditions</a>.',
            },
          },
        ],
        ['TranslatedText', 'app.common.termconditions.modal.accepting.text'],
      ],
    },
  },
  'app.common.termconditions.modal.content.clientName': {
    config: {
      element: 'span',
      i18n: {
        id: 'i18n.common.termconditions.modal.content.text.clientName',
        fallback: '{{clientName}}',
      },
    },
  },
  'app.common.termconditions.modal.content.text': {
    config: {
      element: 'span',
    },
  },
  'app.common.termconditions.modal.accepting.text': {
    config: {
      element: 'p',
      i18n: {
        id: 'i18n.common.termconditions.modal.accepting.text',
        fallback: 'You must accept to continue.',
      },
    },
    schema: {
      default: {
        margin: '16px 0',
      },
    },
  },
  'app.common.button.small': {
    schema: {
      default: {
        height: '40px',
        lineHeight: '1em',
      },
    },
  },
  'app.common.button.medium': {
    schema: {
      default: {
        height: '48px',
      },
    },
  },
  'app.common.button.large': {
    schema: {
      default: {
        height: '56px',
      },
    },
  },
  'app.common.link.button': {
    config: {
      component: 'Link',
      role: 'button',
    },
  },
  'app.common.button': {
    schema: {
      default: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'text-transform': 'uppercase',
        'border-radius': '50px',
        textAlign: 'center',
        border: '1px solid var(--button-primary-color)',
      },
      disabled: {
        border: 'none',
        opacity: 0.3,
        backgroundColor: 'var(--disabled-background-color)',
        '& > span': {
          color: 'var(--disabled-text-color)',
        },
        'pointer-events': 'none',
        cursor: 'default',
      },
    },
  },
  'app.common.button.primary': {
    _extends: ['app.common.button', 'app.common.button.medium'],
    schema: {
      default: {
        color: 'white',
        backgroundColor: 'var(--button-primary-color)',
      },
      hover: {
        backgroundColor: 'var(--button-primary-color-hover)',
        borderColor: 'var(--button-primary-color-hover)',
      },
    },
  },
  'app.common.button.secondary': {
    _extends: ['app.common.button', 'app.common.button.medium'],
    schema: {
      default: {
        backgroundColor: 'white',
        color: 'var(--button-primary-color)',
      },
      hover: {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
    },
  },
  'app.common.icon.secondary': {
    schema: {
      default: {
        backgroundColor: 'var(--button-primary-color)',
      },
      disabled: {
        backgroundColor: 'var(--disabled-color)',
      },
    },
  },
  'app.common.icon.primary': {
    schema: {
      default: {
        backgroundColor: 'white',
      },
    },
    disabled: {
      backgroundColor: 'var(--disabled-color)',
    },
  },
  'app.common.text.secondary': {
    schema: {
      default: {
        color: 'var(--button-primary-color)',
        'text-align': 'center',
      },
    },
  },
  'app.common.text.primary': {
    schema: {
      default: {
        color: 'white',
        'text-align': 'center',
      },
    },
  },

  'app.common.incentives.displayQuery': {
    config: {
      watch: {
        name: ['assumptionsData.hideSummaryIncentives', 'assumptionsData.incentivesType'],
      },
      displayQueries: {
        showSummaryIncentives: {
          $and: [
            {
              '["watched", "assumptionsData.hideSummaryIncentives"]': { $neq: true },
            },
            {
              '["watched", "assumptionsData.incentivesType"]': { $neq: 'commercial' },
            },
          ],
        },
        hideSummaryIncentives: {
          $or: [
            {
              '["watched", "assumptionsData.hideSummaryIncentives"]': { $eq: true },
            },
            {
              '["watched", "assumptionsData.incentivesType"]': { $eq: 'commercial' },
            },
          ],
        },
        commercialIncentives: {
          '["watched", "assumptionsData.incentivesType"]': { $eq: 'commercial' },
        },
        notCommercialIncentives: {
          '["watched", "assumptionsData.incentivesType"]': { $neq: 'commercial' },
        },
      },
    }
  }
};

export default MISC;
