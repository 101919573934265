// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';
import zappy from '@zappy-ride/library.react.components';
import HttpApi from 'i18next-http-backend';
import * as config from '../config';
import currencyFormatter from '../utils/currency';
import { setLocalLang } from '../utils/localStorageUtils';
import { useFormContext } from 'react-hook-form';

const LANG_FOR_FILE_EXTENSIONS = {
  eng: 'en',
  fr: 'fr',
};

function replaceFileExtension(fileName, lang) {
  return fileName.replace('{{lng}}', LANG_FOR_FILE_EXTENSIONS[lang] || lang);
}

export const useLangChanger = ({
  defaultLng,
  fallbackLng,
  enhancedSupportedLngs,
  loadPath,
  basePath,
  getTranslationResources,
  loadPaths,
}) => {
  const { watch } = useFormContext();
  const languageSelected = watch('languageSelected');
  const {changeLanguage} = zappy.useTranslations()

  const [language, setLanguage] = useState(languageSelected);

  useEffect(()=>{
    if(language !== languageSelected){
      setLocalLang(languageSelected);
      setLanguage(languageSelected);
      changeLanguage(languageSelected);
    }
  }, [languageSelected, changeLanguage, language, setLanguage, setLocalLang])

  useEffect(() => {
    if (loadPath || loadPaths) {
      zappy.initI18n(
        [HttpApi],
        {
          lng: language,
          debug: false,
          fallbackLng,
          supportedLngs: enhancedSupportedLngs,
          maxRetries: 0, //for backend fetch when fail
          backend: {
            loadPath: (lng: string, ns: string) => {
              if(loadPaths) {
                return loadPaths[lng];
              }

              if (loadPath) {
                return replaceFileExtension(loadPath, lng);
              }

              let { hostname: clientName } = window.location;
              const extensionLang = LANG_FOR_FILE_EXTENSIONS[lng] || lng;

              //when no loadPath provided, must have at least the basePath and we will try to fetch translation and formatting as separate files
              return `${basePath}${clientName}.${extensionLang}.${ns}`;
            },
            customHeaders: {
              'x-api-key': config.ADMIN_API_KEY_VALUE,
            },
            parse: (data, language, namespace) => {

              let ret = data;
              try {
                ret = JSON.parse(data);

                //keep compatibility with current fleets translations file
                if (namespace === 'translation' && ret['translations']) {
                  return ret['translations'];
                }

                // works if the object key is "translation" (default namespace) on the translation file as well
                if (ret[namespace]) {
                  return ret[namespace];
                }

                return ret;
              } catch (error) {
                console.error(
                  'Something happening while parsing the translation file: ',
                  { error }
                );
                return ret;
              }
            },
          },
          interpolation: {
            escapeValue: false,
            format: (value, rawFormat, lng) => {
              if (!rawFormat || !lng) return value;

              const [format, ...additionalProps] = rawFormat
                .split(',')
                .map((v) => v.trim());
              switch (format) {
                case 'currency':
                  return currencyFormatter(lng, value, additionalProps[0]);
                default:
                  return value;
              }
            },
          },
        },
        getTranslationResources
      );
    }
  }, []);

  return {
    language
  }
}