// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetCurrentOrFirstVehicleSet } from '../../hooks/useGetCurrentOrFirstVehicleSet';
import { useResetFiltersForCatalogs } from '../../hooks/useResetFiltersForCatalogs';
import { parseCharger } from '../../selectors/calculations/selectors/chargers';
import { ArchetypeCharger } from '../../types/form.types';
import { GenericCatalog } from './GenericCatalog';

export const ChargersCatalog = ({ design }: { design: string }) => {
  const { setValue, getValues } = useFormContext();
  const resetUtilities = useResetFiltersForCatalogs('chargers');
  const currentVehicleSet = useGetCurrentOrFirstVehicleSet();
  const defaultsApplied = useRef(null);

  const dataPreparingFn = (values: Array<ArchetypeCharger>) =>
    values?.map((val, index) => parseCharger(val, index)) || [];
  const dataHidingFn = useCallback((values?: Array<ArchetypeCharger>) => {
    if (!values) return values;
    const { is_overhead_charging_capable } = currentVehicleSet?.input || {};

    let result = [...values];
    // Requirement: hide chargers that are not available for current EV to use.
    // Current status: only Overhead chargers are able to be excluded.
    // TODO: add Induction chargers to this logic when added to API. Richard is on it. Non existent atm.
    // is_induction_charging_capable
    if (is_overhead_charging_capable !== true)
      result = result.filter(
        (el) => el.form_factor.toLowerCase() !== 'overhead'
      );

    return result;
  });

  const setDefaultFiltersIfNeeded = useCallback(() => {
    if (defaultsApplied.current) return;
    const { availableSorts, sort } = getValues('catalogs.chargers');
    if (!availableSorts.includes(sort)) {
      setValue('catalogs.sort', getValues('catalogs.chargers.sort'));
    }
    resetUtilities.saveFilters();
    defaultsApplied.current = true;
  }, [defaultsApplied.current, resetUtilities.saveFilters]);

  const resetFunction = useCallback(async () => {
    resetUtilities.resetOriginalValues();

    setDefaultFiltersIfNeeded();
    defaultsApplied.current = false;
  }, [resetUtilities.resetOriginalValues]);

  return (
    <GenericCatalog
      design={design}
      dataPreparingFn={dataPreparingFn}
      dataHidingFn={dataHidingFn}
      defaultFiltersFn={setDefaultFiltersIfNeeded}
      resetFunction={resetFunction}
      catalogName="chargers"
      deferredLoading={150}
    />
  );
};

ChargersCatalog.displayName = 'ChargersCatalog';
