// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useFormContext, useWatch } from "react-hook-form";

export const FormVehicleInOperation = ({ design }: { design: string }) => {
  const { schema, components, config } = zappy.useDesign(design);
  const selectTags = zappy.useDesign(components.selectTags);

  const { setValue, control, resetField } = useFormContext();
  const watchedValue = useWatch({ control, ...config.watch });

  const handleButtonClick = () => {
    setValue(config.watch.name, false);
  };

  const handleCheckboxChange = (e) => {
    if (!e.target.checked) return;
    resetField(selectTags.config.input.name);
  };

  return (
    <zappy.FactoryComponent schema={schema}>
      <zappy.FormField
        design={components.checkbox}
        onChange={handleCheckboxChange}
      />
      {watchedValue ? (
        <zappy.FormField
          design={components.button}
          onClick={handleButtonClick}
        />
      ) : (
        <zappy.FormField design={components.selectTags} />
      )}
    </zappy.FactoryComponent>
  );
};

FormVehicleInOperation.displayName = "FormVehicleInOperation";
