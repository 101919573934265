// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';

export const FormVehicleStatsRow = ({ design }) => {
  const { components, config, schema } = zappy.useDesign(design);

  const { component, i18nLabel, i18nUnit, ...formatOpts } = config;

  return (
    <zappy.FactoryComponent schema={schema}>
      <div>
        <zappy.TranslatedText design={components.label} i18n={i18nLabel} />
        {components.unit && i18nUnit && (
          <zappy.TranslatedText design={components.unit} i18n={i18nUnit} />
        )}
      </div>
      <zappy.Formatter
        design={components.value}
        component={component}
        {...formatOpts}
      />
    </zappy.FactoryComponent>
  );
};

FormVehicleStatsRow.displayName = 'FormVehicleStatsRow';
