// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';
import { Header as FoundationHeader, useAbility } from '@zappy-ride/ev-fleets';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Header = ({ design }: { design: string }) => {
    const { config } = zappy.useDesign(design);
    const watchedValues = zappy.useWatch(config.watch);

    const {pathname} = useLocation();
    const {denyAbility, allowAbility} = useAbility();
    
    useEffect(() => {
        if (pathname.includes('onboarding')) {
            denyAbility('show', 'header.mobileMenu')
        } else {
            allowAbility('show', 'header.mobileMenu')
        }
    }, [pathname])

    const menuItems = config.menuItems.filter(
        ({ displayQuery }) =>
            !displayQuery ||
            (displayQuery &&
                zappy.runQuery(undefined, displayQuery, { watched: watchedValues }))
    );

    return <FoundationHeader {...config} menuItems={menuItems} />
};

Header.displayName = "Header";
