import { VehicleSetDataChargingWindow } from '../../../types/form.types';
import { useFormContext } from 'react-hook-form';
import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { ChargersInfo } from '@zappy-ride/library.calculations';
import zappy from '@zappy-ride/library.react.components';
import get from 'lodash.get';
import { PreloadedCharger } from '../../../types/chargers.types';
import { convertFrom24HsToHours } from '../../../utils/dateTimeUtils';

export const selectedSetOrFirst = (
  selectedSetId: string | undefined,
  setsArray: any[]
) => {
  return selectedSetId
    ? setsArray.find(({ id }: any) => id === selectedSetId)
    : setsArray[0];
};

const connectivity = (charger: PreloadedCharger) => {
  return {
    has3G: charger.cellular_support.includes('3G'),
    has4G: charger.cellular_support.includes('4G'),
    has5G: charger.cellular_support.includes('5G'),
    hasLTE: charger.cellular_support.includes('LTE'),
    hasBluetooth: charger.bluetooth,
    hasWifi: charger.wifi,
    hasEthernet: charger.ethernet,
  };
};

export const parseCharger = (charger: PreloadedCharger, index: number) => {
  const connectivityElements = connectivity(charger);
  const globalDataPerTable = {
    ports: charger.ports,
    label: charger.label,
    translatedLabel: {
      i18n: {
        id: `i18n.output.template.chargerTable.chargerSpecs.rowsHeader.mainHeader.commercial`,
        fallback: charger.label,
      },
    },
    id: charger.id,
    image: charger.imageUrl,
    hasConnectivity: Object.values(connectivityElements).some(
      (c: boolean) => c
    ),
    index,
    commercial: charger.commercial,
    archetype: charger.archetype,
    approved: charger.customer_approved,
    ...connectivityElements,
  };
  return {
    data: [
      { prop: '_', val: null }, // allows to override with the charger header
      { prop: 'cost', val: get(charger, 'price', 0) },
      { prop: 'portKw', val: get(charger, 'portKw', null) },
      { prop: 'amps', val: get(charger, 'amps', null) },
      { prop: 'plugTypes', val: get(charger, 'plug_types', null) },
      { prop: 'cordLength', val: get(charger, 'cord_length', null) },
      { prop: 'ports', val: get(charger, 'ports', null) },
      { prop: 'formFactor', val: get(charger, 'form_factor', null) },
      { prop: 'outlets', val: get(charger, 'home_outlet', null) },
      { prop: 'ocpp', val: get(charger, 'ocpp_version', null) },
      { prop: 'oscp', val: get(charger, 'oscp_version', null) },
      { prop: 'toGrid', val: get(charger, 'vgi_capable', null) },
      { prop: 'warranty', val: get(charger, 'warranty', null) },
      { prop: 'certifications', val: get(charger, 'certified_by', null) },
      { prop: 'chargerType', val: get(charger, 'charger_type', null) },
    ],
    globalDataPerTable,
  };
};

export const chargerTable = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { vehicleSets = [], selectedSetId } = getValues();
  const watchedValues = zappy.useWatch({ name: 'selectedSetId' }) as {
    [key: string]: any;
  };
  const currentSet = selectedSetOrFirst(selectedSetId, vehicleSets) || {};
  const chargingWindows = currentSet.chargingWindows || [];

  const chargerPorts: { [key: string]: number } = {};
  const content = chargingWindows.map(
    ({ charger }: VehicleSetDataChargingWindow, index: number) => {
      chargerPorts[charger.charger_id as string] = charger.ports;
      return parseCharger(charger, index);
    }
  );

  return {
    status: {
      isLoading: false,
      missingRequiredInformation: false,
    },
    data: {
      content,
      selectedSetId: watchedValues?.selectedSetId,
      vehicleCount: currentSet?.vehicleCount,
    },
  };
};

/**
 * wrapper charging selector
 */
export const charger = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  const { selectedSetId } = zappy.useWatch({ name: 'selectedSetId' }) as {
    [key: string]: any;
  };

  const [calculationsStatus, inputsStatus] = getStateValue({
    lookupKey: ['calculations.isReady', 'inputs.isReady'],
    path: 'status',
  }) as [boolean, boolean];

  const [chargingValue, chargingStatus = false] = getStateValue({
    lookupKey: ['total.cumulative[-1].chargers', 'status.chargers.isReady'],
    states: ['chargers'],
  }) as [ChargersInfo, boolean, Array<number>];

  const [dailyChargeSchedule = []] = getStateValue({
    lookupKey: [`perSet.${selectedSetId}.annual.vehicles.dailyChargeSchedule`],
    states: ['chargers'],
  }) as [Array<number>];

  const parsedDailyChargeSchedule = dailyChargeSchedule.map((value) =>
    value > 1 ? value / 100 : value
  );

  // is loading till all status came as true
  const isLoading = !(calculationsStatus && inputsStatus && chargingStatus);

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation = !(
    calculationsStatus &&
    inputsStatus &&
    chargingStatus
  );
  const totalCost = zappy.formatters.formatAsThousands(chargingValue?.total);

  return {
    status: {
      isLoading,
      missingRequiredInformation,
    },
    data: {
      totalCost,
      dailyChargeSchedule: parsedDailyChargeSchedule,
    },
  };
};

export const chargerAssignment = () => {
  const { selectedSetId } = zappy.useWatch({ name: 'selectedSetId' }) as {
    [key: string]: any;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getValues } = useFormContext();
  const { vehicleSets = [] } = getValues();

  const vehicleSet = selectedSetOrFirst(selectedSetId, vehicleSets);
  const {translate} = zappy.useTranslation();

  const vehicleSetWindows: any[] = [];
  vehicleSet?.chargingWindows?.forEach(
    (cw: { [key: string]: any }, index: number) => {
      const i18nStart = translate({
        id: `i18n.time.${convertFrom24HsToHours(cw.start)}`,
        fallback: convertFrom24HsToHours(cw.start).toLowerCase(),
      });
      const i18nEnd = translate({
        id: `i18n.time.${convertFrom24HsToHours(cw.finish)}`,
        fallback: convertFrom24HsToHours(cw.finish).toLowerCase(),
      });

      vehicleSetWindows.push({
        ...vehicleSet.input,
        year: getValues('assumptionsData.year'),
        vehicleSetId: vehicleSet.id,
        vehicleSetIndex: index,
        count: Math.ceil(vehicleSet.vehicleCount / cw.charger?.ports),
        chargerLabel: cw.charger?.label,
        ports: cw.charger?.ports,
        i18Time: `${i18nStart} - ${i18nEnd}`,
        ...cw,
      });
    }
  );
  return {
    status: {
      isLoading: false,
      missingRequiredInformation: false,
    },
    data: {
      chargingWindows: vehicleSetWindows,
    },
  };
};
