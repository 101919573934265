// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from '@zappy-ride/library.react.components';

type emptyStateProps = {
  design: string;
  elements?: Array<any>;
  resetFiltersFn?: (values: AnyObject) => void;
  shouldBeVisible?: boolean;
};

export const CatalogEmptyState = ({
  design,
  elements,
  resetFiltersFn,
  shouldBeVisible = true,
}: emptyStateProps) => {
  const { config } = zappy.useDesign(design);

  return !shouldBeVisible ? null : elements?.length ? null : (
    <zappy.FactoryComponent design={design}>
      <zappy.ContentContainer design={config?.graphics} />
      <zappy.ContentContainer
        design={config?.actionAndLegend}
        actions={{ resetFiltersFn }}
      />
    </zappy.FactoryComponent>
  );
};

CatalogEmptyState.displayName = 'CatalogEmptyState';
