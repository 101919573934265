// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export const SetSelector = ({ design } : { design: string }) => {
  const { getValues, setValue } = useFormContext();
  const { vehicleSets, selectedSetId } = getValues();
  const { components, config } = zappy.useDesign(design);
  const watchedValues = zappy.useWatch({ name: "selectedSetId" });


  // Dynamic creation of the options based on the vehicle sets
  const options = vehicleSets.map((vehicleSet: any) => ({
    value: vehicleSet.id,
    i18n: { fallback: vehicleSet.vehicleSetName, id: `vehicleSet.${vehicleSet.id}` },
  }));

  // Defaults to the first option if no option is selected
  useEffect(() => {
    if (!selectedSetId && options.length) {
      setValue('selectedSetId', options[0].value);
    }
  }, [watchedValues]);

  // We only display the select if there are more than one option
  return options.length > (config?.minToDisplay || 0) ? (
    <zappy.FormField
      design={components.field}
      value={selectedSetId}
      { ...{ options }}
    />
  ) : null;
}

SetSelector.displayName = 'SetSelector';