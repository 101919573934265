// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createContext, useState, useMemo, useLayoutEffect } from 'react';

import { stateAttrs, status as statusConst } from './contants';

const STATUS = {
  [stateAttrs.STATUS]: statusConst.FULL_SETUP_INIT,
};

const RESOURCE_STATE = {
  // Null means it did not run yet
  [stateAttrs.IS_LOADED_FROM_SHARED_LINK]: null,
  [stateAttrs.IS_LOADED_FROM_LOCALSTORAGE]: false,
  [stateAttrs.IS_CALCULATIONS_READY]: false,
  [stateAttrs.IS_ASSUMPTIONS_DATA_READY]: null,
  [stateAttrs.IS_OUTPUT_PREALODS_READY]: false,
  [stateAttrs.IS_INPUT_OUTPUT_READY]: false,
  [stateAttrs.IS_INPUT_OUTPUT_READY]: false,
  [stateAttrs.IS_PROJECT_READY]: false,
  [stateAttrs.IS_SAVE_TO_LOCALSTORAGE_ENABLED]: false,
  [stateAttrs.IS_LOCATION_READY]: false,
  [stateAttrs.IS_FILTERS_CHANGED]: false,
  [stateAttrs.IS_POWER_SUPPLIERS_READY]: false,
  [stateAttrs.IS_COMSUMER_DATA_SENT]: false,
};

const STATE = {
  [stateAttrs.IS_NOT_READY]: true,
  [stateAttrs.IS_STORED_DATA_SETUP_DONE]: false,
  [stateAttrs.IS_FULL_SETUP_RUNNING]: true,
  [stateAttrs.IS_READY]: false,
};

function updateState(state, status) {
  switch (status) {
    case statusConst.FULL_SETUP_INIT:
      return {
        ...state,
        [stateAttrs.IS_STORED_DATA_SETUP_DONE]: false,
        [stateAttrs.IS_FULL_SETUP_RUNNING]: true,
      };

    case statusConst.STORED_DATA_SETUP_DONE:
      return {
        ...state,
        [stateAttrs.IS_STORED_DATA_SETUP_DONE]: true,
      };

    case statusConst.FULL_SETUP_DONE:
      return {
        ...state,
        [stateAttrs.IS_FULL_SETUP_RUNNING]: false,
        [stateAttrs.IS_NOT_READY]: false,
        [stateAttrs.IS_READY]: true,
      };

    case statusConst.START_OVER:
      return {
        ...state,
        [stateAttrs.IS_NOT_READY]: true,
        [stateAttrs.IS_READY]: false,
      };

    default:
      return state;
  }
}

function updateResourceState(state, status) {
  switch (status) {
    case statusConst.START_OVER:
      return {
        ...state,
        [stateAttrs.IS_LOADED_FROM_LOCALSTORAGE]: false,
        [stateAttrs.IS_ASSUMPTIONS_DATA_READY]: null,
        [stateAttrs.IS_INPUT_OUTPUT_READY]: false,
        [stateAttrs.IS_PROJECT_READY]: false,
        [stateAttrs.IS_LOADED_FROM_SHARED_LINK]: false,
      };

    default:
      return state;
  }
}

export const AppStateContext = createContext({
  appState: {
    ...STATUS,
    ...RESOURCE_STATE,
    ...STATE,
  },
});

export const AppStateProvider = ({ children }) => {
  const [appStatus, setAppStatus] = useState(STATUS.status);
  const [state, setState] = useState(STATE);
  const [resourceState, setResourceState] = useState(RESOURCE_STATE);

  const changeAppStatus = (value) => {
    setAppStatus(value);
    setState((previousState) => updateState(previousState, value));
    setResourceState((previousState) =>
      updateResourceState(previousState, value)
    );
  };

  const changeResourceState = (attr, value) => {
    setResourceState((currentValue) => ({
      ...currentValue,
      [attr]: value,
    }));
  };

  const version = useMemo(
    () => document.getElementsByName('version')?.[0].getAttribute('content'),
    []
  );
  useLayoutEffect(() => {
    const savedVersion = localStorage.getItem('version');
    if (version !== savedVersion) {
      localStorage.clear();
    }
  }, []);

  const context = useMemo(
    () => ({
      appState: {
        version,
        status: appStatus,
        ...state,
        ...resourceState,
      },
      changeAppStatus,
      changeResourceState,
    }),
    [appStatus, state, resourceState]
  );

  return (
    <AppStateContext.Provider value={context}>
      {children}
    </AppStateContext.Provider>
  );
};

AppStateContext.displayName = 'AppStateContext';
