import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  filterArray,
  FilterOperatorType,
  sortArray,
} from '../utils/functionHelpers';

const SKIP_DEFAULT_FILTERS = ['all', 0];

export interface useClientSidePaginationProps {
  elements: Array<AnyObject>;
  filters: Array<[string, string | number | boolean, FilterOperatorType]>;
  sort: string;
  perPage: number;
  defaultPage?: number;
  dataPreparingFn?: (values: Array<AnyObject>) => Array<AnyObject>;
  dataHidingFn?: (values: Array<AnyObject>) => Array<AnyObject>;
}

export type PaginationData = {
  paginatedElements: unknown[];
  totalPages: number;
};
export type UseClientSidePaginationReturnType = {
  totalElements: number;
  paginatedElements: PaginationData['paginatedElements'];
  pagination: {
    currentPage: number;
    totalPages: PaginationData['totalPages'];
    onNextPage: () => void;
    onPreviousPage: () => void;
  }
};

export const useClientSidePagination = ({
  elements = [],
  filters,
  sort,
  perPage = 9,
  defaultPage = 1,
  dataPreparingFn,
  dataHidingFn,
}: useClientSidePaginationProps): UseClientSidePaginationReturnType => {

  const [currentPage, setPage] = useState(defaultPage);

  // Gather all elements and filter/sort/prepare them
  const allElements = useMemo(() => {
    const allElements = dataHidingFn ? dataHidingFn(elements) : elements;
    const filtered = filterArray(allElements, filters, SKIP_DEFAULT_FILTERS);
    const sorted = sortArray(filtered, sort);
    return dataPreparingFn ? dataPreparingFn(sorted) : sorted;
  }, [elements, filters, sort, dataPreparingFn]);

  // Split elements into pages
  const paginationData: PaginationData = useMemo(() => {
    const start = (currentPage - 1) * perPage;
    const end = start + perPage;
    return {
      totalPages: Math.ceil(allElements.length / perPage),
      paginatedElements:  allElements.slice(start, end),
    };
  }, [allElements, currentPage, perPage]);

  // Callbacks for page navigation
  const nextPage = useCallback(() => setPage(currentPage + 1), [currentPage]);
  const prevPage = useCallback(() => setPage(currentPage - 1), [currentPage]);

  return {
    totalElements: allElements.length || 0,
    paginatedElements: paginationData.paginatedElements,
    pagination: {
      currentPage,
      totalPages: paginationData.totalPages,
      onNextPage: nextPage,
      onPreviousPage: prevPage,
    }
  }
};
