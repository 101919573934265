// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useMemo } from "react";
import { useLocation, matchPath } from "react-router-dom";

function findCurrentStep({ pathname, routes }) {
  const route = Object.keys(routes).find((key) => {
    return !!matchPath(key, pathname);
  });

  return routes[route];
}

export const SmartStepper = ({ design }: { design: string }) => {
  const { config, components } = zappy.useDesign(design);

  const { pathname } = useLocation();

  const steps = useMemo(
    () => Object.keys(config.routeSteps).length,
    [config.routeSteps]
  );

  const currentStep = useMemo(
    () => findCurrentStep({ routes: config.routeSteps, pathname }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <zappy.Stepper
      design={components.stepper}
      steps={steps}
      currentStep={currentStep}
    />
  );
};

SmartStepper.displayName = "SmartStepper";
