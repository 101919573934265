import { DESKTOP_THRESHOLD } from '../../base';

const VEHICLE_CATALOG = {
  '##### VEHICLE CATALOG #####': '',
  'app.catalog.vehicles': {
    _extends: 'app.catalog.common.template',
    config: {
      as: 'main',
      id: 'main',
      basePath: '/catalog/vehicles',
      content: [
        ['ContentContainer', 'app.catalog.vehicles.frame'],
        ['ContentContainer', 'app.catalog.common.botton'],
      ],
    },
  },
  'app.catalog.vehicles.frame': {
    _extends: 'app.page.common.schema',
    config: {
      as: 'div',
      content: [
        ['ContentContainer', 'app.catalog.vehicles.pageDescription'],
        ['ContentContainer', 'app.catalog.vehicles.content'],
      ],
    },
    schema: {
      default: {
        padding: '0 1em',

        '@media (max-width: 992px)': {
          margin: '16px',
        },

        '&.display-filters': {
          '@media (max-width: 992px)': {
            '[data-design="app.catalog.vehicles.filters"]': {
              display: 'grid',
              marginRight: 0,
            },
            '[data-design="app.catalog.vehicles.content"]': {
              marginTop: 0,
              'grid-row-gap': '12px',
            },
            '[data-design="app.catalog.vehicles.mobile.button.allVehicles.action"]':
              {
                display: 'block',
              },
            '[data-design="app.catalog.vehicles.banner.mobile"]': {
              display: 'none',
            },
            '[data-design="app.catalog.common.mobile.button.filters.button"]': {
              display: 'none',
            },
            '[data-design="app.catalog.vehicles.pageDescription"]': {
              display: 'none',
            },
            '[data-design="app.catalog.vehicles.list.mobile"]': {
              display: 'none',
              '+ div': {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
  'app.catalog.vehicles.pageDescription': {
    _extends: 'app.catalog.common.pageDescription',
    config: {
      content: [
        ['ActionComponent', 'app.catalog.common.heading.backButton'],
        [
          'TranslatedText',
          'app.catalog.common.heading.title',
          {
            i18n: {
              id: 'i18n.catalog.vehicles.heading.title',
              fallback: 'Select an EV that meets your needs',
            },
          },
        ],
        [
          'Formatter',
          'app.catalog.common.description',
          {
            watch: {
              name: ['vehicleSetName'],
            },
            as: 'p',
            pick: 'vehicleSetName',
            text: 'Select an available electric vehicle that meets your needs for {{picked}}',
            formatter: 'interpolate',
          },
        ],
      ],
    },
  },
  'app.catalog.vehicles.content': {
    _extends: 'app.catalog.common.content',
    config: {
      content: [
        ['ActionComponent', 'app.catalog.vehicles.mobile.button.filters'],
        ['ActionComponent', 'app.catalog.vehicles.mobile.button.allVehicles'],
        ['ContentContainer', 'app.catalog.vehicles.banner'],
        ['ContentContainer', 'app.catalog.vehicles.banner.mobile'],
        ['ContentContainer', 'app.catalog.vehicles.filters'],
        [
          'VehiclesCatalog',
          'app.catalog.vehicles.list',
          {
            displayQuery: 'desktop',
          },
        ],
        [
          'VehiclesCatalog',
          'app.catalog.vehicles.list.mobile',
          {
            displayQuery: 'mobile',
          },
        ],
      ],
      watch: {
        name: ['device'],
      },
      displayQueries: {
        mobile: {
          '["watched", "device"]': {
            $eq: 'mobile',
          },
        },
        desktop: {
          '["watched", "device"]': {
            $eq: 'desktop',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.mobile.button.allVehicles': {
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'catalog/showFilters',
        },
      ],
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.catalog.common.mobile.button.allVehicles.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        textAlign: 'left',
        padding: '12px 0',
        display: 'none',
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.catalog.vehicles.mobile.button.filters': {
    components: {
      button: 'app.catalog.common.mobile.button.filters.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'catalog/showFilters',
        },
      ],
      component: 'SmartNextButton',
    },
  },
  'app.catalog.vehicles.filters': {
    _extends: ['app.catalog.common.leftBar', 'app.catalog.common.filters'],
    config: {
      content: [
        ['TranslatedText', 'app.catalog.common.filters.header'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.category'],
        // ['FormField', 'app.catalog.vehicles.filters.vehicle.fuelType'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.type'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.subtype'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.make'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.weightClass'],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.budget'],
        [
          'FormField',
          'app.catalog.vehicles.filters.vehicle.minRange',
          { displayQuery: 'onRoad' },
        ],
        [
          'FormField',
          'app.catalog.vehicles.filters.vehicle.minRuntime',
          { displayQuery: 'offRoad' },
        ],
        ['FormField', 'app.catalog.vehicles.filters.vehicle.availability'],
      ],
      watch: {
        name: ['catalogs.vehicles.category'],
      },
      displayQueries: {
        offRoad: {
          '["watched", "catalogs.vehicles.category"]': { $eq: 'off-road' },
        },
        onRoad: {
          '["watched", "catalogs.vehicles.category"]': { $eq: 'on-road' },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.category': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.category',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.category.label',
          fallback: 'Vehicle Category',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          'on-road': {
            value: 'on-road',
            i18n: {
              id: 'i18n.vehicle.category.on-road.value',
              fallback: 'On-Road',
            },
          },
          'off-road': {
            value: 'off-road',
            i18n: {
              id: 'i18n.vehicle.category.off-road.value',
              fallback: 'Off-Road',
            },
          },
        },
        name: 'catalogs.vehicles.category',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.category.name.placeholder',
            fallback: 'Select Vehicle Category',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.fuelType': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        htmlFor: 'catalogs.vehicles.fuelType',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.fuelType.label',
          fallback: 'Fuel Type',
        },
      },
      input: {
        name: 'catalogs.vehicles.fuelType',
        component: 'FormVehicleAttrSelector',
        preload: 'electric_vehicles',
        preloadAttrs: {
          attrForValue: 'fuel',
        },
        skipValues: ['all'],
        watch: {
          name: ['catalogs.vehicles.category'],
        },
        watchToFiltersMap: {
          'catalogs.vehicles.category': 'is_offroad',
        },
        includeStaticOption: {
          value: 'all',
          fallback: 'All',
        },
        mapFilteredValues: {
          'catalogs.vehicles.category': {
            'on-road': false,
            'off-road': true,
          },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.type': {
    _extends: 'app.output.common.multipleSelect.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.type',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.type.label',
          fallback: 'Vehicle Type',
        },
      },
      input: {
        name: 'catalogs.vehicles.type',
        component: 'FormVehicleAttrSelector',
        preload: 'electric_vehicles',
        preloadAttrs: {
          attrForValue: 'type',
        },
        skipValues: ['all'],
        watch: {
          name: ['catalogs.vehicles.fuelType', 'catalogs.vehicles.category'],
        },
        watchToFiltersMap: {
          'catalogs.vehicles.fuelType': 'fuel',
          'catalogs.vehicles.category': 'is_offroad',
        },
        mapFilteredValues: {
          'catalogs.vehicles.category': {
            'on-road': false,
            'off-road': true,
          },
        },
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.type.name.input.placeholder',
            fallback: 'Select Vehicle Type',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.subtype': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.subType',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.subtype.label',
          fallback: 'Vehicle Subtype',
        },
      },
      input: {
        component: 'FormVehicleAttrSelector',
        preload: 'electric_vehicles',
        preloadAttrs: {
          attrForValue: 'subtype',
        },
        sortingOrder: 'asc',
        sortTranslated: true,
        skipValues: ['all'],
        watchToFiltersMap: {
          'catalogs.vehicles.type': 'type',
        },
        watch: {
          name: ['catalogs.vehicles.type'],
        },
        name: 'catalogs.vehicles.subType',
        includeStaticOption: {
          value: 'all',
          fallback: 'All',
        },
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.subtype.name.input.placeholder',
            fallback: 'Select Vehicle Subtype',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.make': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.make',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.make.label',
          fallback: 'Make',
        },
      },
      input: {
        component: 'FormVehicleAttrSelector',
        preload: 'electric_vehicles',
        preloadAttrs: {
          attrForValue: 'make',
        },
        skipValues: ['all'],
        watchToFiltersMap: {
          'catalogs.vehicles.type': 'type',
          'catalogs.vehicles.subType': 'subtype',
        },
        watch: {
          name: ['catalogs.vehicles.type', 'catalogs.vehicles.subType'],
        },
        name: 'catalogs.vehicles.make',
        includeStaticOption: {
          value: 'all',
          fallback: 'All',
        },
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.make.name.input.placeholder',
            fallback: 'Select Vehicle Make',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.budget': {
    _extends: 'app.catalog.common.field.range',
    components: {
      input: 'app.catalog.vehicles.budget.rangeInput',
    },
    config: {
      label: {
        htmlFor: 'catalogs.vehicles.budget',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.budget.label',
          fallback: 'Budget',
        },
      },
      input: {
        name: 'catalogs.vehicles.budget',
        step: 20000,
        min: 40000,
        max: 2000000,
      },
    },
  },
  'app.catalog.vehicles.budget.rangeInput': {
    _extends: 'app.catalog.common.rangeInput',
    components: {
      text: 'app.catalog.vehicles.budget.rangeInput.text',
      input: 'app.catalog.common.rangeInput.input',
    },
  },
  'app.catalog.vehicles.budget.rangeInput.text': {
    _extends: 'app.catalog.common.rangeInput.text',
    config: {
      pick: 'budget',
      format: {
        formatter: 'formatAsDollars',
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.minRange': {
    _extends: 'app.catalog.common.field.range',
    components: {
      input: 'app.catalog.vehicles.minRange.rangeInput',
    },
    config: {
      label: {
        i18n: {
          id: 'i18n.catalog.filters.vehicle.minRange.label',
          fallback: 'Minimum Range',
        },
      },
      input: {
        name: 'catalogs.vehicles.minRange',
        step: 25,
        min: 50,
        max: 500,
      },
    },
  },
  'app.catalog.vehicles.minRange.rangeInput': {
    _extends: 'app.catalog.common.rangeInput',
    components: {
      text: 'app.catalog.vehicles.minRange.rangeInput.text',
      input: 'app.catalog.common.rangeInput.input',
    },
  },
  'app.catalog.vehicles.minRange.rangeInput.text': {
    _extends: 'app.catalog.common.rangeInput.text',
    config: {
      pick: 'minRange',
      text: '{{picked}} Miles',
      format: {
        formatter: 'interpolate',
      },
    },
  },

  'app.catalog.vehicles.filters.vehicle.minRuntime': {
    _extends: 'app.catalog.common.field.range',
    components: {
      input: 'app.catalog.vehicles.minRuntime.rangeInput',
    },
    config: {
      label: {
        i18n: {
          id: 'i18n.catalog.filters.vehicle.minRuntime.label',
          fallback: 'Minimum Runtime',
        },
      },
      input: {
        name: 'catalogs.vehicles.minRuntime',
        step: 5,
        min: 0,
        max: 40,
        watchToFiltersMap: {
          'catalogs.vehicles.minRuntime': 'minRange',
        },
      },
    },
  },
  'app.catalog.vehicles.minRuntime.rangeInput': {
    _extends: 'app.catalog.common.rangeInput',
    components: {
      text: 'app.catalog.vehicles.minRuntime.rangeInput.text',
      input: 'app.catalog.common.rangeInput.input',
    },
  },
  'app.catalog.vehicles.minRuntime.rangeInput.text': {
    _extends: 'app.catalog.common.rangeInput.text',
    config: {
      pick: 'minRuntime',
      text: '{{picked}} hours',
      format: {
        formatter: 'interpolate',
      },
    },
  },

  'app.catalog.vehicles.filters.vehicle.availability': {
    _extends: 'app.common.field',
    components: {
      label: null,
      input: 'app.catalog.vehicles.filters.availability.checkbox',
      tooltip: 'app.catalog.vehicles.filters.availability.tooltip',
    },
    config: {
      input: {
        component: 'FormInput',
        name: 'catalogs.vehicles.availability',
        type: 'checkbox',
      },
      validations: null,
    },
    schema: {
      default: {
        'grid-template-columns': 'auto 1fr',
        'grid-template-areas': '"input tooltip"',
      },
    },
  },
  'app.catalog.vehicles.filters.availability.checkbox': {
    _extends: 'app.onboarding.common.checkbox',
    config: {
      type: 'checkbox',
      text: {
        i18n: {
          id: 'i18n.checkboxInput.text.available',
          fallback: 'Available Now',
        },
      },
      name: 'catalogs.vehicles.availability',
    },
  },
  'app.catalog.vehicles.filters.availability.tooltip': {
    config: {
      event: 'hover',
    },
    components: {
      activator: 'app.catalog.vehicles.filters.availability.tooltip.activator',
      popover: 'app.catalog.vehicles.filters.availability.tooltip.popover',
    },
    schema: {
      default: {
        'margin-left': '10px',
        'align-self': 'center',
      },
    },
  },
  'app.catalog.vehicles.filters.availability.tooltip.activator': {
    config: {
      component: 'Icon',
      src: 'info',
    },
    schema: {
      default: {
        'margin-left': '10px',
        backgroundColor: '#323232',
        height: '14px',
        width: '14px',
        margin: 'auto',
        'align-self': 'center',
      },
      error: {
        'background-color': 'red',
      },
    },
  },
  'app.catalog.vehicles.filters.availability.tooltip.popover': {
    _extends: 'app.catalog.vehicles.common.field.error',
    config: {
      position: 'E',
      content: [
        [
          'TranslatedText',
          'app.catalog.vehicles.filters.availability.tooltip.popover.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        alignItems: 'center',
        backgroundColor: 'white',
        border: '1px solid grey',
        borderRadius: '0.4rem',
        display: 'flex',
        justifyContent: 'center',
        padding: '1.125rem',
        'margin-left': '20px',
        width: '20rem',
      },
    },
  },
  'app.catalog.vehicles.filters.availability.tooltip.popover.translatedText': {
    _extends: 'app.catalog.vehicles.common.field.error',
    config: {
      element: 'span',
      fontSize: '16px',
      color: 'var(--second-color)',
      i18n: {
        id: 'i18n.tooltipIcon.popover.translatedText',
        fallback:
          'Vehicles available now can be purchased for delivery, instead of just being open for pre-orders. Actual inventory may vary locally.',
      },
    },
  },
  'app.catalog.vehicles.filters.vehicle.weightClass': {
    _extends: 'app.output.common.select.field',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.weightClass',
        i18n: {
          id: 'i18n.catalog.filters.vehicle.weightClass.label',
          fallback: 'Weight Class (GVWR)',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          0: {
            value: '0',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass',
              fallback: 'All',
            },
          },
          1: {
            value: '1',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class1',
              fallback: 'Class 1: <6,000 lbs',
            },
          },
          2: {
            value: '2',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class2',
              fallback: 'Class 2: 6,001 - 10,000 lbs',
            },
          },
          3: {
            value: '3',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class3',
              fallback: 'Class 3: 10,001 - 14,000 lbs',
            },
          },
          4: {
            value: '4',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class4',
              fallback: 'Class 4: 14,001 - 16,000 lbs',
            },
          },
          5: {
            value: '5',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class5',
              fallback: 'Class 5: 16,001 - 19,000 lbs',
            },
          },
          6: {
            value: '6',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class6',
              fallback: 'Class 6: 19,001 - 26,000 lbs',
            },
          },
          7: {
            value: '7',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class7',
              fallback: 'Class 7: 26,001 - 33,000 lbs',
            },
          },
          8: {
            value: '8',
            i18n: {
              id: 'i18n.app.catalog.vehicles.filters.vehicle.weightClass.class8',
              fallback: 'Class 8: >33,001 lbs',
            },
          },
        },
        name: 'catalogs.vehicles.weightClass',
        placeholder: {
          i18n: {
            id: 'i18n.catalog.filters.vehicle.weightClass.name.placeholder',
            fallback: 'Select GVWR',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.list.mobile': {
    _extends: 'app.common.catalog.vehicle.list',
    config: {
      limit: 6,
    },
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
          ' + div': {
            display: 'none',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.list': {
    _extends: 'app.common.catalog.vehicle.list',
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
          ' + div': {
            display: 'none',
          },
        },
      },
    },
  },
  'app.catalog.vehicles.list.item': {
    _extends: 'app.catalog.common.list.item',
    config: {
      content: [
        ['ContentContainer', 'app.catalog.vehicles.list.item.makeIcon'],
        ['Formatter', 'app.catalog.vehicles.list.item.model'],
        ['Formatter', 'app.catalog.vehicles.list.item.image'],
        [
          'TranslatedText',
          'app.catalog.vehicles.list.item.range',
          { displayQuery: 'onRoad' },
        ],
        [
          'Formatter',
          'app.catalog.vehicles.list.item.range.value',
          { displayQuery: 'onRoad' },
        ],
        [
          'TranslatedText',
          'app.catalog.vehicles.list.item.runtime',
          { displayQuery: 'offRoad' },
        ],
        [
          'Formatter',
          'app.catalog.vehicles.list.item.runtime.value',
          { displayQuery: 'offRoad' },
        ],
        ['TranslatedText', 'app.catalog.vehicles.list.item.fuel.efficiency'],
        [
          'Formatter',
          'app.catalog.vehicles.list.item.fuel.efficiency.value',
          { displayQuery: 'onRoad' },
        ],
        [
          'Formatter',
          'app.catalog.vehicles.list.item.fuel.efficiency.offRoad.value',
          { displayQuery: 'offRoad' },
        ],
        ['TranslatedText', 'app.catalog.vehicles.list.item.weight.class'],
        [
          'Formatter',
          'app.catalog.vehicles.list.item.range.weight.class.value',
        ],
        ['TranslatedText', 'app.catalog.vehicles.list.item.battery'],
        ['Formatter', 'app.catalog.vehicles.list.item.battery.value'],
        ['', 'app.catalog.vehicles.list.item.divider'],
        ['TranslatedText', 'app.catalog.vehicles.list.item.msrp'],
        ['Formatter', 'app.catalog.vehicles.list.item.msrp.value'],
        ['ActionComponent', 'app.catalog.vehicles.list.item.button.select'],
        ['Formatter', 'app.catalog.vehicles.list.item.button.learn'],
      ],
      displayQueries: {
        onRoad: { '["props", "is_offroad"]': { $neq: true } },
        offRoad: {
          '["props", "is_offroad"]': { $eq: true },
        },
      },
    },
    schema: {
      default: {
        display: 'grid',
        'align-items': 'center',

        'background-color': 'white',
        padding: '8px',
        border: '1px solid var(--second-color)',

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'grid-column-gap': '5px',
          'grid-row-gap': '6px',
          'border-radius': '3px',
          'min-height': '410px',
          'grid-template-columns': 'repeat(2, 1fr)',
        },

        '@media (max-width: 992px)': {
          'border-radius': '5px',
          'grid-template-columns': 'repeat(2, auto)',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.makeIcon': {
    config: {
      content: [
        ['Formatter', 'app.catalog.vehicles.list.item.make'],
        ['Formatter', 'app.catalog.vehicles.list.item.icon'],
      ],
    },
    schema: {
      default: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridColumn: '1/-1',
      },
    },
  },
  'app.catalog.vehicles.list.item.make': {
    _extends: 'app.common.skeleton',
    config: {
      as: 'p',
      pick: 'make',
    },
    schema: {
      default: {
        'font-family': 'var(--font-family)',
        'font-style': 'normal',
        'font-weight': 'var(--item-font-weight-value)',
        'font-size': 'var(--font-size)',
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      },
      skeleton: {
        height: '32px',
      },
    },
  },
  'app.catalog.vehicles.list.item.icon': {
    _extends: 'app.catalog.vehicles.common.fuel.icon',
    schema: {
      default: {
        'justify-self': 'flex-end',
        height: '20px',
      },
    },
  },
  'app.catalog.vehicles.list.item.model': {
    _extends: 'app.common.skeleton',
    config: {
      as: 'p',
      pick: 'model',
    },
    schema: {
      default: {
        'grid-column': '1/-1',
        'min-height': '50px',

        '@media (max-width: 992px)': {
          'font-size': '14px',
        },
      },
      skeleton: {
        height: '20px',
      },
    },
  },
  'app.catalog.vehicles.list.item.image': {
    _extends: 'app.catalog.vehicles.details.common.image',
    schema: {
      default: {
        width: 'auto',
        height: '72px',
        margin: '16px 0',
        'grid-column': '1/-1',
        'justify-self': 'center',
      },
    },
  },
  'app.catalog.vehicles.list.item.range': {
    _extends: 'app.catalog.vehicles.list.item.common.label',
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.range',
        fallback: 'Electric Range',
      },
    },
  },
  'app.catalog.vehicles.list.item.range.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      pick: 'total_range',
    },
  },
  'app.catalog.vehicles.list.item.runtime': {
    _extends: 'app.catalog.vehicles.list.item.common.label',
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.runtime',
        fallback: 'Runtime',
      },
    },
  },
  'app.catalog.vehicles.list.item.runtime.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      pick: 'total_range',
      formatter: 'interpolate',
      text: '{{picked}} hours',
      fallback: 'N/A',
    },
  },
  'app.catalog.vehicles.list.item.weight.class': {
    _extends: 'app.catalog.vehicles.list.item.common.label',
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.weight.class',
        fallback: 'Weight Class',
      },
    },
  },
  'app.catalog.vehicles.list.item.range.weight.class.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      pick: 'weight_class',
    },
  },
  'app.catalog.vehicles.list.item.fuel.efficiency': {
    _extends: 'app.catalog.vehicles.list.item.common.label',
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.fuel.efficiency',
        fallback: 'Fuel Efficiency',
      },
    },
  },
  'app.catalog.vehicles.list.item.fuel.efficiency.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      autoPick: 'miles_per_galon_e',
      preset: {
        mapper: {
          miles_per_galon_e: 'rowData[val]',
        },
      },
      mapper: {
        'rowData[val]': 'picked',
      },
    },
  },
  'app.catalog.vehicles.list.item.fuel.efficiency.offRoad.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      pick: 'electricEfficiency',
      autoPick: 'electric_efficiency',
      fallback: 'N/A',
      autoFormats: {
        electric_efficiency: {
          partials: [
            {
              pick: 'electric_efficiency',
              format: {
                formatter: 'formatAsNumber',
                maximumFractionDigits: 0,
                as: 'electricEfficiency',
              },
            },
          ],
          text: '{{picked}} hours',
          formatter: 'interpolate',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.battery': {
    _extends: 'app.catalog.vehicles.list.item.common.label',
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.battery',
        fallback: 'Battery Capacity',
      },
    },
  },
  'app.catalog.vehicles.list.item.battery.value': {
    _extends: 'app.catalog.vehicles.list.item.common.value',
    config: {
      as: 'p',
      pick: 'battery_capacity',
      text: '{{picked}} kWh',
      formatter: 'interpolate',
    },
  },
  'app.catalog.vehicles.list.item.divider': {
    schema: {
      default: {
        height: '1px',
        width: '100%',
        'background-color': 'var(--color-gray-500)',
        margin: '24px 0 6px',
        'grid-column': '1/-1',
        'place-self': 'center',

        '@media (max-width: 992px)': {
          margin: '8px 0px',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.msrp': {
    _extends: ['app.common.skeleton', 'app.catalog.vehicles.common.msrp.label'],
    schema: {
      default: {
        'font-size': '16px',
        color: 'var(--item-text-color-label)',

        '@media (max-width: 992px)': {
          'grid-column': '1/-1',
          'font-size': '12px',
        },
      },
      skeleton: {
        color: 'transparent',
      },
    },
  },
  'app.catalog.vehicles.list.item.msrp.value': {
    _extends: ['app.common.skeleton', 'app.catalog.vehicles.common.msrp.value'],
    schema: {
      default: {
        'font-size': '18px',
        'text-align': 'right',

        '@media (max-width: 992px)': {
          'grid-column': '1/-1',
          'text-align': 'left',
          'margin-bottom': '6px',
        },
      },
      skeleton: {
        height: '24px',
        'text-indent': '200px',
      },
    },
  },
  'app.catalog.vehicles.list.item.button.select': {
    components: {
      button: 'app.catalog.vehicles.list.item.button.select.button',
    },
    config: {
      eventName: 'onClick',
      actions: [
        {
          type: 'form/updateInput',
        },
        {
          type: 'form/navigateTo',
          payload: {
            redirectsToSavedState: true,
          },
        },
      ],
      component: 'SmartNextButton',
    },
  },
  'app.catalog.vehicles.list.item.button.select.button': {
    _extends: ['app.common.button.secondary', 'app.common.button.small'],
    config: {
      component: 'Button',
      content: [
        [
          'TranslatedText',
          'app.catalog.vehicles.list.item.button.select.button.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        'align-self': 'center',
        'text-align': 'center',

        '@media (max-width: 992px)': {
          'grid-column': '1/-1',
          height: '36.5px',
          'margin-bottom': '8px',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.button.select.button.translatedText': {
    _extends: 'app.common.text.secondary',
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.catalog.list.item.button.select.translatedText',
        fallback: 'SELECT',
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'font-size': '12px',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.button.learn': {
    _extends: [
      'app.common.button.secondary',
      'app.common.button.small',
      'app.common.link.button',
    ],
    components: {
      translatedText:
        'app.catalog.vehicles.list.item.button.learn.translatedText',
    },
    config: {
      text: '/catalog/vehicles/{{picked}}',
      pick: 'handle',
      format: {
        formatter: 'interpolate',
        as: 'to',
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'grid-column': '1/-1',
          height: '36.5px',
          padding: '0.3rem',
        },
      },
    },
  },
  'app.catalog.vehicles.list.item.button.learn.translatedText': {
    _extends: ['app.common.skeleton', 'app.common.text.secondary'],
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.catalog.list.item.button.learnMore.translatedText',
        fallback: 'LEARN MORE',
      },
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          'font-size': '12px',
        },
      },
    },
  },
  'app.catalog.vehicles.banner.mobile': {
    config: {
      content: [
        ['FormField', 'app.catalog.vehicles.banner.sort'],
        ['ContentContainer', 'app.catalog.vehicles.banner.left'],
        ['Formatter', 'app.catalog.vehicles.banner.evCounting'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'grid',
          'grid-row-gap': '16px',
        },

        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          display: 'none',
        },
      },
    },
  },
  'app.catalog.vehicles.banner': {
    _extends: 'app.catalog.common.banner',
    config: {
      content: [
        ['ContentContainer', 'app.catalog.vehicles.banner.left'],
        ['ContentContainer', 'app.catalog.vehicles.banner.right'],
      ],
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },
  },
  'app.catalog.vehicles.banner.right': {
    _extends: 'app.catalog.common.banner.right',
    config: {
      content: [
        ['Formatter', 'app.catalog.vehicles.banner.evCounting'],
        ['FormField', 'app.catalog.vehicles.banner.sort'],
      ],
    },
  },
  'app.catalog.vehicles.banner.left': {
    _extends: 'app.catalog.common.banner.left',
    config: {
      content: [
        ['IconText', 'app.catalog.vehicles.banner.ev'],
        ['IconText', 'app.catalog.vehicles.banner.phev'],
      ],
    },
  },
  'app.catalog.vehicles.banner.evCounting': {
    config: {
      as: 'p',
      mapper: {
        'catalogs.filteredListSize': 'filteredListSize',
        'catalogs.allListSize': 'allListSize',
      },
      watch: {
        name: ['catalogs.filteredListSize', 'catalogs.allListSize'],
      },
      text: 'Showing {{filteredListSize}} of {{allListSize}} Vehicles',
      formatter: 'interpolate',
    },
    schema: {
      default: {
        'font-size': 'var(--label-font-size)',
        'align-self': 'flex-end',
        color: 'var(--second-color)',
      },
    },
  },
  'app.catalog.vehicles.banner.ev': {
    _extends: 'app.catalog.vehicles.banner.common.iconText',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/bev-blue.svg',
        alt: 'Electric Vehicles',
        as: 'img',
      },
      text: {
        i18n: {
          id: 'i18n.catalog.banner.ev',
          fallback: 'Electric Vehicle',
        },
      },
    },
  },
  'app.catalog.vehicles.banner.phev': {
    _extends: 'app.catalog.vehicles.banner.common.iconText',
    config: {
      icon: {
        src: 'https://assets.zappyride.com/img/icons/evergy/phev-blue.svg',
        alt: 'Hybrid vehicles',
        as: 'img',
      },
      text: {
        i18n: {
          id: 'i18n.catalog.banner.hybrid',
          fallback: 'Hybrid Vehicle',
        },
      },
    },
    schema: {
      default: {
        display: 'none',
      },
    },
  },
  'app.catalog.vehicles.banner.sort': {
    _extends: 'app.catalog.common.banner.sort',
    config: {
      label: {
        fontSize: 'var(--label-font-size)',
        htmlFor: 'catalogs.vehicles.sort',
        color: 'var(--second-color)',
        i18n: {
          id: 'i18n.catalog.banner.sort.label',
          fallback: 'Sort By',
        },
      },
      input: {
        component: 'FormSelect',
        options: {
          'total_range:-1': {
            value: 'total_range:-1',
            i18n: {
              id: 'i18n.sort.electricRange',
              fallback: 'Electric Range',
            },
          },
          'msrp:1': {
            value: 'msrp:1',
            i18n: {
              id: 'i18n.sort.msrp.1',
              fallback: '$: Low to High',
            },
          },
          'msrp:-1': {
            value: 'msrp:-1',
            i18n: {
              id: 'i18n.sort.msrp.-1',
              fallback: '$: High to Low',
            },
          },
          'make:1': {
            value: 'make:1',
            i18n: {
              id: 'i18n.sort.make.1',
              fallback: 'Make: A to Z',
            },
          },
          'make:-1': {
            value: 'make:-1',
            i18n: {
              id: 'i18n.sort.make.-1',
              fallback: 'Make: Z to A',
            },
          },
        },
        name: 'catalogs.vehicles.sort',
        placeholder: {
          i18n: {
            id: 'i18n.sort.placeholder',
            fallback: 'Select Sort',
          },
        },
      },
    },
  },

  'app.catalog.vehicles.common.fuel.icon': {
    config: {
      component: 'Icon',
      as: 'img',
      mapper: {
        fuel: 'src',
      },
      alt: 'Fuel type',
      converter: {
        PHEV: 'https://assets.zappyride.com/img/icons/evergy/phev-blue.svg',
        BEV: 'https://assets.zappyride.com/img/icons/evergy/bev-blue.svg',
      },
    },
    schema: {
      default: {
        width: 'auto',
      },
      skeleton: {
        display: 'none',
      },
    },
  },
  'app.catalog.vehicles.details.common.image': {
    config: {
      component: 'Logo',
      mapper: {
        image_url: 'src',
        name: 'alt',
      },
      props: {
        src: 'https://assets.zappyride.com/img/vehicles/placeholder.png',
      },
    },
  },
  'app.catalog.vehicles.list.item.common.label': {
    _extends: 'app.common.skeleton',
    schema: {
      default: {
        'font-size': 'var(--item-font-size)',
        color: 'var(--item-text-color-label)',

        '@media (max-width: 992px)': {
          'font-size': 'var(--item-font-size-mobile)',
          fontWeight: 'var(--font-weight)',
        },
      },
      skeleton: {
        height: '18px',
        'text-indent': '-200px',
      },
    },
  },
  'app.catalog.vehicles.list.item.common.value': {
    _extends: ['app.common.skeleton', 'app.common.formatter.auto.value'],
    schema: {
      default: {
        font: 'var(--font-family)',
        'font-weight': 'var(--item-font-weight-value)',
        'font-size': 'var(--item-font-size)',
        'text-align': 'right',
        color: 'var(--primary-dark-color)',

        '@media (max-width: 992px)': {
          'font-size': 'var(--item-font-size-mobile)',
        },
      },
      skeleton: {
        height: '18px',
        'text-indent': '200px',
      },
    },
  },
  'app.catalog.vehicles.common.msrp.label': {
    config: {
      i18n: {
        id: 'i18n.catalog.list.item.msrp.label',
        fallback: 'Estimated Price',
      },
    },
  },
  'app.catalog.vehicles.common.msrp.value': {
    _extends: 'app.common.formatter.auto.value',
    config: {
      as: 'p',
      pick: 'msrp',
    },
    schema: {
      default: {
        fontWeight: 'var(--item-font-weight-value)',
      },
    },
  },
  'app.catalog.vehicles.banner.common.iconText.icon': {
    config: {
      component: 'Icon',
      as: 'img',
    },
    schema: {
      default: {
        height: '20px',
        width: 'auto',
        'align-self': 'center',
      },
    },
  },
  'app.catalog.vehicles.banner.common.iconText.text': {
    config: {
      as: 'p',
      textAlign: 'left',
      color: 'var(--primary-dark-color)',
      scale: '3',
    },
    schema: {
      default: {
        '@media (max-width: 992px)': {
          fontSize: '12px',
        },
      },
    },
  },

  'app.catalog.vehicles.banner.common.iconText': {
    _extends: 'app.common.iconText.fuel',
    components: {
      text: 'app.catalog.vehicles.banner.common.iconText.text',
      icon: 'app.catalog.vehicles.banner.common.iconText.icon',
    },
  },

  // Vehicles Empty State
  'app.catalog.emptyState.vehicles': {
    _extends: 'app.catalog.common.emptyState',
    config: {
      graphics: 'app.catalog.emptyState.vehicles.icon',
      actionAndLegend: 'app.catalog.emptyState.vehicles.actionAndLegend',
    },
  },
  'app.catalog.emptyState.vehicles.icon': {
    _extends: 'app.catalog.common.emptyState.iconsSection',
    config: {
      content: [
        ['Icon', 'app.catalog.common.emptyState.magnifier'],
        ['Icon', 'app.catalog.common.emptyState.serviceIcon', { src: 'car' }],
      ],
    },
  },
  'app.catalog.emptyState.vehicles.actionAndLegend': {
    _extends: 'app.catalog.common.emptyState.actionAndLegend',
    config: {
      content: [
        [
          'TranslatedText',
          '',
          {
            i18n: {
              id: 'i18n.catalog.emptyState.vehicles.actionAndLegend',
              fallback: 'No vehicles meet the criteria. Remove criteria or',
            },
          },
        ],
        [
          'Button',
          'app.catalog.common.emptyState.resetButton',
          { action: 'resetFiltersFn' },
        ],
      ],
    },
  },

  'app.common.catalog.vehicle.list': {
    _extends: 'app.catalog.common.list',
    components: {
      listItem: 'app.catalog.vehicles.list.item',
    },
    config: {
      preload: 'electric_vehicles',
      keyAttribute: 'handle',
      redirectToIfNoHistory: '/onboarding',
      limit: 9,
      filters: {
        mapper: [
          ['fuel', 'vehicles.fuelType'],
          ['type', 'vehicles.type', '$inc'],
          ['subtype', 'vehicles.subType'],
          ['make', 'vehicles.make'],
          ['msrp', 'vehicles.budget', '$lte'],
          [
            'total_range',
            'vehicles.minRange',
            '$gte',
            { displayQuery: 'onRoad' },
          ],
          [
            'total_range',
            'vehicles.minRuntime',
            '$gte',
            { displayQuery: 'offRoad' },
          ],
          ['weight_class', 'vehicles.weightClass'],
          [
            'availability',
            'vehicles.availability',
            '$eq',
            {
              true: 'released',
              false: 'all',
            },
          ],
          [
            'is_offroad',
            'vehicles.category',
            '$eq',
            {
              'off-road': true,
              'on-road': false,
            },
          ],
        ],
        filtersQuery: {
          onRoad: {
            '["form", "vehicles", "category"]': { $eq: 'on-road' },
          },
          offRoad: {
            '["form", "vehicles", "category"]': { $eq: 'off-road' },
          },
        },
      },
      emptyState: 'app.catalog.emptyState.vehicles',
    },
  },
};
export default VEHICLE_CATALOG;
