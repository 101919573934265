import { DESKTOP_THRESHOLD } from '../../base';

const ONBOARDING_COMMON = {
  'app.onboarding.common.heading': {
    _extends: 'app.common.heading',
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'max-width': '37.64vw',
        },
      },
    },
  },
  'app.onboarding.common.description': {
    _extends: 'app.common.description',
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          'max-width': '50.7vw',
          marginBottom: '4vh',
        },
      },
    },
  },
  'app.onboarding.common.vehicleInOperation': {
    schema: {
      default: {
        display: 'grid',
        'grid-row-gap': '24px',
        'grid-column': '1 / -1',
        marginBottom: '24px',
      },
    },
  },
  'app.onboarding.common.vehicleInOperation.button.input': {
    _extends: 'app.onboarding.common.selectTags.tag',
    schema: {
      default: {
        'border-color': 'var(--primary-color)',
        margin: 0,
        width: '200px',
      },
    },
  },
  'app.onboarding.common.selectTags.input': {
    schema: {
      default: {
        display: 'flex',
        margin: '-8px',
        'flex-wrap': 'wrap',
      },
    },
  },
  'app.onboarding.common.selectTags.tag.typography': {
    config: {
      scale: '3',
      fontWeight: '400',
      color: 'var(--button-primary-color)',
      textAlign: 'center',
    },
    schema: {
      default: {
        'text-transform': 'uppercase',
        color: 'var(--button-primary-color)',
      },
      active: {
        color: 'white',
      },
    },
  },
  'app.onboarding.common.selectTags.tag.skeleton': {
    schema: {
      skeleton: {
        width: '80px',
        height: '40px',
        background:
          'linear-gradient(-90deg, #f6f6f6 0%, #bbbbbb 50%, #f6f6f6 100%)',
        'pointer-events': 'none',
        animation: 'slidein 1.2s ease-in-out infinite',
        'background-size': '400% 400%',
        border: 'none',

        '@keyframes slidein': {
          '0%': {
            'background-position': 'left',
          },
          '100%': {
            'background-position': 'right',
          },
        },
      },
    },
  },
  'app.onboarding.common.selectTags.tag': {
    _extends: [
      'app.common.button.secondary',
      'app.onboarding.common.selectTags.tag.skeleton',
      'app.common.button.small',
    ],
    schema: {
      default: {
        margin: '8px',
        width: '80px',

        '@media (max-width: 992px)': {
          width: '72px',
          margin: '4px',
        },
      },

      active: {
        'background-color': 'var(--button-primary-color)',
        '&:hover': {
          backgroundColor: 'var(--button-primary-color-hover)',
        },
      },

      hover: {
        backgroundColor: 'var(--button-primary-color-hover)',
      },
    },
  },
  'app.onboarding.common.checkbox': {
    components: {
      text: 'app.onboarding.common.checkbox.text',
      checkbox: 'app.onboarding.common.checkbox.input',
      checkedIcon: 'app.onboarding.common.checkbox.checkedIcon',
    },
    schema: {
      default: {
        display: 'grid',
        'grid-template-columns': 'auto 1fr',
        'grid-column-gap': '8px',
        'align-items': 'center',
      },
    },
  },
  'app.onboarding.common.checkbox.input': {
    schema: {
      default: {
        width: '22px',
        height: '22px',
        'background-color': 'white',
        'border-radius': '5px',
        border: '1px solid var(--button-primary-color)',
        display: 'flex',
        cursor: 'pointer',
      },
      active: {
        'border-color': 'var(--button-primary-color)',
        backgroundColor: 'var(--button-primary-color)',
        width: '20px',
        height: '20px',
      },
    },
  },
  'app.onboarding.common.checkbox.text': {
    config: {
      element: 'label',
      fontSize: '14px',
      color: 'var(--primary-dark-color)',
    },
    schema: {
      default: {
        cursor: 'pointer',
      },
    },
  },
  'app.onboarding.common.checkbox.checkedIcon': {
    config: {
      src: 'check',
    },
    schema: {
      default: {
        background: 'white',
      },
    },
  },

  'app.onboarding.common.footer.button': {
    _extends: ['app.common.button', 'app.common.link.button'],
    schema: {
      default: {
        'padding-left': '2.78vw',
        'padding-right': '2.78vw',
        'min-width': '6.5vw',
      },
    },
  },
  'app.onboarding.common.footer.button.back': {
    _extends: [
      'app.onboarding.common.footer.button',
      'app.common.button.secondary',
    ],
    config: {
      to: '../intro',
      content: [
        [
          'TranslatedText',
          'app.onboarding.common.footer.button.back.translatedText',
        ],
      ],
    },
  },
  'app.onboarding.common.footer.button.back.translatedText': {
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.onboarding.common.button.back.translatedText',
        fallback: 'Back',
      },
    },
  },
  'app.onboarding.common.footer.smartButton.next': {
    components: {
      button: 'app.onboarding.common.footer.smartButton.next.button',
    },
  },
  'app.onboarding.common.footer.smartButton.next.button': {
    _extends: [
      'app.onboarding.common.footer.button',
      'app.common.button.primary',
    ],
    config: {
      content: [
        [
          'TranslatedText',
          'app.onboarding.common.footer.smartButton.next.translatedText',
        ],
      ],
    },
    schema: {
      default: {
        width: 'auto',
      },
    },
  },
  'app.onboarding.common.footer.smartButton.next.translatedText': {
    config: {
      fontSize: '1rem',
      i18n: {
        id: 'i18n.onboarding.common.footer.smartButton.next.translatedText',
        fallback: 'Next',
      },
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox': {
    components: {
      input: 'app.onboarding.common.InputWithToggleCheckbox.input',
      checkbox: 'app.onboarding.common.InputWithToggleCheckbox.checkbox',
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox.input': {
    _extends: 'app.common.field',
    components: {
      input: 'app.onboarding.common.InputWithToggleCheckbox.input.input',
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox.input.input': {
    _extends: 'app.common.field.input',
    schema: {
      readonly: {
        opacity: 0.4,
      },
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox.checkbox': {
    components: {
      checkbox: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.input',
      label: 'app.onboarding.common.InputWithToggleCheckbox.checkbox.label',
    },
    schema: {
      default: {
        display: 'flex',
        'align-items': 'flex-start',
        'margin-top': '16px',
      },
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox.checkbox.input': {
    schema: {
      default: {
        'margin-top': '4px',
        marginRight: '8px',
        cursor: 'pointer',

        '& > input': {
          position: 'inherit',
          visibility: 'visible',
        },
      },
    },
  },
  'app.onboarding.common.InputWithToggleCheckbox.checkbox.label': {
    config: {
      as: 'label',
    },
    schema: {
      default: {
        cursor: 'pointer',
      },
    },
  },
  'app.onboarding.common.form.footer': {
    schema: {
      default: {
        [`@media (min-width: ${DESKTOP_THRESHOLD}px)`]: {
          padding: '3.7vh 0',
          display: 'flex',
          'justify-content': 'space-between',
          'margin-top': '1.68vh',
          margin: '1.68vh 7.64vw 0 7.64vw',
          width: 'initial',
          borderTop: '1px solid #E4E4E4',
        },

        '@media (max-width: 992px)': {
          display: 'grid',
          'grid-row-gap': '16px',
          'padding-top': '24px',
          'border-top': '1px solid var(--border-color)',
          width: '100%',
        },
      },
    },
  },
  'app.onboarding.common.form': {
    schema: {
      default: {
        '@media (max-width: 992px)': {
          width: '100%',
        },
      },
    },
  },
  'app.onboarding.common.message': {
    schema: {
      default: {
        'grid-column': '1 / -1',
        fontSize: '14px',
        fontStyle: 'italic',
      },
    },
  },
};

export default ONBOARDING_COMMON;
