// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';

export const useValidateMultipleStatusElements = (...loadingElements) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadComplete =
      loadingElements.length && loadingElements.every((param) => !param);
    window.globalLoading(!loadComplete);
    if (loadComplete && loading) {
      setLoading(false);
      window.globalLoading(false);
    }
  }, [loadingElements, loading]);

  return !loading;
};
