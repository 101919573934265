// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import zappy from "@zappy-ride/library.react.components";
import { useCalculationSelectors } from "../../../../selectors/calculations";

export const OutputVehicles = ({ design }: { design: string }) => {
  const { schema } = zappy.useDesign(design);
  const [{ data, status }] = useCalculationSelectors("vehicleComparisonTable");

  if (status.isLoading) return "Loading";

  if (status.missingRequiredInformation)
    return "Missing required info for calcutaions";

  return (
    <zappy.FactoryComponent schema={schema} data-design={design}>
      <zappy.ContentContainer design={design} values={data} key={data?.selectedSetId}/>
    </zappy.FactoryComponent>
  );
};

OutputVehicles.displayName = "OutputVehicles";
